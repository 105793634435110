var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('q-table', {
    staticClass: "s-b-white",
    attrs: {
      "data": _vm.rows,
      "columns": _vm.columns,
      "wrap-cells": "",
      "filter": _vm.nameFilter,
      "flat": "",
      "separator": "cell"
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "sn-table__top-filters"
        }, [_c('q-input', {
          attrs: {
            "dense": "",
            "debounce": "300",
            "placeholder": _vm.trans('placeholder.search')
          },
          model: {
            value: _vm.nameFilter,
            callback: function callback($$v) {
              _vm.nameFilter = $$v;
            },
            expression: "nameFilter"
          }
        }, [_c('q-icon', {
          attrs: {
            "slot": "append",
            "name": "search"
          },
          slot: "append"
        })], 1)], 1), _vm._v(" "), _c('a', {
          staticClass: "s-btn s-btn--w-200 s-btn--thm-green",
          attrs: {
            "href": _vm.uriAddSubjectsForm
          }
        }, [_c('span', {
          staticClass: "s-str-ico s-str-ico--wider s-str-ico--thm-plus"
        }, [_vm._v("\n          " + _vm._s(_vm.trans('button.add_subjects')) + "\n        ")])])];
      }
    }, {
      key: "body",
      fn: function fn(props) {
        return [_c('q-tr', {
          attrs: {
            "props": props
          }
        }, [_c('q-td', {
          staticClass: "s-to-center",
          style: props.cols[0].style
        }, [_vm._v("\n          " + _vm._s(props.row.educationDisciplineId) + "\n        ")]), _vm._v(" "), _vm.editDisciplineRowId !== props.row.id ? [_vm._l(props.cols, function (col, i) {
          return [!['id', 'educationDisciplineId', '__index', 'actions'].includes(col.field) ? _c('q-td', {
            key: i,
            class: "s-to-".concat(col.align),
            style: col.style
          }, [_vm._v("\n              " + _vm._s(col.value) + "\n            ")]) : _vm._e()];
        }), _vm._v(" "), _c('q-td', {
          staticClass: "s-to-center",
          style: props.cols[props.cols.length - 1].style
        }, [_c('div', {
          staticClass: "s-group s-group--to-center"
        }, [_c('q-btn', {
          attrs: {
            "color": "white",
            "text-color": "black",
            "icon": "pencil",
            "squire": ""
          },
          on: {
            "click": function click($event) {
              return _vm.onEditDiscipline(props.row);
            }
          }
        }, [_c('q-tooltip', [_vm._v("\n                  " + _vm._s(_vm.trans('button.edit')) + "\n                ")])], 1), _vm._v(" "), _c('q-btn', {
          attrs: {
            "color": "white",
            "text-color": "black",
            "icon": "trash",
            "squire": ""
          },
          on: {
            "click": function click($event) {
              return _vm.onDeleteDisciplineOpenModal(props.row.id);
            }
          }
        }, [_c('q-tooltip', [_vm._v("\n                  " + _vm._s(_vm.trans('button.remove')) + "\n                ")])], 1)], 1)])] : [_c('q-td', {
          staticClass: "s-to-center",
          attrs: {
            "colspan": "2"
          }
        }, [_c('v-wrapper-q-select', {
          ref: "subjectSelect",
          attrs: {
            "options": _vm.refs.disciplines,
            "input-debounce": "500",
            "use-options-from-prop": "",
            "add-default-option": _vm.editDisciplineOption,
            "extra-binds": {
              placeholder: _vm.trans('placeholder.choose'),
              hideBottomSpace: true
            },
            "custom-filter-fn": _vm.requestSearchDisciplines
          },
          on: {
            "input-value-change": function inputValueChange($event) {
              _vm.disciplineSelectInputValue = $event;
            },
            "input-full-option": function inputFullOption($event) {
              _vm.editDisciplineOption = $event;
            },
            "virtual-scroll": _vm.onSubScroll
          },
          model: {
            value: _vm.editDisciplineId,
            callback: function callback($$v) {
              _vm.editDisciplineId = $$v;
            },
            expression: "editDisciplineId"
          }
        })], 1), _vm._v(" "), _c('q-td', {
          staticClass: "s-to-center"
        }, [_c('div', {
          staticClass: "s-group s-group--to-center"
        }, [_c('q-btn', {
          attrs: {
            "color": "white",
            "text-color": "black",
            "icon": "check",
            "squire": ""
          },
          on: {
            "click": _vm.onUpdateDisciplineOpenModal
          }
        }, [_c('q-tooltip', [_vm._v("\n                  " + _vm._s(_vm.trans('button.approve')) + "\n                ")])], 1), _vm._v(" "), _c('q-btn', {
          attrs: {
            "color": "white",
            "text-color": "black",
            "icon": "close",
            "squire": ""
          },
          on: {
            "click": _vm.onCancelUpdateDiscipline
          }
        }, [_c('q-tooltip', [_vm._v("\n                  " + _vm._s(_vm.trans('button.cancel')) + "\n                ")])], 1)], 1)])]], 2)];
      }
    }, {
      key: "no-data",
      fn: function fn() {
        return [_c('v-no-results', {
          staticClass: "s-w-100",
          attrs: {
            "image": "search",
            "title": _vm.trans('warning_text.org_doesnt_have_disciplines')
          }
        })];
      },
      proxy: true
    }])
  }), _vm._v(" "), _c('v-confirm-dialog-new', {
    attrs: {
      "active": _vm.isModalOpened,
      "confirm": _vm.onConfirmModal,
      "size": "480",
      "persistent": "",
      "in-body": ""
    },
    on: {
      "modal-close": _vm.onModalClose
    }
  }, [!_vm.deleteDisciplineId ? _c('v-form-inform', {
    attrs: {
      "message": _vm.trans('notice.org_discipline_update_warning')
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }