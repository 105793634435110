var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('button', {
    staticClass: "s-btn-table s-btn--ico",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.show();
      }
    }
  }, [_c('span', {
    staticClass: "s-ico s-ico--thm-eye"
  })]), _vm._v(" "), _c('image-viewer', {
    ref: "viewer"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }