var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "exam-questions__items mt-10"
  }, [_c('q-form', {
    staticClass: "exam-questions__item",
    on: {
      "submit": _vm.questionAddForm
    }
  }, [_c('div', {
    staticClass: "exam-questions__header"
  }, [_c('div', [_c('b', [_vm._v(_vm._s(_vm.trans('test.question_new')))])]), _vm._v(" "), _c('div', [_c('q-btn', {
    attrs: {
      "color": "orange",
      "icon": "close",
      "squire": ""
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.cancelQuestion();
      }
    }
  }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('label.cancel_question')))])], 1), _vm._v(" "), _c('q-btn', {
    attrs: {
      "type": "submit",
      "color": "green",
      "icon": "check",
      "loading": _vm.disableSave,
      "squire": ""
    }
  }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('label.save_question')))])], 1)], 1)]), _vm._v(" "), _c('div', {
    staticClass: "exam-questions__line"
  }, [_c('h5', {
    staticClass: "s-title"
  }, [_vm._v(_vm._s(_vm.trans('test.question_text')))]), _vm._v(" "), _c('hr', {
    staticClass: "s-w-100 s-hr"
  })]), _vm._v(" "), _c('ckeditor', {
    attrs: {
      "editor": _vm.editor,
      "config": _vm.editorConfig
    },
    on: {
      "ready": _vm.onEditorReady
    },
    model: {
      value: _vm.dataQuestion.question,
      callback: function callback($$v) {
        _vm.$set(_vm.dataQuestion, "question", $$v);
      },
      expression: "dataQuestion.question"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "exam-questions__line"
  }, [_c('h5', {
    staticClass: "s-title"
  }, [_vm._v(_vm._s(_vm.trans('test.answer_list')))]), _vm._v(" "), _c('hr', {
    staticClass: "s-w-100 s-hr"
  })]), _vm._v(" "), _c('div', {
    staticClass: "exam-questions__answers-container container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm col-sm-1"
  }, [_vm._v("#")]), _vm._v(" "), _c('div', {
    staticClass: "col-sm col-sm-5"
  }, [_vm._v(_vm._s(_vm.trans('label.answer')))]), _vm._v(" "), _vm.group.type !== 'weight' ? _c('div', {
    staticClass: "col-sm col-sm-2"
  }, [_vm._v(_vm._s(_vm.trans('test.answer_correct')))]) : _vm._e(), _vm._v(" "), _vm.group.type === 'weight' ? _c('div', {
    staticClass: "col-sm col-sm-2"
  }, [_vm._v(_vm._s(_vm.trans('fields.weight')))]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "col-sm col-sm-2"
  }, [_vm._v(_vm._s(_vm.trans('table.action')))])]), _vm._v(" "), _vm._l(_vm.dataQuestion.answers, function (answer, answer_key) {
    return _c('div', {
      key: answer_key,
      staticClass: "row exam-questions__answer"
    }, [_c('div', {
      staticClass: "col-sm col-sm-1"
    }, [_vm._v(_vm._s(answer_key + 1))]), _vm._v(" "), _c('div', {
      staticClass: "col-sm col-sm-5"
    }, [_c('ckeditor', {
      attrs: {
        "editor": _vm.editor,
        "config": _vm.editorConfig
      },
      on: {
        "ready": _vm.onEditorReady
      },
      model: {
        value: answer.answer,
        callback: function callback($$v) {
          _vm.$set(answer, "answer", $$v);
        },
        expression: "answer.answer"
      }
    })], 1), _vm._v(" "), _vm.group.type !== 'weight' ? _c('div', {
      staticClass: "col-sm col-sm-2"
    }, [_c('q-toggle', {
      attrs: {
        "checked-icon": "check",
        "color": "green",
        "unchecked-icon": "close",
        "false-value": 0,
        "true-value": 1,
        "disable": answer.disable
      },
      on: {
        "input": function input($event) {
          return _vm.changeAnswerCorrect(answer_key);
        }
      },
      model: {
        value: answer.correct,
        callback: function callback($$v) {
          _vm.$set(answer, "correct", $$v);
        },
        expression: "answer.correct"
      }
    }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('test.answer_correct')))])], 1)], 1) : _vm._e(), _vm._v(" "), _vm.group.type === 'weight' ? _c('div', {
      staticClass: "col-sm col-sm-2"
    }, [_c('q-input', {
      attrs: {
        "type": "number",
        "min": 0,
        "max": 4
      },
      model: {
        value: answer.weight,
        callback: function callback($$v) {
          _vm.$set(answer, "weight", $$v);
        },
        expression: "answer.weight"
      }
    })], 1) : _vm._e(), _vm._v(" "), _c('div', {
      staticClass: "col-sm col-sm-2"
    }, [_c('q-btn', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.largerTwo(),
        expression: "largerTwo()"
      }],
      attrs: {
        "color": "red",
        "icon": "trash",
        "squire": ""
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.deleteNewAnswer(answer_key);
        }
      }
    }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('label.delete_answer')))])], 1)], 1)]);
  }), _vm._v(" "), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm col-sm-1"
  }), _vm._v(" "), _c('div', {
    staticClass: "col-sm col-sm-5"
  }), _vm._v(" "), _c('div', {
    staticClass: "col-sm col-sm-2"
  }), _vm._v(" "), _c('div', {
    staticClass: "col-sm col-sm-2"
  }), _vm._v(" "), _c('div', {
    staticClass: "col-sm col-sm-2"
  }, [_c('q-btn', {
    attrs: {
      "color": "blue",
      "icon": "plus",
      "squire": ""
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.newAnswer();
      }
    }
  }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('label.create_answer')))])], 1)], 1)])], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }