var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "diploma-form"
  }, [_c('v-form-inform', {
    attrs: {
      "message": _vm.trans('warning_text.modal_inform')
    }
  }), _vm._v(" "), _c('q-form', [_c('q-select', {
    class: {
      'sn--cursor-wait': _vm.loading,
      's-c-placeholder': _vm.evaluationType === null || _vm.evaluationType.value === null
    },
    attrs: {
      "filled": "",
      "bottom-slots": "",
      "emit-value": "",
      "map-options": "",
      "options": _vm.options.evaluationTypes,
      "disable": _vm.loading,
      "loading": _vm.loading,
      "label": _vm.trans('main.type_of_evaluation'),
      "required": ""
    },
    model: {
      value: _vm.evaluationType,
      callback: function callback($$v) {
        _vm.evaluationType = $$v;
      },
      expression: "evaluationType"
    }
  }), _vm._v(" "), _c('q-select', {
    staticClass: "sn-select__input--noclear",
    class: _vm.loading ? 'sn--cursor-wait' : '',
    attrs: {
      "filled": "",
      "bottom-slots": "",
      "options": _vm.options.qualifications,
      "label": _vm.trans('curriculum.qualification'),
      "hide-selected": "",
      "fill-input": "",
      "placeholder": _vm.trans('select.placeholder'),
      "use-input": "",
      "emit-value": "",
      "map-options": "",
      "input-debounce": "0",
      "loading": _vm.loading,
      "disable": _vm.loading
    },
    on: {
      "filter": _vm.filterQualifications,
      "input": _vm.requestGetGroups
    },
    model: {
      value: _vm.qualification,
      callback: function callback($$v) {
        _vm.qualification = $$v;
      },
      expression: "qualification"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "mb-10"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 col-sm-6 s-doc-flow-form__organizations mb-10"
  }, [_c('div', {
    staticClass: "s-form__label"
  }, [_vm._v("\n            " + _vm._s(_vm.trans('label.select_groups')) + ": "), _c('i', [_vm._v("*")])]), _vm._v(" "), _c('div', {
    staticClass: "s-doc-flow-form__organizations-inner s-w-100"
  }, [_c('div', {
    staticClass: "s-doc-flow-form__organizations-list pt-10 pb-10",
    class: _vm.loadingGroups ? 's-to-center' : '',
    staticStyle: {
      "margin-bottom": "0",
      "height": "33rem"
    }
  }, [_vm.loadingGroups ? _c('q-spinner-gears', {
    staticClass: "pt-30 pt-30 sn--cursor-wait",
    attrs: {
      "color": "blue",
      "size": "25rem"
    }
  }) : _vm.groups.length === 0 || _vm.qualification === null ? _c('span', {
    staticClass: "d-block mt-20 s-to-center s-c-grey s-medium",
    staticStyle: {
      "padding-right": "3rem",
      "padding-left": "3rem"
    }
  }, [_vm._v("\n                " + _vm._s(_vm.groupsListLabel()) + "\n              ")]) : _vm._l(_vm.groups, function (group, key) {
    return _c('q-input', {
      key: key,
      staticClass: "mb-5",
      staticStyle: {
        "padding-right": "2rem",
        "padding-left": "2rem"
      },
      attrs: {
        "value": group.label,
        "readonly": ""
      },
      scopedSlots: _vm._u([{
        key: "after",
        fn: function fn() {
          return [_c('q-btn', {
            attrs: {
              "squire": "",
              "color": "orange",
              "icon": "plus",
              "loading": _vm.loading,
              "disable": group.disable
            },
            on: {
              "click": function click($event) {
                return _vm.addGroup(key);
              }
            }
          })];
        },
        proxy: true
      }], null, true)
    });
  })], 2)])]), _vm._v(" "), _c('div', {
    staticClass: "col-12 col-sm-6 s-doc-flow-form__organizations"
  }, [_c('div', {
    staticClass: "s-form__label"
  }, [_vm._v("\n            " + _vm._s(_vm.trans('label.selected_groups')) + ":\n          ")]), _vm._v(" "), _c('div', {
    staticClass: "s-doc-flow-form__organizations-inner s-w-100"
  }, [_c('div', {
    staticClass: "s-doc-flow-form__organizations-list pt-10 pb-10",
    style: {
      marginBottom: 0,
      height: '33rem',
      maxHeight: '40rem'
    }
  }, [_vm.groupsSelected.length === 0 ? _c('span', {
    staticClass: "d-block mt-20 s-to-center s-c-grey s-medium",
    staticStyle: {
      "padding-right": "3rem",
      "padding-left": "3rem"
    }
  }, [_vm._v("\n                " + _vm._s(_vm.trans('placeholder.please_select_groups')) + "\n              ")]) : _c('div', _vm._l(_vm.groupsSelected, function (group, key) {
    return _c('q-input', {
      key: key,
      staticClass: "mb-5",
      staticStyle: {
        "padding-right": "2rem",
        "padding-left": "2rem"
      },
      attrs: {
        "value": group.label,
        "readonly": ""
      },
      scopedSlots: _vm._u([{
        key: "before",
        fn: function fn() {
          return [_c('q-btn', {
            attrs: {
              "squire": "",
              "color": "red",
              "icon": "minus",
              "loading": _vm.loading
            },
            on: {
              "click": function click($event) {
                return _vm.deleteGroup(key);
              }
            }
          })];
        },
        proxy: true
      }], null, true)
    });
  }), 1)])])])])]), _vm._v(" "), _c('div', {
    staticClass: "row-sort-end"
  }, [_c('q-btn', {
    staticClass: "s-btn--w-200",
    attrs: {
      "label": _vm.trans('button.save'),
      "color": "green",
      "icon": "mark",
      "loading": _vm.loading,
      "disable": !_vm.validate
    },
    on: {
      "click": _vm.requestAction
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }