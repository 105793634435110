import {
  QBtn,
  QCheckbox,
  QDate,
  QForm,
  QIcon,
  QInput,
  QPopupProxy,
  QSelect,
  QSlideTransition,
} from '@quasar/components';
import { Loading } from '@quasar/plugins';
import { requestWrapper, } from '@vjs/helpers';
import VFormInform from '@vjs/components/VFormInform/VFormInform';
import Validation from '@vjs/mixins/Validation';
import _ from 'lodash';

export default {
  name: 'VStatementAdmissionForm',

  mixins: [Validation],

  components: {
    QForm,
    QBtn,
    QSelect,
    QInput,
    VFormInform,
    QCheckbox,
    QSlideTransition,
    QIcon,
    QPopupProxy,
    QDate,
  },
  props: {
    uriAction: {
      type: String,
      required: true,
    },
    uriGetOptions: {
      type: String,
      required: true,
    },
    uriGetDates: {
      type: String,
      required: true,
    },
    uriGetExistingData: {
      type: String,
      required: false,
    },
    uriGetQualifications: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        profession_id: null,
        is_creative: 0,
        handwriteDate: false,
        edu_form: null,
        edu_lang_id: null,
        edu_level_id: null,
        prepare_type: null,
        date_type: null,
        date_start: null,
        date_end: null,
        qualification_id: null,
        places_count: '',
      },
      options: {
        professions: [],
        professionsFiltered: [],
        creativeProfessions: [],
        creativeProfessionsFiltered: [],
        eduForms: [],
        eduLangs: [],
        eduLevels: [],
        prepareTypes: [],
        dates: [],
        qualifications: [],
      },
      qLoading: Loading,
    };
  },

  computed: {
    validate() {
      return this.form.edu_form !== null
        && this.form.edu_lang_id !== null
        && this.form.prepare_type !== null
        && this.form.profession_id !== null
        && this.form.qualification_id !== null
        && this.form.places_count !== ''
        && (
          (this.form.handwriteDate === false && this.form.date_type !== null)
          || (this.form.handwriteDate === true && this.form.date_start !== null && this.form.date_end !== null)
        );
    },
  },

  async mounted() {
    await this.$trans.add([
      'fields',
      'label',
      'button',
      'placeholder',
      'table',
      'warning_text',
      'select',
      'curriculum',
      'main',
      'notice',
      'statement',
    ]);

    this.qLoading.show();
    await this.requestGetOptions();
    if (this.uriGetExistingData) {
      await this.requestGetExistingData();
      await this.requestGetQualifications(null, true);
    }
    this.qLoading.hide();
  },
  methods: {
    // Метод при смене checkbox-a ручного ввода даты для сброса выбранных полей
    async handwriteDateCheck() {
      this.form.date_type = null;
      this.form.date_start = null;
      this.form.date_end = null;
    },
    // запрос на получение опций для всех селектов
    async requestGetOptions() {
      const res = await requestWrapper.call(this, {
        url: this.uriGetOptions,
      });

      if (!res.error) {
        this.options = res.data;
        this.options.professionsFiltered = this.options.professions;
        this.options.creativeProfessionsFiltered = this.options.creativeProfessions;
      }
    },
    // запрос на получение опций для квалификаций выбранной специальности
    async requestGetQualifications(e, withoutClear = false) {
      this.qLoading.show({ message: this.trans('label.wait_for_load_data') });
      if (!withoutClear) {
        this.form.qualification_id = null;
        this.options.qualifications = [];
      }
      const res = await requestWrapper.call(this, {
        url: this.uriGetQualifications,
        params: {
          profession_id: this.form.profession_id,
        },
      });

      if (!res.error) {
        this.options.qualifications = res.data;
        this.qLoading.hide();
      }
      if (res.error && res.data.errors) {
        this.qLoading.hide();
        this.$notify({
          text: _.first(_.flatten(res.data.errors[_.first(Object.keys(res.data.errors))])) ?? this.trans('notice.error_on_server'),
          type: 'error',
        });
      }
    },
    async requestGetExistingData() {
      const res = await requestWrapper.call(this, {
        url: this.uriGetExistingData,
      });

      if (!res.error) {
        this.form = res.data;
        await this.requestGetDates();
      }
    },
    filterProfessions(val, update) {
      let options = 'professions';
      let optionsFiltered = 'professionsFiltered';
      if (this.form.is_creative) {
        options = 'creativeProfessions';
        optionsFiltered = 'creativeProfessionsFiltered';
      }
      if (val === '') {
        update(() => {
          this.options[options] = this.options[optionsFiltered];
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.options[options] = this.options[optionsFiltered].filter(item => _.lowerCase(item.label)
          .indexOf(needle) > -1);
      });
    },
    // запрос на получение опций дат
    async requestGetDates(withClear = false, withProfessionClear = false) {
      this.qLoading.show({ message: this.trans('label.wait_for_load_data') });
      if (withClear) {
        this.form.date_type = null;
      }
      if (withProfessionClear) {
        this.form.profession_id = null;
      }
      const res = await requestWrapper.call(this, {
        url: this.uriGetDates,
        params: {
          qualification_id: this.form.qualification_id,
          is_creative: this.form.is_creative,
        },
      });
      if (!res.error) {
        this.options.dates = res.data;
        this.form.prepare_type = res.prepare_type;
      }
      this.qLoading.hide();
    },
    // запрос на отправку данных для сохранения
    async requestAction() {
      this.qLoading.show();
      const res = await requestWrapper.call(this, {
        method: this.uriGetExistingData ? 'patch' : 'post',
        url: this.uriAction,
        data: this.form,
      });

      if (res.error) {
        this.qLoading.hide();
        if (res.data.errors !== undefined) {
          this.$notify({
            text: _.first(_.flatten(res.data.errors[_.first(Object.keys(res.data.errors))])) ?? this.trans('notice.error_on_server'),
            type: 'error',
          });
        } else if (res.data.message !== undefined) {
          this.$notify({
            text: res.data.message,
            type: 'error',
          });
        }
      }
    },
  },
};
