import {
  QForm,
  QBtn,
  QSelect,
  QIcon,
  QInput,
  QTable,
  QTh,
  QTr,
  QTd,
  QTooltip,
} from '@quasar/components';
import { Loading } from '@quasar/plugins';
import {
  requestWrapper,
} from '@vjs/helpers';

export default {
  name: 'VAdmissionResultProfessionsList',
  components: {
    QForm,
    QBtn,
    QSelect,
    QInput,
    QTable,
    QTh,
    QTr,
    QTd,
    QIcon,
    QTooltip,
  },
  props: {
    uriGetData: {
      type: String,
      required: true,
    },
    tableName: {
      type: String,
      required: false,
    },
    backLink: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      table: {
        rows: [],
        columns: [],
        rowsFiltered: [],
      },
      pagination: {
        rowsPerPage: 10,
      },
      filter: '',
      loading: false,
      noResults: false,
      qLoading: Loading,
      yearFilter: null,
      rowIndex: 1,
    };
  },

  async mounted() {
    await this.$trans.add([
      'label',
      'button',
      'placeholder',
      'warning_text',
      'table',
      'fields',
      'statement',
    ]);
    await this.requestGetTable();
  },
  methods: {
    search(val) {
      if (val) {
        this.table.rowsFiltered = this.table.rows.filter(ele => ele.name.toLowerCase().includes(val.toLowerCase()));
      } else {
        this.table.rowsFiltered = this.table.rows;
      }
    },
    async requestGetTable() {
      this.loading = true;
      this.qLoading.show({ message: this.trans('label.wait_for_load_data') });
      const res = await requestWrapper.call(this, {
        url: this.uriGetData,
      });
      if (!res.error) {
        if (res.rows) {
          this.table.rows = res.rows;
          this.table.columns = res.columns;
          this.table.rowsFiltered = res.rows;
          this.noResults = res.rows.length === 0;
        }
      }
      this.loading = false;
      this.qLoading.hide();
    },
  },
};
