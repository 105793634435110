import ImageViewer from '../ImageViewer';

export default {
  components: {
    ImageViewer,
  },

  props: {
    uriImage: {
      type: String,
      required: true,
    },
  },
  computed: {
    genImages() {
      const sourceImages = [];
      sourceImages.push({
        thumbnail: this.uriImage,
        source: this.uriImage,
      });
      return sourceImages;
    },
  },

  methods: {
    show() {
      this.$refs.viewer.show(this.genImages);
    },
  },
};
