import _ from 'lodash';

export default {
  SET_VM: (state, payload) => {
    state.vm = payload;
  },
  SET_JOURNAL_ID: (state, payload) => {
    state.journalId = payload;
  },
  SET_API_CONFIG: (state, payload) => {
    state.apiConfig = payload;
  },
  SET_COLORS_ARR: (state, payload) => {
    state.colorsArr = payload;
  },
  TOGGLE_SHOW_RETIRED_STUDENTS: (state) => {
    state.showRetiredStudents = !state.showRetiredStudents;
    localStorage.setItem('hideRetired', state.showRetiredStudents ? 1 : 2);
  },
  TOGGLE_IS_REMARKS_TABLE_ACTIVE: (state) => {
    state.isRemarksTableActive = !state.isRemarksTableActive;
  },
  SET_CURRENT_FILTER: (state, payload) => {
    state.currentFilter = payload;
  },
  ADD_NEW_REMARK: (state, payload) => {
    state.remarks.push(payload);
  },
  DELETE_REMARK: (state, payload) => {
    state.remarks = state.remarks.filter(item => item.id !== payload);
  },
  CLEAR_GENERATE_MONTHS: (state) => {
    state.refs.generateMonths = [];
  },
  VIEW_REMARK: (state, payload) => {
    const index = _.findIndex(state.remarks, item => item.id === payload);
    state.remarks[index].isViewed = true;
  },
  UPDATE_DAY_COL: (state, day) => {
    const index = _.findIndex(state.journalDays, item => item.id === day.id);
    state.vm.$set(state.journalDays, index, day);
  },
  DELETE_DAY_COL: (state, dayId) => {
    const index = _.findIndex(state.journalDays, item => item.id === dayId);
    state.vm.$delete(state.journalDays, index);
  },
  RECALCULATE_INFO_TABLE_HOURS: (state) => {
    const hours = _.flatten(_.map(state.journalDays, item => Number(item.hours)));
    state.vm.$set(state.infoTable.hours, 'value', _.sum(hours).toFixed(1));
  },
  DELETE_POINT_FROM_DAY: (state, { dayId, contingentId }) => {
    const index = _.findIndex(state.journalDays, item => item.id === dayId);
    state.vm.$delete(state.journalDays[index].points, contingentId);
  },
};
