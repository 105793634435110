<template>
  <div>
    <a
      v-if="statement.soc_status_id !== 1 && approveSocStatusActive && statement.is_check !== 0"
      class="s-btn s-btn--thm-orange s-btn--w-150"
      data-action="confirm"
      data-method="POST"
      :href="approveSocStatusUrl"
    >
      {{ trans('button.approve_soc_status') }}
    </a>

    <template v-if="!statement.is_check">
      <button
        v-if="signXml === null"
        class="s-btn s-btn--thm-blue s-btn--w-150"
        :class="signXml === null ? 's-btn--thm-blue' : 's-btn--thm-green'"
        @click="sign()"
      >
        {{
          signXml === null
            ? trans('button.add_sign_ncalayer')
            : trans('button.add_signed_ncalayer')
        }}
      </button>
      <label
        v-else
        class="s-btn"
      >
        {{ trans('button.add_signed_ncalayer') }}
      </label>

      <template v-if="!isNedb">
        <template v-if="signXml !== null">
          <button
            v-if="statement.soc_status_id !== 1 && approveSocStatusActive"
            v-s-modal="{
              id: 'v-modal-statement-attestation-average-point',
              component: 'v-modal-statement-attestation-average-point',
              notPersistent: true,
              binds: {
                uriSubmit: modalApproveWithSocStatusCandidate,
                signXml: signXml
              }}"
            class="s-btn s-btn--thm-orange s-btn--w-150"
          >
            {{ trans('button.approve_with_soc_status') }}
          </button>
          <button
            v-s-modal="{
              id: 'v-modal-statement-attestation-average-point',
              component: 'v-modal-statement-attestation-average-point',
              notPersistent: true,
              binds: {
                uriSubmit: modalApproveCandidate,
                signXml: signXml
              }}"
            class="s-btn s-btn--thm-green s-btn--w-150"
          >
            {{ trans('button.approve') }}
          </button>
        </template>
        <template v-else>
          <button
            v-if="statement.soc_status_id !== 1 && approveSocStatusActive"
            class="s-btn s-btn--thm-orange s-btn--w-150"
            disabled="disabled"
          >
            {{ trans('button.approve_with_soc_status') }}
          </button>
          <button
            class="s-btn s-btn--thm-green s-btn--w-150"
            disabled="disabled"
          >
            {{ trans('button.approve') }}
          </button>
        </template>
      </template>
      <template v-else>
        <button
          v-if="statement.soc_status_id !== 1 && approveSocStatusActive"
          class="s-btn s-btn--thm-orange s-btn--w-150"
          data-toggle="modal"
          :disabled="signXml === null"
          :data-target="'#modal-approve-with-soc-status-candidate-'+statement.id"
        >
          {{ trans('button.approve_with_soc_status') }}
        </button>
        <button
          v-s-modal="{
            id: 'v-modal-statement-attestation-average-point',
            component: 'v-modal-statement-attestation-average-point',
            notPersistent: true,
            binds: {
              uriSubmit: modalApproveCandidate,
              signXml: signXml
            }}"
          class="s-btn s-btn--thm-green s-btn--w-150"
          :disabled="signXml === null"
        >
          {{ trans('button.approve') }}
        </button>
      </template>
      <button
        class="s-btn s-btn--thm-red s-btn--w-150"
        data-toggle="modal"
        :disabled="signXml === null"
        :data-target="'#modal-confirm-candidate-'+statement.id"
      >
        {{ trans('button.decline') }}
      </button>
    </template>

    <a
      v-if="setTargetTrainingActive"
      data-action="confirm"
      data-method="POST"
      :data-inform-message="trans('notice.statement_target_training_warning')"
      class="s-btn s-btn--thm-blue s-btn--w-150"
      :href="setTargetTrainingUrl"
    >
      {{ trans('button.set_target_training') }}
    </a>

    <a
      v-if="statementReceipt && statement.is_check"
      class="s-btn s-btn--thm-green s-btn--w-150"
      target="_blank"
      :href="statementReceiptUrl"
    >
      {{ trans('statement.download_receipt') }}
    </a>
    <v-statement-sign-modals
      :statement="statement"
      :set-modal-confirm-candidate-rework="setModalConfirmCandidateRework"
      :statement-disapprove="statementDisapprove "
      :modal-confirm-candidate="modalConfirmCandidate "
      :modal-approve-with-soc-status-candidate="approveWithStatusUrl"
      :modal-approve-candidate="approveUrl"
      :sign-xml="signXml"
      :csrf-token="csrfToken"
    />
  </div>
</template>

<script>
import VStatementSignModals from './VStatementSignModals';

export default {
  name: 'VStatementSignButtons',
  components: { VStatementSignModals },
  props: {
    isNedb: {
      type: String,
      required: true,
      default: '',
    },
    status: {
      type: Number,
      required: true,
    },
    csrfToken: {
      type: String,
      default: '',
    },
    statementReceipt: {
      type: Object,
      default: null,
      required: false,
    },
    statement: {
      type: Object,
      required: true,
    },
    statementReceiptUrl: {
      type: String,
      default: '',
      required: false,
    },
    approveUrl: {
      type: String,
      required: true,
    },
    approveWithStatusUrl: {
      type: String,
      default: '',
      required: false,
    },
    approveSocStatusActive: Boolean,
    approveSocStatusUrl: {
      type: String,
      default: '',
      required: false,
    },
    setAveragePointAction: {
      type: String,
      required: true,
    },
    setModalConfirmCandidateRework: {
      type: String,
      required: true,
    },
    statementDisapprove: {
      type: String,
      required: true,
    },
    modalConfirmCandidate: {
      type: String,
      required: true,
    },
    modalApproveWithSocStatusCandidate: {
      type: String,
      required: true,
    },
    modalApproveCandidate: {
      type: String,
      required: true,
    },
    uriCheckSign: {
      type: String,
      required: true,
    },
    setTargetTrainingActive: {
      type: Boolean,
      required: true,
    },
    setTargetTrainingUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      signXml: null,
      loading: false,
    };
  },
  async mounted() {
    this.signXml = this.statement.sign_xml_accept;
    await this.$trans.add(['statement', 'label', 'button', 'notice']);
  },
  methods: {
    async sign() {
      this.$emit('loading', true);
      const webSocket = new WebSocket('wss://127.0.0.1:13579/');
      let callback = null;
      const xmlToSign = '<status>Sign</status>';
      webSocket.onopen = (event) => {
        const vm = this;
        callback = (response) => {
          $.ajax({
            url: this.uriCheckSign,
            method: 'POST',
            dataType: 'JSON',
            data: {
              signXml: response.responseObject,
            },
            success() {
              vm.signXml = response.responseObject;
            },
            error(res) {
              vm.signXml = null;
              if (res.status === 422 || res.status === 423) {
                let errors = [];
                $.each(res.responseJSON.errors, (i, val) => {
                  errors = errors.concat(val);
                });
                show_notice(errors, 'error');
              }
            },
          });
          // this.signXml = response.responseObject;
          this.$emit('loading', false);
        };
        webSocket.send(JSON.stringify(
          {
            id: 1,
            module: 'kz.gov.pki.knca.commonUtils',
            method: 'signXml',
            args: ['PKCS12', 'SIGNATURE', xmlToSign, '', ''],
          },
        ));
      };

      webSocket.onclose = (event) => {
        if (event.wasClean) {
          console.log('connection has been closed');
        } else {
          console.log('Connection error');
          // TODO: переделать на нормальные notice
          show_notice(this.trans('notice.ncalayer_not_run'), 'error');
          this.$emit('loading', false);
        }
        console.log(`Code: ${event.code} Reason: ${event.reason}`);
      };

      webSocket.onmessage = (event) => {
        const result = JSON.parse(event.data);
        console.log(event);
        console.log(result);
        if (result != null) {
          if (result?.code === '200') {
            if (typeof callback === 'function') {
              callback(result);
            }
          } else if (result?.code === '500') {
            this.$emit('loading', false);
          }
        }
      };
    },
  },
};
</script>
