var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "exam-questions__item"
  }, [_vm.loading ? _c('q-spinner-gears', {
    staticClass: "align-self-center sn--cursor-wait s-w-100",
    attrs: {
      "color": "blue",
      "size": "20rem"
    }
  }) : _c('q-form', {
    on: {
      "submit": _vm.requestEditTicket
    }
  }, [_c('div', {
    staticClass: "exam-questions__header"
  }, [_c('div', [_vm.ticketEditId !== 0 ? _c('q-input', {
    attrs: {
      "placeholder": _vm.trans('label.ticket_name'),
      "loading": _vm.ticketLoading
    },
    model: {
      value: _vm.dataTicket.name,
      callback: function callback($$v) {
        _vm.$set(_vm.dataTicket, "name", $$v);
      },
      expression: "dataTicket.name"
    }
  }) : _c('div', {
    staticClass: "s-static-rectangle"
  }, [_vm._v("\n          " + _vm._s(_vm.dataTicket.name) + "\n        ")])], 1), _vm._v(" "), _c('div'), _vm._v(" "), _c('div', [_vm.ticketEditId !== 0 ? [_c('q-btn', {
    attrs: {
      "color": "orange",
      "icon": "close",
      "squire": ""
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.cancelEdit($event);
      }
    }
  }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('button.cancel_ticket_edit')))])], 1), _vm._v(" "), _c('q-btn', {
    attrs: {
      "type": "submit",
      "color": "green",
      "icon": "check",
      "loading": _vm.ticketLoading,
      "squire": ""
    }
  }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('button.save_ticket')))])], 1)] : _c('div', [_c('q-btn', {
    attrs: {
      "color": "red",
      "icon": "trash",
      "squire": "",
      "loading": _vm.ticketLoading
    },
    on: {
      "click": function click($event) {
        return _vm.openDestroyModal(_vm.dataTicket.id);
      }
    }
  }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('button.destroy_ticket')))])], 1), _vm._v(" "), _c('q-btn', {
    attrs: {
      "color": "blue",
      "icon": "pencil",
      "squire": "",
      "loading": _vm.ticketLoading
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.changeTicket(_vm.dataTicket.id);
      }
    }
  }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('button.edit_ticket')))])], 1)], 1)], 2)]), _vm._v(" "), _c('div', {
    staticClass: "exam-questions__line"
  }, [_c('h5', {
    staticClass: "s-title"
  }, [_vm._v("\n        " + _vm._s(_vm.trans('label.ticket_body')) + "\n      ")]), _vm._v(" "), _c('hr', {
    staticClass: "s-w-100 s-hr"
  })]), _vm._v(" "), _vm.ticketEditId !== 0 ? _c('ckeditor', {
    attrs: {
      "editor": _vm.editor,
      "config": _vm.editorConfig,
      "disabled": _vm.loading
    },
    on: {
      "ready": _vm.onEditorReady
    },
    model: {
      value: _vm.dataTicket.body,
      callback: function callback($$v) {
        _vm.$set(_vm.dataTicket, "body", $$v);
      },
      expression: "dataTicket.body"
    }
  }) : _c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.dataTicket.body)
    }
  })], 1), _vm._v(" "), _c('v-confirm-dialog', {
    attrs: {
      "modal": _vm.modalConfirmTicketRemove,
      "loading": _vm.loading,
      "decline-color": "blue",
      "submit-color": "green",
      "confirm": _vm.requestDestroyTicket
    },
    on: {
      "modal-close": function modalClose($event) {
        _vm.modalConfirmTicketRemove = $event;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }