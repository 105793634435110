var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.data.length ? _vm._l(_vm.data, function (card, key) {
    return _c('v-card', {
      key: key,
      staticClass: "mb-20",
      attrs: {
        "title": card.title,
        "data": card.data
      }
    });
  }) : _c('v-no-results', {
    attrs: {
      "image": "search",
      "title": _vm.trans('contingent-performance-book.no-results-diploma-title')
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }