var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('q-form', {
    attrs: {
      "autocorrect": "off",
      "autocapitalize": "off",
      "autocomplete": "off",
      "spellcheck": "false"
    },
    on: {
      "submit": _vm.requestGetTable
    }
  }, [_c('div', {
    staticClass: "row align-items-stretch mb-10"
  }, [_c('div', {
    staticClass: "col-12 col-sm-6 col-lg-3"
  }, [_c('q-select', {
    staticClass: "sn-select__input--noclear",
    attrs: {
      "filled": "",
      "map-options": "",
      "emit-value": "",
      "options": _vm.options.isPpz,
      "label": _vm.trans('statement.ppz_filter')
    },
    on: {
      "input": function input($event) {
        return _vm.handlePpzInput(true);
      }
    },
    model: {
      value: _vm.filters.isPpz,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "isPpz", $$v);
      },
      expression: "filters.isPpz"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-12 col-sm-6 col-lg-3"
  }, [_c('q-select', {
    staticClass: "sn-select__input--noclear",
    attrs: {
      "required": "",
      "filled": "",
      "map-options": "",
      "emit-value": "",
      "options": _vm.options.eduLevel,
      "label": _vm.trans('label.edu_level')
    },
    on: {
      "input": function input($event) {
        return _vm.inputGetRefs('eduLevel');
      }
    },
    model: {
      value: _vm.filters.eduLevel,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "eduLevel", $$v);
      },
      expression: "filters.eduLevel"
    }
  })], 1), _vm._v(" "), _c('q-slide-transition', {
    attrs: {
      "duration": 500
    }
  }, [_vm.filters.eduLevel && _vm.options.specialty.length > 0 ? _c('div', {
    staticClass: "col-12 col-sm-6 col-lg-3"
  }, [_c('q-select', {
    staticClass: "sn-select__input--noclear",
    attrs: {
      "required": "",
      "filled": "",
      "map-options": "",
      "emit-value": "",
      "options": _vm.options.specialty,
      "label": _vm.trans('statement.specialty'),
      "popup-content-style": {
        width: _vm.$q.platform.is.desktop ? '1px' : undefined
      }
    },
    on: {
      "input": function input($event) {
        return _vm.inputGetRefs('specialty');
      }
    },
    model: {
      value: _vm.filters.specialty,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "specialty", $$v);
      },
      expression: "filters.specialty"
    }
  })], 1) : _vm._e()]), _vm._v(" "), _c('q-slide-transition', {
    attrs: {
      "duration": 500
    }
  }, [_vm.filters.specialty && _vm.options.qualification.length > 0 ? _c('div', {
    staticClass: "col-12 col-sm-6 col-lg-3"
  }, [_c('q-select', {
    staticClass: "sn-select__input--noclear",
    attrs: {
      "required": "",
      "filled": "",
      "map-options": "",
      "emit-value": "",
      "options": _vm.options.qualification,
      "label": _vm.trans('statement.qualification'),
      "popup-content-style": {
        width: _vm.$q.platform.is.desktop ? '1px' : undefined
      }
    },
    model: {
      value: _vm.filters.qualification,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "qualification", $$v);
      },
      expression: "filters.qualification"
    }
  })], 1) : _vm._e()])], 1), _vm._v(" "), _c('div', {
    staticClass: "row justify-content-end"
  }, [_c('div', {
    staticClass: "col-12 col-sm-auto mb-10"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "color": "orange",
      "no-caps": "",
      "width": "20",
      "label": _vm.trans('button.clear_filter'),
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.clearFilters(false);
      }
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-12 col-sm-auto"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "color": "green",
      "no-caps": "",
      "width": "20",
      "label": _vm.trans('button.submit'),
      "loading": _vm.loading,
      "type": "submit",
      "disabled": !_vm.validateFilterBtn
    }
  })], 1)])]), _vm._v(" "), _vm.selectFilters ? _c('v-no-results', {
    staticStyle: {
      "border-top": "1px solid rgba(0, 0, 0, 0.12)"
    },
    attrs: {
      "image": "search",
      "title": _vm.trans('warning_text.select_filters_title'),
      "text": _vm.trans('warning_text.select_filters_text')
    }
  }) : _c('div', {
    staticClass: "v-admission-result-list"
  }, [_c('q-table', {
    staticClass: "s-b-white",
    attrs: {
      "data": _vm.table.rows,
      "columns": _vm.table.columns,
      "row-key": "__index",
      "flat": _vm.isTableFlat,
      "grid": _vm.isTableGrid,
      "pagination": _vm.pagination,
      "filter": _vm.filter,
      "no-data-label": _vm.trans('label.no_data'),
      "no-results-label": _vm.trans('label.no_results'),
      "separator": "cell",
      "wrap-cells": "",
      "loading": _vm.loading
    },
    on: {
      "update:pagination": function updatePagination($event) {
        _vm.pagination = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "sn-table__top-filters"
        }, [_c('q-input', {
          attrs: {
            "dense": "",
            "debounce": "300",
            "placeholder": _vm.trans('placeholder.search')
          },
          model: {
            value: _vm.filter,
            callback: function callback($$v) {
              _vm.filter = $$v;
            },
            expression: "filter"
          }
        }, [_c('q-icon', {
          attrs: {
            "slot": "append",
            "name": "search"
          },
          slot: "append"
        })], 1)], 1), _vm._v(" "), !_vm.$q.screen.lt.sm ? _c('q-btn', {
          staticClass: "q-ml-md",
          attrs: {
            "squire": "",
            "unelevated": "",
            "icon": "fullscreen",
            "color": props.inFullscreen ? 'blue' : 'grey'
          },
          on: {
            "click": props.toggleFullscreen
          }
        }, [_c('q-tooltip', [_vm._v("\n            " + _vm._s(props.inFullscreen ? _vm.trans('label.fullscreen_off') : _vm.trans('label.fullscreen_on')) + "\n          ")])], 1) : _vm._e()];
      }
    }, {
      key: "no-data",
      fn: function fn() {
        return [_vm.noResults ? _c('v-no-results', {
          attrs: {
            "image": "search",
            "title": _vm.trans('warning_text.no_filter_result_title'),
            "text": _vm.trans('warning_text.no_filter_result_text')
          }
        }) : _vm._e()];
      },
      proxy: true
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }