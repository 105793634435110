var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('q-form', {
    attrs: {
      "autocorrect": "off",
      "autocapitalize": "off",
      "autocomplete": "off",
      "spellcheck": "false"
    },
    on: {
      "submit": _vm.handleAjaxFormSubmit
    }
  }, [_c('v-wrapper-q-select', {
    staticClass: "ml-5 mr-5",
    attrs: {
      "required": "",
      "options": _vm.reasons,
      "option-label": "label",
      "option-value": "value",
      "extra-binds": {
        label: _vm.trans('attendance.timesheet-absent-reason'),
        required: true,
        hideBottomSpace: true
      }
    },
    model: {
      value: _vm.fields.attendance,
      callback: function callback($$v) {
        _vm.$set(_vm.fields, "attendance", $$v);
      },
      expression: "fields.attendance"
    }
  }), _vm._v(" "), _c('q-slide-transition', [![0, 1].includes(_vm.fields.attendance) ? _c('div', {
    staticClass: "mt-10"
  }, [_c('div', {
    staticClass: "sn-field__label ml-5 mr-5"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('attendance.add-absent-doc')) + ":\n        ")]), _vm._v(" "), _c('div', {
    staticClass: "s-group s-group--equal s-group--wider"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "label": _vm.fields.userFile.length === 0 ? _vm.trans('button.add') : _vm.trans('label.attached'),
      "color": _vm.fields.userFile.length === 0 ? 'blue' : 'green',
      "icon-right": _vm.fields.userFile.length === 0 ? 'plus' : 'clip',
      "label-wider": "",
      "ellipsis": "",
      "no-caps": ""
    },
    on: {
      "click": function click($event) {
        return _vm.triggerFile('userFile');
      }
    }
  }), _vm._v(" "), _c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "outline": "",
      "label": _vm.fields.userFile.length === 0 ? _vm.trans('label.file_not_chosen') : _vm.fields.userFile.name,
      "label-wider": "",
      "color": "grey",
      "no-caps": "",
      "ellipsis": "",
      "icon-right": _vm.fields.userFile.length === 0 ? undefined : 'close'
    },
    on: {
      "click": function click($event) {
        return _vm.clearFile('userFile');
      }
    }
  })], 1)]) : _vm._e()]), _vm._v(" "), _c('div', {
    staticClass: "s-group s-group--equal s-group--wider mt-10"
  }, [_c('q-btn', {
    attrs: {
      "no-caps": "",
      "color": "grey",
      "label": _vm.trans('button.cancel')
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('onClose');
      }
    }
  }), _vm._v(" "), _c('q-btn', {
    attrs: {
      "disable": !_vm.validate,
      "color": "green",
      "no-caps": "",
      "label": _vm.trans('button.send'),
      "type": "submit"
    }
  })], 1)], 1), _vm._v(" "), _c('select-user-file', {
    attrs: {
      "open-modal": _vm.modalFiles,
      "url-files": _vm.uriUserFiles,
      "url-file-store": _vm.uriUserFiles
    },
    on: {
      "input": _vm.selectFile,
      "select-close": _vm.fileModalClose
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }