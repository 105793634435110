var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sc-curriculum-program__buttons"
  }, [_vm.editData ? [_c('q-btn', {
    staticClass: "mb-5",
    attrs: {
      "color": "green",
      "icon": "check",
      "squire": "",
      "outline": ""
    },
    on: {
      "click": _vm.saveLesson
    }
  }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('label.save_remark')))])], 1), _vm._v(" "), _c('q-btn', {
    attrs: {
      "color": "red",
      "icon": "close",
      "squire": "",
      "outline": ""
    },
    on: {
      "click": function click($event) {
        _vm.editData = !_vm.editData;
      }
    }
  }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('label.cancel_remark_creation')))])], 1)] : [_c('q-btn', {
    staticClass: "mb-5",
    attrs: {
      "color": "blue",
      "icon": "pencil",
      "squire": "",
      "outline": ""
    },
    on: {
      "click": function click($event) {
        _vm.editData = !_vm.editData;
      }
    }
  }), _vm._v(" "), _c('q-btn', {
    attrs: {
      "color": "blue",
      "icon": "trash",
      "squire": "",
      "outline": ""
    },
    on: {
      "click": _vm.openConfirmModal
    }
  })]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }