//TODO: не стал удалять все связанное с Локалити, если вдруг потом решим вернуть

import _ from 'lodash';
import {
  QBtn,
  QRadio,
  QField,
  QSelect,
  QCheckbox,
  QSlideTransition,
} from '@quasar/components';
import MXFilters from '@vjs/mixins/MXFilters';
import {
  requestWrapper,
} from '@vjs/helpers';

export default {
  name: 'VCatalogFilters',
  mixins: [MXFilters],
  components: {
    QBtn,
    QRadio,
    QField,
    QSelect,
    QCheckbox,
    QSlideTransition,
  },
  props: {
    uriStatistic: {
      type: String,
      required: true,
    },
    uriGetFilters: {
      type: String,
      required: true,
    },
    uriGetLocalities: {
      type: String,
      required: true,
    },
    uriGetDepartments: {
      type: String,
      required: true,
    },
    uriGetOrganizations: {
      type: String,
      required: true,
    },
    regionId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      filtersSelected: {
        languages: [],
        type: [],
        onlyActiveAdmission: 0,
        region: null,
        department: null,
        locality: null,
      },
      filterOptions: {
        regions: [],
        languages: [],
        types: [],
      },
      departments: [],
      localities: [],
    };
  },
  async mounted() {
    this.loading = true;
    this.$emit('filter-loading', true);
    this.loadFiltersFromLocalStorage({}, 'catalogFilter');
    await this.requestFilters();

    if (this.filtersSelected.region !== null) {
      await this.requestDepartments(false);
      /*      if (this.filtersSelected.department !== null) {
        await this.requestLocalities(false);
      } */
    }

    //Проверка на наличие выбранного региона в шапке портала
    if (this.regionId !== 0 && this.regionId !== this.filtersSelected.region) {
      this.filtersSelected.department = null;
      this.departments = [];
      this.filtersSelected.region = this.regionId;
      await this.requestDepartments(false);
    }

    await this.getItems();
    this.loading = false;
    this.$emit('filter-loading', false);
  },

  methods: {
    // Передаёт полученный отфильтрованный список через событие
    async getItems() {
      this.loading = true;
      this.$emit('filter-loading', true);
      const data = await this.requestItems();
      this.saveFiltersToLocalStorage({ filtersSelected: { ...this.filtersSelected } }, 'catalogFilter');
      this.loading = false;
      this.$emit('filter-loading', false);
      this.$emit('filtered-list', { data } || []);
    },
    // Отправляет запрос на получение отфильтрованных организаций
    async requestItems() {
      // TODO: позже добавим сатистику, пока не нужна
      /*      const statisticRes = await requestWrapper.call(this, {
        url: this.uriStatistic,
        params: {
          locality: this.localitySelected.value,
        },
      });
      let statistics = [];

      if (!statisticRes.error) {
      statistics = (_.isArray(statisticRes.data) && statisticRes.data) || [];
      } */

      const itemsRes = await requestWrapper.call(this, {
        method: 'get',
        url: this.uriGetOrganizations,
        params: this.filtersSelected,
      });
      let items = [];

      if (!itemsRes.error) {
        items = (_.isArray(itemsRes.data) && itemsRes.data) || [];
      }

      return {
        items,
        // statistics,
      };
    },
    // Запускает фильтрацию
    async handleApplyFilters() {
      this.getItems();
    },
    // Получает список доступных фильтров
    async requestFilters() {
      const res = await requestWrapper.call(this, {
        method: 'get',
        url: this.uriGetFilters,
      });

      if (!res.error) {
        this.filterOptions = res.data;
        this.localities = res.data.localities;
        this.departments = res.data.departments;
      }
    },
    // Запрос списка доступных нас пунктов
    /*    async requestLocalities(withClear) {
      if (withClear !== false) {
        this.localities = this.filterOptions.localities;
        this.filtersSelected.locality = null;
      }

      const res = await requestWrapper.call(this, {
        method: 'get',
        url: this.uriGetLocalities,
        params: {
          department_id: this.filtersSelected.department,
        },
      });

      if (!res.error) {
        this.localities = res;
      }
    }, */
    // Запрос списка доступных районов
    async requestDepartments(withClear) {
      if (withClear !== false) {
        this.departments = this.filterOptions.departments;
        this.filtersSelected.department = null;
        this.localities = this.filterOptions.localities;
        this.filtersSelected.locality = null;
      }
      const res = await requestWrapper.call(this, {
        method: 'get',
        url: this.uriGetDepartments,
        params: {
          region_id: this.filtersSelected.region,
        },
      });

      if (!res.error) {
        this.departments = res;
      }
    },
    // Сбрасывает установленные фильтры на значение по умолчанию
    async resetFilters() {
      this.filtersSelected.languages = [];
      this.filtersSelected.type = [];
      this.filtersSelected.onlyActiveAdmission = 0;
      this.filtersSelected.region = null;
      this.filtersSelected.department = null;
      this.departments = this.filterOptions.departments;
      // this.filtersSelected.locality = null;
      // this.localities = this.filterOptions.localities;

      if (localStorage && localStorage.getItem('catalogFilter')) {
        localStorage.removeItem('catalogFilter');
      }

      await this.getItems();
    },
  },
};
