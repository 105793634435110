var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "diploma-contingent-form"
  }, [_c('h3', {
    staticClass: "s-title s-title--double-line s-upper s-light mb-25"
  }, [_c('span', [_vm._v(" " + _vm._s("".concat(_vm.info.student, " / ").concat(_vm.info.group)))])]), _vm._v(" "), _c('v-form-inform', {
    attrs: {
      "message": _vm.trans('warning_text.modal_inform')
    }
  }), _vm._v(" "), _c('q-form', [_c('q-checkbox', {
    attrs: {
      "label": _vm.trans('label.enter_supervisor_manually'),
      "disable": _vm.loading
    },
    on: {
      "input": _vm.manualPersonnelChange
    },
    model: {
      value: _vm.form.manualPersonnel,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "manualPersonnel", $$v);
      },
      expression: "form.manualPersonnel"
    }
  }), _vm._v(" "), _vm.form.manualPersonnel ? _c('div', [_c('q-input', {
    class: _vm.loading ? 'sn--cursor-wait' : '',
    attrs: {
      "required": "",
      "label": _vm.trans('label.supervisor'),
      "placeholder": _vm.trans('placeholder.fullname'),
      "disable": _vm.loading
    },
    model: {
      value: _vm.form.personnelName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "personnelName", $$v);
      },
      expression: "form.personnelName"
    }
  }), _vm._v(" "), _c('q-input', {
    class: _vm.loading ? 'sn--cursor-wait' : '',
    attrs: {
      "label": _vm.trans('label.note'),
      "placeholder": _vm.trans('label.note'),
      "disable": _vm.loading
    },
    model: {
      value: _vm.form.personnelNote,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "personnelNote", $$v);
      },
      expression: "form.personnelNote"
    }
  })], 1) : _c('q-select', {
    staticClass: "sn-select__input--noclear",
    class: _vm.loading ? 'sn--cursor-wait' : '',
    attrs: {
      "filled": "",
      "bottom-slots": "",
      "emit-value": "",
      "map-options": "",
      "use-input": "",
      "hide-selected": "",
      "fill-input": "",
      "input-debounce": "0",
      "placeholder": _vm.trans('select.placeholder'),
      "options": _vm.options.personnel,
      "loading": _vm.loading,
      "disable": _vm.loading,
      "label": _vm.trans('label.supervisor'),
      "required": ""
    },
    on: {
      "filter": _vm.filterPersonnel
    },
    model: {
      value: _vm.form.personnelSelected,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "personnelSelected", $$v);
      },
      expression: "form.personnelSelected"
    }
  }), _vm._v(" "), _c('q-input', {
    staticClass: "mb-10",
    class: _vm.loading ? 'sn--cursor-wait' : '',
    attrs: {
      "label": _vm.trans('label.diploma_theme'),
      "placeholder": _vm.trans('label.diploma_theme'),
      "required": "",
      "disable": _vm.loading
    },
    model: {
      value: _vm.form.theme,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "theme", $$v);
      },
      expression: "form.theme"
    }
  }), _vm._v(" "), _c('q-input', {
    staticClass: "mb-10",
    class: {
      'sn--cursor-wait': _vm.loading,
      's-maxw-200': _vm.$q.screen.width >= 450
    },
    attrs: {
      "filled": "",
      "mask": "##.##.####",
      "label": _vm.trans('table.protection_date'),
      "placeholder": _vm.trans('table.protection_date'),
      "required": "",
      "disable": _vm.loading
    },
    on: {
      "click": function click() {
        return _vm.$refs.qDateActiveProxy.show();
      }
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('q-icon', {
          staticClass: "sn--cursor-pointer",
          attrs: {
            "name": "calendar"
          }
        }, [_c('q-popup-proxy', {
          ref: "qDateActiveProxy",
          attrs: {
            "transition-show": "scale",
            "transition-hide": "scale"
          }
        }, [_c('q-date', {
          attrs: {
            "mask": "DD.MM.YYYY",
            "color": "blue"
          },
          on: {
            "input": function input() {
              return _vm.$refs.qDateActiveProxy.hide();
            }
          },
          model: {
            value: _vm.form.protectionDate,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "protectionDate", $$v);
            },
            expression: "form.protectionDate"
          }
        })], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.protectionDate,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "protectionDate", $$v);
      },
      expression: "form.protectionDate"
    }
  }), _vm._v(" "), _c('q-checkbox', {
    staticClass: "mb-10",
    attrs: {
      "label": _vm.trans('label.set_mark'),
      "disable": _vm.loading
    },
    model: {
      value: _vm.form.setResult,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "setResult", $$v);
      },
      expression: "form.setResult"
    }
  }), _vm._v(" "), _vm.form.setResult ? _c('div', [_c('q-input', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: _vm.evaluationType === 0 ? '#' : '###',
      expression: "evaluationType === 0 ? '#' : '###'"
    }],
    class: {
      'sn--cursor-wait': _vm.loading,
      's-maxw-200': _vm.$q.screen.width >= 450
    },
    attrs: {
      "label": _vm.trans('fields.point'),
      "placeholder": _vm.trans('fields.point'),
      "disable": _vm.loading,
      "required": ""
    },
    model: {
      value: _vm.form.result,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "result", _vm._n($$v));
      },
      expression: "form.result"
    }
  })], 1) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "row-sort-end"
  }, [_c('q-btn', {
    staticClass: "mt-20",
    class: _vm.$q.screen.width >= 450 ? 's-btn--w-200' : 's-btn--wider',
    attrs: {
      "color": "green",
      "icon": "mark",
      "label": _vm.trans('button.save'),
      "loading": _vm.loading,
      "disable": !_vm.validate
    },
    on: {
      "click": _vm.requestAction
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }