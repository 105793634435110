var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-contest"
  }, [_c('div', {
    staticClass: "mt-50"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-12 col-lg-8"
  }, [_vm.isRatingButtonClicked ? _c('div', {
    staticClass: "row align-items-stretch mb-10"
  }, [_c('div', {
    staticClass: "col-sm-12 col-lg-6 no-gutters"
  }, [_c('q-select', {
    staticClass: "sn-select__input--noclear",
    attrs: {
      "map-options": "",
      "emit-value": "",
      "options": _vm.regions
    },
    on: {
      "input": _vm.handleSearch
    },
    model: {
      value: _vm.filters.region,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "region", $$v);
      },
      expression: "filters.region"
    }
  })], 1), _vm._v(" "), _vm.isPersonnelContest ? _c('div', {
    staticClass: "col-sm-12 col-lg-6 no-gutters"
  }, [_c('v-wrapper-q-select', {
    attrs: {
      "options": _vm.organizations,
      "option-value": "value",
      "option-label": "label"
    },
    on: {
      "input": _vm.handleSearch
    },
    model: {
      value: _vm.filters.organization,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "organization", $$v);
      },
      expression: "filters.organization"
    }
  })], 1) : _vm._e()]) : _c('div', {
    staticClass: "btn-group"
  }, [_c('q-btn', {
    staticClass: "s-btn--w-200 mb-5 block",
    attrs: {
      "outline": "",
      "no-caps": "",
      "color": "blue",
      "label": _vm.trans('cabinet.back'),
      "type": "a",
      "to": _vm.urlBack
    }
  })], 1)]), _vm._v(" "), _c('div', {
    staticClass: "col-sm-12 col-lg-4 d-flex sn-field__wrapper justify-content-end"
  }, [_c('div', {
    staticClass: "btn-group"
  }, [_c('q-btn', {
    staticClass: "s-btn--w-200 mb-5",
    attrs: {
      "outline": "",
      "no-caps": "",
      "color": "green",
      "label": _vm.trans("contest.".concat(_vm.isRatingButtonClicked ? 'conditions' : 'rating'))
    },
    on: {
      "click": function click($event) {
        _vm.isRatingButtonClicked = !_vm.isRatingButtonClicked;
      }
    }
  })], 1)])]), _vm._v(" "), _vm.isRatingButtonClicked ? _c('div', [_c('q-input', {
    staticClass: "s-contest__search mb-30",
    attrs: {
      "placeholder": _vm.isPersonnelContest ? _vm.trans('label.search_fullName') : _vm.trans('label.search_by_organizations')
    },
    on: {
      "keypress": function keypress($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.handleSearch($event);
      }
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_vm.filters.search ? _c('q-icon', {
          staticClass: "mr-5 sn--cursor-pointer",
          attrs: {
            "name": "close"
          },
          on: {
            "click": _vm.clearFilters
          }
        }) : _vm._e(), _vm._v(" "), _c('q-btn', {
          staticStyle: {
            "border-bottom-left-radius": "0",
            "border-top-left-radius": "0"
          },
          attrs: {
            "squire": "",
            "color": "blue",
            "icon": "search",
            "label-wider": "",
            "width": "15",
            "label": _vm.trans('contest.search')
          },
          on: {
            "click": _vm.handleSearch
          }
        })];
      },
      proxy: true
    }], null, false, 3820725206),
    model: {
      value: _vm.filters.search,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "search", $$v);
      },
      expression: "filters.search"
    }
  })], 1) : _vm._e()]), _vm._v(" "), _c('transition-group', {
    attrs: {
      "tag": "div",
      "name": _vm.isRatingButtonClicked ? 'swipe-left' : 'swipe-right'
    }
  }, [_vm.isRatingButtonClicked ? _c('v-contest-results-view', {
    key: 1,
    attrs: {
      "is-personnel-contest": _vm.isPersonnelContest,
      "teachers": _vm.teachers,
      "columns": _vm.columns,
      "pagination": _vm.pagination
    }
  }) : _c('v-contest-conditions-view', {
    key: 2,
    attrs: {
      "contest": _vm.contest,
      "is-personnel-contest": _vm.isPersonnelContest
    }
  })], 1), _vm._v(" "), _vm.modalShow ? _c('v-contest-modal-view', {
    attrs: {
      "modal": _vm.modalShow,
      "user": _vm.user
    },
    on: {
      "modal-close": function modalClose($event) {
        _vm.modalShow = $event;
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }