import Vue from 'vue';
import axios from 'axios';
import store from './VueJS/store';
import VDiplomaContingentForm from './VueJS/views/VDiplomaContingentForm/index';

if (document.getElementById('v-diploma-contingent-form-app')) {

  window.vDiplomaContingentFormApp = new Vue({
    el: '#v-diploma-contingent-form-app',
    components: { VDiplomaContingentForm },
    store,
  });
}
