var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('q-form', {
    on: {
      "submit": _vm.handleAjaxFormSubmit
    }
  }, [_c('v-form-inform', {
    attrs: {
      "message": _vm.trans('warning_text.modal_inform')
    }
  }), _vm._v(" "), _vm._l(_vm.fields, function (field) {
    return [_vm.handleVif(field) ? _c(field.component, _vm._g({
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.handleVShow(field),
        expression: "handleVShow(field)"
      }],
      key: field.name,
      tag: "component",
      attrs: {
        "field": field,
        "field-name": field.labelTransKey ? _vm.trans(field.labelTransKey) : field.label,
        "errors": _vm.getErrors(field.name)
      },
      model: {
        value: field.value,
        callback: function callback($$v) {
          _vm.$set(field, "value", $$v);
        },
        expression: "field.value"
      }
    }, field.events ? field.events : {})) : _vm._e()];
  }), _vm._v(" "), _c('div', {
    staticClass: "row justify-content-between"
  }, [_c('div', {
    staticClass: "col-lg-3 col-md-6 col-sm-12",
    class: _vm.$q.screen.width < 768 ? 'order-2' : ''
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "to": _vm.backLink,
      "type": "a",
      "color": "grey",
      "label": _vm.trans('button.cancel'),
      "no-caps": ""
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-lg-3 col-md-6 col-sm-12 mb-10"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "type": "submit",
      "color": "green",
      "label": _vm.trans('button.submit'),
      "no-caps": ""
    }
  })], 1)])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }