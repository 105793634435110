import JournalEditPoint from './Journal/JournalEditPoint';
import JournalGenerateWeeks from './Journal/JournalGenerateWeeks';
import JournalCalcResult from './Journal/JournalCalcResult';
import JournalDayForm from './Journal/JournalDayForm';
import Confirm from './Confirm';
import ConfirmNew from './ConfirmNew';
import ConfirmWithMessage from './ConfirmWithMessage';
import StatementAttestationAveragePoint from './StatementAttestationAveragePoint';
import SelectUserFile from '../../views/SelectUserFile';
import ReassignSpecialtyPlaces from './ReassignSpecialtyPlaces';
import VAttendanceModal from './Attendance/DayAttendance';
import VContingentAttendanceModal from './Attendance/ContingentAttendance';
import VMultipleButtonsModal from './MultipleButtons';
import VModalExamQuestionsImport from './ExamQuestions/Import';
import VHolidaysModal from './Attendance/Holidays';
import ProgramChapter from './CurriculumWorkingProgram/ProgramChapter';
import ProgramLesson from './CurriculumWorkingProgram/ProgramLesson';


export default function (Vue) {
  Vue.component('v-modal-journal-edit-point', JournalEditPoint);
  Vue.component('v-modal-journal-generate-weeks', JournalGenerateWeeks);
  Vue.component('v-modal-journal-calc-result', JournalCalcResult);
  Vue.component('v-modal-journal-day-form', JournalDayForm);
  Vue.component('v-modal-confirm', Confirm);
  Vue.component('v-modal-confirm-new', ConfirmNew);
  Vue.component('v-modal-statement-attestation-average-point', StatementAttestationAveragePoint);
  Vue.component('v-modal-select-user-files', SelectUserFile);
  Vue.component('v-modal-reassign-specialty-places', ReassignSpecialtyPlaces);
  Vue.component('v-modal-confirm-with-message', ConfirmWithMessage);
  Vue.component('v-modal-attendance', VAttendanceModal);
  Vue.component('v-modal-multi-button', VMultipleButtonsModal);
  Vue.component('v-modal-contingent-attendance', VContingentAttendanceModal);
  Vue.component('v-modal-multi-button', VMultipleButtonsModal);
  Vue.component('v-modal-exam-questions-import', VModalExamQuestionsImport);
  Vue.component('v-holidays-modal', VHolidaysModal);
  Vue.component('v-modal-add-chapter', ProgramChapter);
  Vue.component('v-modal-add-lesson', ProgramLesson);
}
