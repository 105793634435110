<template>
  <div>
    <div v-if="teachers.length">
      <div class="s-b-white contest__table">
        <div class="s-contest__table--head">
          <div
            class="s-contest__table--row"
            :class="!isPersonnelContest ? 's-contest__table--college':''"
          >
            <div class="s-table__col--to-center">
              {{ trans('contest.place') }}
            </div>
            <div
              v-for="column in columns"
              :class="column.class"
            >
              {{ column.label }}
            </div>
          </div>
        </div>
        <div class="s-contest__table--body">
          <div
            v-for="(item, index) in teachers"
            class="s-contest__table--row s-contest__table--border"
            :class="!isPersonnelContest ? 's-contest__table--college':''"
          >
            <div class="s-contest__table--data s-table__col--to-center">
              <img
                v-if="[1,2,3].includes(item.place) && isPersonnelContest
                  || item.place === 1"
                :src="`/images/medal_${item.place}.svg`"
              >
              <span v-else> {{ item.place }} </span>
            </div>
            <div
              v-for="column in columns"
              class="s-contest__table--data"
              :class="column.cellClass"
              :data-table-head="column.label"
            >
              <span
                class="page-link"
                aria-hidden="true"
                v-html="getAttribute(item, column.attribute)"
              />
            </div>
          </div>
        </div>
      </div>
      <ul
        class="pagination mt-50 mb-50"
        role="navigation"
      >
        <template v-for="(link,index) in pagination.links">
          <template v-if="link.disabled || link.link===null">
            <li
              :key="index"
              class="page-item disabled"
              aria-disabled="true"
            >
              <span
                class="page-link"
                aria-hidden="true"
                v-html="link.text"
              />
            </li>
          </template>
          <template v-else-if="link.current">
            <li
              :key="index"
              class="page-item is-active"
              aria-current="page"
            >
              <span
                class="page-link"
                v-html="link.text"
              />
            </li>
          </template>
          <template v-else>
            <li
              :key="index"
              class="page-item"
            >
              <a
                class="page-link"
                :href="link.link"
                @click.prevent="requestGetContestResults(link.link)"
                v-html="link.text"
              />
            </li>
          </template>
        </template>
      </ul>
    </div>
    <v-no-results
      v-else
      image="search"
      :title="trans('warning_text.no_data_title')"
      :text="trans('warning_text.no_data_label')"
    />
  </div>
</template>

<script>
import eventHub from '@vjs/config/eventHub';
import VNoResults from '@vjs/components/VNoResults';

export default {
  name: 'VContestResultsView',
  components: {
    VNoResults,
  },
  props: {
    teachers: [Array, Object],
    columns: [Array, Object],
    pagination: [Array, Object],
    isPersonnelContest: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    getAttribute(item, attribute) {
      let value = _.get(item, attribute, '');
      if (_.isArray(value)) {
        let tmpValue = '';
        _.forEach(value, (itm, index) => {
          tmpValue += index === 0 ? `<b> ${itm} </b> <br>` : itm;
        });
        value = tmpValue;
      }
      return value;
    },
    itemIndexNumber(itemIndex) {
      const index = (this.pagination.currentPage - 1) * 15 + itemIndex + 1;

      return index;
    },
    requestGetContestResults(url) {
      eventHub.$emit('requestGetContestResults', url);
    },
  },
};
</script>
