var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-pie-chart__wrapper"
  }, [_c('div', {
    staticClass: "s-pie-chart__head"
  }, [_c('div', {
    staticClass: "s-pie-chart__title"
  }, [_vm._v("\n      " + _vm._s(_vm.title) + "\n    ")])]), _vm._v(" "), _c('div', {
    staticClass: "s-bar-chart__body"
  }, [!_vm.noData && _vm.show ? [_c('table', {
    staticClass: "s-table-cabinet"
  }, [_c('thead', {
    staticClass: "s-table__head"
  }, [_c('tr', [_c('th', {
    staticClass: "s-to-center"
  }, [_vm._v("\n              №\n            ")]), _vm._v(" "), _c('th', {
    staticClass: "s-table__col-8"
  }, [_vm._v("\n              " + _vm._s(_vm.langs.college) + "\n            ")]), _vm._v(" "), _vm._l(_vm.columns, function (col, index) {
    return _c('th', {
      key: index,
      staticClass: "s-to-center"
    }, [_vm._v("\n              " + _vm._s(col.name) + "\n            ")]);
  }), _vm._v(" "), _c('th', {
    staticClass: "s-to-center"
  }, [_vm._v("\n              " + _vm._s(_vm.langs.men) + "\n            ")]), _vm._v(" "), _c('th', {
    staticClass: "s-to-center"
  }, [_vm._v("\n              " + _vm._s(_vm.langs.women) + "\n            ")]), _vm._v(" "), _c('th', {
    staticClass: "s-to-center"
  }, [_vm._v("\n              " + _vm._s(_vm.langs.all) + "\n            ")])], 2)]), _vm._v(" "), _c('tbody', {
    staticClass: "s-table__body"
  }, _vm._l(_vm.rows, function (row, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(index + 1))]), _vm._v(" "), _c('td', [_vm._v(_vm._s(row.name))]), _vm._v(" "), _vm._l(row.years, function (col, idx) {
      return _c('td', {
        key: idx,
        staticClass: "s-to-center"
      }, [_vm._v("\n              " + _vm._s(col.gender.m + col.gender.w) + "\n            ")]);
    }), _vm._v(" "), _c('td', {
      staticClass: "s-to-center"
    }, [_vm._v("\n              " + _vm._s(_vm.getSummGender(row.years, 'm')) + "\n            ")]), _vm._v(" "), _c('td', {
      staticClass: "s-to-center"
    }, [_vm._v("\n              " + _vm._s(_vm.getSummGender(row.years, 'w')) + "\n            ")]), _vm._v(" "), _c('td', {
      staticClass: "s-to-center"
    }, [_vm._v("\n              " + _vm._s(_vm.getSummGenderAll(row.years)) + "\n            ")])], 2);
  }), 0)])] : !_vm.show ? [_c('h2', {
    staticClass: "s-to-center",
    staticStyle: {
      "padding": "5rem 0"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.langs.mobileOff) + "\n      ")])] : [_c('h2', {
    staticClass: "s-to-center",
    staticStyle: {
      "padding": "5rem 0"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.langs.nodata) + "\n      ")])]], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }