var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-form-inform', {
    attrs: {
      "message": _vm.trans('warning_text.exam_question_import_warning')
    }
  }), _vm._v(" "), _c('q-form', {
    on: {
      "submit": _vm.handleAction
    }
  }, [_c('div', {
    staticStyle: {
      "padding": "0 2rem"
    }
  }, [_c('q-btn', {
    staticClass: "s-w-100 mb-10",
    attrs: {
      "color": "orange",
      "label": _vm.trans('button.download_import_template'),
      "target": "_blank",
      "type": "a",
      "to": _vm.uriTemplate,
      "no-caps": ""
    }
  }), _vm._v(" "), !_vm.file ? _c('q-btn', {
    staticClass: "s-w-100 form-upload__button",
    attrs: {
      "no-caps": "",
      "icon": "clip",
      "color": "blue",
      "type": "button",
      "label": _vm.trans('button.upload_file_with_questions'),
      "label-wider": ""
    },
    on: {
      "click": function click($event) {
        return _vm.triggerFile();
      }
    }
  }) : _c('q-btn', {
    staticClass: "s-w-100 s-btn--border sn-btn--ellipsis",
    attrs: {
      "no-caps": "",
      "icon": "close",
      "label": _vm.file.name,
      "label-wider": "",
      "color": "green",
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.clearFile();
      }
    },
    model: {
      value: _vm.file,
      callback: function callback($$v) {
        _vm.file = $$v;
      },
      expression: "file"
    }
  }), _vm._v(" "), _c('input', {
    ref: "fileInput",
    staticClass: "d-none",
    attrs: {
      "type": "file"
    },
    on: {
      "change": _vm.fileChange
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "mt-20 s-group s-group--wider s-group--nowrap"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "no-caps": "",
      "color": "grey",
      "label": _vm.trans('button.cancel')
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('onClose');
      }
    }
  }), _vm._v(" "), _c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "disable": !_vm.validate,
      "color": "green",
      "no-caps": "",
      "label": _vm.trans('button.send'),
      "type": "submit"
    }
  })], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }