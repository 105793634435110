var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-timer",
    class: _vm.level
  }, [_c('div', {
    staticClass: "s-str-ico s-str-ico--thm-alarm-clock"
  }, [_vm.hours ? _c('span', [_vm._v(_vm._s(_vm.getHours))]) : _vm._e(), _vm._v(" "), _vm.minutes ? _c('span', [_vm._v(_vm._s(_vm.getMinutes))]) : _vm._e(), _vm._v(" "), _vm.seconds ? _c('span', [_vm._v(_vm._s(_vm.getSeconds))]) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }