var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sc-journal__control"
  }, [_c('div', {
    staticClass: "sc-journal__control--table-wrapper"
  }, [_c('table', [_c('tbody', [_vm._l(_vm.table, function (item, key) {
    return [item.value ? _c('tr', {
      key: key,
      staticClass: "sc-journal__control--table-row"
    }, [_c('td', {
      staticClass: "sc-journal__control--table-label"
    }, [_vm._v("\n              " + _vm._s(item.label) + ":\n            ")]), _vm._v(" "), _c('td', {
      staticClass: "sc-journal__control--table-value",
      domProps: {
        "innerHTML": _vm._s(item.value)
      }
    })]) : _vm._e()];
  })], 2)])]), _vm._v(" "), _c('div', {
    staticClass: "sc-journal__control--buttons-wrapper"
  }, [_vm._l(_vm.buttons, function (btn, key) {
    return [btn.withHelp ? _c('div', {
      key: key,
      staticClass: "s-pos-relative-position"
    }, [_c('q-btn', {
      key: key,
      staticClass: "s-w-100",
      attrs: {
        "color": btn.color,
        "outline": btn.outline,
        "icon": btn.icon,
        "label": btn.label,
        "no-caps": "",
        "label-wider": "",
        "use-fa-icons": ""
      },
      on: {
        "click": btn.onClick
      }
    }), _vm._v(" "), _c('div', {
      staticClass: "sc-journal__control--result-helper"
    }, [_c('q-btn', {
      attrs: {
        "outline": "",
        "color": "blue",
        "label": "?",
        "round": ""
      }
    }, [_c('q-popup-proxy', {
      ref: "popupProxy",
      refInFor: true,
      attrs: {
        "breakpoint": 0,
        "transition-show": "scale",
        "transition-hide": "scale",
        "content-class": "sc-journal__control--result-popup-wrapper",
        "anchor": "top left",
        "self": "top end",
        "offset": _vm.$q.screen.width > 768 ? [15, -40] : [0, -40]
      }
    }, [_c('div', {
      staticClass: "sc-journal__control--result-popup-body"
    }, [_c('div', {
      staticClass: "sc-journal__control--result-popup-text",
      domProps: {
        "innerHTML": _vm._s(_vm.trans("journal.calc_result_popup.".concat(_vm.isModule ? 'result_module' : 'result')))
      }
    }), _vm._v(" "), _c('div', {
      staticClass: "mt-20 s-to-center"
    }, [_c('q-icon', {
      staticClass: "sc-journal__control--result-popup-more",
      class: {
        active: _vm.resultSpoiler
      },
      attrs: {
        "size": "2rem",
        "icon-class": "s-ico-fa",
        "name": "chevron-double-down"
      },
      on: {
        "click": function click($event) {
          _vm.resultSpoiler = !_vm.resultSpoiler;
        }
      }
    })], 1), _vm._v(" "), _c('q-slide-transition', [_vm.resultSpoiler ? _c('div', {
      staticClass: "mt-20"
    }, _vm._l(_vm.resultPopupTexts, function (text, textKey) {
      return _c('div', {
        key: textKey,
        staticClass: "sc-journal__control--result-popup-text",
        domProps: {
          "innerHTML": _vm._s(text)
        }
      });
    }), 0) : _vm._e()])], 1)])], 1)], 1)], 1) : _c('q-btn', {
      key: key,
      attrs: {
        "color": btn.color,
        "outline": btn.outline,
        "icon": btn.icon,
        "label": btn.label,
        "no-caps": "",
        "label-wider": "",
        "use-fa-icons": ""
      },
      on: {
        "click": btn.onClick
      }
    })];
  })], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }