var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "row-sort-start mt-15"
  }, [_c('div', [_c('div', {
    staticClass: "college-library-card"
  }, [_c('img', {
    attrs: {
      "src": _vm.description.cover_path,
      "alt": ""
    }
  })])]), _vm._v(" "), _c('div', {
    staticClass: "college-library-desc--open"
  }, [_c('div', {
    staticClass: "college-library-desc--type"
  }, [_vm._v("\n        " + _vm._s(_vm.getType(_vm.description.type)) + "\n      ")]), _vm._v(" "), _c('div', {
    staticClass: "college-library-desc--name open mt-10"
  }, [_vm._v("\n        " + _vm._s(_vm.description.book_name) + " (" + _vm._s(_vm.description.year) + ")\n      ")]), _vm._v(" "), _c('div', {
    staticClass: "college-library-desc--author open"
  }, [_vm._v("\n        " + _vm._s(_vm.description.author) + "\n      ")]), _vm._v(" "), _vm.description.book_path ? _c('div', {
    staticClass: "college-library-desc--watches row-sort--center mb-30"
  }, [_vm._v("\n        " + _vm._s(_vm.description.downloads_count) + "\n      ")]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "row justify-content-start no-gutters"
  }, [_vm.description.book_path ? _c('q-btn', {
    staticStyle: {
      "margin-right": "1rem"
    },
    attrs: {
      "type": "a",
      "squire": "",
      "color": "green",
      "icon": "download",
      "to": _vm.getBook + '/' + _vm.description.id,
      "target": "_blank"
    },
    on: {
      "~click": function click($event) {
        !_vm.itsLibrarian && _vm.description.getCookie && _vm.description.downloads_count++;
      }
    }
  }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('button.download')))])], 1) : _vm._e(), _vm._v(" "), _vm.itsLibrarian ? _c('q-btn', {
    staticStyle: {
      "margin-right": "1rem"
    },
    attrs: {
      "squire": "",
      "color": "orange",
      "icon": "pencil"
    },
    on: {
      "click": _vm.openEditModal
    }
  }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('button.edit')))])], 1) : _vm._e(), _vm._v(" "), _vm.canDeleteBook ? _c('q-btn', {
    attrs: {
      "squire": "",
      "color": "red",
      "icon": "trash"
    },
    on: {
      "click": _vm.openDestroyModal
    }
  }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('button.remove')))])], 1) : _vm._e()], 1), _vm._v(" "), !_vm.description.book_path ? _c('div', {
    staticClass: "college-library-desc--link "
  }, [_c('div', {
    staticClass: "mt-10 "
  }, [_c('span', {
    staticClass: "s-bold s-f-14 s-w-100"
  }, [_vm._v(_vm._s(_vm.trans('placeholder.book_link')) + ":")]), _vm._v(" "), _c('hr', {
    staticClass: "lib-hr"
  }), _vm._v(" "), _c('a', {
    attrs: {
      "target": "_blank",
      "href": _vm.description.book_link
    }
  }, [_vm._v(_vm._s(_vm.trans('button.go_to')))])])]) : _vm._e()])]), _vm._v(" "), _c('div', {
    staticClass: "college-library-desc--description mb-30"
  }, [_c('div', {
    staticClass: "mt-10 s-panel mb-30"
  }, [_c('h4', {
    staticClass: "s-w-100 s-bold"
  }, [_vm._v(_vm._s(_vm.trans('label.description')) + ":")]), _vm._v(" "), _c('hr', {
    staticClass: "s-hr"
  }), _vm._v(" "), _c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.description.description)
    }
  })])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }