import RegistrationPhoneForm from '@common/views/FormRegistrationSms';
import VOrganizationProfile from '@common/views/VOrganizationProfile';
import VOrganizationEditForm from '@common/views/VOrganizationEditForm';
import VOrganizationInfo from '@common/views/VOrganizationInfo';
import VContestView from '@app_college/views/VContestView';
import VNameBookView from '@app_college/views/VNameBookView';
import VAdmissionResultProfessionsList from '@app_college/views/VAdmissionResult/ProfessionsList';
import VAdmissionResultList from '@app_college/views/VAdmissionResult/ContestResultList';
import VAdmissionMonitoringView from '@app_college/views/VAdmissionMonitoringView';
import NewTableWithFilters from '@common/views/NewTableWithFilters';
import JitsiConference from './JitsiConference';
import TrainingScheduleShow from './TrainingSchedule/Show';
import VExternalStatementsGradesView from './VExternalStatementsGradesView';
import VHomeworkQuizResult from './VHomeworkQuizResult';
import VContingentPerformanceBook from './VContingentPerformanceBook';
import VOrganizationDisciplinesTable from './VOrganizationDisciplinesTable';
import VOrganizationDisciplinesAddSubjectsForm from './VOrganizationDisciplinesAddSubjectsForm';
import VJournal from './VJournal';
import VStatementSignButtons from './VStatementSignButtons';
import VGrantRefusalsList from './VGrantRefusalsList';
import VStudentTranscript from './VStudentTranscript';
import VEducationModuleForm from './VEducationModuleForm';
import VJournalPlanForm from './VJournalPlanForm';
import VTimeSheetView from './VTimeSheetView';
import VCurriculumWorkingProgramForm from './VCurriculumWorkingProgram/Form';
import VCurriculumWorkingProgramView from './VCurriculumWorkingProgram/View';
import VDocumentFlowStatement from './DocumentFlow/Statement';
import SignButton from './DocumentFlow/SignButtons';


export default function (Vue) {
  Vue.component('vue-registration-phone-form', RegistrationPhoneForm);
  Vue.component('vue-jitsi-conference', JitsiConference);
  Vue.component('vue-external-statements-grades', VExternalStatementsGradesView);
  Vue.component('vue-training-schedule-show', TrainingScheduleShow);

  Vue.component('v-organization-profile', VOrganizationProfile);
  Vue.component('v-organization-info', VOrganizationInfo);
  Vue.component('v-organization-edit-form', VOrganizationEditForm);
  Vue.component('v-homework-quiz-result', VHomeworkQuizResult);
  Vue.component('v-contest-view', VContestView);
  Vue.component('v-name-book-view', VNameBookView);
  Vue.component('v-contingent-performance-book', VContingentPerformanceBook);
  Vue.component('v-organization-disciplines-table', VOrganizationDisciplinesTable);
  Vue.component('v-organization-disciplines-add-subjects-form', VOrganizationDisciplinesAddSubjectsForm);
  Vue.component('v-statement-sign-buttons', VStatementSignButtons);
  Vue.component('v-admission-monitoring-view', VAdmissionMonitoringView);
  Vue.component('v-journal', VJournal);
  Vue.component('vue-new-table-with-filters', NewTableWithFilters);
  Vue.component('v-grant-refusals-list', VGrantRefusalsList);
  Vue.component('v-student-transcript', VStudentTranscript);
  Vue.component('v-admission-result-professions-list', VAdmissionResultProfessionsList);
  Vue.component('v-admission-contest-result-list', VAdmissionResultList);
  Vue.component('vue-new-table-with-filters', NewTableWithFilters);
  Vue.component('v-education-module-form', VEducationModuleForm);
  Vue.component('v-journal-plan-form', VJournalPlanForm);
  Vue.component('v-time-sheet-view', VTimeSheetView);
  Vue.component('v-curriculum-working-program', VCurriculumWorkingProgramForm);
  Vue.component('v-curriculum-working-program-view', VCurriculumWorkingProgramView);
  Vue.component('v-document-flow-statement', VDocumentFlowStatement);
  Vue.component('v-sign-button', SignButton);
}
