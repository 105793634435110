var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sc-contingent-performance-book__title-page"
  }, [_c('div', {
    staticClass: "sc-contingent-performance-book__title-page--wrapper"
  }, [_c('div', {
    staticClass: "sc-contingent-performance-book__title-page--left"
  }, [_c('div', {
    staticClass: "sc-contingent-performance-book__title-page--avatar",
    style: {
      'background-image': "url(".concat(_vm.info.avatarUrl, ")")
    }
  }), _vm._v(" "), _c('q-btn', {
    staticClass: "s-w-100 mb-10",
    attrs: {
      "label": _vm.$trans.get('button.change_photo'),
      "color": "orange",
      "no-caps": "",
      "icon-right": "camera",
      "label-wider": ""
    },
    on: {
      "click": _vm.triggerFile
    }
  }), _vm._v(" "), _c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "label": _vm.$trans.get('button.export'),
      "color": "blue",
      "type": "a",
      "no-caps": "",
      "target": "_blank",
      "to": _vm.uriExport
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "sc-contingent-performance-book__title-page--right"
  }, [_c('v-card', {
    attrs: {
      "title": _vm.info.title,
      "data": _vm.info.data
    }
  })], 1)]), _vm._v(" "), _c('input', {
    ref: "fileInput",
    staticClass: "d-none",
    attrs: {
      "type": "file",
      "accept": "image/*"
    },
    on: {
      "change": _vm.fileChange
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }