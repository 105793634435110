import { genArray, scrollTop, requestWrapper } from '@vjs/helpers';
import {
  QField,
  QPagination,
  QTooltip,
  QBtn,
} from '@quasar/components';
import { Loading } from '@quasar/plugins';
import Modal from '@vjs/components/VModal';
import VNoResults from '@vjs/components/VNoResults';
import VFormInform from '@vjs/components/VFormInform';
import eventHub from '@vjs/config/eventHub';
import VExamTicket from './VExamTicket.vue';
import VExamTicketAdd from './VExamTicketAdd.vue';


export default {
  name: 'VExamTickets',
  components: {
    QField,
    QPagination,
    QTooltip,
    QBtn,
    Modal,
    VExamTicket,
    VExamTicketAdd,
    VNoResults,
    VFormInform,
  },
  props: {
    uriGetTickets: {
      type: String,
      required: true,
    },
    uriAddTicket: {
      type: String,
      required: true,
    },
    uriEditTicket: {
      type: String,
      required: true,
    },
    uriDestroyTicket: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      addTicket: false,
      currentPage: 1,
      countByPage: 5,
      ticketItems: [],
      loading: false,
      qLoader: Loading,
    };
  },

  async mounted() {
    await this.$trans.add([
      'button',
      'placeholder',
      'label',
      'title',
      'validation',
      'notice',
      'curriculum',
      'test',
      'main',
      'table',
      'warning_text',
      'cabinet',
    ]);
    await this.$store.commit('setUrlTicketGroup', this.uriGetTickets);
    await this.$store.commit('setImgErrorMsg', this.trans('validation.img_max_size_teacher'));
    await this.requestGetTickets();
    eventHub.$on('addedTicket', async (evt) => {
      this.addTicket = false;
      await this.requestGetTickets();
    });
    eventHub.$on('loadFile', (evt) => { this.handleQLoader(evt); });
    eventHub.$on('deletedTicket', async (evt) => { await this.requestGetTickets(); });
    eventHub.$on('editedTicket', async (evt) => { await this.requestGetTickets(); });
    eventHub.$on('cancelAdding', (evt) => { this.addTicket = false; });
  },
  computed: {
    paginate() {
      if (this.loading) {
        return genArray({}, 5);
      }
      const current = (this.currentPage - 1) * this.countByPage;
      return this.ticketItems.slice(current, current + this.countByPage);
    },
    countOfPages() {
      let count = parseInt(this.ticketItems.length / this.countByPage, 10);
      const remains = this.ticketItems.length % this.countByPage;
      count += remains !== 0 ? 1 : 0;
      this.currentPage = 1;
      return count <= 1 ? 0 : count;
    },
  },
  methods: {
    handleQLoader(loading) {
      if (loading === true) {
        this.qLoader.show({ message: this.trans('label.wait_for_load') });
      } else if (loading === false) {
        this.qLoader.hide();
      }
    },
    openAdding() {
      this.addTicket = true;
      eventHub.$emit('closeEdit', true);
    },
    async requestGetTickets() {
      let pageTemp = this.currentPage;
      this.loading = true;
      const res = await requestWrapper.call(this, {
        url: this.uriGetTickets,
      }, true);

      if (!res.error) {
        this.ticketItems = res.examTickets;
      }
      this.loading = false;
      if (this.countOfPages !== 0 && this.countOfPages < pageTemp) {
        pageTemp -= 1;
      }
      if (this.countOfPages === 0) {
        pageTemp = 1;
      }
      this.currentPage = pageTemp;
    },
    // Запускается на событие @input в q-pagination
    async changePagination() {
      // Перевод страницы к началу при выборе страницы
      scrollTop(
        window,
        window.scrollY,
        window.scrollY - Math.abs(this.$refs.tickets.getBoundingClientRect().top),
        500,
      );
    },
  },
};
