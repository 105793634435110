import _ from 'lodash';
import {
  requestWrapper,
} from '@vjs/helpers';
import {
  QForm,
  QInput,
  QBtn,
  QSelect,
  QField,
  QRadio,
  QOptionGroup,
  QIcon,
  QCheckbox,
  QSlideTransition,
  QTable,
  QTr,
  QTd,
} from '@quasar/components';
import VFormInform from '@vjs/components/VFormInform';
import eventHub from '@vjs/config/eventHub';
import Validation from '../mixins/Validation';
import Files from '../mixins/Files';
import SelectUserFile from '../../SelectUserFile/index.vue';

export default {
  name: 'VStepSubjectAssessment',

  components: {
    VFormInform,
    QForm,
    QInput,
    QBtn,
    QSelect,
    QField,
    QRadio,
    QOptionGroup,
    QIcon,
    QCheckbox,
    QSlideTransition,
    SelectUserFile,
    QTable,
    QTr,
    QTd,
  },

  mixins: [Validation, Files],

  props: {
    uriGetSubjectsStatement: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      access: false,
      loading: false,
      form: {
        point: [],
        subjectPoint: [],
        diploma: [],
        diploma_2: [],
        required: [],
        subjectMapped: [],
      },
      info: {
        educational_institution: null,
        education: null,
        edu_lang: null,
        iin: null,
        statements: [],
        specialties: [],
      },
      formBackup: {},
      options: {
        points: [],
        required: [],
        subjectMapped: [],
        notGraded: [],
        divideBy: 1,
      },
    };
  },

  created() {
    eventHub.$on('SET_NEXT_STEP', this.requestForm);
  },

  beforeDestroy() {
    eventHub.$off('SET_NEXT_STEP', this.requestForm);
  },

  async mounted() {
    this.$emit('loading', true);
    this.loading = true;
    if (this.$store.state.vSteps.userInfo !== null) {
      const {
        educational_institution, education, edu_lang, iin,
      } = this.$store.state.vSteps.userInfo;
      this.info.educational_institution = educational_institution;
      this.info.education = education;
      this.info.edu_lang = edu_lang;
      this.info.iin = iin;
    }
    if (this.$store.state.vSteps.qualificationInfo !== null) {
      const { statements, specialties } = this.$store.state.vSteps.qualificationInfo;
      this.info.statements = statements;
      this.info.specialties = specialties;
    }
    const res = await requestWrapper.call(this, {
      url: this.uriGetSubjectsStatement,
      data: this.info,
      method: 'POST',
    });

    if (!res.error) {
      this.options = res.options;
      this.form.subjectPoint.push(...this.options.required);
      this.options.subjectMapped.forEach((value, index) => {
        value.columns.forEach((subject, key) => {
          this.form.subjectPoint.push(this.options.subjectMapped[index].columns[key]);
        });
      });
      this.form.required = this.options.required;
      this.form.subjectMapped = this.options.subjectMapped;
      this.calculateAverage();
    }
    this.loading = false;
    this.$emit('loading', false);
    this.$store.commit('SET_ACCESS_STEP', {
      step: 5,
      access: this.validate,
    });
  },

  computed: {
    validate() {
      let access = true;
      if (this.options.notGraded.length > 0 && this.options.notGraded.length !== this.form.point.filter(item => item !== undefined).length) {
        access = false;
      }
      return access && this.form.diploma.length > 0 && this.form.diploma_2.length > 0;
    },
  },

  watch: {
    validate(val) {
      this.$store.commit('SET_ACCESS_STEP', {
        step: 5,
        access: val,
      });
    },
    options: {
      handler(val) {
        this.calculateAverage();
      },
      deep: true,
    },
  },

  methods: {
    calculateAverage() {
      if (this.options.subjectMapped.length) {
        this.options.subjectMapped.forEach((value, index) => {
          let requiredSum = 0;
          this.options.required.forEach((requiredData) => {
            if (requiredData.grade !== '-') {
              requiredSum += Number(requiredData.grade);
            }
          });
          value.columns.forEach((specData) => {
            if (specData.grade !== '-') {
              requiredSum += Number(specData.grade);
            }
          });
          this.options.subjectMapped[index].average_point = requiredSum / this.options.divideBy;
        });
      }
    },
    setSubjectGrade(id, grade) {
      this.options.required.forEach((value, index) => {
        if (this.options.required[index].id === id) {
          this.options.required[index].grade = grade;
        }
      });
      this.options.subjectMapped.forEach((value, index) => {
        value.columns.forEach((subject, key) => {
          if (subject.id === id) {
            this.options.subjectMapped[index].columns[key].grade = grade;
          }
        });
      });

    },
    async requestForm() {
      this.loading = true;
      this.$store.commit('SET_NEXT_STEP');
      this.$store.commit('SET_SUBJECTS_FORM_VALUES', this.form);
      this.loading = false;
    },
  },
};
