import _ from 'lodash';
import {
  requestWrapper,
} from '@vjs/helpers';
import {
  QForm,
  QInput,
  QBtn,
  QSelect,
  QField,
  QRadio,
  QOptionGroup,
  QIcon,
  QCheckbox,
  QSlideTransition,
  QTable,
  QTr,
  QTd,
} from '@quasar/components';
import VFormInform from '@vjs/components/VFormInform';
// import eventHub from '@vjs/config/eventHub';
import Validation from '../../../views/VStatementCreateView/mixins/Validation';
import Files from '../../../views/VStatementCreateView/mixins/Files';
import SelectUserFile from '../../../views/SelectUserFile';

export default {
  name: 'VExternalStatementsGradesView',

  components: {
    VFormInform,
    QForm,
    QInput,
    QBtn,
    QSelect,
    QField,
    QRadio,
    QOptionGroup,
    QIcon,
    QCheckbox,
    QSlideTransition,
    SelectUserFile,
    QTable,
    QTr,
    QTd,
  },

  mixins: [Validation, Files],

  props: {
    uriGetSubjectsStatement: {
      type: String,
      required: true,
    },
    uriUpdateStatement: {
      type: String,
      required: true,
    },
    statement: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      form: {
        point: [],
        subjectPoint: [],
        diploma: [],
        diploma_2: [],
        required: [],
        subjectMapped: [],
        source: null,
      },
      info: {
        educational_institution: null,
        education: null,
        edu_lang: null,
        iin: null,
        statements: [],
        specialties: [],
      },
      formBackup: {},
      options: {
        points: [],
        required: [],
        subjectMapped: [],
        notGraded: [],
        divideBy: 1,
      },
      disabled: false,
      requiredSum: 0,
      specificSum: 0,
    };
  },

  beforeCreate() {
    this.$trans.add([
      'statement',
      'label',
      'button',
      'placeholder',
      'validation',
      'notice',
      'college',
      'warning_text',
      'main',
      'fields',
    ]);
  },

  async mounted() {
    this.info.educational_institution = this.statement.educational_institution;
    this.info.education = this.statement.education;
    this.info.edu_lang = this.statement.edu_lang;
    this.info.iin = this.statement.iin;
    this.info.specialties = this.statement.specialties;
    this.info.statements = this.statement.statements;
    this.form.source = this.statement.source;
    const res = await requestWrapper.call(this, {
      url: this.uriGetSubjectsStatement,
      params: this.info,
      method: 'POST',
    });

    if (!res.error) {
      this.options = res.options;
      this.form.subjectPoint.push(...this.options.required);
      this.options.subjectMapped.forEach((value, index) => {
        value.columns.forEach((subject, key) => {
          this.form.subjectPoint.push(this.options.subjectMapped[index].columns[key]);
        });
      });
      this.form.required = this.options.required;
      this.form.subjectMapped = this.options.subjectMapped;
      this.calculateAverage();
    }
  },
  computed: {
    validate() {
      let access = false;
      if (this.options.notGraded.length > 0
        && this.options.notGraded.length !== this.form.point.filter(item => item !== undefined).length) {
        access = true;
      }
      return access;
    },
  },
  watch: {
    validate(val) {
      this.disabled = val;
    },
    options: {
      handler(val) {
        this.calculateAverage();
      },
      deep: true,
    },
  },
  methods: {
    calculateAverage() {
      if (this.options.subjectMapped.length) {
        this.options.subjectMapped.forEach((value, index) => {
          let requiredSum = 0;
          this.options.required.forEach((requiredData) => {
            if (requiredData.grade !== '-') {
              requiredSum += Number(requiredData.grade);
            }
          });
          value.columns.forEach((specData) => {
            if (specData.grade !== '-') {
              requiredSum += Number(specData.grade);
            }
          });
          this.options.subjectMapped[index].average_point = requiredSum / this.options.divideBy;
        });
      }
    },
    setSubjectGrade(id, grade) {
      this.options.required.forEach((value, index) => {
        if (this.options.required[index].id === id) {
          this.options.required[index].grade = grade;
        }
      });
      this.options.subjectMapped.forEach((value, index) => {
        value.columns.forEach((subject, key) => {
          if (subject.id === id) {
            this.options.subjectMapped[index].columns[key].grade = grade;
          }
        });
      });
    },
    async saveGrades(averagePoint = 0) {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.uriUpdateStatement,
        data: { ...this.form, averagePoint },
      });
      if (res.error && res.data?.errors) {
        this.$notify({
          text: _.first(_.flatten(res.data.errors[_.first(Object.keys(res.data.errors))])) ?? this.trans('notice.error_on_server'),
          type: 'error',
        });
        if (_.has(res.data.errors, 'signXml')) {
          this.signXml = null;
        }
        this.$globalLoading.hide();
      }
    },
  },
};
