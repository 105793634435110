var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "s-application-block mb-30"
  }, [_c('div', {
    staticClass: "s-application-block__columns border-bottom pb-5"
  }, [_c('div', {
    staticClass: "s-application-block__column-left"
  }, [_c('div', {
    staticClass: "s-application-block__head"
  }, [_c('div', {
    staticClass: "s-application-block__name mb-10"
  }, [_vm._v("\n          " + _vm._s(_vm.commonData.label) + "\n        ")]), _vm._v(" "), _c('div', {
    staticClass: "s-application-block__mark"
  }, [_vm._v("\n          " + _vm._s(_vm.commonData.identifierDate) + "\n        ")])]), _vm._v(" "), _vm.commonData.isParent ? _c('div', {
    staticClass: "data mt-10 mb-10"
  }, [_vm._v(" " + _vm._s(_vm.commonData.userName) + " ")]) : _vm._e()]), _vm._v(" "), _c('div', {
    staticClass: "s-application-block__column-right"
  }, [_c('div', {
    staticClass: "s-application-block__controls"
  }, [_c('div', {
    staticClass: "s-btn s-btn--border s-btn--thm-light-purple",
    on: {
      "click": function click($event) {
        _vm.stepsTrigger = !_vm.stepsTrigger;
      }
    }
  }, [_c('div', {
    class: "s-str-ico-rev s-ico--thm-angle-".concat(_vm.stepsTrigger ? 'up' : 'down')
  }, [_vm._v("\n            " + _vm._s(_vm.stepsTrigger ? _vm.trans('statement.hide') : _vm.trans('statement.show_more')) + "\n          ")])]), _vm._v(" "), _vm.canRemove ? _c('q-btn', {
    attrs: {
      "icon": "trash",
      "color": "red",
      "squire": "",
      "outline": ""
    },
    on: {
      "click": function click($event) {
        _vm.modalConfirmRemove = true;
      }
    }
  }, [_c('q-tooltip', [_vm._v("\n            " + _vm._s(_vm.trans('statement.remove')) + "\n          ")])], 1) : _vm._e()], 1)])]), _vm._v(" "), _c('div', {
    staticClass: "s-application-block__columns"
  }, [_c('div', {
    staticClass: "s-application-block__column-left no-padding"
  }, [_c('div', {
    staticClass: "s-application-block__body no-padding"
  }, [_c('transition', {
    attrs: {
      "name": "a-slide-vertical"
    }
  }, [_vm.stepsTrigger ? _c('div', {
    staticClass: "row s-application-block__hiddenBlock border-bottom"
  }, [_vm._l(_vm.hidden, function (data) {
    return _c('div', {
      staticClass: "head d-flex align-items-center",
      class: data.class
    }, [_c('div', {
      staticClass: "icon"
    }, [_c('img', {
      staticClass: "s-application-block__images",
      attrs: {
        "src": data.icon
      }
    })]), _vm._v(" "), _c('div', {
      staticClass: "description"
    }, [_c('div', {
      staticClass: "s-application-block__mark mb-1"
    }, [_vm._v("\n                  " + _vm._s(data.label) + ":\n                ")]), _vm._v(" "), _c('div', {
      staticClass: "s-application-block__mark s-application-block__mark--blue"
    }, [_vm._v("\n                  " + _vm._s(data.value) + "\n                ")])])]);
  }), _vm._v(" "), _vm._l(_vm.docsBtns, function (docsBtn, key) {
    return _c('div', {
      key: key,
      staticClass: "head d-flex align-items-center",
      class: docsBtn.class
    }, [_c('div', {
      staticClass: "icon"
    }, [_c('img', {
      staticClass: "s-application-block__images",
      attrs: {
        "src": docsBtn.icon
      }
    })]), _vm._v(" "), _c('div', {
      staticClass: "description"
    }, [_c('div', {
      staticClass: "s-application-block__mark mb-1"
    }, [_vm._v("\n                  " + _vm._s(docsBtn.label) + ":\n                ")]), _vm._v(" "), _c('div', {
      staticClass: "s-application-block__mark s-application-block__mark--blue"
    }, [docsBtn.value.active ? _c('a', {
      staticClass: "s-application-block__mark s-application-block__mark--yellow",
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.handleOpenModal(docsBtn.value);
        }
      }
    }, [_vm._v("\n                    " + _vm._s(_vm.trans('button.add')) + "\n                  ")]) : _c('div', {
      staticClass: "s-application-block__mark s-application-block__mark--green"
    }, [_vm._v("\n                    " + _vm._s(_vm.trans('label.added')) + "\n                  ")])])])]);
  })], 2) : _vm._e()]), _vm._v(" "), _c('div', {
    staticClass: "s-table-cabinet s-w-100"
  }, [_c('div', {
    staticClass: "s-table__head"
  }, [_c('div', {
    staticClass: "row mt-30"
  }, [_vm._l(_vm.columns, function (column) {
    return _c('div', {
      staticClass: "head s-word-break",
      class: column.class
    }, [_vm._v("\n                " + _vm._s(column.label) + "\n              ")]);
  }), _vm._v(" "), _c('div', {
    staticClass: "col-lg-1 head"
  }, [_vm._v("\n                " + _vm._s(_vm.trans('fields.points')) + "\n              ")])], 2)]), _vm._v(" "), _c('div', {
    staticClass: "s-table__body application-data-body"
  }, _vm._l(_vm.items, function (item) {
    return _c('div', {
      staticClass: "row mt-20 border-top"
    }, [_vm._l(_vm.columns, function (column) {
      return _c('div', {
        staticClass: "data s-word-break",
        class: column.class,
        attrs: {
          "data-table-head": column.label
        }
      }, [_vm._v("\n                " + _vm._s(_vm.getAttribute(item, column.attribute)) + "\n              ")]);
    }), _vm._v(" "), _c('div', {
      staticClass: "col-lg-1 s-to-center data",
      attrs: {
        "data-table-head": _vm.trans('fields.points')
      }
    }, [_c('q-btn', {
      staticClass: "s-application-block__show-subject",
      attrs: {
        "icon": item.subjects.icon,
        "label-wider": "",
        "ellipsis": "",
        "no-caps": ""
      },
      on: {
        "click": function click($event) {
          return _vm.triggerSubject(item.subjects.link, item.user.fullName);
        }
      }
    })], 1)], 2);
  }), 0)])], 1)])]), _vm._v(" "), _c('div', {
    staticClass: "s-application-block__steps mt-30"
  }, _vm._l(_vm.steps, function (step, stepIndex) {
    return _c('div', {
      key: stepIndex,
      staticClass: "s-application-block__step"
    }, [_c('div', {
      staticClass: "s-application-block__step-head",
      class: "s-application-block__step-head--".concat(step.status)
    }, [_c('div', {
      staticClass: "s-vis-ellipsis"
    }, [_c('span', {
      class: "s-str-ico s-ico--thm-".concat(_vm.getIconByStatus(step.status))
    }, [_vm._v("\n            " + _vm._s(step.title) + "\n          ")])])]), _vm._v(" "), _c('transition', {
      attrs: {
        "name": "a-slide-vertical"
      }
    }, [_vm.stepsTrigger ? _c('div', {
      staticClass: "s-application-block__step-body"
    }, [_c('div', {
      staticClass: "s-application-block__step-text",
      domProps: {
        "innerHTML": _vm._s(step.text)
      }
    }), _vm._v(" "), _c('div', {
      staticClass: "d-flex"
    }, [_vm._l(step.controls, function (ctrl, ctrlIndex) {
      return [_c('div', {
        key: ctrlIndex
      }, [ctrl.type !== 'button' ? _c('q-btn', {
        class: ctrlIndex % 2 ? 'ml-10' : 'mr-10',
        attrs: {
          "label": ctrl.label,
          "color": ctrl.color ? ctrl.color : 'green',
          "disable": ctrl.disabled,
          "no-caps": "",
          "type": ctrl.disabled ? 'button' : 'a',
          "to": ctrl.disabled ? undefined : ctrl.link,
          "target": ctrl.target ? ctrl.target : '_self'
        }
      }) : _c('q-btn', {
        class: ctrlIndex % 2 ? 'ml-10' : 'mr-10',
        attrs: {
          "no-caps": "",
          "label": ctrl.label,
          "color": ctrl.color ? ctrl.color : 'green'
        },
        on: {
          "click": function click($event) {
            return _vm.setGrantRefuse(ctrl.link);
          }
        }
      })], 1)];
    })], 2)]) : _vm._e()])], 1);
  }), 0), _vm._v(" "), _c('v-confirm-dialog-new', {
    attrs: {
      "persistent": "",
      "in-body": "",
      "active": _vm.modalConfirmRemove || _vm.modalConfirmGrantRefuse,
      "loading": _vm.loadingRemove,
      "confirm": _vm.modalConfirmRemove ? _vm.removeApplication : _vm.grantRefuse
    },
    on: {
      "modal-close": function modalClose($event) {
        _vm.modalConfirmRemove ? _vm.modalConfirmRemove = $event : _vm.modalConfirmGrantRefuse = $event;
      }
    }
  }, [_vm.modalConfirmGrantRefuse ? [_c('v-form-inform', {
    staticClass: "m-0",
    attrs: {
      "message": _vm.trans('warning_text.grant_refusal')
    }
  }), _vm._v(" "), _c('q-input', {
    attrs: {
      "dense": "",
      "debounce": "300",
      "type": "textarea",
      "placeholder": _vm.trans('label.decline_message'),
      "label": _vm.trans('label.decline_message'),
      "color": "blue"
    },
    model: {
      value: _vm.form.message,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "message", $$v);
      },
      expression: "form.message"
    }
  })] : _vm._e()], 2), _vm._v(" "), _vm.renderDocsModal ? _c('v-application-docs-modal', {
    attrs: {
      "modal": _vm.docsModal.active,
      "select": _vm.docsModal.select,
      "type": _vm.docsModal.type,
      "uri-user-files": _vm.docsModal.uriUserFiles,
      "uri-update-files": _vm.docsModal.uriUpdateFiles
    },
    on: {
      "modal-close": _vm.handleCloseModal
    }
  }) : _vm._e(), _vm._v(" "), _vm.options.required.length > 0 ? _c('v-application-subjects-modal', {
    attrs: {
      "modal": _vm.subjectsModal,
      "required": _vm.options.required,
      "name": _vm.userName,
      "class-for-table": _vm.options.class
    },
    on: {
      "modal-close": function modalClose($event) {
        _vm.subjectsModal = $event;
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }