var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('q-dialog', {
    attrs: {
      "value": _vm.modal
    },
    on: {
      "input": _vm.handleModal
    }
  }, [_c('v-modal', {
    attrs: {
      "size": "480"
    }
  }, [_c('div', {
    staticClass: "mb-30"
  }, [_vm._v("\n      " + _vm._s(_vm.trans('notice.region_works_with_egov')) + "\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "row justify-content-between"
  }, [_c('div', {
    staticClass: "col-12 col-sm-auto mb-10"
  }, [_c('q-btn', {
    directives: [{
      name: "close-popup",
      rawName: "v-close-popup"
    }],
    staticClass: "s-w-100",
    attrs: {
      "width": "20",
      "label": _vm.trans('button.close'),
      "label-wider": "",
      "color": "grey"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-12 col-sm-auto"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "width": "20",
      "label": _vm.trans('button.go_to'),
      "color": "green",
      "target": "_blank",
      "type": "a",
      "to": _vm.link
    }
  })], 1)])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }