var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "catalog"
  }, [_vm.itemsPaginated.length === 0 ? _c('v-no-results', {
    attrs: {
      "image": "search",
      "title": _vm.trans('warning_text.no_results_statements_title')
    }
  }) : _vm._e(), _vm._v(" "), _vm._l(_vm.itemsPaginated, function (application) {
    return _c('div', {
      key: application.id
    }, [application.type === 'statement' ? _c('v-application-item', {
      class: {
        'is-loading': _vm.loading
      },
      attrs: {
        "identifier": application.id,
        "type": application.type,
        "columns": application.meta.columns,
        "items": application.data,
        "hidden": application.hiddenData,
        "common-data": application.commonData,
        "can-remove": application.canRemove,
        "uri-remove": application.uriRemove,
        "steps": application.steps,
        "docs-btns": application.docsBtns
      },
      on: {
        "removed": _vm.removeApplication
      }
    }) : _c('v-application-service-item', {
      staticClass: "mb-30",
      class: {
        'is-loading': _vm.loading
      },
      attrs: {
        "identifier": application.id,
        "identifier-date": application.identifierDate,
        "label": application.label,
        "type": application.type,
        "locked": application.locked,
        "desc": application.desc,
        "desc-additional": application.descAdditional,
        "can-remove": application.canRemove,
        "uri-remove": application.uriRemove,
        "steps": application.steps,
        "docs-btn": application.docsBtn
      },
      on: {
        "removed": _vm.removeApplication
      }
    })], 1);
  }), _vm._v(" "), _c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-auto"
  }, [_c('q-pagination', {
    attrs: {
      "max": _vm.countOfPages,
      "max-pages": 5,
      "boundary-links": true,
      "input": _vm.countOfPages > 5,
      "input-class": "s-c-blue"
    },
    on: {
      "input": _vm.changePagination
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }