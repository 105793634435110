var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-time-sheet-container"
  }, [_c('v-time-sheet-title', {
    attrs: {
      "name": _vm.groupName
    }
  }), _vm._v(" "), _c('v-time-sheet-filter', {
    ref: "timeSheetFilter",
    attrs: {
      "loading": _vm.timeSheetLoad,
      "is-exportable": _vm.isExportable,
      "educator-name": _vm.educatorName
    }
  }), _vm._v(" "), _c('v-time-sheet-legend'), _vm._v(" "), !_vm.attendance.length ? _c('v-no-results', {
    attrs: {
      "image": "search",
      "title": _vm.trans('warning_text.no_results_title'),
      "text": _vm.trans('warning_text.no_results_text')
    }
  }) : _c('v-time-sheet-table', {
    ref: "timeSheet",
    attrs: {
      "loading": _vm.timeSheetLoad,
      "current-day": _vm.currentDay,
      "reasons-list": _vm.reasonsList,
      "uri-user-files": _vm.uriUserFiles
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }