var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    attrs: {
      "action": _vm.action,
      "method": _vm.method
    },
    on: {
      "submit": function submit($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.handleAjaxFormSubmit();
      }
    }
  }, [_vm._l(_vm.fields, function (field) {
    return [_c(field.component, _vm._g({
      key: field.name,
      tag: "component",
      attrs: {
        "field": field,
        "field-name": field.labelTransKey ? _vm.trans(field.labelTransKey) : field.label,
        "errors": _vm.getErrors(field.name)
      },
      model: {
        value: field.value,
        callback: function callback($$v) {
          _vm.$set(field, "value", $$v);
        },
        expression: "field.value"
      }
    }, field.events ? field.events : {}))];
  }), _vm._v(" "), _c('div', {
    staticClass: "row-sort-between"
  }, [_c('q-btn', {
    staticClass: "s-btn--w-150",
    attrs: {
      "color": "blue",
      "label": _vm.trans('button.cancel')
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('onClose');
      }
    }
  }), _vm._v(" "), _c('q-btn', {
    staticClass: "s-btn--w-150",
    attrs: {
      "color": "aqua",
      "label": _vm.trans('button.move'),
      "type": "submit"
    }
  })], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }