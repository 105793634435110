<template>
  <div class="sc-curriculum-program__table sc-curriculum-program__table--scroll">
    <table class="s-w-100" cellspacing="0">
      <thead class="sc-curriculum-program__table--head">
        <tr class="sc-curriculum-program__table--tr">
          <th
            class="sc-curriculum-program__table--th"
            rowspan="2"
          >
            {{ trans('curriculum.program.module') }}
          </th>
          <th
            class="sc-curriculum-program__table--th"
            rowspan="2"
          >
            {{ trans('curriculum.program.total_module_hours') }}
          </th>
          <th
            class="sc-curriculum-program__table--th"
            :colspan="semesterNumber"
          >
            {{ trans('curriculum.program.including') }}
          </th>
        </tr>
        <tr class="sc-curriculum-program__table--tr">
          <th
            v-for="(i, key) in courseNumber"
            :key="`course-number${key}`"
            colspan="2"
            class="sc-curriculum-program__table--th course-number"
          >
            {{ $trans.get('curriculum.program.course', {'n': i}) }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="sc-curriculum-program__table--tr">
          <td class="sc-curriculum-program__table--td"></td>
          <td class="sc-curriculum-program__table--td"></td>
          <td
            v-for="(i, key) in semesterNumber"
            :key="`semester-number${key}`"
            class="sc-curriculum-program__table--td semester-number"
          >
            {{ $trans.get('curriculum.program.semester', {'n': i}) }}
          </td>
        </tr>
        <tr
          v-for="(discipline, index) in items"
          :key="index"
          class="sc-curriculum-program__table--tr"
          :class="discipline.is_module ? 'sc-curriculum-program__table--chapter' : ''"
        >
          <td class="sc-curriculum-program__table--td">{{ discipline.name }}</td>
          <td class="sc-curriculum-program__table--td">{{ discipline.total_hours }}</td>
          <td
            v-for="(i, key) in semesterNumber"
            :key="`semester-number${key}`"
            class="sc-curriculum-program__table--td semester-number"
          >
            {{ discipline.semesters ? discipline.semesters[i] : null }}
          </td>
        </tr>
        <tr
          class="sc-curriculum-program__table--tr sc-curriculum-program__table--chapter"
        >
          <td class="sc-curriculum-program__table--td">{{ trans('curriculum.discipline_group_total') }}</td>
          <td class="sc-curriculum-program__table--td">
            {{ calculateTotals('total_hours') }}
          </td>
          <td
            v-for="(i, key) in semesterNumber"
            :key="`semester-number${key}`"
            class="sc-curriculum-program__table--td semester-number"
          >
            {{ calculateTotals('semesters', i) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import helper from '../../../mixins/helper';

export default {
  name: 'HoursDistributionTable',
  mixins: [helper],
  computed: {
    semesterNumber() {
      return this.$store.getters.semesterNumber;
    },
    courseNumber() {
      return this.$store.getters.courseNumber;
    },
    items() {
      return this.$store.getters.disciplines;
    },
  },
};
</script>
