var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "s-modal fade",
    attrs: {
      "id": 'modal-confirm-candidate-' + _vm.statement.id,
      "tabindex": "-1"
    }
  }, [_c('div', {
    staticClass: "s-modal__container"
  }, [_c('div', {
    staticClass: "s-modal__head"
  }, [_c('div', {
    staticClass: "s-modal__close",
    attrs: {
      "data-toggle": "modal",
      "data-target": '#modal-confirm-candidate-' + _vm.statement.id
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "s-modal__body"
  }, [_c('form', {
    attrs: {
      "action": _vm.statementDisapprove,
      "method": "post"
    }
  }, [_c('div', {
    staticClass: "mb-30"
  }, [_c('label', {
    staticClass: "s-form__label mb-10"
  }, [_vm._v(_vm._s(_vm.trans('label.decline_message')) + ": "), _c('i', [_vm._v("*")])]), _vm._v(" "), _c('textarea', {
    staticClass: "s-form__textarea",
    attrs: {
      "name": "message"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "_token"
    },
    domProps: {
      "value": _vm.csrfToken
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "id": "sign-statement",
      "type": "hidden",
      "name": "sign"
    },
    domProps: {
      "value": _vm.signXml
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "row-sort-between-btn"
  }, [_c('div', {
    staticClass: "s-btn s-btn--thm-grey s-btn--w-150",
    attrs: {
      "data-toggle": "modal",
      "data-target": '#modal-confirm-candidate-' + _vm.statement.id
    }
  }, [_vm._v("\n              " + _vm._s(_vm.trans('button.cancel')) + "\n            ")]), _vm._v(" "), _c('div', {
    staticClass: "s-btn s-btn--thm-green s-btn--w-150",
    attrs: {
      "data-action": "send-ajax"
    }
  }, [_vm._v("\n              " + _vm._s(_vm.trans('button.send')) + "\n            ")])])])])])]), _vm._v(" "), _c('div', {
    staticClass: "s-modal fade",
    attrs: {
      "id": 'modal-confirm-candidate-rework-' + _vm.statement.id,
      "tabindex": "-1"
    }
  }, [_c('div', {
    staticClass: "s-modal__container"
  }, [_c('div', {
    staticClass: "s-modal__head"
  }, [_c('div', {
    staticClass: "s-modal__close",
    attrs: {
      "data-toggle": "modal",
      "data-target": '#modal-confirm-candidate-rework-' + _vm.statement.id
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "s-modal__body"
  }, [_c('form', {
    attrs: {
      "action": _vm.setModalConfirmCandidateRework,
      "method": "post"
    }
  }, [_c('div', {
    staticClass: "mb-30"
  }, [_c('label', {
    staticClass: "s-form__label mb-10",
    attrs: {
      "for": "average_point"
    }
  }, [_vm._v("\n              " + _vm._s(_vm.trans('label.average_point')) + ": "), _c('i', [_vm._v("*")])]), _vm._v(" "), _c('input', {
    staticClass: "s-form__input mb-10 pt-10 mt-20",
    attrs: {
      "id": "average_point",
      "name": "average_point"
    }
  }), _vm._v(" "), _c('label', {
    staticClass: "s-form__label mb-10"
  }, [_vm._v(_vm._s(_vm.trans('label.decline_message')) + ":")]), _vm._v(" "), _c('textarea', {
    staticClass: "s-form__textarea",
    attrs: {
      "name": "message"
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "_token"
    },
    domProps: {
      "value": _vm.csrfToken
    }
  }), _vm._v(" "), _c('input', {
    attrs: {
      "id": "sign-statement",
      "type": "hidden",
      "name": "sign"
    },
    domProps: {
      "value": _vm.signXml
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "row-sort-between-btn"
  }, [_c('div', {
    staticClass: "s-btn s-btn--thm-grey s-btn--w-150",
    attrs: {
      "data-toggle": "modal",
      "data-target": '#modal-confirm-candidate-rework-' + _vm.statement.id
    }
  }, [_vm._v("\n              " + _vm._s(_vm.trans('button.cancel')) + "\n            ")]), _vm._v(" "), _c('div', {
    staticClass: "s-btn s-btn--thm-green s-btn--w-150",
    attrs: {
      "data-action": "send-ajax"
    }
  }, [_vm._v("\n              " + _vm._s(_vm.trans('button.send')) + "\n            ")])])])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }