var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.loading ? 'div' : 'a', {
    tag: "component",
    staticClass: "s-catalog-item mb-30",
    class: {
      'is-loading': _vm.loading
    },
    attrs: {
      "href": _vm.linkItem
    }
  }, [_c('div', {
    staticClass: "s-catalog-item__image",
    style: !_vm.loading && _vm.linkImage ? "background-image: url(".concat(_vm.linkImage, ");") : ''
  }), _vm._v(" "), _c('div', {
    staticClass: "s-catalog-item__content"
  }, [_c('div', {
    staticClass: "s-catalog-item__head"
  }, [_c('div', {
    staticClass: "s-catalog-item__title"
  }, [_vm._v("\n        " + _vm._s(_vm.title) + "\n      ")])]), _vm._v(" "), _c('div', {
    staticClass: "s-catalog-item__body"
  }, [_c('div', {
    staticClass: "s-catalog-item__body-item mb-10"
  }, [_c('span', {
    staticClass: "s-medium s-catalog-item__to-column"
  }, [_vm._v(_vm._s(_vm.trans('label.org_type')) + ":")]), _vm._v("\n        " + _vm._s(_vm.orgType) + "\n      ")]), _vm._v(" "), _c('div', {
    staticClass: "s-catalog-item__body-item mb-10"
  }, [_c('span', {
    staticClass: "s-medium s-catalog-item__to-column"
  }, [_vm._v(_vm._s(_vm.trans('label.phone')) + ":")]), _vm._v("\n        " + _vm._s(_vm.phoneNumber) + "\n      ")]), _vm._v(" "), _c('div', {
    staticClass: "s-catalog-item__body-item"
  }, [_c('span', {
    staticClass: "s-medium"
  }, [_vm._v(_vm._s(_vm.trans('label.address')) + ":")]), _vm._v("\n        " + _vm._s(_vm.address) + "\n      ")])]), _vm._v(" "), _c('div', {
    staticClass: "s-catalog-item__foot"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-auto"
  }, [_c('div', {
    staticClass: "s-str-ico-fa s-ico-fa--thm-user-circle s-c-blue s-catalog-item__to-column"
  }, [_c('span', {
    staticClass: "s-medium"
  }, [_vm._v(_vm._s(_vm.trans('label.statement_admission')) + ": ")]), _vm._v(" "), _c('span', {
    staticClass: "s-medium",
    class: _vm.admissionActive ? 's-c-green' : 's-c-grey'
  }, [_vm._v("\n              " + _vm._s(_vm.admissionActive ? _vm.trans('label.admission_active') : _vm.trans('label.admission_not_active')) + "\n            ")])])]), _vm._v(" "), _vm.loading ? _c('div', {
    staticClass: "col-auto"
  }) : _vm._e()])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }