var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('q-form', {
    attrs: {
      "autocorrect": "off",
      "autocapitalize": "off",
      "autocomplete": "off",
      "spellcheck": "false"
    },
    on: {
      "submit": _vm.handleAjaxFormSubmit
    }
  }, [_c('v-wrapper-q-select', {
    attrs: {
      "options": _vm.chapters,
      "extra-binds": {
        required: true,
        labelTransKey: 'curriculum.program.choose_chapter'
      }
    },
    model: {
      value: _vm.fields.parent_id,
      callback: function callback($$v) {
        _vm.$set(_vm.fields, "parent_id", $$v);
      },
      expression: "fields.parent_id"
    }
  }), _vm._v(" "), _c('q-input', {
    attrs: {
      "required": "",
      "label": _vm.trans('curriculum.program.lesson_title')
    },
    model: {
      value: _vm.fields.name,
      callback: function callback($$v) {
        _vm.$set(_vm.fields, "name", $$v);
      },
      expression: "fields.name"
    }
  }), _vm._v(" "), _c('q-input', {
    attrs: {
      "type": "textarea",
      "dense": "",
      "debounce": "300",
      "autogrow": "",
      "label": _vm.trans('curriculum.program.criteria_label')
    },
    model: {
      value: _vm.fields.criteria,
      callback: function callback($$v) {
        _vm.$set(_vm.fields, "criteria", $$v);
      },
      expression: "fields.criteria"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "s-group s-group--equal s-group--wider mt-10"
  }, [_c('q-btn', {
    attrs: {
      "no-caps": "",
      "color": "grey",
      "label": _vm.trans('button.cancel')
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('onClose');
      }
    }
  }), _vm._v(" "), _c('q-btn', {
    attrs: {
      "disable": !_vm.validate,
      "color": "green",
      "no-caps": "",
      "label": _vm.trans('button.send'),
      "type": "submit"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }