import {QTooltip} from '@quasar/components';
import { helper, columns, modals, mappedData } from '../../mixins';
import { eventBus } from '@common/mixins';

export default {
  name: 'VTimeSheetTable',
  components: {
    QTooltip,
  },
  mixins: [helper, columns, modals, mappedData],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    currentDay: {
      type: String,
      default: '',
    },
    reasonsList: {
      type: Array,
      default: () => [],
    },
    uriUserFiles: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      activeTooltipText: null,
      activeTooltipId: null,
      headerHeight: null,
      rowHeight: {},
    };
  },
  computed: {
    availableDates() {
      return this.$store.getters.availableDates;
    },
    holidays() {
      return this.$store.getters.holidays;
    },
    attendance() {
      return this.$store.getters.attendance;
    },
    overallData() {
      return this.$store.getters.overallData;
    },
    dayColumns() {
      return this.$store.getters.dayColumns;
    },
    daysCount() {
      return this.$store.getters.daysCount;
    },
    dateStrings() {
      return this.$store.getters.dateStrings;
    },
    allowEditAll() {
      return this.$store.getters.allowEditAll;
    },
  },
  created() {
    window.addEventListener('resize', this.reCalcSizes);
  },
  mounted() {
    this.reCalcSizes();
    eventBus.$on('update-heights', () => {
      this.rowHeight = {};
      this.$nextTick(this.reCalcSizes);
    });
  },
  destroyed() {
    window.removeEventListener('resize', this.reCalcSizes);
  },
  methods: {
    checkIsEditable(dayRow, isTotal) {
      return dayRow.is_work === true
        && dayRow.attendance.attendance !== null
        && dayRow.canEdit === true
        && isTotal
        && this.$store.getters.allowEditAll;
    },
  },
};
