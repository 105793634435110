var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('q-field', {
    attrs: {
      "hide-bottom-space": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('q-btn', {
          staticStyle: {
            "border-top-left-radius": "0",
            "border-bottom-left-radius": "0"
          },
          attrs: {
            "squire": "",
            "color": "blue",
            "icon": "close"
          },
          on: {
            "click": _vm.handleRemove
          }
        })];
      },
      proxy: true
    }])
  }, [_vm._v("\n  " + _vm._s(_vm.value) + "\n  ")]);
}
var staticRenderFns = []

export { render, staticRenderFns }