var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container-body-wrapper"
  }, [_vm._t("default"), _vm._v(" "), _c('div', {
    staticClass: "s-group s-table-schedule__group mb-20"
  }, [_c('a', {
    staticClass: "s-btn s-btn--thm-green",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.exportDownload($event);
      }
    }
  }, [_c('span', {
    staticClass: "s-str-ico s-str-ico--thm-angle-double-down s-str-ico--wider"
  }, [_vm._v(_vm._s(_vm.trans('button.export')))])]), _vm._v(" "), _vm.accessEdit ? _c('a', {
    staticClass: "s-btn s-btn--thm-blue",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        _vm.isWeeksModalOpen = true;
      }
    }
  }, [_c('div', {
    staticClass: "s-str-ico s-str-ico--thm-plus"
  }, [_vm._v("\n        " + _vm._s(_vm.trans('button.duplicate_to_next_week')) + "\n      ")])]) : _vm._e(), _vm._v(" "), _c('v-wrapper-multi-select', {
    staticStyle: {
      "max-width": "28rem"
    },
    attrs: {
      "extra-binds": {
        placeholder: _vm.trans('label.group_slash_name'),
        showPointer: false,
        allowEmpty: false
      },
      "options": _vm.training_schedules
    },
    model: {
      value: _vm.training_schedule,
      callback: function callback($$v) {
        _vm.training_schedule = $$v;
      },
      expression: "training_schedule"
    }
  }), _vm._v(" "), _c('v-wrapper-multi-select', {
    staticStyle: {
      "max-width": "28rem"
    },
    attrs: {
      "extra-binds": {
        placeholder: _vm.trans('training_schedule.semester'),
        showPointer: false,
        allowEmpty: false
      },
      "options": _vm.semesters
    },
    on: {
      "input": function input($event) {
        return _vm.loadData(1);
      }
    },
    model: {
      value: _vm.semester,
      callback: function callback($$v) {
        _vm.semester = $$v;
      },
      expression: "semester"
    }
  }), _vm._v(" "), _c('v-wrapper-multi-select', {
    staticStyle: {
      "max-width": "28rem"
    },
    attrs: {
      "extra-binds": {
        placeholder: _vm.trans('training_schedule.week'),
        showPointer: false,
        allowEmpty: false
      },
      "options": _vm.weeks
    },
    on: {
      "input": _vm.loadData
    },
    model: {
      value: _vm.week,
      callback: function callback($$v) {
        _vm.week = $$v;
      },
      expression: "week"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "s-table__resp-wrapper"
  }, [_c('div', {
    staticClass: "s-table__resp"
  }, [_c('div', {
    staticClass: "s-table-schedule__wrapper"
  }, [_c('table', {
    staticClass: "s-table-schedule"
  }, [_c('thead', {
    staticClass: "s-table-schedule__head"
  }, [_c('tr', [_c('th', {
    staticClass: "s-table-schedule__col-time"
  }, [_vm._v("\n                " + _vm._s(_vm.trans('label.date_time')) + "\n              ")]), _vm._v(" "), _vm._l(_vm.days, function (day) {
    return _c('th', [_vm._v("\n                " + _vm._s(day.week_name) + " "), _c('br'), _vm._v(" " + _vm._s(day.date) + "\n              ")]);
  })], 2)]), _vm._v(" "), _c('tbody', {
    staticClass: "s-table-schedule__body"
  }, _vm._l(_vm.refs.times, function (time) {
    return _c('tr', [_c('td', {
      staticClass: "s-table-schedule__col-time"
    }, [_vm.accessEdit ? _c('div', {
      staticClass: "s-btn-table s-btn--ico s-table-schedule__col-time--btn",
      on: {
        "click": function click($event) {
          return _vm.showEditTime(time.id);
        }
      }
    }, [_c('div', {
      staticClass: "s-ico s-ico--thm-pencil"
    })]) : _vm._e(), _vm._v(" "), _c('strong', [_vm._v(_vm._s(time.start_time))]), _vm._v(" "), _c('strong', [_vm._v(_vm._s(time.end_time))])]), _vm._v(" "), _vm._l(_vm.days, function (day) {
      return _c('td', {
        style: {
          verticalAlign: day.lessons && day.lessons.length === 0 || !day.lessons || day.lessons && !day.lessons[time.id] ? 'middle !important' : 'top'
        }
      }, [day.lessons && day.lessons[time.id] ? _vm._l(day.lessons[time.id], function (lesson) {
        return _c('div', {
          staticClass: "s-table-schedule__card"
        }, [_c('div', {
          staticClass: "row-sort-start mb-5"
        }, [_c('div', {
          staticClass: "s-group"
        }, [lesson.cabinet.id ? _c('div', {
          staticClass: "s-table-schedule__card-tag-blue"
        }, [_vm._v("\n                          " + _vm._s(lesson.cabinet.name) + "\n                        ")]) : _vm._e(), _vm._v(" "), _c('div', {
          staticClass: "s-table-schedule__card-tag-green",
          staticStyle: {
            "overflow": "hidden",
            "max-width": "330px"
          }
        }, [_vm._v("\n                          " + _vm._s(lesson.discipline_name) + "\n                        ")])])]), _vm._v(" "), _c('div', {
          staticClass: "s-table-schedule__card-label mb-5"
        }, [_vm._v("\n                      " + _vm._s(_vm.trans('training_schedule.fields.teachers')) + "\n                    ")]), _vm._v(" "), _c('div', {
          staticClass: "s-table-schedule__card-desc mb-5"
        }, [_vm._v("\n                      " + _vm._s(lesson.teacher.name) + "\n                      "), _vm._l(lesson.other_teachers, function (other_teacher) {
          return [_vm._v("\n                        " + _vm._s(other_teacher.teacher.name) + " "), _c('br')];
        })], 2), _vm._v(" "), _c('div', {
          staticClass: "s-table-schedule__card-desc"
        }, [_vm._v("\n                      " + _vm._s(lesson.type.name) + "\n                    ")]), _vm._v(" "), lesson.subgroup.id ? [_c('div', {
          staticClass: "s-table-schedule__card-label mb-5"
        }, [_vm._v("\n                        " + _vm._s(_vm.trans('training_schedule.fields.subgroup_id')) + "\n                      ")]), _vm._v(" "), _c('div', {
          staticClass: "s-table-schedule__card-desc mb-5"
        }, [_vm._v("\n                        " + _vm._s(lesson.subgroup.name) + "\n                      ")])] : _vm._e(), _vm._v(" "), _vm.type == 'personal' && lesson.contingent && lesson.contingent.length ? [_c('div', {
          staticClass: "s-table-schedule__card-label mb-5"
        }, [_vm._v("\n                        " + _vm._s(_vm.trans('training_schedule.fields.students')) + "\n                      ")]), _vm._v(" "), _c('div', [_vm._l(lesson.contingent, function (student) {
          return [_vm._v("\n                          " + _vm._s(student.user.name) + " "), _c('br')];
        })], 2)] : _vm._e(), _vm._v(" "), _vm.hasCtp && lesson.theme.class_index ? [_c('div', {
          staticClass: "s-table-schedule__card-label mt-5"
        }, [_vm._v("\n                        " + _vm._s(_vm.trans('training_schedule.fields.class_index')) + "\n                      ")]), _vm._v(" "), _c('div', {
          staticClass: "s-table-schedule__card-desc mt-5"
        }, [_vm._v("\n                        " + _vm._s(lesson.theme.class_index) + "\n                      ")])] : _vm._e(), _vm._v(" "), _vm.accessEdit ? _c('div', {
          staticClass: "s-table-schedule__card-overlay",
          staticStyle: {
            "overflow": "hidden"
          }
        }, [_c('div', {
          staticClass: "s-group"
        }, [_c('div', {
          staticClass: "s-btn-table s-btn--ico",
          on: {
            "click": function click($event) {
              return _vm.editLesson(lesson);
            }
          }
        }, [_c('div', {
          staticClass: "s-ico s-ico--thm-pencil"
        })]), _vm._v(" "), _c('div', {
          staticClass: "s-btn-table s-btn--ico",
          on: {
            "click": function click($event) {
              return _vm.showDestroyLesson(lesson);
            }
          }
        }, [_c('div', {
          staticClass: "s-ico s-ico--thm-trash"
        })])])]) : _vm._e()], 2);
      }) : _vm._e(), _vm._v(" "), day.id ? _c('div', {
        staticClass: "s-table-schedule__add-item-wrapper"
      }, [_vm.accessEdit ? _c('div', {
        staticClass: "s-table-schedule__add-item",
        on: {
          "click": function click($event) {
            return _vm.createLesson(day.id, time.id);
          }
        }
      }) : _vm._e()]) : _c('div', {
        staticClass: "s-table-schedule__empty"
      })], 2);
    })], 2);
  }), 0)])])])]), _vm._v(" "), _vm.modalScheduleLesson ? _c('div', {
    staticClass: "s-modal fade",
    attrs: {
      "id": "modal-schedule-lesson",
      "tabindex": "-1",
      "data-backdrop": "static"
    }
  }, [_c('div', {
    staticClass: "s-modal__container"
  }, [_c('div', {
    staticClass: "s-modal__head"
  }, [_c('div', {
    staticClass: "s-modal__title"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('training_schedule.lesson')) + "\n        ")]), _vm._v(" "), _c('div', {
    staticClass: "s-modal__close",
    attrs: {
      "data-toggle": "modal",
      "data-target": "#modal-schedule-lesson"
    },
    on: {
      "click": _vm.onModalClose
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "s-modal__body"
  }, [_c('form', {
    attrs: {
      "action": _vm.forms.lesson.action,
      "enctype": "multipart/form-data",
      "method": "post"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.handleAjaxFormSubmit(_vm.forms.lesson);
      }
    }
  }, [_c('v-wrapper-q-select', {
    attrs: {
      "options": _vm.refs.types,
      "extra-binds": {
        required: true,
        labelTransKey: 'training_schedule.fields.type'
      },
      "error": _vm.hasValidationFail('lesson.type'),
      "error-message": _vm.getValidationMessage('lesson.type')
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('lesson.type');
      }
    },
    model: {
      value: _vm.forms.lesson.fields.type,
      callback: function callback($$v) {
        _vm.$set(_vm.forms.lesson.fields, "type", $$v);
      },
      expression: "forms.lesson.fields.type"
    }
  }), _vm._v(" "), _c('q-field', {
    attrs: {
      "borderless": "",
      "label": _vm.trans('label.subject'),
      "required": ""
    }
  }, [_c('q-option-group', {
    staticClass: "d-flex s-w-100",
    attrs: {
      "options": _vm.isResultOptions,
      "disable": _vm.isDisabledResult
    },
    on: {
      "input": _vm.updateSelects
    },
    model: {
      value: _vm.forms.lesson.fields.is_result,
      callback: function callback($$v) {
        _vm.$set(_vm.forms.lesson.fields, "is_result", $$v);
      },
      expression: "forms.lesson.fields.is_result"
    }
  })], 1), _vm._v(" "), _c('v-wrapper-q-select', {
    ref: "subjectSelect",
    attrs: {
      "options": _vm.refs.disciplines,
      "input-debounce": "500",
      "use-options-from-prop": "",
      "add-default-option": _vm.forms.lesson.fields.default_discipline_option,
      "extra-binds": {
        required: true
      },
      "error": _vm.hasValidationFail('lesson.discipline_id'),
      "error-message": _vm.getValidationMessage('lesson.discipline_id'),
      "custom-filter-fn": _vm.requestSearchDisciplines
    },
    on: {
      "input-value-change": function inputValueChange($event) {
        _vm.disciplineSelectInputValue = $event;
      },
      "virtual-scroll": _vm.onSubScroll,
      "input": function input($event) {
        _vm.flashValidationFail('lesson.discipline_id');

        _vm.requestGetThemes();
      }
    },
    model: {
      value: _vm.forms.lesson.fields.discipline_id,
      callback: function callback($$v) {
        _vm.$set(_vm.forms.lesson.fields, "discipline_id", $$v);
      },
      expression: "forms.lesson.fields.discipline_id"
    }
  }), _vm._v(" "), _c('v-wrapper-q-select', {
    attrs: {
      "options": _vm.refs.teachers,
      "extra-binds": {
        disable: _vm.refs.teachers.length < 2,
        required: true,
        labelTransKey: 'training_schedule.fields.teacher_id'
      },
      "error": _vm.hasValidationFail('lesson.teacher_id'),
      "error-message": _vm.getValidationMessage('lesson.teacher_id')
    },
    on: {
      "input": function input($event) {
        _vm.flashValidationFail('lesson.teacher_id');

        _vm.requestGetThemes();
      }
    },
    model: {
      value: _vm.forms.lesson.fields.teacher_id,
      callback: function callback($$v) {
        _vm.$set(_vm.forms.lesson.fields, "teacher_id", $$v);
      },
      expression: "forms.lesson.fields.teacher_id"
    }
  }), _vm._v(" "), _vm.forms.lesson.themes.length > 0 && _vm.hasCtp ? _c('v-wrapper-q-select', {
    attrs: {
      "options": _vm.forms.lesson.themes,
      "extra-binds": {
        required: true,
        labelTransKey: 'training_schedule.fields.calendar_thematic_plan_theme_id'
      },
      "error": _vm.hasValidationFail('lesson.calendar_thematic_plan_theme_id'),
      "error-message": _vm.getValidationMessage('lesson.calendar_thematic_plan_theme_id')
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('lesson.calendar_thematic_plan_theme_id');
      }
    },
    model: {
      value: _vm.forms.lesson.fields.calendar_thematic_plan_theme_id,
      callback: function callback($$v) {
        _vm.$set(_vm.forms.lesson.fields, "calendar_thematic_plan_theme_id", $$v);
      },
      expression: "forms.lesson.fields.calendar_thematic_plan_theme_id"
    }
  }) : _vm._e(), _vm._v(" "), _vm.refs.teachers.length > 1 ? _c('v-wrapper-q-select', {
    attrs: {
      "options": _vm.refs.teachers,
      "multiple": "",
      "extra-binds": {
        disable: _vm.refs.teachers.length < 2,
        labelTransKey: 'training_schedule.fields.teacher_ids'
      },
      "error": _vm.hasValidationFail('lesson.teacher_ids'),
      "error-message": _vm.getValidationMessage('lesson.teacher_ids')
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('lesson.teacher_ids');
      }
    },
    model: {
      value: _vm.forms.lesson.fields.teacher_ids,
      callback: function callback($$v) {
        _vm.$set(_vm.forms.lesson.fields, "teacher_ids", $$v);
      },
      expression: "forms.lesson.fields.teacher_ids"
    }
  }) : _vm._e(), _vm._v(" "), ['personal', 'normal'].includes(_vm.type) ? _c('q-field', {
    attrs: {
      "borderless": "",
      "error": _vm.hasValidationFail('lesson.studentsType'),
      "error-message": _vm.getValidationMessage('lesson.studentsType')
    }
  }, [_vm.type === 'normal' ? _c('q-option-group', {
    staticClass: "d-flex s-w-100 justify-content-between",
    attrs: {
      "options": _vm.studentTypesOptions
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('lesson.teacher_ids');
      }
    },
    model: {
      value: _vm.forms.lesson.fields.studentsType,
      callback: function callback($$v) {
        _vm.$set(_vm.forms.lesson.fields, "studentsType", $$v);
      },
      expression: "forms.lesson.fields.studentsType"
    }
  }) : _vm.type === 'personal' ? _c('q-checkbox', {
    attrs: {
      "label": _vm.trans('label.select_all_students')
    },
    model: {
      value: _vm.forms.lesson.fields.contingents_all,
      callback: function callback($$v) {
        _vm.$set(_vm.forms.lesson.fields, "contingents_all", $$v);
      },
      expression: "forms.lesson.fields.contingents_all"
    }
  }) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _vm.forms.lesson.fields.studentsType === 'subgroups' || _vm.forms.lesson.fields.subgroup_id ? _c('v-wrapper-q-select', {
    attrs: {
      "options": _vm.refs.subgroups,
      "extra-binds": {
        required: true,
        labelTransKey: 'training_schedule.fields.subgroup_id'
      },
      "error": _vm.hasValidationFail('lesson.subgroup_id'),
      "error-message": _vm.getValidationMessage('lesson.subgroup_id')
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('lesson.subgroup_id');
      }
    },
    model: {
      value: _vm.forms.lesson.fields.subgroup_id,
      callback: function callback($$v) {
        _vm.$set(_vm.forms.lesson.fields, "subgroup_id", $$v);
      },
      expression: "forms.lesson.fields.subgroup_id"
    }
  }) : _vm._e(), _vm._v(" "), _vm.refs.contingent && _vm.type === 'personal' && (!_vm.forms.lesson.fields.studentsType === 'contingents_all' || !_vm.forms.lesson.fields.contingents_all) ? _c('v-wrapper-q-select', {
    attrs: {
      "options": _vm.refs.contingent,
      "multiple": "",
      "extra-binds": {
        required: true,
        labelTransKey: 'label.students'
      },
      "error": _vm.hasValidationFail('lesson.contingent'),
      "error-message": _vm.getValidationMessage('lesson.contingent')
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('lesson.contingent');
      }
    },
    model: {
      value: _vm.forms.lesson.fields.contingent,
      callback: function callback($$v) {
        _vm.$set(_vm.forms.lesson.fields, "contingent", $$v);
      },
      expression: "forms.lesson.fields.contingent"
    }
  }) : _vm._e(), _vm._v(" "), _c('q-input', {
    attrs: {
      "required": "",
      "label": _vm.trans('training_schedule.fields.cabinet'),
      "maxlength": 30,
      "counter": "",
      "error": _vm.hasValidationFail('lesson.cabinet'),
      "error-message": _vm.getValidationMessage('lesson.cabinet')
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('lesson.cabinet');
      }
    },
    model: {
      value: _vm.forms.lesson.fields.cabinet,
      callback: function callback($$v) {
        _vm.$set(_vm.forms.lesson.fields, "cabinet", $$v);
      },
      expression: "forms.lesson.fields.cabinet"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "row-sort-end"
  }, [_c('div', {
    staticClass: "s-group"
  }, [_c('div', {
    staticClass: "s-btn s-btn--thm-grey s-btn--w-150",
    attrs: {
      "data-toggle": "modal",
      "data-target": "#modal-schedule-lesson"
    },
    on: {
      "click": _vm.onModalClose
    }
  }, [_vm._v("\n                " + _vm._s(_vm.trans('button.cancel')) + "\n              ")]), _vm._v(" "), _c('button', {
    staticClass: "s-btn s-btn--thm-green s-btn--w-150",
    class: {
      'loading-progress': _vm.processSend
    },
    attrs: {
      "type": "submit"
    }
  }, [_vm._v("\n                " + _vm._s(_vm.trans('button.save')) + "\n              ")])])])], 1)])])]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "s-modal s-modal--small fade",
    attrs: {
      "id": "modal-schedule-lesson-confirm-destroy",
      "tabindex": "-1"
    }
  }, [_c('div', {
    staticClass: "s-modal__container"
  }, [_c('div', {
    staticClass: "s-modal__head"
  }, [_c('div', {
    staticClass: "s-modal__title"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('notice.confirm')) + "\n        ")]), _vm._v(" "), _c('div', {
    staticClass: "s-modal__close",
    attrs: {
      "data-toggle": "modal",
      "data-target": "#modal-schedule-lesson-confirm-destroy"
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "s-modal__body"
  }, [_c('div', {
    staticClass: "row-sort-between-btn"
  }, [_c('div', {
    staticClass: "s-btn s-btn--thm-green s-btn--w-150",
    class: {
      'loading-progress': _vm.processSend
    },
    on: {
      "click": function click($event) {
        return _vm.destroyLesson();
      }
    }
  }, [_vm._v("\n            " + _vm._s(_vm.trans('button.approve')) + "\n          ")]), _vm._v(" "), _c('div', {
    staticClass: "s-btn s-btn--thm-blue s-btn--w-150",
    attrs: {
      "data-toggle": "modal",
      "data-target": "#modal-schedule-lesson-confirm-destroy"
    }
  }, [_vm._v("\n            " + _vm._s(_vm.trans('button.cancel')) + "\n          ")])])])])]), _vm._v(" "), _c('div', {
    staticClass: "s-modal s-modal--small fade",
    attrs: {
      "id": "modal-schedule-lesson-edit-time",
      "tabindex": "-1"
    }
  }, [_c('div', {
    staticClass: "s-modal__container"
  }, [_c('div', {
    staticClass: "s-modal__head"
  }, [_c('div', {
    staticClass: "s-modal__title"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('notice.confirm')) + "\n        ")]), _vm._v(" "), _c('div', {
    staticClass: "s-modal__close",
    attrs: {
      "data-toggle": "modal",
      "data-target": "#modal-schedule-lesson-edit-time"
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "s-modal__body"
  }, [_c('div', {
    staticClass: "row mb-30"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('label', {
    staticClass: "s-form__label mb-10"
  }, [_vm._v(_vm._s(_vm.trans('training_schedule.times.start')) + ": "), _c('i', [_vm._v("*")])]), _vm._v(" "), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.startTime,
      expression: "startTime"
    }, {
      name: "mask",
      rawName: "v-mask",
      value: {
        mask: '99:99'
      },
      expression: "{\n                mask: '99:99',\n              }"
    }],
    staticClass: "s-form__input",
    domProps: {
      "value": _vm.startTime
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.startTime = $event.target.value;
      }
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "col-6"
  }, [_c('label', {
    staticClass: "s-form__label mb-10"
  }, [_vm._v(_vm._s(_vm.trans('training_schedule.times.end')) + ": "), _c('i', [_vm._v("*")])]), _vm._v(" "), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.endTime,
      expression: "endTime"
    }, {
      name: "mask",
      rawName: "v-mask",
      value: {
        mask: '99:99'
      },
      expression: "{\n                mask: '99:99',\n              }"
    }],
    staticClass: "s-form__input",
    domProps: {
      "value": _vm.endTime
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.endTime = $event.target.value;
      }
    }
  })])]), _vm._v(" "), _c('div', {
    staticClass: "row-sort-between-btn"
  }, [_c('div', {
    staticClass: "s-btn s-btn--thm-green s-btn--w-150",
    class: {
      'loading-progress': _vm.processSend
    },
    on: {
      "click": function click($event) {
        return _vm.saveTime();
      }
    }
  }, [_vm._v("\n            " + _vm._s(_vm.trans('button.approve')) + "\n          ")]), _vm._v(" "), _c('div', {
    staticClass: "s-btn s-btn--thm-blue s-btn--w-150",
    attrs: {
      "data-toggle": "modal",
      "data-target": "#modal-schedule-lesson-edit-time"
    }
  }, [_vm._v("\n            " + _vm._s(_vm.trans('button.cancel')) + "\n          ")])])])])]), _vm._v(" "), _c('q-dialog', {
    attrs: {
      "persistent": ""
    },
    on: {
      "input": _vm.onErrNoThemesModalClose
    },
    model: {
      value: _vm.isDuplicateModalOpen,
      callback: function callback($$v) {
        _vm.isDuplicateModalOpen = $$v;
      },
      expression: "isDuplicateModalOpen"
    }
  }, [_c('v-modal', {
    attrs: {
      "in-body": "",
      "title": _vm.trans('calendar-thematic-plan.training_schedule.no_theme_title')
    }
  }, [_c('div', {
    staticClass: "mb-30"
  }, [_vm._v("\n        " + _vm._s(_vm.currentErrModalText) + "\n      ")]), _vm._v(" "), _c('div', {
    staticClass: "s-group s-group--equal s-group--wider"
  }, [_c('div', {
    staticClass: "s-btn s-btn--thm-grey s-btn--w-100",
    attrs: {
      "data-toggle": "modal",
      "data-target": "#modal-schedule-duplicate_err_no_themes"
    },
    on: {
      "click": _vm.onErrNoThemesModalClose
    }
  }, [_vm._v("\n          " + _vm._s(_vm.trans('button.cancel')) + "\n        ")]), _vm._v(" "), _c('a', {
    staticClass: "s-btn s-btn--thm-blue s-btn--w-100",
    attrs: {
      "href": _vm.currentErrModalUrl
    }
  }, [_vm._v("\n          " + _vm._s(_vm.trans('calendar-thematic-plan.training_schedule.go_to_ctp')) + "\n        ")]), _vm._v(" "), _c('div', {
    staticClass: "s-btn s-btn--thm-green s-btn--w-100",
    on: {
      "click": function click($event) {
        return _vm.duplicateNextWeek(true);
      }
    }
  }, [_vm._v("\n          " + _vm._s(_vm.trans('button.continue')) + "\n        ")])])])], 1), _vm._v(" "), _c('q-dialog', {
    model: {
      value: _vm.isWeeksModalOpen,
      callback: function callback($$v) {
        _vm.isWeeksModalOpen = $$v;
      },
      expression: "isWeeksModalOpen"
    }
  }, [_c('v-modal', {
    attrs: {
      "size": "480",
      "title": _vm.trans('calendar-thematic-plan.training_schedule.weeks')
    }
  }, [_c('v-wrapper-q-select', {
    attrs: {
      "options": _vm.weeksForDuplicate,
      "option-value": "id",
      "option-label": "name"
    },
    model: {
      value: _vm.weekForDuplicate,
      callback: function callback($$v) {
        _vm.weekForDuplicate = $$v;
      },
      expression: "weekForDuplicate"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "row justify-content-between"
  }, [_c('div', {
    staticClass: "col-12 col-sm-auto mb-10"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "width": "20",
      "label": _vm.trans('button.close'),
      "label-wider": "",
      "color": "grey"
    },
    on: {
      "click": function click($event) {
        _vm.isWeeksModalOpen = false;
      }
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-12 col-sm-auto"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "width": "20",
      "label": _vm.trans('button.send'),
      "label-wider": "",
      "color": "green"
    },
    on: {
      "click": function click() {
        return _vm.duplicateNextWeek(false);
      }
    }
  })], 1)])], 1)], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }