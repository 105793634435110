var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('q-table', {
    staticClass: "s-b-white",
    attrs: {
      "data": _vm.rows,
      "columns": _vm.cols,
      "row-key": "index",
      "flat": _vm.$q.screen.width > _vm.adaptiveWhen,
      "grid": _vm.$q.screen.width <= _vm.adaptiveWhen,
      "no-data-label": _vm.trans('label.no_data'),
      "separator": "vertical",
      "wrap-cells": ""
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn(props) {
        return [_vm.headerTitle && _vm.$q.screen.width <= _vm.adaptiveWhen ? _c('div', {
          staticClass: "table-title"
        }, [_vm._v("\n        " + _vm._s(_vm.headerTitle) + "\n      ")]) : _vm._e()];
      }
    }, {
      key: "header",
      fn: function fn(props) {
        return [_vm.headerTitle ? _c('q-tr', {
          attrs: {
            "props": props
          }
        }, [_c('th', {
          staticClass: "s-to-center",
          staticStyle: {
            "border-bottom-width": "1px",
            "font-weight": "600"
          },
          attrs: {
            "colspan": props.cols.length
          }
        }, [_vm._v("\n          " + _vm._s(_vm.headerTitle) + "\n        ")])]) : _vm._e(), _vm._v(" "), _c('q-tr', {
          attrs: {
            "props": props
          }
        }, _vm._l(props.cols, function (col) {
          return _c('q-th', {
            key: col.name,
            style: {
              width: col.width ? "".concat(col.width, "px") : undefined
            },
            attrs: {
              "props": props
            }
          }, [_vm._v("\n          " + _vm._s(col.label) + "\n        ")]);
        }), 1)];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }