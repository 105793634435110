var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.$q.screen.width <= 660 ? _c('div', [_c('v-no-results', {
    attrs: {
      "image": "pc",
      "title": _vm.trans('warning_text.use_pc_title'),
      "text": _vm.trans('warning_text.use_pc_label')
    }
  })], 1) : _c('div', {
    ref: "tickets",
    staticClass: "v-exam-tickets"
  }, [_c('v-form-inform', {
    attrs: {
      "message": _vm.trans('warning_text.max_image_size_10mb')
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "exam-questions"
  }, [_c('div', {
    staticClass: "row-sort-start-btn s-w-100"
  }, [_vm.addTicket ? _c('v-exam-ticket-add', {
    attrs: {
      "uri-add-ticket": _vm.uriAddTicket
    }
  }) : _c('q-btn', {
    staticClass: "s-btn--w-200",
    attrs: {
      "color": "blue",
      "icon": "plus",
      "no-caps": "",
      "label-wider": "",
      "loading": _vm.loading,
      "label": _vm.trans('button.add_exam_ticket')
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.openAdding($event);
      }
    }
  }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('button.add_exam_ticket')))])], 1)], 1), _vm._v(" "), _c('div', {
    staticClass: "exam-questions__items mt-10 sn-ck-editor-data"
  }, _vm._l(_vm.paginate, function (item, key) {
    return _c('v-exam-ticket', {
      key: item.id,
      attrs: {
        "ticket-prop": item,
        "uri-destroy-ticket": _vm.uriDestroyTicket,
        "uri-edit-ticket": _vm.uriEditTicket,
        "loading": _vm.loading
      }
    });
  }), 1)]), _vm._v(" "), _vm.countOfPages ? _c('div', {
    staticClass: "row justify-content-center mt-30"
  }, [_c('div', {
    staticClass: "col-auto"
  }, [_c('q-pagination', {
    attrs: {
      "max": _vm.countOfPages,
      "max-pages": 5,
      "boundary-links": true,
      "input": _vm.countOfPages > 5,
      "input-class": "s-c-blue"
    },
    on: {
      "input": _vm.changePagination
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }