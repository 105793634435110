import Vue from 'vue';
import Vuex from 'vuex';
import Trans from '@vjs/store/translator';
import library from './library';
import examQuestions from './examQuestions';
import vSteps from './VSteps';
import vJournal from './vJournal';
import VAttendance from './VAttendance';
import VCurriculumProgram from './VCurriculumProgram';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    library,
    examQuestions,
    Trans,
    vSteps,
    vJournal,
    VAttendance,
    VCurriculumProgram,
  },
});
