import {
  QForm,
  QBtn,
  QSelect,
  QIcon,
  QInput,
  QDate,
  QPopupProxy,
} from '@quasar/components';
import {
  requestWrapper,
} from '@vjs/helpers';
import VNoResults from '@vjs/components/VNoResults';
import VFormInform from '@vjs/components/VFormInform';
import VTable
  from '@vjs/components/VTable/VTable.vue';
import axios from 'axios';
import _ from 'lodash';

export default {
  name: 'VContingentMonitoring',
  components: {
    QForm,
    QBtn,
    QSelect,
    QIcon,
    QInput,
    QDate,
    QPopupProxy,
    VNoResults,
    VFormInform,
    VTable,
  },
  props: {
    uriGetData: {
      type: String,
      required: true,
    },
    uriGetGroups: {
      type: String,
      required: true,
    },
    uriGetContingent: {
      type: String,
      required: true,
    },
    uriGetDepartments: {
      type: String,
      required: false,
    },
    uriGetProfessions: {
      type: String,
      required: false,
    },
    uriGetModules: {
      type: String,
      required: false,
    },
    uriGetModuleDisciplines: {
      type: String,
      required: false,
    },
    uriGetOrganizations: {
      type: String,
      required: false,
    },
    isAttendance: {
      type: Boolean,
      default: false,
    },
    isModule: {
      type: Boolean,
      default: false,
    },
    hasOrganizationFilter: {
      type: Boolean,
      default: false,
    },
    hasDepartmentFilter: {
      type: Boolean,
      default: false,
    },
    hasProfessionFilter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      table: {
        rows: [],
        columns: [],
      },
      filters: {
        semester: {},
        module: {},
        moduleDiscipline: {},
        group: {},
        contingents: [],
        organization: {},
        profession: {},
        department: {},
        date_from: '',
        date_to: '',
      },
      options: {
        semesters: [],
        groups: [],
        groupsFiltered: [],
        contingent: [],
        departments: [],
        departmentsFiltered: [],
        organizations: [],
        organizationsFiltered: [],
        professions: [],
        professionsFiltered: [],
        modules: [],
        modulesFiltered: [],
        moduleDisciplines: [],
        moduleDisciplinesFiltered: [],
      },
      loading: false,
      noResults: false,
    };
  },

  computed: {
    contingentValue() {
      return this.trans('placeholder.choosed_students') + this.filters.contingents.length;
    },
  },
  async mounted() {
    await this.$trans.add([
      'label',
      'table',
      'button',
      'placeholder',
      'warning_text',
      'files',
    ]);

    this.options.semesters = [
      {
        value: '1',
        label: this.trans('label.first_semester'),
      }, {
        value: '2',
        label: this.trans('label.second_semester'),
      },
    ];

    if (!this.hasProfessionFilter) {
      this.options.semesters.push({
        value: '0',
        label: this.trans('label.college_year_end'),
      });
    }

    if (this.hasOrganizationFilter && !this.hasDepartmentFilter) {
      await this.requestGetOrganizations();
    } else if (this.hasDepartmentFilter) {
      await this.requestGetDepartments();
    } else if (this.hasProfessionFilter) {
      await this.requestGetProfessions();
    } else if (!this.hasOrganizationFilter) {
      await this.requestGetGroups();
    }
    this.clearFilters();
  },
  methods: {
    async selectOrganization() {
      await this.requestGetGroups();
    },
    async selectProfession(val) {
      if (val.isCreative) {
        await this.requestGetContingent();
      } else {
        await this.requestGetGroups();
      }
    },
    async selectGroup() {
      await this.requestGetContingent();
      if (this.isModule) {
        await this.requestGetModules();
      }
    },
    async requestGetGroups() {
      this.clearGroup();
      let requestData = {};
      if (this.hasOrganizationFilter) {
        requestData = {
          organization: this.filters.organization.value,
        };
      }
      if (this.hasProfessionFilter) {
        requestData.professionId = this.filters.profession.value;
      }
      const res = await requestWrapper.call(this, {
        url: this.uriGetGroups,
        params: requestData,
      });
      if (!res.error) {
        this.options.groups = res.data;
        this.options.groupsFiltered = res.data;
      }
    },
    async requestGetModules() {
      this.filters.module = {};
      const requestData = {
        quasar: true,
        groupId: this.filters.group.value,
      };
      if (this.hasOrganizationFilter) {
        requestData.organization = this.filters.organization.value;
      }
      const res = await requestWrapper.call(this, {
        url: this.uriGetModules,
        params: requestData,
      });
      if (!res.error) {
        this.options.modules = res.data;
        this.options.modulesFiltered = res.data;
      }
    },
    async requestGetModuleDisciplines() {
      if (_.isEmpty(this.filters.module)) {
        this.options.moduleDisciplines = [];
        this.options.moduleDisciplinesFiltered = [];
        return;
      }
      this.filters.moduleDiscipline = {};
      const requestData = {
        quasar: true,
        module_id: this.filters.module.value,
      };
      const res = await requestWrapper.call(this, {
        url: this.uriGetModuleDisciplines,
        params: requestData,
      });
      if (!res.error) {
        this.options.moduleDisciplines = res.data;
        this.options.moduleDisciplinesFiltered = res.data;
      }
    },
    clearFilters() {
      if (this.hasOrganizationFilter) {
        this.options.groups = [];
        this.options.modules = [];
      }
      if (this.hasDepartmentFilter) {
        this.options.organizations = [];
      }
      if (this.hasProfessionFilter) {
        this.options.professions = [];
      }
      this.filters.semester = this.options.semesters[0];
      this.filters.group = {};
      this.filters.module = {};
      this.filters.moduleDiscipline = {};
      this.options.moduleDisciplines = [];
      this.filters.department = {};
      this.filters.organization = {};
      this.filters.contingents = [];
      this.filters.date_from = '';
      this.filters.date_to = '';
      this.filters.profession = {};
      this.table.rows = [];
      this.table.columns = [];
      this.options.contingent = [];
    },
    clearDepartment() {
      this.filters.department = {};
      this.options.organizations = [];
      this.options.organizationsFiltered = [];
      this.clearOrganization();
    },
    clearOrganization() {
      this.filters.organization = {};
      this.options.groups = [];
      this.options.groupsFiltered = [];
      this.options.modules = [];
      this.options.moduleDisciplines = [];
      this.clearGroup();
      this.clearModule();
    },
    clearGroup() {
      this.filters.group = {};
      this.filters.contingents = [];
      this.options.contingent = [];
    },
    clearModule() {
      this.filters.module = {};
      this.filters.moduleDiscipline = {};
      this.options.moduleDisciplines = [];
      this.options.moduleDisciplinesFiltered = [];
    },
    async submit() {
      this.loading = true;
      const res = await requestWrapper.call(this, {
        url: this.uriGetData,
        params: this.getParamsData(),
      });
      if (!res.error) {
        this.table = res;
        this.noResults = false;
        if (
          (
            this.isAttendance
            && this.table.columns.length === 2
          )
          || this.table.columns.length < 2
          || this.table.rows.length === 0
        ) {
          this.noResults = true;
          this.loading = false;
          return;
        }

        if ((this.filters.date_from || this.filters.date_to) && this.isAttendance) {
          _.forEach(this.table.columns, (col) => {
            if (col.field !== 'fullName') {
              _.forEach(this.table.rows, (row) => {
                if (!row.hasOwnProperty(col.field)) {
                  row[col.field] = 0;
                }
                return true;
              });
            }
            return true;
          });
        }
      }

      this.loading = false;
    },
    async exportu() {
      this.loading = true;

      axios({
        url: `${this.uriGetData}`,
        method: 'GET',
        responseType: 'blob',
        params: this.getParamsData(true),
      })
        .then((response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement('a');
          fileLink.href = fileURL;
          const date = new Date();
          const formattedDate = `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()}`;

          fileLink.setAttribute('download',
            this.isAttendance
              ? `${this.trans('files.contingent_monitoring.attendance_report', {
                group: this.filters.group.label,
                date: formattedDate,
              })}.xlsx`
              : `${this.trans('files.contingent_monitoring.academic_performance_report', {
                semester: this.filters.semester.label.toLowerCase(),
                group: this.filters.group.label,
                date: formattedDate,
              })}.xlsx`);

          document.body.appendChild(fileLink);

          fileLink.click();
          this.loading = false;
        });
    },
    async requestGetContingent() {
      this.filters.contingents = [];
      const res = await requestWrapper.call(this, {
        url: this.uriGetContingent,
        params: {
          groupId: this.filters.group.value,
          professionId: this.filters.profession.value,
        },
      });
      if (!res.error) {
        this.options.contingent = res.data;
        _.forEach(this.options.contingent, (student) => {
          this.filters.contingents.push(student.value);
        });
      }
    },
    async requestGetDepartments() {
      const res = await requestWrapper.call(this, {
        url: this.uriGetDepartments,
      });
      if (!res.error) {
        this.options.departments = res.data;
        this.options.departmentsFiltered = res.data;
      }
    },
    async requestGetProfessions() {
      const res = await requestWrapper.call(this, {
        url: this.uriGetProfessions,
        params: {
          quasar: true,
        },
      });
      if (!res.error) {
        this.options.professions = res.data;
        this.options.professionsFiltered = res.data;
      }
    },
    async requestGetOrganizations() {
      this.filters.organization = {};
      this.filters.group = {};
      this.options.groups = [];
      let paramsData = {};
      if (this.hasDepartmentFilter) {
        paramsData = {
          department: this.filters.department.value,
        };
      }
      const res = await requestWrapper.call(this, {
        url: this.uriGetOrganizations,
        params: paramsData,
      });
      if (!res.error) {
        this.options.organizations = res.data;
        this.options.organizationsFiltered = res.data;
      }
    },
    getParamsData(exportu = false) {
      let paramsData = {
        groupId: this.filters.group.value,
        groupName: this.filters.group.label,
        contingents: this.filters.contingents,
      };
      if (exportu === true) {
        paramsData = {
          ...paramsData,
          export: true,
        };
      }
      if (!this.isAttendance && !this.isModule) {
        paramsData = {
          ...paramsData,
          semesterId: this.filters.semester.value,
          semesterName: this.filters.semester.label,
        };
      }
      if (this.isAttendance) {
        paramsData = {
          ...paramsData,
          dateFrom: this.filters.date_from,
          dateTo: this.filters.date_to,
        };
      }
      if (this.isModule) {
        paramsData = {
          ...paramsData,
          isModule: true,
          moduleId: this.filters.module.value,
          moduleName: this.filters.module.label,
          moduleDisciplineId: this.filters.moduleDiscipline.value,
          moduleDisciplineName: this.filters.moduleDiscipline.label,
        };
      }
      if (this.hasOrganizationFilter) {
        paramsData = {
          ...paramsData,
          organizationId: this.filters.organization.value,
          organizationName: this.filters.organization.label,
        };
      }
      if (this.hasDepartmentFilter) {
        paramsData = {
          ...paramsData,
          deparmentId: this.filters.department.value,
          departmentName: this.filters.department.label,
        };
      }
      if (this.hasProfessionFilter) {
        paramsData = {
          ...paramsData,
          professionId: this.filters.profession.value,
          professionName: this.filters.profession.label,
        };
      }
      return paramsData;
    },

    // Поиск по селектам
    defaultFilterFn(val, update, options, optionsFiltered) {
      if (val === '') {
        update(() => {
          this.options[options] = this.options[optionsFiltered];
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.options[options] = this.options[optionsFiltered].filter(
          item => _.lowerCase(item.label).indexOf(needle) > -1,
        );
      });
    },
  },
};
