var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "v-statement-files-edit"
  }, [_c('v-form-inform', {
    attrs: {
      "message": _vm.trans('warning_text.modal_inform')
    }
  }), _vm._v(" "), _c('v-form-inform', {
    attrs: {
      "message": _vm.trans('warning_text.files_supported')
    }
  }), _vm._v(" "), _c('v-form-inform', {
    attrs: {
      "message": _vm.trans('college.template_request', {
        url: _vm.trans('college.template_url')
      })
    }
  }), _vm._v(" "), _c('q-form', {
    staticClass: "mb-30"
  }, [_vm._l(_vm.form, function (file, name) {
    return _c('div', {
      key: name
    }, [_c('div', {
      staticClass: "sn-field__label"
    }, [_vm._v("\n        " + _vm._s(_vm.getFileLabel(name)) + ": "), _vm.isFileRequired(name) ? _c('i', [_vm._v("*")]) : _vm._e()]), _vm._v(" "), _c('div', {
      staticClass: "row align-items-end mb-20"
    }, [_c('div', {
      staticClass: "col-sm-6 col-md-4 col-12",
      class: _vm.$q.screen.width < 576 ? 'mb-10' : ''
    }, [_c('q-btn', {
      staticClass: "s-w-100",
      attrs: {
        "label": file.length === 0 ? _vm.trans('button.add') : _vm.trans('label.attached'),
        "color": file.length === 0 ? 'blue' : 'green',
        "icon-right": file.length === 0 ? 'plus' : 'clip',
        "label-wider": "",
        "ellipsis": "",
        "no-caps": ""
      },
      on: {
        "click": function click($event) {
          return _vm.triggerFile(name);
        }
      }
    })], 1), _vm._v(" "), _c('div', {
      staticClass: "col-sm-6 col-md-8 col-12"
    }, [_c('q-btn', {
      staticClass: "s-w-100",
      attrs: {
        "outline": "",
        "label": file.length === 0 ? _vm.trans('label.file_not_chosen') : _vm.trans('label.files_downloaded', {
          count: file.length
        }),
        "label-wider": "",
        "color": "grey",
        "no-caps": "",
        "ellipsis": "",
        "icon-right": file.length === 0 ? undefined : 'close'
      },
      on: {
        "click": function click($event) {
          return _vm.clearFile(name);
        }
      }
    })], 1)])]);
  }), _vm._v(" "), _c('div', {
    staticClass: "row justify-content-end"
  }, [_c('div', {
    staticClass: "col-auto"
  }, [_c('q-btn', {
    staticClass: "s-btn--mw-150",
    attrs: {
      "disable": !_vm.validate,
      "color": "green",
      "label": _vm.trans('button.send')
    },
    on: {
      "click": _vm.requestForm
    }
  })], 1)])], 2), _vm._v(" "), _c('select-user-file', {
    attrs: {
      "open-modal": _vm.modalFiles,
      "url-files": _vm.uriUserFiles,
      "url-file-store": _vm.uriUserFiles,
      "multiple": "",
      "with-loader": false
    },
    on: {
      "input": _vm.selectFile,
      "select-close": _vm.fileModalClose
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }