var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-audio-player mb-10"
  }, [_c('Player', {
    attrs: {
      "volume": _vm.volume
    }
  }, [_c('Audio', [_c('source', {
    attrs: {
      "data-src": _vm.src,
      "type": _vm.type
    }
  })]), _vm._v(" "), _c('Ui', [_c('Controls', {
    ref: "audioControls"
  }, [_c('PlaybackControl', {
    attrs: {
      "hide-tooltip": ""
    }
  }), _vm._v(" "), _c('TimeProgress'), _vm._v(" "), _c('ScrubberControl'), _vm._v(" "), _c('div', {
    staticClass: "d-flex align-items-center",
    staticStyle: {
      "max-width": "11rem"
    }
  }, [_c('MuteControl', {
    attrs: {
      "hide-tooltip": ""
    }
  }), _vm._v(" "), _c('Slider', {
    attrs: {
      "step": 1,
      "max": 100,
      "value": _vm.volume
    },
    on: {
      "vmValueChange": _vm.onVolumeChange
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }