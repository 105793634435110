import {
  QForm,
  QIcon,
  QPopupProxy,
  QDate,
  QInput,
  QBtn,
  QCheckbox,
  QSpinnerGears,
} from '@quasar/components';
import { date } from '@quasar/utils';
import {
  requestWrapper,
} from '@vjs/helpers';
import _ from 'lodash';
import Validation
  from '@vjs/balabaqsha/views/VStatementCreateView/mixins/Validation';
import VFormInform from '@vjs/components/VFormInform/VFormInform';
import SelectUserFile from '../SelectUserFile/index.vue';

export default {
  name: 'VDocFlowForm',

  mixins: [Validation],
  components: {
    VFormInform,
    SelectUserFile,
    QForm,
    QIcon,
    QPopupProxy,
    QDate,
    QInput,
    QBtn,
    QCheckbox,
    QSpinnerGears,
    date,
  },
  props: {
    uriAction: {
      type: String,
      required: true,
    },
    uriGetOrganizations: {
      type: String,
      required: true,
    },
    uriUserFiles: {
      type: String,
      required: true,
    },
    uriGetInfo: {
      type: String,
      required: false,
    },
    method: {
      type: String,
      required: true,
    },
    letters: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentSelectFile: {},
      openModalFiles: false,
      form: {
        name: '',
        date: '',
        docNumber: '',
        body: '',
        organizationsSelected: [],
        files: [
          {
            user_file_id: null,
            user_file_name: null,
          },
        ],
      },
      checkAllOrgs: false,
      organizations: [],
      organizationsFiltered: [],
      searchValue: '',
      loadingOrganizations: true,
      loading: true,
    };
  },

  beforeCreate() {
    this.$trans.add(['workflow', 'label', 'notice', 'document_flow']);
  },

  computed: {
    sendLabel() {
      if (!this.letters) {
        return this.method === 'post' ? 'document_flow.send_order' : 'button.save';
      }
      return this.method === 'post' ? 'document_flow.send_letter' : 'button.save';
    },
    validate() {
      if (this.letters) {
        return !!this.form.name
          && !!this.form.body
          && !!this.form.organizationsSelected[0];
      }
      return !!this.form.name
        && !!this.form.date
        && !!this.form.docNumber
        && !!this.form.body
        && !!this.form.organizationsSelected[0]
        && !!this.form.files[0].user_file_id;
    },
  },

  mounted() {
    this.requestOrganizations();
    this.requestGetInfo();
    this.setCurrentDate();
  },
  watch: {
    'form.organizationsSelected': function (newValue) {
      newValue.length === 0 ? false : this.flashValidationFail('organizations');
      newValue.length === this.organizations.length ? this.checkAllOrgs = true : this.checkAllOrgs = false;
    },
    'form.files': function (newValue) {
      if (!this.letters) {
        newValue[0].user_file_id === null ? false : this.flashValidationFail('files');
      }
    },
  },
  methods: {
    debounceSearch() {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(
        this.handleSearch,
        300,
      );
    },
    handleSearch() {
      if (this.searchValue !== '') {
        const searchStr = this.searchValue.toLowerCase();
        this.organizationsFiltered = this.organizations.filter((a) => {
          const orgName = a.name || '';
          return orgName.toLowerCase().indexOf(searchStr) !== -1;
        });
      } else {
        this.organizationsFiltered = this.organizations;
      }
    },
    checkAll() {
      if (this.checkAllOrgs) {
        this.form.organizationsSelected = [];
        _.each(this.organizations, (value) => {
          this.form.organizationsSelected.push(value.id);
        });
      } else {
        this.form.organizationsSelected = [];
      }
    },
    clearOrganizations() {
      if (!this.loading || !this.loadingOrganizations) {
        this.form.organizationsSelected = [];
        this.searchValue = '';
      }
    },
    setCurrentDate() {
      this.form.date = date.formatDate(Date.now(), 'DD.MM.YYYY');
    },
    async requestOrganizations() {
      const res = await requestWrapper.call(this, {
        url: this.uriGetOrganizations,
      });

      if (!res.error) {
        this.organizations = res.data;
        this.organizationsFiltered = this.organizations;
        this.loadingOrganizations = false;
        return true;
      }
    },
    openSelectFile(file) {
      this.currentSelectFile = file;
      this.openModalFiles = true;
    },
    onSelectClose() {
      this.openModalFiles = false;
    },
    selectFile(file) {
      this.currentSelectFile.user_file_id = file.id;
      this.currentSelectFile.user_file_name = file.name;
      this.openModalFiles = false;
      if (this.form.files.length < 2) {
        this.createNewFile();
      }
    },
    clearFile(file) {
      this.form.files.splice(file, 1);
      if (!this.form.files.length || this.form.files[0].user_file_id) {
        this.createNewFile();
      }
    },
    setFileName(fileName) {
      let name = fileName;
      if (name.length > 10) {
        name = `${fileName.substr(0, 15)}...`;
      }
      return name;
    },
    createNewFile() {
      this.form.files.push({
        user_file_id: null,
        user_file_name: null,
      });
    },
    async requestAction() {
      this.loading = true;
      let responseData = {};
      if (!this.letters) {
        responseData = {
          date: this.form.date,
          doc_number: this.form.docNumber,
          name: this.form.name,
          body: this.form.body,
          organizations: this.form.organizationsSelected,
          files: this.getFileIdx(this.form.files),
        };
      } else {
        responseData = {
          name: this.form.name,
          doc_number: this.form.docNumber,
          body: this.form.body,
          organizations: this.form.organizationsSelected,
          files: this.getFileIdx(this.form.files),
        };
      }
      const actionRes = await requestWrapper.call(this, {
        method: this.method,
        url: this.uriAction,
        data: responseData,
      });

      if (actionRes.data.errors && actionRes.errorType === 422) {
        this.$notify({
          text: _.first(_.flatten(actionRes.data.errors[_.first(Object.keys(actionRes.data.errors))])) ?? this.trans('notice.error_on_server'),
          type: 'error',
        });
      }

      this.loading = false;
      return true;
    },
    async requestGetInfo() {
      if (this.uriGetInfo) {
        const res = await requestWrapper.call(this, {
          url: this.uriGetInfo,
        });
        this.form = res.data;
        this.setCurrentDate();
        if (this.form.files.length <= 1) {
          this.createNewFile();
        }
      }
      this.loading = false;
    },
    getFileIdx(arr) {
      const filtered = _.filter(arr, item => item.user_file_id);
      return _.map(filtered, item => item.user_file_id);
    },
  },
};
