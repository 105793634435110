var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.status === 'joined',
      expression: "status==='joined'"
    }],
    ref: "jitsiContainer",
    staticStyle: {
      "height": "800px",
      "width": "100%"
    }
  }), _vm._v(" "), _vm.status === 'wait_moderator' ? _c('div', {
    staticClass: "s-no-results"
  }, [_c('div', {
    staticClass: "s-no-results__image s-no-results__image--search"
  }), _vm._v(" "), _c('div', {
    staticClass: "s-no-results__message"
  }, [_c('div', {
    staticClass: "s-no-results__message-title"
  }, [_vm._v("\n        " + _vm._s(_vm.trans('notice.conference.pages.moderator_not_exists.title')) + "\n      ")])])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }