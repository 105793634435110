var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-statement-create__item-inner college-v-step-qualification"
  }, [_c('v-form-inform', {
    attrs: {
      "message": _vm.trans('warning_text.modal_inform')
    }
  }), _vm._v(" "), _c('q-form', [_c('q-input', {
    staticClass: "s-ellipsis",
    attrs: {
      "label": _vm.trans('statement.specialty'),
      "required": "",
      "readonly": ""
    },
    model: {
      value: _vm.professionLabel,
      callback: function callback($$v) {
        _vm.professionLabel = $$v;
      },
      expression: "professionLabel"
    }
  }), _vm._v(" "), _c('q-input', {
    staticClass: "s-ellipsis",
    attrs: {
      "required": "",
      "readonly": "",
      "label": _vm.trans('statement.qualification')
    },
    model: {
      value: _vm.qualificationLabel,
      callback: function callback($$v) {
        _vm.qualificationLabel = $$v;
      },
      expression: "qualificationLabel"
    }
  }), _vm._v(" "), _c('q-select', {
    attrs: {
      "options": _vm.options.eduLangs,
      "required": "",
      "label": _vm.trans('statement.edu_lang'),
      "emit-value": "",
      "map-options": ""
    },
    model: {
      value: _vm.form.edu_lang,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "edu_lang", $$v);
      },
      expression: "form.edu_lang"
    }
  }), _vm._v(" "), _c('q-input', {
    attrs: {
      "label": _vm.trans('statement.edu_form'),
      "required": "",
      "readonly": ""
    },
    model: {
      value: _vm.type_of_training_label,
      callback: function callback($$v) {
        _vm.type_of_training_label = $$v;
      },
      expression: "type_of_training_label"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }