var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sc-journal-plan__control"
  }, [_vm._l(_vm.table, function (item, key) {
    return _c('div', {
      key: key,
      staticClass: "sc-journal-plan__panel sc-journal-plan__editor"
    }, [key !== 'files' ? [_c('div', {
      staticClass: "sc-journal-plan__title sc-journal-plan__title--border mb-15"
    }, [_vm._v("\n        " + _vm._s(item.label) + ":\n      ")]), _vm._v(" "), item.value ? _c('div', {
      staticClass: "sc-journal-plan__text",
      domProps: {
        "innerHTML": _vm._s(item.value)
      }
    }) : _c('div', {
      staticClass: "sc-journal-plan__text sc-journal-plan__text--grey"
    }, [_vm._v("\n        " + _vm._s(_vm.trans('table.empty_value')) + "\n      ")])] : [_c('div', {
      staticClass: "sc-journal-plan__title sc-journal-plan__title--border mb-15"
    }, [_vm._v("\n        " + _vm._s(item.label) + ":\n      ")]), _vm._v(" "), item.value.length !== 0 ? _vm._l(item.value, function (el) {
      return _c('div', {
        staticClass: "d-flex align-items-center mt-10"
      }, [['jpeg', 'jpg', 'png', 'gif', 'bmp'].includes(el.extension) ? [_c('vue-image-modal', {
        staticClass: "d-inline-block",
        attrs: {
          "uri-image": el.value
        }
      })] : [_c('a', {
        staticClass: "d-inline-block s-btn-table s-btn--ico",
        attrs: {
          "href": el.value,
          "target": "_blank"
        }
      }, [_c('span', {
        staticClass: "s-ico s-ico--thm-eye"
      })])], _vm._v(" "), _c('span', {
        staticClass: "d-inline-block ml-20"
      }, [_vm._v(" " + _vm._s(el.name))])], 2);
    }) : _c('div', {
      staticClass: "sc-journal-plan__text sc-journal-plan__text--grey"
    }, [_vm._v("\n        " + _vm._s(_vm.trans('label.not_load')) + "\n      ")])]], 2);
  }), _vm._v(" "), _c('div', {
    staticClass: "row justify-content-end mb-60 mt-60"
  }, [_c('div', {
    staticClass: "col-lg-3 col-md-3 col-sm-12",
    class: _vm.$q.screen.width < 768 ? 'order-2' : ''
  }, [_vm.canEditPlan ? _c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "to": _vm.uriPlanEdit,
      "type": "a",
      "color": "blue",
      "label": _vm.trans('button.edit'),
      "no-caps": ""
    }
  }) : _vm._e()], 1)])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }