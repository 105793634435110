var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sc-curriculum-program__table"
  }, [_vm._l(_vm.commonInfo, function (info, key) {
    return [_c('div', {
      key: "".concat(key),
      staticClass: "row no-gutters sc-curriculum-program__table--row",
      class: {
        active: (key + 1) % 2 !== 0
      }
    }, [_c('div', {
      staticClass: "col-lg-6 col-md-6 sc-curriculum-program__table--col sc-curriculum-program__table--label sn--text-right"
    }, [_vm._v("\n        " + _vm._s(info.label) + "\n      ")]), _vm._v(" "), _c('div', {
      staticClass: "col-lg-6 col-md-6 col-sm-12 sc-curriculum-program__table--col"
    }, [_vm._v("\n        " + _vm._s(info.value) + "\n      ")])])];
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }