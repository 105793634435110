import {
  QForm,
  QBtn,
  QSelect,
  QIcon,
  QInput,
  QTable,
  QTh,
  QTr,
  QTd,
  QTooltip,
} from '@quasar/components';
import {
  requestWrapper,
} from '@vjs/helpers';
import VConfirmDialog from '@vjs/modals/VConfirmDialog.vue';
import {capitalizeByWords} from '@vjs/helpers/text';
import VNoResults from '@vjs/components/VNoResults';

export default {
  name: 'VDiplomaList',
  components: {
    QForm,
    QBtn,
    QSelect,
    QInput,
    QTable,
    QTh,
    QTr,
    QTd,
    QIcon,
    QTooltip,
    VConfirmDialog,
    VNoResults,
  },
  props: {
    uriGetData: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      table: {
        rows: [],
        columns: [],
      },
      pagination: {
        rowsPerPage: 20,
      },
      openModal: false,
      modalMethod: 'post',
      uriConfirm: '',
      filter: '',
      loading: false,
      loadingModal: false,
      noResults: false,
    };
  },

  async mounted() {
    await this.$trans.add([
      'label',
      'button',
      'placeholder',
      'warning_text',
      'table',
      'fields',
    ]);

    this.table.columns = [
      {
        name: 'index',
        required: false,
        label: '№',
        align: 'center',
        field: 'index',
        sortable: false,
        width: 75,
      },
      {
        name: 'fullName',
        required: true,
        label: this.trans('label.fullname'),
        align: 'center',
        field: 'fullName',
        sortable: true,
        width: 300,
      },
      {
        name: 'group',
        required: true,
        label: this.trans('label.group'),
        align: 'center',
        field: 'group',
        sortable: true,
        width: 150,
      },
      {
        name: 'theme',
        required: true,
        label: this.trans('table.theme'),
        align: 'center',
        field: 'theme',
        sortable: true,
        width: 350,
      },
      {
        name: 'personnelName',
        required: true,
        label: this.trans('table.personnel_name'),
        align: 'center',
        field: 'personnelName',
        sortable: true,
        width: 300,
      },
      {
        name: 'protectionDate',
        required: true,
        label: this.trans('table.protection_date'),
        align: 'center',
        field: 'protectionDate',
        sortable: true,
        width: 150,
      },
      {
        name: 'result',
        required: true,
        label: this.trans('fields.point'),
        align: 'center',
        field: 'result',
        sortable: true,
        width: 150,
      },
      {
        name: 'actions',
        required: false,
        label: this.trans('table.operations'),
        align: 'center',
        field: 'actions',
        sortable: false,
        width: 150,
      },
    ];

    await this.requestGetTable();
  },
  methods: {
    async requestGetTable() {
      this.loading = true;
      const res = await requestWrapper.call(this, {
        url: this.uriGetData,
      });
      if (!res.error) {
        if (res.rows.length > 0) {
          this.table.rows = res.rows.map((item, index) => ({
            ...item,
            index: index + 1,
            fullName: capitalizeByWords(item.fullName),
            personnelName: item.personnelName !== this.trans('label.not_exists') ? capitalizeByWords(item.personnelName) : item.personnelName,
          }));
        }
        if (res.rows.length === 0) {
          this.noResults = true;
        }
      }
      this.loading = false;
    },
    openConfirmModal(action) {
      if (action.type === 'button' && action.modal === 'confirm' && action.modalLink && action.method) {
        this.openModal = true;
        this.uriConfirm = action.modalLink;
        this.modalMethod = action.method;
      }
    },

    async requestConfirmModal() {
      this.loadingModal = true;
      const res = await requestWrapper.call(this, {
        method: this.modalMethod,
        url: this.uriConfirm,
      });

      if (!res.error) {
        this.openModal = false;
        if (res.data && res.data.message) {
          this.$notify({
            text: res.data.message,
            type: 'success',
          });
        }
      }
      this.loadingModal = false;
      await this.requestGetTable();
    },
    getCellClass(name, value) {
      switch (name) {
        case 'group':
          return 's-to-center';
        case 'protectionDate':
          return 's-to-center';
        case 'result':
          return 's-to-center';
        default:
          return '';
      }
    },
  },
};
