<template>
  <div>
    <div
      :id="'modal-confirm-candidate-'+ statement.id"
      class="s-modal fade"
      tabindex="-1"
    >
      <div class="s-modal__container">
        <div class="s-modal__head">
          <div
            class="s-modal__close"
            data-toggle="modal"
            :data-target="'#modal-confirm-candidate-'+ statement.id"
          />
        </div>
        <div class="s-modal__body">
          <form
            :action="statementDisapprove"
            method="post"
          >
            <div class="mb-30">
              <label class="s-form__label mb-10">{{ trans('label.decline_message') }}: <i>*</i></label>
              <textarea
                class="s-form__textarea"
                name="message"
              />
              <input
                type="hidden"
                :value="csrfToken"
                name="_token"
              >
              <input
                id="sign-statement"
                type="hidden"
                name="sign"
                :value="signXml"
              >
            </div>
            <div class="row-sort-between-btn">
              <div
                class="s-btn s-btn--thm-grey s-btn--w-150"
                data-toggle="modal"
                :data-target="'#modal-confirm-candidate-'+ statement.id"
              >
                {{ trans('button.cancel') }}
              </div>
              <div
                class="s-btn s-btn--thm-green s-btn--w-150"
                data-action="send-ajax"
              >
                {{ trans('button.send') }}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div
      :id="'modal-confirm-candidate-rework-'+ statement.id"
      class="s-modal fade"
      tabindex="-1"
    >
      <div class="s-modal__container">
        <div class="s-modal__head">
          <div
            class="s-modal__close"
            data-toggle="modal"
            :data-target="'#modal-confirm-candidate-rework-'+ statement.id"
          />
        </div>
        <div class="s-modal__body">
          <form
            :action="setModalConfirmCandidateRework"
            method="post"
          >
            <div class="mb-30">
              <label
                class="s-form__label mb-10"
                for="average_point"
              >
                {{ trans('label.average_point') }}: <i>*</i>
              </label>
              <input
                id="average_point"
                class="s-form__input mb-10 pt-10 mt-20"
                name="average_point"
              >
              <label class="s-form__label mb-10">{{ trans('label.decline_message') }}:</label>
              <textarea
                class="s-form__textarea"
                name="message"
              />
              <input
                type="hidden"
                :value="csrfToken"
                name="_token"
              >
              <input
                id="sign-statement"
                type="hidden"
                name="sign"
                :value="signXml"
              >
            </div>
            <div class="row-sort-between-btn">
              <div
                class="s-btn s-btn--thm-grey s-btn--w-150"
                data-toggle="modal"
                :data-target="'#modal-confirm-candidate-rework-'+ statement.id"
              >
                {{ trans('button.cancel') }}
              </div>
              <div
                class="s-btn s-btn--thm-green s-btn--w-150"
                data-action="send-ajax"
              >
                {{ trans('button.send') }}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VStatementSignModals',
  props: {
    signXml: {
      type: String,
      default: '',
    },
    csrfToken: {
      type: String,
      default: '',
    },
    statement: {
      type: Object,
      required: true,
    },
    setModalConfirmCandidateRework: {
      type: String,
      required: true,
    },
    statementDisapprove: {
      type: String,
      required: true,
    },
  },
};
</script>
