<template>
  <div class="mt-50 s-w-100 sc-journal__legend">
    <div
      @click="spoiler = !spoiler"
    >
      <div class="sc-journal__legend--title">
        <div
          class="sc-journal__legend--label s-upper"
        >
          {{ trans('label.colors_title') }}
        </div>
        <div class="s-combine-title__line">
          <div
            class="sc-journal__legend--button"
          >
            <span
              class="s-ico"
              :class="`s-ico--thm-angle-double-${spoiler ? 'up' : 'down'}`"
            />
            <span class="sc-journal__legend--button-text">
              {{ trans(`button.${spoiler ? 'hide_spoiler' : 'show_spoiler'}`) }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <q-slide-transition>
      <div
        v-if="spoiler"
        class="sc-journal__legend--wrapper"
      >
        <template
          v-for="(colorGroup, groupKey) in computedColorsArr"
        >
          <template
            v-for="(color, key) in colorGroup"
          >
            <div
              :key="`${groupKey}-${key}`"
              class="row no-gutters sc-journal__legend--cell"
              :class="{active: (groupKey + 1) % 2 !== 0}"
            >
              <div class="col-2">
                <div
                  class="sc-journal__legend--color-squire"
                  style="margin-right: 1rem;"
                  :style="`background-color: ${color.name}`"
                />
              </div>
              <div class="col-10">
                <span>
                  {{ color.label }}
                </span>
              </div>
            </div>
          </template>
        </template>
      </div>
    </q-slide-transition>
  </div>
</template>

<script>
import { QSlideTransition } from '@quasar/components';

export default {
  name: 'VJournalLegend',
  components: { QSlideTransition },
  data: () => ({ spoiler: false }),
  computed: {
    colorsArr() { return this.$store.getters.colorsArr; },
    computedColorsArr() {
      if (this.$q.screen.width < 661) {
        return _.chunk(this.colorsArr, 1);
      }
      if (this.$q.screen.width < 955) {
        return _.chunk(this.colorsArr, 2);
      }
      if (this.$q.screen.width < 1255) {
        return _.chunk(this.colorsArr, 3);
      }
      return _.chunk(this.colorsArr, 4);
    },
  },
};
</script>
