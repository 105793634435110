var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('q-dialog', {
    attrs: {
      "value": _vm.active,
      "persistent": _vm.persistent
    },
    on: {
      "hide": _vm.hideHandler
    }
  }, [_c('v-modal', {
    class: {
      's-light': _vm.lightText
    },
    attrs: {
      "title": _vm.title || _vm.trans('notice.confirm'),
      "size": _vm.size,
      "in-body": _vm.inBody
    },
    on: {
      "modal-close": function modalClose($event) {
        return _vm.$emit('modal-close');
      }
    }
  }, [_vm._t("default"), _vm._v(" "), _c('div', {
    staticClass: "row-sort-between-btn"
  }, [_c('q-btn', {
    directives: [{
      name: "close-popup",
      rawName: "v-close-popup"
    }],
    attrs: {
      "icon": "close",
      "width": "20",
      "label": _vm.trans('button.cancel'),
      "label-wider": "",
      "no-caps": "",
      "color": _vm.declineColor
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('modal-close');
      }
    }
  }), _vm._v(" "), _c('q-btn', {
    attrs: {
      "icon": "check",
      "width": "20",
      "label": _vm.trans('button.approve'),
      "label-wider": "",
      "no-caps": "",
      "color": _vm.submitColor,
      "loading": _vm.loading
    },
    on: {
      "click": _vm.confirm
    }
  })], 1)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }