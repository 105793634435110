var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-table', {
    staticClass: "mb-50",
    attrs: {
      "cols": _vm.firstCols,
      "rows": _vm.firstRows,
      "header-title": _vm.firstTitle,
      "adaptive-when": _vm.firstAdaptiveWhen
    }
  }), _vm._v(" "), _c('v-table', {
    attrs: {
      "cols": _vm.secondCols,
      "rows": _vm.secondRows,
      "header-title": _vm.secondTitle,
      "adaptive-when": _vm.secondAdaptiveWhen
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }