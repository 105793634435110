<template>
  <div class="sc-curriculum-program__buttons">
    <template v-if="editData">
      <q-btn
        class="mb-5"
        color="green"
        icon="check"
        squire
        outline
        @click="saveLesson"
      >
        <q-tooltip>{{ trans('label.save_remark') }}</q-tooltip>
      </q-btn>
      <q-btn
        color="red"
        icon="close"
        squire
        outline
        @click="editData = !editData"
      >
        <q-tooltip>{{ trans('label.cancel_remark_creation') }}</q-tooltip>
      </q-btn>
    </template>
    <template v-else>
      <q-btn
        class="mb-5"
        color="blue"
        icon="pencil"
        squire
        outline
        @click="editData = !editData"
      />
      <q-btn
        color="blue"
        icon="trash"
        squire
        outline
        @click="openConfirmModal"
      />
    </template>
  </div>
</template>

<script>
import { Helper } from '@common/src/helpers';
import { requestWrapper } from '@vjs/helpers';
import { eventBus } from '@common/mixins';
import {QTooltip, QBtn} from '@quasar/components';

export default {
  name: 'ControlButtons',
  components: {
    QTooltip,
    QBtn,
  },
  mixins: [eventBus],
  props: {
    lesson: Object,
  },
  data() {
    return {
      editData: false,
    };
  },
  watch: {
    editData() {
      eventBus.$emit('add-edit', this.lesson.lessons_id.value);
    },
  },
  computed: {
    discipline() {
      return this.$store.getters.programDisciplineId;
    },
  },
  methods: {
    async saveLesson() {
      this.$globalLoading.show();
      const data = this.lesson;
      const { curriculumProgramApiConfig, makeProgramUrl, parentId } = this.$store.getters;
      const res = await requestWrapper.call(this, {
        url: makeProgramUrl(curriculumProgramApiConfig.lesson.update, parentId),
        method: 'post',
        data,
      });

      if (!res.error) {
        await this.$store.dispatch('GET_CURRICULUM_PROGRAM_CONTENT_TABLE', {discipline_id: this.discipline});
        this.$emit('onClose');
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.editData = !this.editData;
      this.$globalLoading.hide();
    },
    async confirmDelete() {
      this.$globalLoading.show();
      const url = Helper.urlGenerate(
        this.$store.getters.curriculumProgramApiConfig.lesson.delete,
        { lessonId: this.lesson.lessons_id.value },
      );
      const res = await requestWrapper.call(this, {
        url,
        method: 'delete',
      });

      if (!res.error) {
        await this.$store.dispatch('GET_CURRICULUM_PROGRAM_CONTENT_TABLE', {discipline_id: this.discipline});
        this.$emit('onSuccess');
        eventBus.$emit('onCloseAll');
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }

      this.$globalLoading.hide();
    },
    openConfirmModal() {
      this.$sModal.open('v-modal-confirm', {
        title: this.trans('notice.are_you_sure'),
        component: 'v-modal-confirm',
        events: { onApprove: this.confirmDelete },
      });
    },
  },
};
</script>
