var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-contest__conditions mt-30"
  }, [_vm.contest ? _c('div', [_c('h1', {
    staticClass: "s-contest__description--heading"
  }, [_vm._v("\n      " + _vm._s(_vm.isPersonnelContest ? _vm.contest.title : _vm.contest.college_title) + "\n    ")]), _vm._v(" "), _c('p', {
    staticClass: "s-contest__description--subheading"
  }, [_c('b', [_vm._v(_vm._s(_vm.trans('contest.dates')) + ":")]), _vm._v(" " + _vm._s(_vm.contest.date) + "\n    ")]), _vm._v(" "), _vm.isPersonnelContest ? _c('div', {
    staticClass: "s-contest__prizes"
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.contest.prizes)
    }
  })]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "s-contest__description"
  }, [_vm.isPersonnelContest ? _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.contest.description)
    }
  }) : _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.contest.college_description)
    }
  })])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }