var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('q-table', {
    attrs: {
      "data": _vm.table.rows,
      "columns": _vm.table.columns,
      "row-key": "name",
      "separator": "cell",
      "flat": _vm.$q.screen.width > 1024,
      "grid": _vm.$q.screen.width <= 1024,
      "wrap-cells": "",
      "color": "blue",
      "pagination": _vm.pagination,
      "no-data-label": _vm.trans('label.no_data'),
      "no-results-label": _vm.trans('label.no_results'),
      "loading": _vm.loading
    },
    on: {
      "update:pagination": function updatePagination($event) {
        _vm.pagination = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn(props) {
        return [_c('q-tr', {
          attrs: {
            "props": props
          }
        }, [_c('q-td', {
          staticClass: "s-to-center"
        }, [_vm._v("\n          " + _vm._s(props.row.__index) + "\n        ")]), _vm._v(" "), _vm._l(props.cols, function (col, i) {
          return [!['__index', 'index', 'actions'].includes(col.field) ? _c('q-td', {
            key: i
          }, [[_vm._v("\n              " + _vm._s(col.value) + "\n            ")]], 2) : _vm._e(), _vm._v(" "), col.field === 'actions' ? _c('q-td', {
            key: i,
            staticClass: "s-to-center"
          }, [_c('div', {
            staticClass: "s-group s-group--to-center"
          }, _vm._l(props.row.actions, function (action, key) {
            return _c('q-btn', {
              key: key,
              attrs: {
                "color": action.color,
                "icon": action.icon,
                "squire": "",
                "outline": "",
                "loading": _vm.loading,
                "type": action.type
              },
              on: {
                "click": function click($event) {
                  return _vm.openConfirmModal(action);
                }
              }
            }, [_c('q-tooltip', [_vm._v(_vm._s(action.tooltip))])], 1);
          }), 1)]) : _vm._e()];
        })], 2)];
      }
    }, {
      key: "item",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "sn-table__grid-item col-xs-12 col-sm-6 col-md-4 col-lg-3"
        }, [_c('div', {
          staticClass: "sn-table__grid-item-card sn-table__card"
        }, _vm._l(props.cols, function (col, i) {
          return _c('div', {
            key: i,
            staticClass: "sn-table__grid-item-row"
          }, [!['__index', 'index', 'actions'].includes(col.field) ? _c('div', {
            key: i
          }, [_c('div', {
            staticClass: "sn-table__grid-item-title"
          }, [_vm._v("\n                " + _vm._s(col.label) + "\n              ")]), _vm._v(" "), _c('div', {
            staticClass: "sn-table__grid-item-value"
          }, [_vm._v("\n                " + _vm._s(col.value) + "\n              ")])]) : _vm._e(), _vm._v(" "), col.field === 'actions' ? _c('div', [_c('div', {
            staticClass: "sn-table__grid-item-title"
          }, [_vm._v("\n                " + _vm._s(col.label) + "\n              ")]), _vm._v(" "), _c('div', {
            staticClass: "sn-table__grid-item-value"
          }, [_c('div', {
            staticClass: "s-group s-group--to-center"
          }, _vm._l(col.value, function (action, key) {
            return _c('q-btn', {
              key: key,
              attrs: {
                "color": action.color,
                "icon": action.icon,
                "squire": "",
                "outline": "",
                "loading": _vm.loading,
                "type": action.type,
                "label": action.tooltip
              },
              on: {
                "click": function click($event) {
                  return _vm.openConfirmModal(action);
                }
              }
            });
          }), 1)])]) : _vm._e()]);
        }), 0)])];
      }
    }, {
      key: "no-data",
      fn: function fn() {
        return [!_vm.table.rows.length ? _c('v-no-results', {
          staticClass: "s-w-100",
          attrs: {
            "image": "search",
            "title": _vm.trans('warning_text.no_results_title'),
            "text": _vm.trans('warning_text.no_results_text')
          }
        }) : _vm._e()];
      },
      proxy: true
    }])
  }), _vm._v(" "), _c('v-confirm-dialog-new', {
    attrs: {
      "active": _vm.openModal,
      "confirm": _vm.requestConfirmModal,
      "persistent": "",
      "size": "480",
      "in-body": ""
    },
    on: {
      "modal-close": function modalClose($event) {
        _vm.openModal = $event;
      }
    }
  }, [_vm.withInput ? _c('q-input', {
    attrs: {
      "dense": "",
      "debounce": "300",
      "type": "textarea",
      "placeholder": _vm.trans('label.decline_message'),
      "label": _vm.trans('label.decline_message'),
      "color": "blue"
    },
    model: {
      value: _vm.form.message,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "message", $$v);
      },
      expression: "form.message"
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }