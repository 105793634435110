var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "exam-questions__items mt-10"
  }, [_c('q-form', {
    staticClass: "exam-questions__item",
    on: {
      "submit": _vm.requestAddTicket
    }
  }, [_c('div', {
    staticClass: "exam-questions__header"
  }, [_c('div', [_c('q-input', {
    attrs: {
      "placeholder": _vm.trans('label.ticket_name'),
      "disable": _vm.loading
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1), _vm._v(" "), _c('div', [_c('q-btn', {
    attrs: {
      "color": "orange",
      "icon": "close",
      "squire": "",
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.cancelAdd($event);
      }
    }
  }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('button.cancel_ticket_edit')))])], 1), _vm._v(" "), _c('q-btn', {
    attrs: {
      "type": "submit",
      "color": "green",
      "icon": "check",
      "squire": "",
      "loading": _vm.loading
    }
  }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('button.save_ticket')))])], 1)], 1)]), _vm._v(" "), _c('div', {
    staticClass: "exam-questions__line"
  }, [_c('h5', {
    staticClass: "s-title"
  }, [_vm._v("\n        " + _vm._s(_vm.trans('label.ticket_body')) + "\n      ")]), _vm._v(" "), _c('hr', {
    staticClass: "s-w-100 s-hr"
  })]), _vm._v(" "), _c('ckeditor', {
    attrs: {
      "editor": _vm.editor,
      "config": _vm.editorConfig,
      "disabled": _vm.loading
    },
    on: {
      "ready": _vm.onEditorReady
    },
    model: {
      value: _vm.body,
      callback: function callback($$v) {
        _vm.body = $$v;
      },
      expression: "body"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }