var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sc-curriculum-working-program"
  }, [_c('v-wrapper-q-select', {
    attrs: {
      "options": _vm.options.qualifications,
      "extra-binds": {
        required: true,
        label: _vm.trans('curriculum.qualification')
      }
    },
    on: {
      "input": function input($event) {
        return _vm.loadOptions(_vm.uriGetGroups, 'groups');
      }
    },
    model: {
      value: _vm.field.qualification,
      callback: function callback($$v) {
        _vm.$set(_vm.field, "qualification", $$v);
      },
      expression: "field.qualification"
    }
  }), _vm._v(" "), _c('v-wrapper-q-select', {
    attrs: {
      "options": _vm.options.groups,
      "extra-binds": {
        required: true,
        label: _vm.trans('label.group')
      }
    },
    on: {
      "input": function input($event) {
        return _vm.loadOptions(_vm.uriGetCurriculumWorking, 'curriculumWorkingList');
      }
    },
    model: {
      value: _vm.field.group,
      callback: function callback($$v) {
        _vm.$set(_vm.field, "group", $$v);
      },
      expression: "field.group"
    }
  }), _vm._v(" "), _c('v-wrapper-q-select', {
    attrs: {
      "options": _vm.options.curriculumWorkingList,
      "extra-binds": {
        required: true,
        label: _vm.trans('menu.curriculum'),
        placeholder: _vm.field.curriculum_working_id ? '' : _vm.trans('placeholder.choose'),
        mapOptions: true,
        emitValues: true
      },
      "old-search": false
    },
    on: {
      "input": function input($event) {
        return _vm.loadOptions(_vm.uriGetOptions, ['modules', 'disciplines', 'personnel']);
      }
    },
    model: {
      value: _vm.field.curriculum_working_id,
      callback: function callback($$v) {
        _vm.$set(_vm.field, "curriculum_working_id", $$v);
      },
      expression: "field.curriculum_working_id"
    }
  }), _vm._v(" "), _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.field.curriculum_working_id ? _c('div', [_vm.options.disciplines.length ? _c('v-wrapper-q-select', {
    attrs: {
      "options": _vm.options.disciplines,
      "extra-binds": {
        required: true,
        label: _vm.trans('label.discipline')
      }
    },
    model: {
      value: _vm.field.discipline_id,
      callback: function callback($$v) {
        _vm.$set(_vm.field, "discipline_id", $$v);
      },
      expression: "field.discipline_id"
    }
  }) : _vm._e()], 1) : _vm._e()]), _vm._v(" "), _c('v-wrapper-q-select', {
    attrs: {
      "multiple": "",
      "options": _vm.options.personnel,
      "extra-binds": {
        required: true,
        label: _vm.trans('label.personnel'),
        placeholder: _vm.field.teachers.length > 0 ? '' : _vm.trans('placeholder.choose')
      }
    },
    model: {
      value: _vm.field.teachers,
      callback: function callback($$v) {
        _vm.$set(_vm.field, "teachers", $$v);
      },
      expression: "field.teachers"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "row-sort-between mt-20"
  }, [_c('q-btn', {
    staticClass: "s-btn--w-200 btn-adaptive-helper",
    attrs: {
      "label": _vm.trans('button.cancel'),
      "color": "blue",
      "icon": "mark",
      "type": "a",
      "to": _vm.uriBack
    }
  }), _vm._v(" "), _c('q-btn', {
    staticClass: "s-btn--w-200 btn-adaptive-helper",
    attrs: {
      "label": _vm.trans('button.save'),
      "color": "green",
      "icon": "mark",
      "disable": !_vm.validate
    },
    on: {
      "click": _vm.requestAction
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }