export default {
  methods: {
    getFileLabel(name) {
      switch (name) {
        case 'doc_statement': return this.trans('label.doc_statement');
        case 'diploma': return this.trans('label.diploma');
        case 'diploma_2': return this.trans('label.el_diploma_2');
        case 'certificate': return this.trans('label.certificate');
        case 'doc_photo': return this.trans('label.doc_photo');
        case 'doc_medical_examination_086_y': return this.trans('label.doc_medical_examination_086_y');
        case 'doc_medical_examination_086_flyura': return this.trans('label.doc_medical_examination_086_flyura');
        case 'doc_medical_examination_088': return this.trans('label.doc_medical_examination_088');
        case 'doc_for_privilege': return this.trans('label.doc_for_privilege');
        case 'doc_ppz': return this.trans('statement.doc_ppz');
        case 'doc_relation': return this.trans('statement.doc_relation');
        default: return '';
      }
    },
    isFileRequired(name) {
      switch (name) {
        case 'doc_statement': return true;
        case 'diploma': return true;
        case 'diploma_2': return true;
        case 'doc_photo': return true;
        case 'doc_relation': return true;
        case 'doc_medical_examination_086_y': return true;
        default: return false;
      }
    },
  },
};
