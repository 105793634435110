var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "exam-questions__item"
  }, [_vm.loading ? _c('q-spinner-gears', {
    staticClass: "align-self-center sn--cursor-wait s-w-100",
    attrs: {
      "color": "blue",
      "size": "20rem"
    }
  }) : _c('q-form', {
    on: {
      "submit": _vm.questionForm
    }
  }, [_c('div', {
    staticClass: "exam-questions__header"
  }, [_c('div', [_c('div', {
    staticClass: "s-static-rectangle"
  }, [_c('b', [_vm._v("№" + _vm._s(_vm.questionNumber))])])]), _vm._v(" "), _vm.getGroup.subject ? _c('div', [_vm._v(_vm._s(_vm.trans('label.subject')) + ": " + _vm._s(_vm.getGroup.subject.name) + "\n      ")]) : _vm._e(), _vm._v(" "), _c('div', [_c('q-btn', {
    attrs: {
      "color": !_vm.dataQuestion.is_visible ? 'grey' : 'aqua',
      "icon": "eye",
      "squire": ""
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        !_vm.dataQuestion.is_visible ? _vm.questionShow(_vm.dataQuestion.id) : _vm.questionHide(_vm.dataQuestion.id);
      }
    }
  }, [_c('q-tooltip', [_vm._v(_vm._s(!_vm.dataQuestion.is_visible ? _vm.trans('label.question_disabled') : _vm.trans('label.question_enabled')) + "\n          ")])], 1), _vm._v(" "), _vm.questionEditForm() ? [_c('q-btn', {
    attrs: {
      "color": "red",
      "icon": "trash",
      "squire": ""
    },
    on: {
      "click": function click($event) {
        _vm.modalConfirmQuestionRemove = !_vm.modalConfirmQuestionRemove;
      }
    }
  }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('label.delete_question')))])], 1), _vm._v(" "), _c('q-btn', {
    attrs: {
      "color": "orange",
      "icon": "close",
      "squire": ""
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.cancelQuestion();
      }
    }
  }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('label.cancel_question')))])], 1), _vm._v(" "), _c('q-btn', {
    attrs: {
      "type": "submit",
      "color": "green",
      "icon": "check",
      "loading": _vm.disableSave,
      "squire": ""
    }
  }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('label.save_question')))])], 1)] : _c('q-btn', {
    attrs: {
      "color": "blue",
      "icon": "pencil",
      "squire": ""
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.changeQuestion(_vm.dataQuestion.id);
      }
    }
  }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('label.change_question')))])], 1)], 2)]), _vm._v(" "), _c('div', {
    staticClass: "exam-questions__line"
  }, [_c('h5', {
    staticClass: "s-title"
  }, [_vm._v(_vm._s(_vm.trans('test.question_text')))]), _vm._v(" "), _c('hr', {
    staticClass: "s-w-100 s-hr"
  })]), _vm._v(" "), _vm.questionEditForm() ? _c('ckeditor', {
    attrs: {
      "editor": _vm.editor,
      "config": _vm.editorConfig
    },
    on: {
      "ready": _vm.onEditorReady
    },
    model: {
      value: _vm.dataQuestion.question,
      callback: function callback($$v) {
        _vm.$set(_vm.dataQuestion, "question", $$v);
      },
      expression: "dataQuestion.question"
    }
  }) : _c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.dataQuestion.question)
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "exam-questions__line s-w-100"
  }, [_c('h5', {
    staticClass: "s-title"
  }, [_vm._v(_vm._s(_vm.trans('test.answer_list')))]), _vm._v(" "), _c('hr', {
    staticClass: "s-w-100 s-hr"
  }), _vm._v(" "), _c('q-toggle', {
    staticClass: "ml-10",
    attrs: {
      "checked-icon": "eye",
      "unchecked-icon": "close",
      "color": "blue",
      "label": _vm.trans('main.show'),
      "left-label": ""
    },
    model: {
      value: _vm.visibleListAnswers,
      callback: function callback($$v) {
        _vm.visibleListAnswers = $$v;
      },
      expression: "visibleListAnswers"
    }
  }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('label.show_answers')))])], 1)], 1), _vm._v(" "), _c('q-slide-transition', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.visibleListAnswers,
      expression: "visibleListAnswers"
    }]
  }, [_c('div', {
    staticClass: "exam-questions__answers"
  }, [_c('div', {
    staticClass: "exam-questions__answers-container container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm col-sm-1"
  }, [_vm._v("#")]), _vm._v(" "), _c('div', {
    staticClass: "col-sm col-sm-5"
  }, [_vm._v(_vm._s(_vm.trans('label.answer')))]), _vm._v(" "), _vm.group.type !== 'weight' ? _c('div', {
    staticClass: "col-sm col-sm-2"
  }, [_vm._v(_vm._s(_vm.trans('test.answer_correct')) + "\n          ")]) : _vm._e(), _vm._v(" "), _vm.group.type === 'weight' ? _c('div', {
    staticClass: "col-sm col-sm-2"
  }, [_vm._v(_vm._s(_vm.trans('fields.weight')) + "\n          ")]) : _vm._e(), _vm._v(" "), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.questionEditForm(),
      expression: "questionEditForm()"
    }],
    staticClass: "col-sm col-sm-2"
  }, [_vm._v("\n            " + _vm._s(_vm.trans('table.action')) + "\n          ")])]), _vm._v(" "), _vm._l(_vm.dataQuestion.answers, function (dataAnswer, answer_key) {
    return _c('div', {
      key: answer_key,
      staticClass: "row exam-questions__answer",
      class: _vm.examQuestionsAnswerClass(answer_key)
    }, [_c('div', {
      staticClass: "col-sm col-sm-1"
    }, [_vm._v(_vm._s(answer_key + 1))]), _vm._v(" "), _vm.questionEditForm() ? [_c('div', {
      staticClass: "col-sm col-sm-5"
    }, [_c('ckeditor', {
      attrs: {
        "editor": _vm.editor,
        "config": _vm.editorConfig
      },
      on: {
        "ready": _vm.onEditorReady
      },
      model: {
        value: dataAnswer.answer,
        callback: function callback($$v) {
          _vm.$set(dataAnswer, "answer", $$v);
        },
        expression: "dataAnswer.answer"
      }
    })], 1), _vm._v(" "), _vm.group.type !== 'weight' ? _c('div', {
      staticClass: "col-sm col-sm-2"
    }, [_c('q-toggle', {
      attrs: {
        "checked-icon": "check",
        "color": "green'",
        "unchecked-icon": "close",
        "false-value": 0,
        "true-value": 1
      },
      on: {
        "input": function input($event) {
          return _vm.changeAnswerCorrect(answer_key);
        }
      },
      model: {
        value: dataAnswer.correct,
        callback: function callback($$v) {
          _vm.$set(dataAnswer, "correct", $$v);
        },
        expression: "dataAnswer.correct"
      }
    }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('test.answer_correct')))])], 1)], 1) : _vm._e(), _vm._v(" "), _vm.group.type === 'weight' ? _c('div', {
      staticClass: "col-sm col-sm-2"
    }, [_c('q-input', {
      attrs: {
        "type": "number",
        "min": 0,
        "max": 4
      },
      model: {
        value: dataAnswer.weight,
        callback: function callback($$v) {
          _vm.$set(dataAnswer, "weight", $$v);
        },
        expression: "dataAnswer.weight"
      }
    })], 1) : _vm._e(), _vm._v(" "), _c('div', {
      staticClass: "col-sm col-sm-2"
    }, [_c('q-btn', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.deleteBtn(),
        expression: "deleteBtn()"
      }],
      attrs: {
        "color": "red",
        "icon": "trash",
        "squire": ""
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.deleteAnswer(answer_key);
        }
      }
    }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('label.delete_answer')))])], 1)], 1)] : [_c('div', {
      staticClass: "col-sm col-sm-5",
      domProps: {
        "innerHTML": _vm._s(dataAnswer.answer)
      }
    }), _vm._v(" "), _vm.group.type !== 'weight' ? _c('div', {
      staticClass: "col-sm col-sm-2"
    }, [_vm._v("\n              " + _vm._s(dataAnswer.correct ? _vm.trans('main.yes') : _vm.trans('main.not')) + "\n            ")]) : _vm._e(), _vm._v(" "), _vm.group.type === 'weight' ? _c('div', {
      staticClass: "col-sm col-sm-2"
    }, [_vm._v("\n              " + _vm._s(dataAnswer.weight) + "\n            ")]) : _vm._e()]], 2);
  }), _vm._v(" "), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.questionEditForm(),
      expression: "questionEditForm()"
    }],
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm col-sm-1"
  }), _vm._v(" "), _c('div', {
    staticClass: "col-sm col-sm-5"
  }), _vm._v(" "), _c('div', {
    staticClass: "col-sm col-sm-2"
  }), _vm._v(" "), _c('div', {
    staticClass: "col-sm col-sm-2"
  }), _vm._v(" "), _c('div', {
    staticClass: "col-sm col-sm-2"
  }, [_c('q-btn', {
    attrs: {
      "color": "blue",
      "icon": "plus",
      "squire": ""
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.newAnswer();
      }
    }
  }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('label.create_answer')))])], 1)], 1)])], 2)])]), _vm._v(" "), _c('q-dialog', {
    attrs: {
      "persistent": ""
    },
    model: {
      value: _vm.modalConfirmQuestionRemove,
      callback: function callback($$v) {
        _vm.modalConfirmQuestionRemove = $$v;
      },
      expression: "modalConfirmQuestionRemove"
    }
  }, [_c('v-modal', {
    attrs: {
      "size": "480",
      "title": _vm.trans('test.confirm')
    }
  }, [_c('div', {
    staticClass: "row-sort-between-btn"
  }, [_c('q-btn', {
    directives: [{
      name: "close-popup",
      rawName: "v-close-popup"
    }],
    attrs: {
      "icon": "close",
      "width": "20",
      "label": _vm.trans('button.cancel'),
      "labelWider": "",
      "color": "blue"
    }
  }), _vm._v(" "), _c('q-btn', {
    attrs: {
      "icon": "check",
      "width": "20",
      "label": _vm.trans('button.approve'),
      "labelWider": "",
      "color": "green"
    },
    on: {
      "click": function click($event) {
        return _vm.destroyQuestion();
      }
    }
  })], 1)])], 1), _vm._v(" "), _c('q-dialog', {
    attrs: {
      "persistent": ""
    },
    model: {
      value: _vm.modalConfirmAnswerRemove,
      callback: function callback($$v) {
        _vm.modalConfirmAnswerRemove = $$v;
      },
      expression: "modalConfirmAnswerRemove"
    }
  }, [_c('v-modal', {
    attrs: {
      "size": "480",
      "title": _vm.trans('test.confirm')
    }
  }, [_c('div', {
    staticClass: "row-sort-between-btn"
  }, [_c('q-btn', {
    directives: [{
      name: "close-popup",
      rawName: "v-close-popup"
    }],
    attrs: {
      "icon": "close",
      "width": "20",
      "label": _vm.trans('button.cancel'),
      "labelWider": "",
      "color": "blue"
    }
  }), _vm._v(" "), _c('q-btn', {
    attrs: {
      "icon": "check",
      "width": "20",
      "label": _vm.trans('button.approve'),
      "labelWider": "",
      "color": "green"
    },
    on: {
      "click": function click($event) {
        return _vm.destroyAnswer();
      }
    }
  })], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }