var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-select-from-list"
  }, [_c('q-checkbox', {
    staticClass: "mb-20",
    attrs: {
      "label": _vm.trans('organization_disciplines.add_subjects_form.use_all_disciplines')
    },
    model: {
      value: _vm.useAllDisciplines,
      callback: function callback($$v) {
        _vm.useAllDisciplines = $$v;
      },
      expression: "useAllDisciplines"
    }
  }), _vm._v(" "), !_vm.useAllDisciplines ? _c('div', [_c('v-wrapper-q-select', {
    ref: "subjectSelect",
    staticClass: "mb-20",
    attrs: {
      "options": _vm.options,
      "input-debounce": "500",
      "use-options-from-prop": "",
      "extra-binds": {
        placeholder: _vm.trans('placeholder.choose'),
        hideBottomSpace: true,
        labelTransKey: _vm.trans('organization_disciplines.add_subjects_form.choose_disciplines')
      },
      "multiple": true,
      "show-multiple-values": false,
      "custom-filter-fn": _vm.requestSearchDisciplines
    },
    on: {
      "input-value-change": function inputValueChange($event) {
        _vm.inputValue = $event;
      },
      "input": _vm.handleSelect,
      "virtual-scroll": _vm.onSubScroll
    },
    model: {
      value: _vm.selectedOption,
      callback: function callback($$v) {
        _vm.selectedOption = $$v;
      },
      expression: "selectedOption"
    }
  }), _vm._v(" "), _vm.selectedList.length ? _c('span', {
    staticClass: "sn-field__label"
  }, [_vm._v("\n      " + _vm._s(_vm.trans('organization_disciplines.add_subjects_form.chosen_disciplines')) + "\n    ")]) : _vm._e(), _vm._v(" "), _vm._l(_vm.selectedList, function (option, optionIdx) {
    return _c('v-select-from-list-option', {
      key: optionIdx,
      attrs: {
        "value": option.name,
        "key-id": option.id,
        "readonly": ""
      },
      on: {
        "remove": _vm.handleRemoveOption
      }
    });
  })], 2) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "mt-10 row-sort-between-btn"
  }, [_c('q-btn', {
    attrs: {
      "type": "a",
      "to": _vm.uriBack,
      "label": _vm.trans('button.cancel'),
      "color": "blue",
      "width": "20"
    }
  }), _vm._v(" "), _c('q-btn', {
    attrs: {
      "disabled": _vm.selectedList.length === 0 && !_vm.useAllDisciplines,
      "label": _vm.trans('button.save'),
      "color": "green",
      "width": "20"
    },
    on: {
      "click": _vm.handleSave
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }