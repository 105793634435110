var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sc-title-link"
  }, [_c('a', {
    attrs: {
      "href": _vm.href
    }
  }, [_c('q-icon', {
    attrs: {
      "name": "long-arrow-left",
      "icon-class": "s-ico-fa"
    }
  })], 1), _vm._v("\n  " + _vm._s(_vm.$q.screen.width > 640 ? "".concat(_vm.trans('label.group'), " \u2014 ").concat(_vm.name) : _vm.trans('label.group')) + "\n")]);
}
var staticRenderFns = []

export { render, staticRenderFns }