import { requestWrapper } from '@vjs/helpers';
import { Loading } from '@quasar/plugins';
import _ from 'lodash';
import VCard from '../VCard';

export default {
  components: { VCard },
  props: {
    info: Object,
    uriExport: String,
    uriChangePhoto: String,
    canEditPhoto: Boolean,
  },
  data() {
    return {
      qLoading: Loading,
    };
  },
  methods: {
    triggerFile() {
      this.$refs.fileInput.click();
    },
    async fileChange() {
      const file = this.$refs.fileInput.files[0];
      if (file) {
        this.qLoading.show({ message: this.trans('label.wait_for_load_data') });
        const formData = new FormData();
        formData.append('file', file);

        const res = await requestWrapper.call(this, {
          url: this.uriChangePhoto,
          method: 'POST',
          data: formData,
        });
        if (!res.error) {
          this.$emit('photo-changed');
        } else if (res.error) {
          if (res.errorType === 422) {
            this.$notify({
              text: _.first(_.flatten(res.data.errors[_.first(Object.keys(res.data.errors))])) ?? this.trans('notice.error_on_server'),
              type: 'error',
            });
          } else {
            this.$notify({
              text: this.trans('notice.error_on_server'),
              type: 'error',
            });
          }
        }
        this.qLoading.hide();
      }
      this.$refs.fileInput.value = null;
    },
  },
};
