var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('q-dialog', {
    attrs: {
      "persistent": "",
      "value": _vm.modal
    },
    on: {
      "input": _vm.handleModal
    }
  }, [_c('v-modal', {
    staticClass: "contest__modal",
    attrs: {
      "size": "480"
    }
  }, [_c('div', {
    staticClass: "row no-gutters justify-content-center",
    attrs: {
      "loading": _vm.loading
    }
  }, [_c('p', {
    staticClass: "contest__modelHeading"
  }, [_vm._v("\n        " + _vm._s(_vm.trans('contest.modal_heading')) + "\n      ")])]), _vm._v(" "), _c('div', {
    staticClass: "contest__form"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-sm-between"
  }, [_c('p', [_vm._v("\n          " + _vm._s(_vm.trans('label.applicant.name')) + "\n        ")]), _vm._v(" "), _c('p', {
    staticClass: "contest__right"
  }, [_vm._v("\n          " + _vm._s(_vm.user.fullName) + "\n        ")])]), _vm._v(" "), _c('div', {
    staticClass: "d-flex align-items-center justify-content-sm-between"
  }, [_c('p', [_vm._v("\n          " + _vm._s(_vm.trans('label.applicant.organization')) + "\n        ")]), _vm._v(" "), _c('p', {
    staticClass: "contest__right"
  }, [_vm._v("\n          " + _vm._s(_vm.user.personal.organization.short_name) + "\n        ")])]), _vm._v(" "), _c('div', {
    staticClass: "d-flex align-items-center justify-content-sm-between"
  }, [_c('p', [_vm._v("\n          " + _vm._s(_vm.trans('label.email')) + "\n        ")]), _vm._v(" "), _vm.user.email ? _c('p', {
    staticClass: "contest__right"
  }, [_vm._v("\n          " + _vm._s(_vm.user.email) + "\n        ")]) : _c('q-input', {
    attrs: {
      "required": ""
    },
    model: {
      value: _vm.form.email,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "d-flex align-items-center justify-content-sm-between"
  }, [_c('p', [_vm._v("\n          " + _vm._s(_vm.trans('label.insta_account')) + "\n        ")]), _vm._v(" "), _c('q-input', {
    attrs: {
      "required": ""
    },
    model: {
      value: _vm.form.account,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "account", $$v);
      },
      expression: "form.account"
    }
  })], 1)]), _vm._v(" "), _c('div', {
    staticClass: "mt-20 row-sort-between-btn pl-20 pr-20"
  }, [_c('q-btn', {
    staticClass: "s-btn--w-150",
    attrs: {
      "color": "grey",
      "label": _vm.trans('button.cancel'),
      "loading": _vm.loading
    },
    on: {
      "click": _vm.handleModal
    }
  }), _vm._v(" "), _c('q-btn', {
    staticClass: "s-btn--w-200",
    attrs: {
      "color": "green",
      "label": _vm.trans('button.save'),
      "loading": _vm.loading
    }
  })], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }