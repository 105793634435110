var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-journal-plan-info', {
    attrs: {
      "uri-plan-info": _vm.uriPlanInfo
    }
  }), _vm._v(" "), _c('h3', {
    staticClass: "s-title s-upper s-title--right-line mb-20"
  }, [_c('span', [_vm._v(_vm._s(_vm.trans('label.journal_plan.type')))])]), _vm._v(" "), !_vm.uriPlanUpdate ? _c('v-journal-plan-description', {
    attrs: {
      "uri-plan-description": _vm.uriPlanDescription,
      "uri-plan-edit": _vm.uriPlanEdit,
      "can-edit-plan": _vm.canEditPlan
    }
  }) : _c('v-journal-plan-edit-form', {
    attrs: {
      "uri-plan-update": _vm.uriPlanUpdate,
      "uri-plan-description": _vm.uriPlanDescription,
      "back-link": _vm.backLink,
      "uri-user-files": _vm.uriUserFiles
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }