var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.signXml === null ? _c('button', {
    staticClass: "s-btn s-btn--thm-blue s-btn--w-150",
    class: _vm.signXml === null ? 's-btn--thm-blue' : 's-btn--thm-green',
    on: {
      "click": function click($event) {
        return _vm.sign();
      }
    }
  }, [_vm._v("\n    " + _vm._s(_vm.signXml === null ? _vm.trans('button.add_sign_ncalayer') : _vm.trans('button.add_signed_ncalayer')) + "\n  ")]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }