var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-20"
  }, [_c('div', {
    staticClass: "col-lg-5 col-md-8 s-group s-group--equal s-group--wider mb-20 no-padding",
    staticStyle: {
      "margin-left": "auto"
    }
  }, [_c('q-btn', {
    attrs: {
      "no-caps": "",
      "disable": !_vm.allowEditAll,
      "color": "blue",
      "label": _vm.trans('button.add_holidays')
    },
    on: {
      "click": function click($event) {
        return _vm.openHolidaysModal();
      }
    }
  }), _vm._v(" "), _c('q-btn', {
    attrs: {
      "no-caps": "",
      "color": "green",
      "disable": !_vm.allowEditAll,
      "label": _vm.trans('button.upload_docs')
    },
    on: {
      "click": function click($event) {
        return _vm.openContingentAttendanceModal();
      }
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "s-time-sheet"
  }, [_c('div', {
    staticClass: "s-time-sheet-fixed-part-container"
  }, [_c('table', {
    staticClass: "s-time-sheet-fixed-part"
  }, [_c('tr', {
    staticClass: "s-time-sheet--head-row"
  }, _vm._l(_vm.fixedColumns, function (col, index) {
    return _c('th', {
      key: index,
      staticClass: "s-time-sheet--head-cell"
    }, [_c('span', [_vm._v(_vm._s(col.label))])]);
  }), 0), _vm._v(" "), _c('tr', {
    ref: "fixedHeader",
    staticClass: "s-time-sheet-row",
    attrs: {
      "height": _vm.headerHeight
    }
  }, [_c('td', {
    staticClass: "s-time-sheet-cell-header s-time-sheet-cell"
  }), _vm._v(" "), _c('td', {
    staticClass: "s-time-sheet-cell-header s-time-sheet-cell"
  })]), _vm._v(" "), _vm._l(_vm.timeSheetData, function (row, index) {
    return _c('tr', {
      key: "fullname-".concat(index),
      ref: "fixedRow-".concat(index),
      refInFor: true,
      staticClass: "s-time-sheet-row",
      attrs: {
        "height": _vm.getHeight(index)
      }
    }, [_c('td', {
      staticClass: "s-time-sheet-cell"
    }, [_vm._v("\n            " + _vm._s(row.is_total ? '' : index + 1) + "\n          ")]), _vm._v(" "), _c('td', {
      staticClass: "s-time-sheet-cell",
      class: {
        's-time-sheet-cell-total': row.is_total
      }
    }, [_vm._v("\n            " + _vm._s(row.fixed.full_name) + "\n          ")])]);
  })], 2)]), _vm._v(" "), _c('div', {
    staticClass: "s-time-sheet-scroll-part-container"
  }, [_c('table', {
    staticClass: "s-time-sheet-scroll-part"
  }, [_c('tr', {
    staticClass: "s-time-sheet--head-row"
  }, [_vm._l(_vm.dateStrings, function (item, key) {
    return _c('th', {
      key: "date".concat(key),
      staticClass: "s-time-sheet--head-cell s-time-sheet--head-cell-month",
      style: _vm.getMonthStyle(item.daysCount),
      attrs: {
        "colspan": item.daysCount
      }
    }, [_vm._v("\n            " + _vm._s(item.label) + "\n          ")]);
  }), _vm._v(" "), _c('th', {
    staticClass: "s-time-sheet--head-cell",
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v("\n            " + _vm._s(_vm.trans('attendance.time-sheet.absent-header')) + "\n          ")])], 2), _vm._v(" "), _c('tr', {
    ref: "scrollHeader",
    staticClass: "s-time-sheet-row",
    attrs: {
      "height": _vm.headerHeight
    }
  }, _vm._l(_vm.scrollColumns, function (col, index) {
    return _c('th', {
      key: "label-".concat(index),
      staticClass: "s-time-sheet-cell-header s-time-sheet-cell",
      class: {
        's-time-sheet-cell-header--current': _vm.checkIsCurrentDay(col.field)
      }
    }, [col !== null ? _c('span', [_vm._v(_vm._s(col.label))]) : _vm._e()]);
  }), 0), _vm._v(" "), _vm._l(_vm.timeSheetData, function (data, index) {
    return _c('tr', {
      key: "timesheet-".concat(index),
      ref: "scrollRow-".concat(index),
      refInFor: true,
      staticClass: "s-time-sheet-row",
      attrs: {
        "height": _vm.getHeight(index)
      }
    }, [_vm._l(data.scroll.days, function (day, dayIndex) {
      return _c('td', {
        key: "day-".concat(dayIndex),
        staticClass: "s-time-sheet-cell",
        class: {
          's-time-sheet-cell-day': true,
          's-time-sheet-cell-holiday': day.is_work === false,
          's-time-sheet-cell-absent': _vm.checkIsEditable(day, !data.is_total)
        },
        style: "color: ".concat(day.attendance.color),
        on: {
          "click": function click($event) {
            return _vm.openAttendanceModal(day, data.fixed.full_name, !data.is_total);
          }
        }
      }, [_c('span', [_vm._v("\n              " + _vm._s(_vm.getLabel(day, data)) + "\n            ")])]);
    }), _vm._v(" "), _c('td', {
      staticClass: "s-time-sheet-cell"
    }, [_c('span', [_vm._v("\n              " + _vm._s(data.scroll.absentDays) + "\n            ")])]), _vm._v(" "), _c('td', {
      staticClass: "s-time-sheet-cell"
    }, [_c('span', [_vm._v("\n              " + _vm._s(data.scroll.respectfulAbsentDays) + "\n            ")])]), _vm._v(" "), _c('td', {
      staticClass: "s-time-sheet-cell",
      attrs: {
        "height": _vm.rowHeight[index]
      }
    }, [_c('span', [_vm._v("\n              " + _vm._s(data.scroll.reasons) + "\n            ")])])], 2);
  })], 2)])]), _vm._v(" "), _vm.activeTooltipText && _vm.$q.screen.width > 768 ? _c('q-tooltip', {
    ref: "timesheetTooltip",
    attrs: {
      "max-width": "24rem",
      "target": _vm.activeTooltipId
    }
  }, [_vm._v("\n    " + _vm._s(_vm.activeTooltipText) + "\n  ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }