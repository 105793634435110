var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h3', {
    staticClass: "s-title s-upper s-title--right-line mb-20"
  }, [_c('span', [_vm._v(_vm._s(_vm.trans('label.journal_plan.theme')))])]), _vm._v(" "), _c('div', {
    staticClass: "sc-journal-plan__panel"
  }, [_c('table', [_c('tbody', [_vm._l(_vm.table, function (item, key) {
    return [item.value ? _c('tr', {
      key: key,
      staticClass: "sc-journal-plan__row"
    }, [_c('td', {
      staticClass: "sc-journal-plan__row--label"
    }, [_vm._v("\n              " + _vm._s(item.label) + ":\n            ")]), _vm._v(" "), _c('td', {
      staticClass: "sc-journal-plan__row--value",
      domProps: {
        "innerHTML": _vm._s(item.value)
      }
    })]) : _vm._e()];
  })], 2)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }