import { render, staticRenderFns } from "./VEducationModuleForm.vue?vue&type=template&id=292a6f84&"
import script from "./VEducationModuleForm.js?vue&type=script&lang=js&"
export * from "./VEducationModuleForm.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports