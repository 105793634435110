import Vue from 'vue';
import axios from 'axios';
import store from './VueJS/store';
import VAdmissionResultOrgList from './VueJS/views/VAdmissionResult/OrgList/index';


if (document.getElementById('v-admission-result-org-list-app')) {

  window.vAdmissionResultOrgListApp = new Vue({
    el: '#v-admission-result-org-list-app',
    components: { VAdmissionResultOrgList },
    store,
  });
}
