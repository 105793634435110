var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "journal-form"
  }, [_c('v-form-inform', {
    attrs: {
      "message": _vm.trans('warning_text.modal_inform')
    }
  }), _vm._v(" "), _c('v-form-inform', {
    attrs: {
      "message": _vm.trans('warning_text.mixed_journal_inform')
    }
  }), _vm._v(" "), !!_vm.uriGetExistingData ? _c('v-form-inform', {
    attrs: {
      "message": _vm.trans('warning_text.journal_point_type_change_warning')
    }
  }) : _vm._e(), _vm._v(" "), _c('q-form', [_c('q-checkbox', {
    attrs: {
      "disable": !!_vm.uriGetExistingData,
      "label": _vm.trans('label.is_module_education'),
      "true-value": 1,
      "false-value": 0
    },
    on: {
      "input": _vm.handleModuleCheckbox
    },
    model: {
      value: _vm.form.isModule,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "isModule", $$v);
      },
      expression: "form.isModule"
    }
  }), _vm._v(" "), _c('q-select', {
    class: _vm.loading ? 'sn--cursor-wait' : '',
    attrs: {
      "filled": "",
      "bottom-slots": "",
      "options": _vm.options.journalType,
      "label": _vm.trans('label.journal_type'),
      "emit-value": "",
      "map-options": "",
      "readonly": !!_vm.uriGetExistingData,
      "loading": _vm.loading,
      "disable": _vm.loading
    },
    on: {
      "input": _vm.journalTypeChange
    },
    model: {
      value: _vm.form.journalType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "journalType", $$v);
      },
      expression: "form.journalType"
    }
  }), _vm._v(" "), _c('q-select', {
    staticClass: "sn-select__input--noclear",
    class: _vm.loading ? 'sn--cursor-wait' : '',
    attrs: {
      "filled": "",
      "bottom-slots": "",
      "options": _vm.options.qualifications,
      "label": _vm.trans('curriculum.qualification'),
      "hide-selected": "",
      "fill-input": "",
      "placeholder": _vm.trans('select.placeholder'),
      "use-input": "",
      "emit-value": "",
      "map-options": "",
      "required": _vm.form.journalType === 'normal',
      "readonly": _vm.uriGetExistingData && !['mixed', 'personal'].includes(_vm.form.journalType),
      "input-debounce": "0",
      "loading": _vm.loading,
      "disable": _vm.loading
    },
    on: {
      "filter": function filter(val, update) {
        return _vm.defaultFilterFn(val, update, 'qualifications', 'qualificationsFiltered');
      },
      "input": _vm.requestGetGroups
    },
    model: {
      value: _vm.form.qualification,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "qualification", $$v);
      },
      expression: "form.qualification"
    }
  }), _vm._v(" "), _c('q-slide-transition', [_vm.form.qualification ? _c('div', [_c('q-select', {
    staticClass: "sn-select__input--noclear",
    class: _vm.loading ? 'sn--cursor-wait' : '',
    attrs: {
      "filled": "",
      "fill-input": "",
      "input-debounce": "0",
      "bottom-slots": "",
      "options": _vm.options.groups,
      "label": _vm.trans('main.group'),
      "use-input": "",
      "placeholder": _vm.trans('select.placeholder'),
      "required": _vm.form.journalType === 'normal',
      "emit-value": "",
      "map-options": "",
      "hide-selected": "",
      "readonly": _vm.uriGetExistingData && _vm.form.journalType === 'normal',
      "loading": _vm.loading,
      "disable": _vm.loading
    },
    on: {
      "filter": function filter(val, update) {
        return _vm.defaultFilterFn(val, update, 'groups', 'groupsFiltered');
      },
      "input": _vm.handleGroupInput
    },
    model: {
      value: _vm.form.group,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "group", $$v);
      },
      expression: "form.group"
    }
  }), _vm._v(" "), _c('q-slide-transition', [_vm.form.journalType !== 'individual' && _vm.form.group && _vm.options.subgroups.length > 0 ? _c('q-field', {
    attrs: {
      "label": _vm.trans('label.group_type'),
      "borderless": ""
    }
  }, [_c('q-option-group', {
    staticClass: "d-flex",
    attrs: {
      "inline": "",
      "disable": !!_vm.uriGetExistingData,
      "options": _vm.options.groupTypes
    },
    model: {
      value: _vm.form.groupType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "groupType", $$v);
      },
      expression: "form.groupType"
    }
  })], 1) : _vm._e()], 1), _vm._v(" "), _c('q-slide-transition', [_vm.form.group && _vm.form.groupType === 'subgroup' ? _c('q-select', {
    staticClass: "sn-select__input--noclear",
    class: _vm.loading ? 'sn--cursor-wait' : '',
    attrs: {
      "filled": "",
      "fill-input": "",
      "input-debounce": "0",
      "bottom-slots": "",
      "options": _vm.options.subgroups,
      "label": _vm.trans('label.subgroup'),
      "use-input": "",
      "placeholder": _vm.trans('select.placeholder'),
      "required": "",
      "emit-value": "",
      "map-options": "",
      "hide-selected": "",
      "readonly": !!_vm.uriGetExistingData,
      "loading": _vm.loading,
      "disable": _vm.loading
    },
    on: {
      "filter": function filter(val, update) {
        return _vm.defaultFilterFn(val, update, 'subgroups', 'subgroupsFiltered');
      }
    },
    model: {
      value: _vm.form.subgroup,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "subgroup", $$v);
      },
      expression: "form.subgroup"
    }
  }) : _vm._e()], 1)], 1) : _vm._e()]), _vm._v(" "), _c('q-slide-transition', [_vm.form.journalType === 'individual' && _vm.form.group ? _c('q-select', {
    staticClass: "sn-select__input--noclear",
    class: _vm.loading ? 'sn--cursor-wait' : '',
    attrs: {
      "emit-value": !!_vm.uriGetExistingData,
      "map-options": !!_vm.uriGetExistingData,
      "use-input": "",
      "hide-selected": "",
      "fill-input": "",
      "input-debounce": "0",
      "readonly": !!_vm.uriGetExistingData,
      "placeholder": _vm.contingentSelected.length === 0 ? _vm.trans('select.placeholder') : '',
      "loading": _vm.loading || _vm.loadingContingent,
      "disable": _vm.loading || _vm.loadingContingent,
      "options": _vm.contingent,
      "label": _vm.trans('label.select_student')
    },
    on: {
      "filter": _vm.filterContingents
    },
    model: {
      value: _vm.individualContingentSelected,
      callback: function callback($$v) {
        _vm.individualContingentSelected = $$v;
      },
      expression: "individualContingentSelected"
    }
  }) : _vm._e()], 1), _vm._v(" "), _c('q-slide-transition', [(_vm.form.journalType === 'mixed' || _vm.form.journalType === 'personal') && (_vm.form.group !== null || _vm.contingentSelected.length > 0) ? _c('div', {
    staticClass: "mb-10"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 col-sm-6 s-doc-flow-form__organizations mb-10"
  }, [_c('div', {
    staticClass: "s-form__label"
  }, [_vm._v("\n              " + _vm._s(_vm.trans('label.select_students')) + ": "), _c('i', [_vm._v("*")])]), _vm._v(" "), _c('div', {
    staticClass: "s-doc-flow-form__organizations-inner s-w-100"
  }, [_vm.contingent.length ? _c('q-input', {
    staticStyle: {
      "padding": "1rem 2rem"
    },
    attrs: {
      "placholder": _vm.trans('placeholder.search_journal_students')
    },
    model: {
      value: _vm.contingentSearch,
      callback: function callback($$v) {
        _vm.contingentSearch = $$v;
      },
      expression: "contingentSearch"
    }
  }) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "s-doc-flow-form__organizations-list pt-10 pb-10",
    class: _vm.loadingContingent ? 's-to-center' : '',
    staticStyle: {
      "margin-bottom": "0",
      "height": "100%",
      "max-height": "34rem"
    }
  }, [_vm.loadingContingent ? _c('q-spinner-gears', {
    staticClass: "pt-30 pt-30 sn--cursor-wait",
    attrs: {
      "color": "blue",
      "size": "25rem"
    }
  }) : _vm.contingent.length === 0 || _vm.form.group === null ? _c('span', {
    staticClass: "d-block mt-20 s-to-center s-c-grey s-medium",
    staticStyle: {
      "padding-right": "3rem",
      "padding-left": "3rem"
    }
  }, [_vm._v("\n                  " + _vm._s(_vm.studentsListLabel()) + "\n                ")]) : _vm._l(_vm.contingentFiltered, function (student, key) {
    return _c('q-input', {
      key: key,
      staticClass: "mb-5",
      staticStyle: {
        "padding-right": "2rem",
        "padding-left": "2rem"
      },
      attrs: {
        "value": student.label,
        "readonly": ""
      },
      scopedSlots: _vm._u([{
        key: "after",
        fn: function fn() {
          return [_c('q-btn', {
            attrs: {
              "squire": "",
              "color": "orange",
              "icon": "plus",
              "disable": _vm.isStudentSelected(student.value)
            },
            on: {
              "click": function click($event) {
                return _vm.addStudent(student);
              }
            }
          })];
        },
        proxy: true
      }], null, true)
    });
  })], 2)], 1)]), _vm._v(" "), _c('div', {
    staticClass: "col-12 col-sm-6 s-doc-flow-form__organizations mb-10"
  }, [_c('div', {
    staticClass: "s-form__label"
  }, [_vm._v("\n              " + _vm._s(_vm.trans('label.selected_students')) + ":\n            ")]), _vm._v(" "), _c('div', {
    staticClass: "s-doc-flow-form__organizations-inner s-w-100"
  }, [_c('div', {
    staticClass: "s-doc-flow-form__organizations-list pt-10 pb-10",
    style: {
      marginBottom: 0,
      height: '100%',
      maxHeight: '40rem'
    }
  }, [_vm.contingentSelected.length === 0 ? _c('span', {
    staticClass: "d-block mt-20 s-to-center s-c-grey s-medium",
    staticStyle: {
      "padding-right": "3rem",
      "padding-left": "3rem"
    }
  }, [_vm._v("\n                  " + _vm._s(_vm.trans('placeholder.please_select_students')) + "\n                ")]) : _vm._l(_vm.contingentSelected, function (student, key) {
    return _c('q-input', {
      key: key,
      staticClass: "mb-5",
      staticStyle: {
        "padding-right": "2rem",
        "padding-left": "2rem"
      },
      attrs: {
        "value": student.label,
        "readonly": ""
      },
      scopedSlots: _vm._u([{
        key: "before",
        fn: function fn() {
          return [_c('q-btn', {
            attrs: {
              "squire": "",
              "color": "red",
              "icon": "minus"
            },
            on: {
              "click": function click($event) {
                return _vm.deleteStudent(key);
              }
            }
          })];
        },
        proxy: true
      }], null, true)
    });
  })], 2)])])])]) : _vm._e()]), _vm._v(" "), _c('q-input', {
    class: _vm.loading ? 'sn--cursor-wait' : '',
    attrs: {
      "label": _vm.trans('fields.name'),
      "required": "",
      "loading": _vm.loading,
      "placeholder": _vm.trans('placeholder.name'),
      "disable": _vm.loading
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _vm._v(" "), _c('q-select', {
    staticClass: "sn-select__input--noclear wrap-chips",
    class: _vm.loading ? 'sn--cursor-wait' : '',
    attrs: {
      "filled": "",
      "bottom-slots": "",
      "use-input": "",
      "use-chips": "",
      "input-debounce": "0",
      "placeholder": _vm.form.type.length === 0 ? _vm.trans('select.placeholder') : '',
      "multiple": "",
      "options": _vm.options.types,
      "loading": _vm.loading,
      "disable": _vm.loading,
      "readonly": !!_vm.uriGetExistingData && _vm.form.type.length > 0,
      "label": _vm.trans('training_schedule.fields.type'),
      "required": ""
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }), _vm._v(" "), _c('q-slide-transition', [!_vm.form.isModule ? _c('q-select', {
    class: {
      'sn--cursor-wait': _vm.loading,
      's-c-placeholder': _vm.form.semester === null || _vm.form.semester.value === null
    },
    attrs: {
      "filled": "",
      "bottom-slots": "",
      "emit-value": "",
      "map-options": "",
      "options": _vm.options.semesters,
      "loading": _vm.loading,
      "disable": _vm.loading,
      "label": _vm.trans('journal.semester_count'),
      "required": ""
    },
    model: {
      value: _vm.form.semester,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "semester", $$v);
      },
      expression: "form.semester"
    }
  }) : _vm._e()], 1), _vm._v(" "), _c('q-slide-transition', [_vm.form.semester && !_vm.form.isModule ? _c('div', {
    staticClass: "row"
  }, [_vm.form.semester === 'full_year' || _vm.form.semester === 'only_first' ? _c('q-input', {
    staticClass: "col-6 col-sm-12 col-lg-6",
    class: _vm.loading ? 'sn--cursor-wait' : '',
    attrs: {
      "label": _vm.trans('journal.period_hours.first'),
      "loading": _vm.loading,
      "placeholder": _vm.trans('journal.period_hours.fill'),
      "disable": _vm.loading,
      "mask": "#.#",
      "fill-mask": "0",
      "reverse-fill-mask": "",
      "filled": ""
    },
    model: {
      value: _vm.form.hours.first_semester_hours,
      callback: function callback($$v) {
        _vm.$set(_vm.form.hours, "first_semester_hours", $$v);
      },
      expression: "form.hours.first_semester_hours"
    }
  }) : _vm._e(), _vm._v(" "), _vm.form.semester === 'full_year' || _vm.form.semester === 'only_second' ? _c('q-input', {
    staticClass: "col-6 col-sm-12 col-lg-6",
    class: _vm.loading ? 'sn--cursor-wait' : '',
    attrs: {
      "label": _vm.trans('journal.period_hours.second'),
      "loading": _vm.loading,
      "placeholder": _vm.trans('journal.period_hours.fill'),
      "disable": _vm.loading,
      "mask": "#.#",
      "fill-mask": "0",
      "reverse-fill-mask": "",
      "filled": ""
    },
    model: {
      value: _vm.form.hours.second_semester_hours,
      callback: function callback($$v) {
        _vm.$set(_vm.form.hours, "second_semester_hours", $$v);
      },
      expression: "form.hours.second_semester_hours"
    }
  }) : _vm._e()], 1) : _vm._e()]), _vm._v(" "), _c('q-slide-transition', [_vm.options.schedule.length ? _c('q-select', {
    class: {
      'sn--cursor-wait': _vm.loading,
      's-c-placeholder': _vm.form.schedule === null || _vm.form.schedule.value === null
    },
    attrs: {
      "filled": "",
      "bottom-slots": "",
      "emit-value": "",
      "map-options": "",
      "options": _vm.options.schedule,
      "loading": _vm.loading,
      "disable": _vm.loading,
      "readonly": !!_vm.uriGetExistingData && _vm.isScheduleSelected,
      "label": _vm.trans('label.schedule')
    },
    model: {
      value: _vm.form.schedule,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "schedule", $$v);
      },
      expression: "form.schedule"
    }
  }) : _vm._e()], 1), _vm._v(" "), _c('q-select', {
    class: {
      'sn--cursor-wait': _vm.loading,
      's-c-placeholder': _vm.form.markType === null || _vm.form.markType.value === null
    },
    attrs: {
      "filled": "",
      "bottom-slots": "",
      "emit-value": "",
      "map-options": "",
      "options": _vm.options.markTypes,
      "disable": _vm.loading,
      "loading": _vm.loading,
      "label": _vm.trans('main.type_of_evaluation'),
      "required": ""
    },
    model: {
      value: _vm.form.markType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "markType", $$v);
      },
      expression: "form.markType"
    }
  }), _vm._v(" "), _c('q-field', {
    attrs: {
      "label": _vm.trans('label.subject'),
      "borderless": ""
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-auto"
  }, [_c('q-radio', {
    attrs: {
      "val": 0,
      "disable": !!_vm.uriGetExistingData,
      "label": _vm.trans('table.discipline')
    },
    on: {
      "input": function input($event) {
        return _vm.updateSelects();
      }
    },
    model: {
      value: _vm.form.isResult,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "isResult", $$v);
      },
      expression: "form.isResult"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-auto"
  }, [_c('q-radio', {
    attrs: {
      "val": 1,
      "disable": !!_vm.uriGetExistingData,
      "label": _vm.trans('fields.with_result')
    },
    on: {
      "input": function input($event) {
        return _vm.updateSelects();
      }
    },
    model: {
      value: _vm.form.isResult,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "isResult", $$v);
      },
      expression: "form.isResult"
    }
  })], 1)])]), _vm._v(" "), _c('q-slide-transition', [!_vm.form.isModule ? _c('q-select', {
    ref: "subjectSelect",
    staticClass: "sn-select__input--noclear",
    class: {
      'sn--cursor-wait': _vm.loading || _vm.subjectsLoading,
      's-c-placeholder': _vm.form.subject.value === null
    },
    attrs: {
      "options": _vm.form.isResult ? _vm.options.results : _vm.options.subjects,
      "required": "",
      "use-input": "",
      "filled": "",
      "bottom-slots": "",
      "fill-input": "",
      "hide-selected": "",
      "disable": _vm.loading,
      "loading": _vm.subjectsLoading || _vm.loading,
      "input-debounce": "500",
      "readonly": !!_vm.uriGetExistingData,
      "popup-content-style": {
        width: _vm.$q.platform.is.desktop ? '1px' : undefined
      }
    },
    on: {
      "filter": _vm.requestSearchSubjects,
      "virtual-scroll": _vm.onSubScroll
    },
    model: {
      value: _vm.form.subject,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "subject", $$v);
      },
      expression: "form.subject"
    }
  }) : _vm._e()], 1), _vm._v(" "), _c('q-slide-transition', [_vm.form.isModule ? _c('q-select', {
    staticClass: "sn-select__input--noclear",
    class: _vm.loading ? 'sn--cursor-wait' : '',
    attrs: {
      "filled": "",
      "bottom-slots": "",
      "emit-value": "",
      "map-options": "",
      "use-input": "",
      "hide-selected": "",
      "fill-input": "",
      "input-debounce": "0",
      "placeholder": _vm.trans('select.placeholder'),
      "options": _vm.options.modules,
      "loading": _vm.loading,
      "disable": _vm.loading,
      "label": _vm.trans('label.module'),
      "readonly": !!_vm.uriGetExistingData && typeof _vm.form.module === 'number',
      "required": ""
    },
    on: {
      "filter": function filter(val, update) {
        return _vm.defaultFilterFn(val, update, 'modules', 'modulesFiltered');
      },
      "input": _vm.requestGetModuleSubjects
    },
    model: {
      value: _vm.form.module,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "module", $$v);
      },
      expression: "form.module"
    }
  }) : _vm._e()], 1), _vm._v(" "), _c('q-slide-transition', [_vm.form.module ? _c('div', [_c('q-select', {
    staticClass: "sn-select__input--noclear wrap-chips",
    class: _vm.loading ? 'sn--cursor-wait' : '',
    attrs: {
      "filled": "",
      "bottom-slots": "",
      "multiple": "",
      "use-input": "",
      "use-chips": "",
      "input-debounce": "0",
      "placeholder": _vm.form.moduleSubjects.length === 0 ? _vm.trans('select.placeholder') : '',
      "loading": _vm.loading,
      "disable": _vm.loading,
      "required": "",
      "options": _vm.options.moduleSubjects,
      "label": _vm.form.isResult ? _vm.trans('label.results_label') : _vm.trans('label.subject')
    },
    on: {
      "filter": function filter(val, update) {
        return _vm.defaultFilterFn(val, update, 'moduleSubjects', 'moduleSubjectsFiltered');
      }
    },
    model: {
      value: _vm.form.moduleSubjects,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "moduleSubjects", $$v);
      },
      expression: "form.moduleSubjects"
    }
  }), _vm._v(" "), _vm.form.moduleSubjects.length > 0 ? _c('div', [_vm.$q.screen.width >= 1010 ? _c('div', {
    staticClass: "journal-form__flex"
  }, [_c('div', {
    staticClass: "col-lg-8 pl-0"
  }, [_vm._v("\n              " + _vm._s(_vm.trans("".concat(_vm.form.isResult ? 'label.chosen_results' : 'label.chosen_disciplines'))) + ":\n            ")]), _vm._v(" "), _c('div', {
    staticClass: "col-lg-4 s-form__label"
  }, [_vm._v("\n              " + _vm._s(_vm.trans("".concat(_vm.form.isResult ? 'label.results_hours' : 'journal.period_hours.subject'))) + ": "), _c('i', [_vm._v("*")])])]) : _vm._e(), _vm._v(" "), _vm._l(_vm.form.moduleSubjects, function (subject, inx) {
    return _c('div', {
      key: inx,
      staticClass: "journal-form__flex",
      class: _vm.$q.screen.width < 1010 ? 'flex-column' : ''
    }, [_vm.$q.screen.width >= 1010 ? _c('div', {
      staticClass: "sn-field__control col-lg-8 col-sm-12 journal-form__input-text-wrap"
    }, [_vm._v("\n              " + _vm._s(subject.label) + "\n            ")]) : _vm._e(), _vm._v(" "), _c('div', {
      staticClass: "col-lg-4 col-sm-12",
      class: _vm.$q.screen.width < 1010 ? 'p-0' : ''
    }, [_vm.$q.screen.width < 1010 ? _c('div', {
      staticClass: "s-form__label"
    }, [_vm._v("\n                " + _vm._s(subject.label) + ": "), _c('i', [_vm._v("*")])]) : _vm._e(), _vm._v(" "), _c('q-input', {
      class: _vm.loading ? 'sn--cursor-wait' : '',
      attrs: {
        "loading": _vm.loading,
        "placeholder": _vm.trans('journal.period_hours.fill'),
        "disable": _vm.loading,
        "mask": "#.#",
        "fill-mask": "0",
        "reverse-fill-mask": "",
        "filled": ""
      },
      model: {
        value: _vm.form.moduleSubjects[inx].hours,
        callback: function callback($$v) {
          _vm.$set(_vm.form.moduleSubjects[inx], "hours", $$v);
        },
        expression: "form.moduleSubjects[inx].hours"
      }
    })], 1)]);
  })], 2) : _vm._e()], 1) : _vm._e()]), _vm._v(" "), _c('q-select', {
    staticClass: "sn-select__input--noclear",
    class: _vm.loading ? 'sn--cursor-wait' : '',
    attrs: {
      "filled": "",
      "bottom-slots": "",
      "emit-value": "",
      "map-options": "",
      "use-input": "",
      "hide-selected": "",
      "fill-input": "",
      "input-debounce": "0",
      "placeholder": _vm.trans('select.placeholder'),
      "options": _vm.options.personnel,
      "loading": _vm.loading,
      "disable": _vm.loading,
      "label": _vm.trans('personnel_dicts.personnel_types.educator'),
      "readonly": !!_vm.createPersonnelId,
      "required": ""
    },
    on: {
      "input": _vm.requestGetCalendarThematicPlans,
      "filter": function filter(val, update) {
        return _vm.defaultFilterFn(val, update, 'personnel', 'personnelFiltered');
      }
    },
    model: {
      value: _vm.form.personnel,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "personnel", $$v);
      },
      expression: "form.personnel"
    }
  }), _vm._v(" "), _c('q-select', {
    staticClass: "sn-select__input--noclear wrap-chips",
    class: _vm.loading ? 'sn--cursor-wait' : '',
    attrs: {
      "filled": "",
      "bottom-slots": "",
      "multiple": "",
      "use-input": "",
      "use-chips": "",
      "input-debounce": "0",
      "placeholder": _vm.form.additionalPersonnels.length === 0 ? _vm.trans('select.placeholder') : '',
      "loading": _vm.loading,
      "disable": _vm.loading,
      "options": _vm.options.additionalPersonnels,
      "label": _vm.trans('training_schedule.fields.teacher_ids')
    },
    on: {
      "input": _vm.requestGetCalendarThematicPlans,
      "filter": function filter(val, update) {
        return _vm.defaultFilterFn(val, update, 'additionalPersonnels', 'additionalPersonnelsFiltered');
      }
    },
    model: {
      value: _vm.form.additionalPersonnels,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "additionalPersonnels", $$v);
      },
      expression: "form.additionalPersonnels"
    }
  }), _vm._v(" "), _c('q-slide-transition', [!_vm.form.isModule && _vm.options.calendarThematicPlansFiltered.length && (_vm.form.personnel || _vm.form.additionalPersonnels.length) ? _c('q-select', {
    staticClass: "sn-select__input--noclear",
    class: _vm.loading ? 'sn--cursor-wait' : '',
    attrs: {
      "filled": "",
      "bottom-slots": "",
      "emit-value": "",
      "map-options": "",
      "use-input": "",
      "hide-selected": "",
      "fill-input": "",
      "input-debounce": "0",
      "placeholder": _vm.trans('select.placeholder'),
      "options": _vm.options.calendarThematicPlans,
      "loading": _vm.loading,
      "disable": _vm.loading,
      "label": _vm.trans('label.calendar_thematic_plan_short')
    },
    on: {
      "filter": function filter(val, update) {
        return _vm.defaultFilterFn(val, update, 'calendarThematicPlans', 'calendarThematicPlansFiltered');
      }
    },
    model: {
      value: _vm.form.calendarThematicPlans,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "calendarThematicPlans", $$v);
      },
      expression: "form.calendarThematicPlans"
    }
  }) : _vm._e()], 1), _vm._v(" "), _c('q-slide-transition', [_vm.form.isModule && _vm.options.calendarThematicPlansFiltered.length && (_vm.form.personnel || _vm.form.additionalPersonnels.length) ? _c('q-select', {
    staticClass: "sn-select__input--noclear wrap-chips",
    class: _vm.loading ? 'sn--cursor-wait' : '',
    attrs: {
      "filled": "",
      "bottom-slots": "",
      "use-input": "",
      "input-debounce": "0",
      "placeholder": _vm.trans('select.placeholder'),
      "options": _vm.options.calendarThematicPlans,
      "loading": _vm.loading,
      "disable": _vm.loading,
      "label": _vm.trans('label.calendar_thematic_plan_short'),
      "multiple": "",
      "use-chips": ""
    },
    on: {
      "filter": function filter(val, update) {
        return _vm.defaultFilterFn(val, update, 'calendarThematicPlans', 'calendarThematicPlansFiltered');
      }
    },
    model: {
      value: _vm.form.calendarThematicPlans,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "calendarThematicPlans", $$v);
      },
      expression: "form.calendarThematicPlans"
    }
  }) : _vm._e()], 1), _vm._v(" "), _c('div', {
    staticClass: "row-sort-end"
  }, [_c('q-btn', {
    staticClass: "s-btn--w-200 btn-adaptive-helper",
    attrs: {
      "label": _vm.trans('button.save'),
      "color": "green",
      "icon": "mark",
      "loading": _vm.loading,
      "disable": !_vm.validate
    },
    on: {
      "click": _vm.requestAction
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }