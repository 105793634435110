var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sc-curriculum-program__table"
  }, [_c('table-filters'), _vm._v(" "), _c('div', {
    staticClass: "sc-curriculum-program__table--scroll"
  }, [_c('table', {
    staticClass: "s-w-100",
    attrs: {
      "cellspacing": "0"
    }
  }, [_c('thead', {
    staticClass: "sc-curriculum-program__table--head"
  }, _vm._l(_vm.rows, function (row, rowIndex) {
    return _c('tr', {
      key: "th-row".concat(rowIndex),
      staticClass: "sc-curriculum-program__table--tr"
    }, _vm._l(row, function (col, colIndex) {
      return _c('th', {
        key: "th-col".concat(colIndex),
        staticClass: "sc-curriculum-program__table--th",
        class: col.class,
        attrs: {
          "rowspan": col.rowspan,
          "colspan": col.colspan
        }
      }, [_vm._v("\n            " + _vm._s(col.label) + "\n          ")]);
    }), 0);
  }), 0), _vm._v(" "), _c('tbody', [_vm._l(_vm.items, function (chapter, chKey) {
    return [_c('tr', {
      key: "chapter-".concat(chKey),
      staticClass: "sc-curriculum-program__table--tr sc-curriculum-program__table--chapter"
    }, [_c('td', {
      staticClass: "sc-curriculum-program__table--td",
      domProps: {
        "innerHTML": _vm._s(_vm.chapterName(chKey, chapter))
      }
    }), _vm._v(" "), _vm._l(_vm.cols, function (column, cKey) {
      return [chapter.lessons.length > 0 ? _c('table-data', {
        key: "td-col".concat(cKey),
        attrs: {
          "column": column,
          "lesson": chapter.lessons[0]
        }
      }) : !['chapter_name', 'actions'].includes(column.attribute) ? _c('td', {
        key: "td-col".concat(cKey),
        staticClass: "sc-curriculum-program__table--td"
      }) : _vm._e(), _vm._v(" "), column.attribute === 'actions' ? _c('td', {
        key: cKey,
        staticClass: "s-to-center"
      }, [_c('div', {
        staticClass: "s-group s-group--to-center"
      }, _vm._l(chapter.actions, function (action, key) {
        return _c('q-btn', {
          key: key,
          attrs: {
            "color": action.color,
            "to": action.to,
            "icon": action.icon,
            "label": action.label,
            "outline": action.outline,
            "width": action.width,
            "target": action.target,
            "squire": "",
            "no-caps": "",
            "type": action.type
          },
          on: {
            "click": function click($event) {
              return _vm.handleModal(action, chapter);
            }
          }
        });
      }), 1)]) : _vm._e()];
    })], 2), _vm._v(" "), _vm._l(chapter.lessons, function (lesson, key) {
      return [key !== 0 ? _c('tr', {
        key: "td-row".concat(key),
        staticClass: "sc-curriculum-program__table--tr"
      }, [_c('td', {
        staticClass: "sc-curriculum-program__table--td"
      }), _vm._v(" "), _vm._l(_vm.cols, function (column, cKey) {
        return [_c('table-data', {
          key: "td-col".concat(cKey),
          attrs: {
            "column": column,
            "lesson": lesson
          }
        })];
      })], 2) : _vm._e()];
    })];
  }), _vm._v(" "), _c('tr', {
    staticClass: "sc-curriculum-program__table--tr sc-curriculum-program__table--chapter"
  }, [_c('td', {
    staticClass: "sc-curriculum-program__table--td"
  }, [_vm._v("\n            " + _vm._s(_vm.trans('curriculum.discipline_group_total')) + "\n          ")]), _vm._v(" "), _c('td', {
    staticClass: "sc-curriculum-program__table--td"
  }), _vm._v(" "), _c('td', {
    staticClass: "sc-curriculum-program__table--td"
  }, [_vm._v("\n            " + _vm._s(_vm.total()) + "\n          ")]), _vm._v(" "), _vm._l(_vm.cols, function (column, cKey) {
    return [!['lessons_name', 'chapter_name', 'total_hours', 'actions'].includes(column.attribute) ? _c('td', {
      key: "td-totals".concat(cKey),
      staticClass: "sc-curriculum-program__table--td",
      attrs: {
        "data-table-head": column.label
      }
    }, [_vm._v("\n              " + _vm._s(_vm.calculateTotals(column.attribute)) + "\n            ")]) : _vm._e()];
  }), _vm._v(" "), _c('td', {
    staticClass: "sc-curriculum-program__table--td"
  })], 2)], 2)])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }