var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-w-100",
    staticStyle: {
      "max-width": "60rem"
    }
  }, [_c('q-tabs', {
    staticClass: "mb-10",
    attrs: {
      "active-color": "blue",
      "indicator-color": "blue",
      "mobile-arrows": ""
    },
    on: {
      "click": _vm.changeTab
    },
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, _vm._l(_vm.tabsItems, function (tab, key) {
    return _c('q-tab', {
      key: key,
      class: _vm.$q.platform.is.mobile ? '' : 's-w-100',
      attrs: {
        "name": tab,
        "label": _vm.trans("auth.tab_types.".concat(tab))
      }
    });
  }), 1), _vm._v(" "), _c('div', {
    staticClass: "row-sort-center"
  }, [_c('q-form', {
    staticClass: "s-auth-form",
    on: {
      "submit": function submit($event) {
        return _vm.requestAction();
      }
    }
  }, [_c('q-input', {
    attrs: {
      "mask": _vm.maskComputed,
      "label": _vm.trans("auth.login_tab_fields.".concat(_vm.tab)),
      "placeholder": _vm.trans("auth.login_tab_fields.".concat(_vm.tab)),
      "error": _vm.hasValidationFail(_vm.tab),
      "error-message": _vm.getValidationMessage(_vm.tab)
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail(_vm.tab);
      }
    },
    model: {
      value: _vm.login,
      callback: function callback($$v) {
        _vm.login = $$v;
      },
      expression: "login"
    }
  }), _vm._v(" "), _c('q-input', {
    attrs: {
      "label": _vm.trans('auth.password'),
      "type": "password",
      "placeholder": _vm.trans('auth.password_placeholder'),
      "error": _vm.hasValidationFail('password'),
      "error-message": _vm.getValidationMessage('password')
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('password');
      }
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "row-sort-between align-items-center mb-30"
  }, [_c('q-checkbox', {
    staticClass: "s-form__checkbox",
    attrs: {
      "label": _vm.trans('auth.auth_remember')
    },
    model: {
      value: _vm.remember,
      callback: function callback($$v) {
        _vm.remember = $$v;
      },
      expression: "remember"
    }
  }), _vm._v(" "), _c('a', {
    staticClass: "s-auth-form__lost-pass",
    attrs: {
      "href": _vm.urlForgotPassword
    }
  }, [_vm._v(_vm._s(_vm.trans('auth.forgot_password')))])], 1), _vm._v(" "), _c('div', {
    staticClass: "row",
    class: _vm.urlAuthCode ? 'justify-content-between' : 'justify-content-end'
  }, [_c('div', {
    staticClass: "col-12 col-sm-5 mb-10 auth-code-link"
  }, [_vm.urlAuthCode ? _c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "color": "blue",
      "label": _vm.trans('button.sign_in_code'),
      "type": "a",
      "to": _vm.urlAuthCode,
      "no-caps": ""
    }
  }) : _vm._e()], 1), _vm._v(" "), _c('div', {
    staticClass: "col-12 col-sm-5 mb-10"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "color": "green",
      "label": _vm.trans('auth.auth_to_system_title_enter'),
      "type": "submit",
      "loading": _vm.loading,
      "no-caps": ""
    }
  })], 1)])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }