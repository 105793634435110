import { mapGetters } from 'vuex';
import { genArray, scrollTop } from '@vjs/helpers';

import {
  QField,
  QPagination,
  QTooltip,
  QBtn,
} from '@quasar/components';
import { Loading } from '@quasar/plugins';
import eventHub from '@vjs/config/eventHub';
import Modal from '@vjs/components/VModal';
import VFormInform from '@vjs/components/VFormInform';
import VNoResults from '@vjs/components/VNoResults';
import VExamQuestion from './VExamQuestion.vue';
import VExamQuestionAdd from './VExamQuestionAdd.vue';
import VExamQuestionModal from './VExamQuestionModal.vue';


// import activateMathJax from '/helpers/activateMathJax';

export default {
  name: 'VExamQuestions',
  components: {
    QField,
    QPagination,
    QTooltip,
    QBtn,
    Modal,
    VExamQuestion,
    VExamQuestionAdd,
    VExamQuestionModal,
    VNoResults,
    VFormInform,
  },
  props: {
    urlExam: {
      type: String,
      required: true,
    },
    uriImportAction: {
      type: String,
      required: true,
    },
    uriImportTemplate: {
      type: String,
      required: true,
    },
    group: {
      type: Object,
    },
  },
  data() {
    return {
      currentPage: 1,
      countByPage: 5,
      questionItems: [],
      modalExamQuestion: false,
      loading: true,
      qLoader: Loading,
    };
  },
  async mounted() {
    await this.$trans.add([
      'button',
      'placeholder',
      'label',
      'title',
      'validation',
      'notice',
      'curriculum',
      'test',
      'main',
      'table',
      'warning_text',
      'cabinet',
      'fields',
    ]);
    await this.load();
    this.questionItems = this.getQuestions;
    eventHub.$on('loadFile', (evt) => { this.handleQLoader(evt); });
    await this.$store.commit('setImgErrorMsg', this.trans('validation.img_max_size_teacher'));
  },
  computed: {
    ...mapGetters([
      'getQuestions',
      'getQuestionAdd',
    ]),
    paginate() {
      if (this.loading) {
        return genArray({}, 5);
      }
      const current = (this.currentPage - 1) * this.countByPage;
      return this.questionItems.slice(current, current + this.countByPage);
    },
    countOfPages() {
      let count = parseInt(this.questionItems.length / this.countByPage, 10);
      const remains = this.questionItems.length % this.countByPage;
      count += remains !== 0 ? 1 : 0;
      this.currentPage = 1;
      return count <= 1 ? 0 : count;
    },
  },
  methods: {
    handleQLoader(loading) {
      if (loading === true) {
        this.qLoader.show({ message: this.trans('label.wait_for_load') });
      } else if (loading === false) {
        this.qLoader.hide();
      }
    },
    questionNum(key) {
      let res = key + 1;
      if (this.currentPage > 1) {
        res += (_.toNumber(this.currentPage) - 1) * _.toNumber(this.countByPage);
      }
      return res;
    },
    async openModal() {
      this.modalExamQuestion = true;
    },
    // Запускается на событие @input в q-pagination
    async changePagination() {
      // Перевод страницы к началу при выборе страницы
      scrollTop(
        window,
        window.scrollY,
        window.scrollY - Math.abs(this.$refs.questions.getBoundingClientRect().top),
        500,
      );
    },
    async load() {
      await this.$store.commit('setUrlExam', this.urlExam);
      await this.$store.dispatch('GET_QUESTIONS');
      await this.$store.dispatch('GET_GROUP');
      this.loading = false;
    },
    questionAdd() {
      this.$store.commit('setQuestionEditId');
      this.$store.commit('setAnswerEditId');
      this.$store.commit('setQuestionAdd', 1);
    },
  },
};
