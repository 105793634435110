import { Helper } from '@app_jumys/helpers';
import { StoreTranslator } from '@common/mixins';
import { PLUGIN_NAME } from '@common/plugins/Translator/const';

export default {
  mixins: [StoreTranslator],
  props: {
    domain: {
      type: String,
      default: 'college-vb-lb-1.snation.kz',
    },
    room: {
      type: String,
    },
    roomName: {
      type: String,
    },
    userName: {
      type: String,
    },
    role: {
      type: String,
    },
    token: String,
    urlBack: {
      type: String,
      default: '/',
    },
    wsEnabled: {
      type: Boolean,
      default: true,
    },
    wsUrl: {
      type: String,
      default: 'ws://localhost:8080',
    },
  },

  data() {
    return {
      status: 'connection',
      jitsiApi: null,
      socket: null,
      timerWaitReconnectModerator: null,
    };
  },

  mounted() {
    this.$trans.eventBus.$on(`${PLUGIN_NAME}/loaded-translate`, () => {
      if (this.wsEnabled) {
        this.wsConnect();
      } else {
        this.loadJitsiApi();
      }
    });
    this.$trans.add(['notice']);
  },
  async beforeDestroy() {
    this.removeJitsiWidget();
  },
  methods: {
    wsEvent(event) {
      event.token = this.token;
      return JSON.stringify(event);
    },
    eventJoin() {
      this.socket.send(this.wsEvent(
        {
          type: 'join',
        },
      ));
    },
    wsConnect() {
      this.socket = new WebSocket(`${this.wsUrl}/ws/${this.room}`);
      this.socket.onopen = (e) => {
        this.eventJoin();
      };
      // Message received on the socket
      this.socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        switch (data.type) {
          case 'success_join':
            if (this.jitsiApi === null) {
              this.loadJitsiApi();
            }
            this.status = 'joined';
            break;
          case 'joined':
            if (data.payload.participant.role === 'moderator') {
              this.eventJoin();
              if (this.timerWaitReconnectModerator !== null) {
                clearTimeout(this.timerWaitReconnectModerator);
                this.timerWaitReconnectModerator = null;
              }
            }
            break;
          case 'moderator_not_exists':
            Helper.showNotice(this.trans('notice.conference.moderator_not_exists'), 'info', '');
            this.status = 'wait_moderator';
            break;
          case 'leave_all_moderator':
            if (this.timerWaitReconnectModerator !== null) {
              clearTimeout(this.timerWaitReconnectModerator);
            }
            // ставим таймер на 5 минут
            this.timerWaitReconnectModerator = setTimeout(
              () => {
                Helper.showNotice(this.trans('notice.conference.event_closed'), 'info', '');
                this.removeJitsiWidget();
                this.status = 'wait_moderator';
              },
              5 * 60 * 1000,
            );

            break;
          default:
            break;
        }
      };
      this.socket.onerror = (event) => {

      };
      this.socket.onclose = (event) => {
        if (event.wasClean) {
          console.log(`[close] Соединение закрыто чисто, код=${event.code} причина=${event.reason}`);
        } else {
          setTimeout(() => {
              this.wsConnect();
            },
            10000);
          // например, сервер убил процесс или сеть недоступна
          // обычно в этом случае event.code 1006
          console.log('[close] Соединение прервано');
        }
      };
    },
    loadJitsiApi() {
      this.loadScript(`https://${this.domain}/external_api.js`, () => {
        if (!window.JitsiMeetExternalAPI) throw new Error('Jitsi Meet API not loaded');
        this.embedJitsiWidget();
      });
    },
    loadScript(src, cb) {
      const scriptEl = document.createElement('script');
      scriptEl.src = src;
      scriptEl.async = 1;
      document.querySelector('head').appendChild(scriptEl);
      scriptEl.addEventListener('load', cb);
    },
    embedJitsiWidget() {
      let toolbarButtons;
      if (this.role === 'moderator') {
        toolbarButtons = [
          'microphone', 'camera', 'closedcaptions', 'desktop', 'fullscreen',
          'fodeviceselection', 'hangup', 'chat',
          'etherpad', 'settings', 'raisehand',
          'filmstrip', 'shortcuts',
          'mute-everyone', 'mute-video-everyone',
        ];
      } else {
        toolbarButtons = [
          'microphone', 'camera', 'closedcaptions', 'desktop', 'fullscreen',
          'fodeviceselection', 'hangup', 'chat',
          'etherpad', 'settings', 'raisehand',
          'filmstrip', 'shortcuts',
        ];
      }
      const options = {
        roomName: this.room,
        jwt: this.token,
        parentNode: this.$refs.jitsiContainer,
        interfaceConfigOverwrite: {
          DEFAULT_REMOTE_DISPLAY_NAME: this.userName,
        },
        configOverwrite: {
          enableDisplayNameInStats: false,
          analytics: {
            rtcstatsEnabled: false,
          },
          toolbarButtons,
          e2eping: {
            pingInterval: -1,
          },
          defaultLanguage: 'ru',
        },
        userInfo: {
          displayName: this.userName,
        },
        onload: () => {
          this.jitsiApi.on('videoConferenceJoined', () => {
            this.executeCommand('subject', this.roomName);
          });
          this.jitsiApi.on('readyToClose', () => {
            console.log('readyToClose');
            window.location = this.urlBack;
          });
        },
      };
      this.jitsiApi = new window.JitsiMeetExternalAPI(this.domain, options);
    },

    executeCommand(command, ...value) {
      this.jitsiApi.executeCommand(command, ...value);
    },

    addEventListener(event, fn) {
      this.jitsiApi.on(event, fn);
    },

    getVideoQuality() {
      return this.jitsiApi.api.getVideoQuality();
    },

    removeJitsiWidget() {
      if (this.jitsiApi) {
        this.jitsiApi.dispose();
        this.jitsiApi = null;
      }
    },
  },
};
