import Vue from 'vue';
import store from './VueJS/store';
import VCatalogListView from './VueJS/views/VCatalogListView';


if (document.getElementById('v-catalog-list-view-app')) {

  window.vCatalogListViewApp = new Vue({
    el: '#v-catalog-list-view-app',
    components: { VCatalogListView },
    store,
  });
}
