var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.$q.screen.width <= 660 ? _c('div', [_c('v-no-results', {
    attrs: {
      "image": "search",
      "title": _vm.trans('warning_text.use_pc_title'),
      "text": _vm.trans('warning_text.use_pc_label')
    }
  })], 1) : _c('div', {
    ref: "questions"
  }, [_c('v-form-inform', {
    attrs: {
      "message": _vm.trans('warning_text.max_image_size_10mb')
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "exam-questions"
  }, [_c('div', {
    staticClass: "row-sort-start-btn s-w-100"
  }, [_vm.getQuestionAdd ? _c('v-exam-question-add', {
    attrs: {
      "group": _vm.group
    }
  }) : _c('q-btn', {
    staticClass: "s-btn--w-200",
    attrs: {
      "color": "blue",
      "icon": "plus",
      "no-caps": "",
      "label-wider": "",
      "label": _vm.trans('label.create_question')
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.questionAdd($event);
      }
    }
  }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('label.create_question')))])], 1), _vm._v(" "), _c('q-btn', {
    staticClass: "ml-15 s-btn--w-200",
    attrs: {
      "color": "grey",
      "icon": "settings",
      "label-wider": "",
      "no-caps": "",
      "label": _vm.trans('cabinet.settings'),
      "disable": _vm.questionItems.length === 0
    },
    on: {
      "click": _vm.openModal
    }
  }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('cabinet.settings')))])], 1), _vm._v(" "), _vm.uriImportAction ? _c('q-btn', {
    directives: [{
      name: "s-modal",
      rawName: "v-s-modal",
      value: {
        id: 'v-modal-exam-questions-import',
        component: 'v-modal-exam-questions-import',
        size: 480,
        binds: {
          uriTemplate: _vm.uriImportTemplate,
          uriAction: _vm.uriImportAction
        }
      },
      expression: "{\n          id: 'v-modal-exam-questions-import',\n          component: 'v-modal-exam-questions-import',\n          size: 480,\n          binds: {\n            uriTemplate: uriImportTemplate,\n            uriAction: uriImportAction,\n          },\n        }"
    }],
    staticClass: "ml-15 s-btn--w-200",
    attrs: {
      "color": "orange",
      "icon": "upload",
      "no-caps": "",
      "label": _vm.trans('button.import_questions')
    }
  }) : _vm._e()], 1), _vm._v(" "), _c('div', {
    staticClass: "exam-questions__items mt-10 sn-ck-editor-data"
  }, _vm._l(_vm.paginate, function (item, key) {
    return _c('v-exam-question', {
      key: item.id,
      attrs: {
        "question-number": _vm.questionNum(key),
        "question-prop": item,
        "loading": _vm.loading,
        "group": _vm.group
      }
    });
  }), 1)]), _vm._v(" "), _vm.countOfPages ? _c('div', {
    staticClass: "row justify-content-center mt-30"
  }, [_c('div', {
    staticClass: "col-auto"
  }, [_c('q-pagination', {
    attrs: {
      "max": _vm.countOfPages,
      "max-pages": 5,
      "boundary-links": true,
      "input": _vm.countOfPages > 5,
      "input-class": "s-c-blue"
    },
    on: {
      "input": _vm.changePagination
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)]) : _vm._e(), _vm._v(" "), _vm.modalExamQuestion ? _c('v-exam-question-modal', {
    attrs: {
      "modal": _vm.modalExamQuestion,
      "question-items": _vm.questionItems
    },
    on: {
      "modal-close": function modalClose($event) {
        _vm.modalExamQuestion = $event;
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }