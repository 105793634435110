var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('q-dialog', {
    attrs: {
      "persistent": "",
      "value": _vm.modal
    },
    on: {
      "input": _vm.handleModal
    }
  }, [_c('v-modal', {
    attrs: {
      "title": _vm.trans('notice.confirm'),
      "size": "700"
    }
  }, [_c('div', {
    staticClass: "row no-gutters",
    attrs: {
      "loading": _vm.loading
    }
  }, _vm._l(_vm.questionItems, function (question, question_key) {
    return _c('div', {
      key: question_key,
      staticClass: "col-auto mb-10",
      staticStyle: {
        "margin-right": "0.5rem",
        "margin-left": "0.5rem"
      }
    }, [_c('q-btn', {
      staticClass: "s-btn--w-45",
      attrs: {
        "color": question.is_visible ? 'aqua' : 'grey',
        "squire": "",
        "loading": _vm.loading,
        "label": question_key + 1
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          question.is_visible ? _vm.questionHide(question.id) : _vm.questionShow(question.id);
        }
      }
    }, [_c('q-tooltip', [_c('div', {
      domProps: {
        "innerHTML": _vm._s(question.question)
      }
    })])], 1)], 1);
  }), 0), _vm._v(" "), _c('div', {
    staticClass: "mt-20 row-sort-between-btn"
  }, [_c('q-btn', {
    staticClass: "s-btn--w-200",
    attrs: {
      "color": "aqua",
      "label": _vm.trans('button.show_all'),
      "loading": _vm.loading
    },
    on: {
      "click": _vm.showAllQuestions
    }
  }), _vm._v(" "), _c('q-btn', {
    staticClass: "s-btn--w-200",
    attrs: {
      "color": "grey",
      "label": _vm.trans('button.hide_all'),
      "loading": _vm.loading
    },
    on: {
      "click": _vm.hideAllQuestions
    }
  }), _vm._v(" "), _c('q-btn', {
    staticClass: "s-btn--w-150",
    attrs: {
      "color": "grey",
      "label": _vm.trans('button.close'),
      "loading": _vm.loading
    },
    on: {
      "click": _vm.handleModal
    }
  })], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }