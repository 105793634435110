import {
  QInput,
  QBtn,
  QPagination,
  QSelect,
  QIcon,
} from '@quasar/components';
import {
  requestWrapper,
} from '@vjs/helpers';
import eventHub from '@vjs/config/eventHub';
import { Loading } from '@quasar/plugins';
import _ from 'lodash';
import VContestConditionsView from './VContestConditionsView';
import VContestResultsView from './VContestResultsView';
import VContestModalView from './VContestModalView';

export default {
  name: 'VContestView',
  components: {
    QInput,
    QBtn,
    QPagination,
    QSelect,
    QIcon,
    VContestConditionsView,
    VContestResultsView,
    VContestModalView,
  },
  props: {
    contestResultsUrl: String,
    urlOptions: String,
    urlBack: String,
    redirectUrl: String,
    contest: [Array, Object],
    user: Object,
    isPersonnelContest: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      qLoading: Loading,
      teachers: [],
      pagination: {
        links: [],
        currentPage: 1,
      },
      columns: [],
      filters: {
        region: null,
        search: '',
        organization: null,
      },
      regions: [],
      organizations: [],
      isRatingButtonClicked: false,
      modalShow: false,
    };
  },
  async mounted() {
    await this.$trans.add([
      'contest',
      'cabinet',
      'label',
      'table',
      'button',
      'placeholder',
      'warning_text',
    ]);
    const vm = this;
    eventHub.$on('requestGetContestResults', async (url) => {
      await vm.requestGetContestResults(url);
    });
    await vm.requestGetContestResults(vm.contestResultsUrl);
    await vm.requestGetOptions();
  },
  methods: {
    async requestGetContestResults(url) {
      this.qLoading.show({ message: this.trans('label.wait_for_load_data') });
      const res = await requestWrapper.call(this, {
        method: 'get',
        url,
        params: this.filters,
      });
      if (!res.error) {
        this.teachers = res.data;
        this.pagination.links = res.generated_links;
        this.columns = res.meta.columns;
        this.pagination.currentPage = res.meta.current_page;
      }
      await this.requestGetOptions();
      this.qLoading.hide();
    },
    async clearFilters() {
      this.$set(this.filters, 'search', '');
      await this.requestGetContestResults(this.contestResultsUrl);
    },
    async requestGetOptions() {
      this.qLoading.show({ message: this.trans('label.wait_for_load_data') });
      const vm = this;
      const res = await requestWrapper.call(this, {
        method: 'get',
        url: vm.urlOptions,
        params: this.filters,
      });
      if (!res.error) {
        this.regions = res.regions;
        this.organizations = res.organizations;
      }
      this.qLoading.hide();
    },
    handleSearch() {
      this.requestGetContestResults(this.contestResultsUrl);
    },
    async openModal() {
      this.modalShow = true;
    },
  },

};
