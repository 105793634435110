var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "v-diploma-list"
  }, [_c('q-table', {
    staticClass: "s-b-white",
    attrs: {
      "data": _vm.table.rows,
      "columns": _vm.table.columns,
      "row-key": "index",
      "flat": _vm.$q.screen.width >= 1260,
      "grid": _vm.$q.screen.width <= 1260,
      "pagination": _vm.pagination,
      "filter": _vm.filter,
      "no-data-label": _vm.trans('label.no_data'),
      "no-results-label": _vm.trans('label.no_results'),
      "loading": _vm.loading
    },
    on: {
      "update:pagination": function updatePagination($event) {
        _vm.pagination = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "sn-table__top-filters"
        }, [_c('q-input', {
          attrs: {
            "dense": "",
            "debounce": "300",
            "placeholder": _vm.trans('placeholder.search')
          },
          model: {
            value: _vm.filter,
            callback: function callback($$v) {
              _vm.filter = $$v;
            },
            expression: "filter"
          }
        }, [_c('q-icon', {
          attrs: {
            "slot": "append",
            "name": "search"
          },
          slot: "append"
        })], 1)], 1), _vm._v(" "), !_vm.$q.screen.lt.sm ? _c('q-btn', {
          staticClass: "q-ml-md",
          attrs: {
            "squire": "",
            "unelevated": "",
            "icon": "fullscreen",
            "color": props.inFullscreen ? 'blue' : 'grey'
          },
          on: {
            "click": props.toggleFullscreen
          }
        }, [_c('q-tooltip', [_vm._v("\n            " + _vm._s(props.inFullscreen ? _vm.trans('label.fullscreen_off') : _vm.trans('label.fullscreen_on')) + "\n          ")])], 1) : _vm._e()];
      }
    }, {
      key: "header",
      fn: function fn(props) {
        return [_c('q-tr', {
          attrs: {
            "props": props
          }
        }, _vm._l(props.cols, function (col) {
          return _c('q-th', {
            key: col.name,
            style: {
              width: "".concat(col.width, "px")
            },
            attrs: {
              "props": props
            }
          }, [_vm._v("\n            " + _vm._s(col.label) + "\n          ")]);
        }), 1)];
      }
    }, {
      key: "body",
      fn: function fn(props) {
        return [_c('q-tr', {
          attrs: {
            "props": props
          }
        }, [_c('q-td', {
          staticClass: "s-to-center"
        }, [_vm._v("\n            " + _vm._s(props.row.index) + "\n          ")]), _vm._v(" "), _vm._l(props.row, function (item, i) {
          return [i !== '__index' && i !== 'actions' && i !== 'index' ? _c('q-td', {
            key: i,
            class: _vm.getCellClass(i, item)
          }, [_vm._v("\n              " + _vm._s(item) + "\n            ")]) : _vm._e(), _vm._v(" "), i === 'actions' ? _c('q-td', {
            key: i,
            staticClass: "s-to-center"
          }, [_c('div', {
            staticClass: "s-group s-group--to-center"
          }, _vm._l(item, function (action, key) {
            return _c('q-btn', {
              key: key,
              attrs: {
                "color": action.color,
                "to": action.to,
                "icon": action.icon,
                "loading": _vm.loading,
                "squire": "",
                "type": action.type
              },
              on: {
                "click": function click($event) {
                  return _vm.openConfirmModal(action);
                }
              }
            }, [_c('q-tooltip', [_vm._v(_vm._s(action.tooltip))])], 1);
          }), 1)]) : _vm._e()];
        })], 2)];
      }
    }, {
      key: "item",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "sn-table__grid-item col-xs-12 col-sm-6 col-md-4 col-lg-3"
        }, [_c('div', {
          staticClass: "sn-table__grid-item-card sn-table__card"
        }, _vm._l(props.cols, function (col, i) {
          return _c('div', {
            key: i,
            staticClass: "sn-table__grid-item-row"
          }, [col.field !== '__index' && col.field !== 'actions' && col.field !== 'index' ? _c('div', {
            key: i
          }, [_c('div', {
            staticClass: "sn-table__grid-item-title"
          }, [_vm._v("\n                  " + _vm._s(col.label) + "\n                ")]), _vm._v(" "), _c('div', {
            staticClass: "sn-table__grid-item-value"
          }, [_vm._v("\n                  " + _vm._s(col.value) + "\n                ")])]) : _vm._e(), _vm._v(" "), col.field === 'actions' ? _c('div', [_c('div', {
            staticClass: "sn-table__grid-item-title"
          }, [_vm._v("\n                  " + _vm._s(col.label) + "\n                ")]), _vm._v(" "), _c('div', {
            staticClass: "sn-table__grid-item-value"
          }, [_c('div', {
            staticClass: "s-group s-group--to-center"
          }, _vm._l(col.value, function (action, key) {
            return _c('q-btn', {
              key: key,
              attrs: {
                "color": action.color,
                "to": action.to,
                "icon": action.icon,
                "squire": "",
                "loading": _vm.loading,
                "type": action.type
              },
              on: {
                "click": function click($event) {
                  return _vm.openConfirmModal(action);
                }
              }
            }, [_c('q-tooltip', [_vm._v(_vm._s(action.tooltip))])], 1);
          }), 1)])]) : _vm._e()]);
        }), 0)])];
      }
    }, {
      key: "no-data",
      fn: function fn() {
        return [_vm.noResults ? _c('v-no-results', {
          attrs: {
            "image": "search",
            "title": _vm.trans('warning_text.no_diploma_data_title')
          }
        }) : _vm._e()];
      },
      proxy: true
    }])
  })], 1), _vm._v(" "), _c('v-confirm-dialog', {
    attrs: {
      "modal": _vm.openModal,
      "decline-color": "blue",
      "submit-color": "green",
      "loading": _vm.loadingModal,
      "confirm": _vm.requestConfirmModal
    },
    on: {
      "modal-close": function modalClose($event) {
        _vm.openModal = $event;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }