var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "v-statement-admission-form"
  }, [_c('v-form-inform', {
    attrs: {
      "message": _vm.trans('warning_text.modal_inform')
    }
  }), _vm._v(" "), _c('label', {
    staticClass: "s-form__label"
  }, [_vm._v(_vm._s(_vm.trans('label.comission_type')) + ":")]), _vm._v(" "), _c('q-form', [_c('q-checkbox', {
    attrs: {
      "label": _vm.trans('label.creative'),
      "true-value": 1,
      "false-value": 0,
      "disable": !!_vm.uriGetExistingData
    },
    on: {
      "input": function input($event) {
        return _vm.requestGetDates(true, true);
      }
    },
    model: {
      value: _vm.form.is_creative,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "is_creative", $$v);
      },
      expression: "form.is_creative"
    }
  }), _vm._v(" "), _c('q-select', {
    attrs: {
      "map-options": "",
      "emit-value": "",
      "filled": "",
      "bottom-slots": "",
      "required": "",
      "label": _vm.trans('table.profession'),
      "options": _vm.form.is_creative === 1 ? _vm.options.creativeProfessions : _vm.options.professions,
      "readonly": !!_vm.uriGetExistingData,
      "use-input": "",
      "hide-selected": "",
      "fill-input": "",
      "input-debounce": "0"
    },
    on: {
      "filter": _vm.filterProfessions,
      "input": _vm.requestGetQualifications
    },
    model: {
      value: _vm.form.profession_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "profession_id", $$v);
      },
      expression: "form.profession_id"
    }
  }), _vm._v(" "), _c('q-slide-transition', [_vm.form.profession_id && _vm.options.qualifications.length > 0 ? _c('div', [_c('q-select', {
    attrs: {
      "map-options": "",
      "emit-value": "",
      "filled": "",
      "bottom-slots": "",
      "required": "",
      "label": _vm.trans('table.qualification'),
      "options": _vm.options.qualifications,
      "readonly": !!_vm.uriGetExistingData
    },
    on: {
      "input": function input($event) {
        return _vm.requestGetDates(true);
      }
    },
    model: {
      value: _vm.form.qualification_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "qualification_id", $$v);
      },
      expression: "form.qualification_id"
    }
  })], 1) : _vm._e()]), _vm._v(" "), _c('q-select', {
    attrs: {
      "map-options": "",
      "emit-value": "",
      "filled": "",
      "bottom-slots": "",
      "required": "",
      "display-value": _vm.form.edu_lang_id === null ? _vm.trans('label.not_selected') : undefined,
      "label": _vm.trans('label.edu_lang'),
      "options": _vm.options.eduLangs
    },
    model: {
      value: _vm.form.edu_lang_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "edu_lang_id", $$v);
      },
      expression: "form.edu_lang_id"
    }
  }), _vm._v(" "), _c('q-select', {
    attrs: {
      "map-options": "",
      "emit-value": "",
      "filled": "",
      "bottom-slots": "",
      "required": "",
      "label": _vm.trans('curriculum.education_form'),
      "options": _vm.options.eduForms
    },
    model: {
      value: _vm.form.edu_form,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "edu_form", $$v);
      },
      expression: "form.edu_form"
    }
  }), _vm._v(" "), _c('q-select', {
    attrs: {
      "map-options": "",
      "emit-value": "",
      "filled": "",
      "bottom-slots": "",
      "required": "",
      "label": _vm.trans('label.edu_level'),
      "options": _vm.options.eduLevels
    },
    model: {
      value: _vm.form.edu_level_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "edu_level_id", $$v);
      },
      expression: "form.edu_level_id"
    }
  }), _vm._v(" "), _c('q-slide-transition', [_vm.options.dates.length > 1 ? _c('div', [_c('q-slide-transition', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.form.handwriteDate,
      expression: "!form.handwriteDate"
    }]
  }, [_c('q-select', {
    attrs: {
      "map-options": "",
      "emit-value": "",
      "filled": "",
      "bottom-slots": "",
      "required": "",
      "label": _vm.trans('label.admission_date_from_to'),
      "options": _vm.options.dates
    },
    model: {
      value: _vm.form.date_type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "date_type", $$v);
      },
      expression: "form.date_type"
    }
  })], 1)]), _vm._v(" "), _c('q-slide-transition', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.form.handwriteDate,
      expression: "form.handwriteDate"
    }],
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 col-sm-6"
  }, [_c('q-input', {
    attrs: {
      "filled": "",
      "required": "",
      "mask": "##.##.####",
      "label": _vm.trans('label.date_start'),
      "error": _vm.hasValidationFail('date_start'),
      "error-message": _vm.getValidationMessage('date_start')
    },
    on: {
      "click": function click() {
        return _vm.$refs.qDateStartProxy.show();
      }
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('q-icon', {
          staticClass: "sn--cursor-pointer",
          attrs: {
            "name": "calendar"
          }
        }, [_c('q-popup-proxy', {
          ref: "qDateStartProxy",
          attrs: {
            "transition-show": "scale",
            "transition-hide": "scale"
          }
        }, [_c('q-date', {
          attrs: {
            "mask": "DD.MM.YYYY",
            "color": "blue",
            "today-btn": ""
          },
          on: {
            "input": function input() {
              return _vm.$refs.qDateStartProxy.hide();
            }
          },
          model: {
            value: _vm.form.date_start,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "date_start", $$v);
            },
            expression: "form.date_start"
          }
        })], 1)], 1)];
      },
      proxy: true
    }], null, false, 1221222258),
    model: {
      value: _vm.form.date_start,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "date_start", $$v);
      },
      expression: "form.date_start"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-12 col-sm-6"
  }, [_c('q-input', {
    attrs: {
      "filled": "",
      "required": "",
      "mask": "##.##.####",
      "label": _vm.trans('label.date_end'),
      "error": _vm.hasValidationFail('date_end'),
      "error-message": _vm.getValidationMessage('date_end')
    },
    on: {
      "click": function click() {
        return _vm.$refs.qDateEndProxy.show();
      }
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('q-icon', {
          staticClass: "sn--cursor-pointer",
          attrs: {
            "name": "calendar"
          }
        }, [_c('q-popup-proxy', {
          ref: "qDateEndProxy",
          attrs: {
            "transition-show": "scale",
            "transition-hide": "scale"
          }
        }, [_c('q-date', {
          attrs: {
            "mask": "DD.MM.YYYY",
            "color": "blue",
            "today-btn": ""
          },
          on: {
            "input": function input() {
              return _vm.$refs.qDateEndProxy.hide();
            }
          },
          model: {
            value: _vm.form.date_end,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "date_end", $$v);
            },
            expression: "form.date_end"
          }
        })], 1)], 1)];
      },
      proxy: true
    }], null, false, 3307813981),
    model: {
      value: _vm.form.date_end,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "date_end", $$v);
      },
      expression: "form.date_end"
    }
  })], 1)])]), _vm._v(" "), _c('q-checkbox', {
    staticClass: "mb-15",
    attrs: {
      "label": _vm.trans('label.handwrite_date')
    },
    on: {
      "input": _vm.handwriteDateCheck
    },
    model: {
      value: _vm.form.handwriteDate,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "handwriteDate", $$v);
      },
      expression: "form.handwriteDate"
    }
  })], 1) : _vm._e()]), _vm._v(" "), _c('q-input', {
    staticClass: "s-w-100 input-bg-white mb-20",
    attrs: {
      "mask": "###",
      "label": _vm.trans('fields.places_count'),
      "placeholder": _vm.trans('placeholder.places_count'),
      "filled": "",
      "required": ""
    },
    model: {
      value: _vm.form.places_count,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "places_count", $$v);
      },
      expression: "form.places_count"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "row-sort-end"
  }, [_c('q-btn', {
    staticClass: "s-btn--w-200",
    attrs: {
      "label": _vm.trans('button.save'),
      "color": "green",
      "icon": "mark",
      "disable": !_vm.validate
    },
    on: {
      "click": _vm.requestAction
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }