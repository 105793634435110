var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-form__edit--wide"
  }, [_c('div', {
    staticClass: "s-form__inform"
  }, [_c('div', {
    staticClass: "s-str-ico s-str-ico--thm-info-alt s-str-ico--wider",
    domProps: {
      "innerHTML": _vm._s(_vm.trans('warning_text.modal_inform'))
    }
  })]), _vm._v(" "), _c('v-wrapper-q-select', {
    attrs: {
      "options": _vm.options.types,
      "extra-binds": {
        required: true,
        label: _vm.trans('document_flow.fields.type'),
        hideBottomSpace: true
      }
    },
    on: {
      "input": function input($event) {
        return _vm.loadFiles();
      }
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }), _vm._v(" "), _c('transition-group', {
    attrs: {
      "name": "files"
    }
  }, _vm._l(_vm.form.files, function (file, name) {
    return _c('div', {
      key: "file-".concat(name),
      staticClass: "sn-field__wrapper"
    }, [_c('div', {
      staticClass: "sn-field__label"
    }, [_vm._v("\n        " + _vm._s(file.name) + ": "), file.required ? _c('i', [_vm._v("*")]) : _vm._e()]), _vm._v(" "), _c('div', {
      staticClass: "row justify-content-between align-items-end"
    }, [_c('div', {
      staticClass: "col-md-6 col-sm-12"
    }, [_c('q-btn', {
      staticClass: "s-w-100",
      attrs: {
        "label": file.user_file_id ? _vm.trans('label.attached') : _vm.trans('button.add'),
        "color": file.user_file_id ? 'green' : 'blue',
        "icon-right": file.user_file_id ? 'clip' : 'plus',
        "label-wider": "",
        "ellipsis": "",
        "no-caps": ""
      },
      on: {
        "click": function click($event) {
          return _vm.triggerFile(name);
        }
      }
    })], 1), _vm._v(" "), _c('div', {
      staticClass: "col-md-6 col-sm-12"
    }, [_c('q-btn', {
      staticClass: "s-w-100",
      attrs: {
        "outline": "",
        "label": file.user_file_id ? file.user_file_name : _vm.trans('label.file_not_chosen'),
        "label-wider": "",
        "color": "grey",
        "no-caps": "",
        "ellipsis": "",
        "icon-right": file.user_file_id ? 'close' : undefined
      },
      on: {
        "click": function click($event) {
          return _vm.clearFile(name);
        }
      }
    })], 1)])]);
  }), 0), _vm._v(" "), _c('div', {
    staticClass: "row-sort-end mt-20"
  }, [_c('q-btn', {
    staticClass: "s-btn--w-200 btn-adaptive-helper",
    attrs: {
      "label": _vm.trans('button.send'),
      "color": "blue",
      "icon": "check",
      "disable": !_vm.validate
    },
    on: {
      "click": _vm.requestAction
    }
  })], 1), _vm._v(" "), _c('select-user-file', {
    attrs: {
      "open-modal": _vm.modalFiles,
      "url-files": _vm.uriUserFiles,
      "url-file-store": _vm.uriUserFiles
    },
    on: {
      "input": _vm.selectFile,
      "select-close": _vm.fileModalClose
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }