import {requestWrapper} from '@vjs/helpers';

export default {
  GET_CURRICULUM_PROGRAM_INFO_TABLE: async ({state}, params = {}) => {
    const {vm} = state;
    vm.$globalLoading.show();
    const res = await requestWrapper.call(vm, {
      url: state.makeProgramUrl(state.curriculumProgramApiConfig.infoTable, state.parentId),
      params,
    });

    if (!res.error) {
      state.commonInfo = res;
    }
    vm.$globalLoading.hide();
  },
  GET_CURRICULUM_PROGRAM_TOTAL_HOURS_TABLE: async ({state}, params = {}) => {
    const {vm} = state;
    vm.$globalLoading.show();
    const res = await requestWrapper.call(vm, {
      url: state.makeProgramUrl(state.curriculumProgramApiConfig.totalHours, state.parentId),
      params,
    });

    if (!res.error) {
      state.disciplines = res.disciplines;
    }
    vm.$globalLoading.hide();
  },
  GET_CURRICULUM_PROGRAM_CONTENT_TABLE: async ({state}, params = {}) => {
    const {vm} = state;
    vm.$globalLoading.show();
    const res = await requestWrapper.call(vm, {
      url: state.makeProgramUrl(state.curriculumProgramApiConfig.contentList, state.parentId),
      params,
    });

    if (!res.error) {
      state.content = res.data;
    }
    vm.$globalLoading.hide();
  },
};
