import Vue from 'vue';
import store from './VueJS/store';
import VScheduleView from './VueJS/views/VScheduleView/index';

if (document.getElementById('v-schedule-view-app')) {

  window.vApplicationListViewApp = new Vue({
    el: '#v-schedule-view-app',
    components: { VScheduleView },
    store,
  });
}
