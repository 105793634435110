var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "homeworkQuizResultRef",
    staticClass: "s-homework-quiz-result"
  }, [_vm.quizResult.loaded ? _c('div', {
    staticClass: "s-card-with-title"
  }, [_c('div', {
    staticClass: "s-card-with-title__title"
  }, [_vm._v("\n      " + _vm._s(_vm.quizResult.name) + "\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "s-card-with-title__body"
  }, [_c('div', {
    staticClass: "s-homework-quiz-result-item__value s-homework-quiz-result__result"
  }, [_c('p', [_vm._v("\n          " + _vm._s("\n            ".concat(_vm.trans('label.result'), " -\n            ").concat(_vm.quizResult.result.percent, "%\n            (").concat(_vm.quizResult.result.correct, "/").concat(_vm.quizResult.result.questions, ")\n        ")) + "\n        ")])]), _vm._v(" "), _vm._l(_vm.paginate, function (item) {
    return _c('v-homework-quiz-result-item', {
      key: item.id,
      attrs: {
        "item": item
      }
    });
  })], 2)]) : _vm._e(), _vm._v(" "), _vm.countOfPages ? _c('div', {
    staticClass: "row justify-content-center mt-30"
  }, [_c('div', {
    staticClass: "col-auto"
  }, [_c('q-pagination', {
    attrs: {
      "max": _vm.countOfPages,
      "max-pages": 5,
      "boundary-links": true,
      "input": _vm.countOfPages > 5,
      "input-class": "s-c-blue"
    },
    on: {
      "input": _vm.changePagination
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }