export default {
  commonInfo: state => state.commonInfo,
  disciplines: state => state.disciplines,
  parentId: state => state.parentId,
  makeProgramUrl: state => state.makeProgramUrl,
  semesterNumber: state => state.semesterNumber,
  courseNumber: state => state.courseNumber,
  curriculumProgramApiConfig: state => state.curriculumProgramApiConfig,
  content: state => state.content,
  programDisciplineId: state => state.programDisciplineId,
};
