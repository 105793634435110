var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sc-curriculum-program__table sc-curriculum-program__table--scroll"
  }, [_c('table', {
    staticClass: "s-w-100",
    attrs: {
      "cellspacing": "0"
    }
  }, [_c('thead', {
    staticClass: "sc-curriculum-program__table--head"
  }, [_c('tr', {
    staticClass: "sc-curriculum-program__table--tr"
  }, [_c('th', {
    staticClass: "sc-curriculum-program__table--th",
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.trans('curriculum.program.module')) + "\n        ")]), _vm._v(" "), _c('th', {
    staticClass: "sc-curriculum-program__table--th",
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.trans('curriculum.program.total_module_hours')) + "\n        ")]), _vm._v(" "), _c('th', {
    staticClass: "sc-curriculum-program__table--th",
    attrs: {
      "colspan": _vm.semesterNumber
    }
  }, [_vm._v("\n          " + _vm._s(_vm.trans('curriculum.program.including')) + "\n        ")])]), _vm._v(" "), _c('tr', {
    staticClass: "sc-curriculum-program__table--tr"
  }, _vm._l(_vm.courseNumber, function (i, key) {
    return _c('th', {
      key: "course-number".concat(key),
      staticClass: "sc-curriculum-program__table--th course-number",
      attrs: {
        "colspan": "2"
      }
    }, [_vm._v("\n          " + _vm._s(_vm.$trans.get('curriculum.program.course', {
      'n': i
    })) + "\n        ")]);
  }), 0)]), _vm._v(" "), _c('tbody', [_c('tr', {
    staticClass: "sc-curriculum-program__table--tr"
  }, [_c('td', {
    staticClass: "sc-curriculum-program__table--td"
  }), _vm._v(" "), _c('td', {
    staticClass: "sc-curriculum-program__table--td"
  }), _vm._v(" "), _vm._l(_vm.semesterNumber, function (i, key) {
    return _c('td', {
      key: "semester-number".concat(key),
      staticClass: "sc-curriculum-program__table--td semester-number"
    }, [_vm._v("\n          " + _vm._s(_vm.$trans.get('curriculum.program.semester', {
      'n': i
    })) + "\n        ")]);
  })], 2), _vm._v(" "), _vm._l(_vm.items, function (discipline, index) {
    return _c('tr', {
      key: index,
      staticClass: "sc-curriculum-program__table--tr",
      class: discipline.is_module ? 'sc-curriculum-program__table--chapter' : ''
    }, [_c('td', {
      staticClass: "sc-curriculum-program__table--td"
    }, [_vm._v(_vm._s(discipline.name))]), _vm._v(" "), _c('td', {
      staticClass: "sc-curriculum-program__table--td"
    }, [_vm._v(_vm._s(discipline.total_hours))]), _vm._v(" "), _vm._l(_vm.semesterNumber, function (i, key) {
      return _c('td', {
        key: "semester-number".concat(key),
        staticClass: "sc-curriculum-program__table--td semester-number"
      }, [_vm._v("\n          " + _vm._s(discipline.semesters ? discipline.semesters[i] : null) + "\n        ")]);
    })], 2);
  }), _vm._v(" "), _c('tr', {
    staticClass: "sc-curriculum-program__table--tr sc-curriculum-program__table--chapter"
  }, [_c('td', {
    staticClass: "sc-curriculum-program__table--td"
  }, [_vm._v(_vm._s(_vm.trans('curriculum.discipline_group_total')))]), _vm._v(" "), _c('td', {
    staticClass: "sc-curriculum-program__table--td"
  }, [_vm._v("\n          " + _vm._s(_vm.calculateTotals('total_hours')) + "\n        ")]), _vm._v(" "), _vm._l(_vm.semesterNumber, function (i, key) {
    return _c('td', {
      key: "semester-number".concat(key),
      staticClass: "sc-curriculum-program__table--td semester-number"
    }, [_vm._v("\n          " + _vm._s(_vm.calculateTotals('semesters', i)) + "\n        ")]);
  })], 2)], 2)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }