import {
  requestWrapper,
} from '@vjs/helpers';
import { QSelect } from '@quasar/components';
import _ from 'lodash';
import { Helper } from '@common/src/helpers';

export default {
  name: 'VCurriculumWorkingProgramForm',
  components: {
    QSelect,
  },
  props: {
    uriGetQualifications: {
      type: String,
      required: true,
    },
    uriGetCurriculumWorking: {
      type: String,
      required: true,
    },
    uriGetGroups: {
      type: String,
      required: true,
    },
    uriGetOptions: {
      type: String,
      required: true,
    },
    uriAction: {
      type: String,
      required: true,
    },
    uriBack: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      optionsLoading: false,
      field: {
        qualification: null,
        group: null,
        curriculum_working_id: null,
        discipline_id: null,
        teachers: [],
      },
      options: {
        groups: [],
        curriculumWorkingList: [],
        qualifications: [],
        disciplines: [],
        personnel: [],
      },
    };
  },
  async mounted() {
    await this.$trans.add([
      'label',
      'button',
      'menu',
      'curriculum',
      'placeholder',
    ]);
    await this.loadOptions(this.uriGetQualifications, 'qualifications');
  },
  computed: {
    validate() {
      return this.field.qualification !== null
        && this.field.group !== null
        && this.field.curriculum_working_id !== null
        && this.field.discipline_id !== null
        && this.field.teachers.length > 0;
    },
  },
  methods: {
    async loadOptions(uriLoadOptions, key) {
      const data = this.field;
      this.optionsLoading = true;
      const res = await requestWrapper.call(this, {
        url: uriLoadOptions,
        params: data,
      });
      if (!res.error) {
        if (typeof key === 'object') {
          _.forEach(key, (el) => {
            this.options[el] = res.options[el];
          });
        } else {
          this.options[key] = res.options[key];
        }
      }
      this.optionsLoading = true;
    },
    async requestAction() {
      this.loading = true;
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        method: 'POST',
        url: this.uriAction,
        data: this.field,
      });
      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
        this.loading = false;
        this.$globalLoading.hide();
      }
    },
  },
};
