var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !['chapter_name'].includes(_vm.column.attribute) ? _c('td', {
    staticClass: "sc-curriculum-program__table--td",
    style: {
      'width': _vm.lesson[_vm.column.attribute] ? _vm.lesson[_vm.column.attribute].width : 'auto'
    },
    attrs: {
      "data-table-head": _vm.column.label
    }
  }, [_vm.column.attribute === 'actions' ? [_c('control-buttons', {
    attrs: {
      "lesson": _vm.lesson
    }
  })] : _vm.isEdit.includes(_vm.lesson.lessons_id.value) && _vm.lesson[_vm.column.attribute].canEdit ? [_vm.lesson[_vm.column.attribute].type === 'number' ? _c('q-input', {
    attrs: {
      "bg-color": "white",
      "mask": "#.#",
      "fill-mask": "0",
      "reverse-fill-mask": "",
      "filled": "",
      "dense": ""
    },
    model: {
      value: _vm.lesson[_vm.column.attribute].value,
      callback: function callback($$v) {
        _vm.$set(_vm.lesson[_vm.column.attribute], "value", $$v);
      },
      expression: "lesson[column.attribute].value"
    }
  }) : _c('q-input', {
    attrs: {
      "bg-color": "white",
      "filled": "",
      "dense": ""
    },
    model: {
      value: _vm.lesson[_vm.column.attribute].value,
      callback: function callback($$v) {
        _vm.$set(_vm.lesson[_vm.column.attribute], "value", $$v);
      },
      expression: "lesson[column.attribute].value"
    }
  })] : [!_vm.column.withTags ? _c('span', [_vm._v("\n      " + _vm._s(_vm.getAttribute(_vm.lesson, _vm.column.attribute)) + "\n    ")]) : _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.getAttribute(_vm.lesson, _vm.column.attribute))
    }
  })]], 2) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }