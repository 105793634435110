import _ from 'lodash';
import {
  QSpinnerGears,
  QTooltip,
  QInput,
  QToggle,
  QForm,
  QBtn,
  QDialog,
  QSlideTransition,
} from '@quasar/components';
import { genFormDataArray } from '@vjs/helpers';
import { mapGetters } from 'vuex';
import { required } from '@vjs/validations';
import { ClosePopup } from '@quasar/directives';
import VModal from '@vjs/components/VModal';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import changeAnswerCorrectHelper from '../../helpers/changeAnswerCorrectHelper';
import filterDisabledAnswersHelper from '../../helpers/filterDisabledAnswersHelper';

export default {
  name: 'VExamQuestion',
  components: {
    QSpinnerGears,
    QTooltip,
    QInput,
    QToggle,
    QForm,
    QBtn,
    QDialog,
    QSlideTransition,
    VModal,
  },
  directives: {
    ClosePopup,
  },
  props: {
    questionProp: {
      type: Object,
      required: true,
    },
    questionNumber: {
      type: Number,
      required: true,
    },
    loading: Boolean,
    group: {
      type: Object,
    },
  },
  data() {
    return {
      visibleListAnswers: false,
      storeData: this.$store,
      questionEditId: 0,
      dataQuestion: {},
      dataAnswers: {},
      destroyList: [],
      dataQuestionEdit: {},
      modalConfirmQuestionRemove: false,
      modalConfirmAnswerRemove: false,
      editor: ClassicEditor,
      editorConfig: {
        language: 'ru',
        toolbar: {
          items: [
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            'imageUpload',
            'blockQuote',
            'insertTable',
            'undo',
            'redo',
            'math'
          ],
        },
        image: {
          toolbar: ['toggleImageCaption', 'imageTextAlternative']
        },
      },
      UploadAdapter(loader, store) {
        this.upload = () => loader.file
          .then(file => new Promise((resolve) => {
            const formData = new FormData();
            _.each(loader, (item, index) => {
              formData.append(index, item);
            });
            store.dispatch('FILE_QUESTION', { payload: formData, file, resolve });
          }));
        this.abort = () => {
          console.log('Abort upload.');
        };
      },
      disableSave: false,
    };
  },
  async mounted() {
    await this.setData();
    this.activateMathJax();

  },
  updated() {
    this.activateMathJax();
  },
  computed: {
    ...mapGetters([
      'getQuestionEditId',
      'getAnswerEditId',
      'getGroup',
    ]),
  },
  methods: {
     activateMathJax() {
      // Активация MathJax, если MathJax найден
      if (window.MathJax) {
        window.MathJax.Hub.Config({
          tex2jax: {
            inlineMath: [['$', '$'], ['\\(', '\\)']],
            processEscapes: true,
          },
        });
        window.MathJax.Hub.Queue([
          'Typeset',
          window.MathJax.Hub,
        ],
        () => {
          // Фикс от скачков формул во время отрисовки
          const mathTex = document.querySelectorAll('.math-tex');
          Array.from(mathTex).forEach((item) => {
            item.style.opacity = '1';
          });
        });
      }
    },
    async setData() {
      this.dataQuestion = await this.questionProp;
    },
    required: message => val => required(message)(val),
    onEditorReady(instance) {
      const fileRepository = instance.plugins.get('FileRepository');

      if (fileRepository != null) {
        fileRepository.createUploadAdapter = loader => new this.UploadAdapter(
          loader, this.storeData,
        );
      }
    },
    changeQuestion(id) {
      this.dataQuestionEdit = _.clone(this.dataQuestion);
      this.dataAnswers = _.clone(this.dataQuestionEdit.answers);
      this.$store.commit('setQuestion', this.questionProp);
      this.$store.commit('setQuestionEditId', id);
      this.$store.commit('setQuestionAdd');
      this.visibleListAnswers = true;
      this.questionEditId = id;
    },
    cancelQuestion() {
      this.dataQuestion = this.dataQuestionEdit;
      this.dataQuestion.answers = this.dataAnswers;
      this.destroyList = [];
      this.$store.commit('setQuestionEditId');
      this.$store.commit('setAnswerEditId');
    },
    async destroyQuestion() {
      const res = await this.$store.dispatch('DELETE_QUESTION');
      this.$notify({
        text: this.trans(res.text),
        type: res.type,
      });
      this.modalConfirmQuestionRemove = false;
    },
    questionShow(id) {
      this.$store.dispatch('SHOW_QUESTION', id);
    },
    async questionHide(id) {
      const res = await this.$store.dispatch('HIDE_QUESTION', id);

      this.$notify({
        text: res.text,
        type: res.type,
      });
    },
    destroyAnswer() {
      const index = _.findIndex(this.dataQuestion.answers, ['id', this.getAnswerEditId]);
      this.destroyList.push(this.dataQuestion.answers[index].id);
      this.deleteNewAnswer(index);
      this.modalConfirmAnswerRemove = false;
    },
    questionEditForm() {
      return this.questionEditId
        && this.getQuestionEditId === this.questionEditId;
    },
    examQuestionsAnswerClass(key) {
      let cssClass = '';
      cssClass += this.dataQuestion.answers[key].correct && this.group.type
      !== 'weight' ? ' exam-questions__answer--correct' : '';
      return cssClass;
    },
    deleteBtn() {
      return this.dataQuestion.answers.length > 2;
    },
    async questionForm() {
      if (this.disableSave) return;
      const data = genFormDataArray({
        question: this.dataQuestion.question,
        answers: this.dataQuestion.answers,
        destroyList: this.destroyList,
      });
      this.disableSave = true;
      const res = await this.$store.dispatch('EDIT_QUESTION', data);

      let text = this.trans(res.text);
      if (res.type === 'error') {
        text += ': ';
        _.forEach(res.data.errors, (item) => {
          text += `${item[0]}<br/>`;
        });
      }
      this.$notify({
        text,
        type: res.type,
      });
      this.disableSave = false;
    },
    newAnswer() {
      this.dataQuestion.answers.push({ answer: '', correct: 0, weight: 0, disable: false });
      filterDisabledAnswersHelper(this.dataQuestion);
      this.$store.commit('setQuestion', this.dataQuestion);
    },
    deleteNewAnswer(key) {
      this.dataQuestion.answers.splice(key, 1);
      filterDisabledAnswersHelper(this.dataQuestion);
      this.$store.commit('setQuestion', this.dataQuestion);
    },
    deleteAnswer(key) {
      if (this.dataQuestion.answers[key].id === undefined) {
        this.deleteNewAnswer(key);
      } else {
        this.modalConfirmAnswerRemove = !this.modalConfirmAnswerRemove;
        this.$store.commit('setAnswerEditId', this.dataQuestion.answers[key].id);
      }
    },
    changeAnswerCorrect(el) {
      this.dataQuestion.answers = changeAnswerCorrectHelper(this.dataQuestion, el);
    },
  },
};
