var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-passport-page__panel mb-30"
  }, [_c('div', {
    staticClass: "s-passport-page__name mb-30"
  }, [_vm._v("\n    " + _vm._s(_vm.post.name) + "\n  ")]), _vm._v(" "), _c('div', {
    staticClass: "row-sort-between row-sort--center s-blog__info s-medium mb-30"
  }, [_c('span', {
    staticClass: "s-group"
  }, [_c('span', [_vm._v(_vm._s(_vm.post.published))])]), _vm._v(" "), _c('span', {
    staticClass: "s-str-ico s-str-ico--thm-eye views-count"
  }, [_vm._v("\n      " + _vm._s(_vm.post.count_views) + "\n    ")])]), _vm._v(" "), _c('p', {
    staticClass: "s-plain-text",
    domProps: {
      "innerHTML": _vm._s(_vm.post.short_body)
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "row-sort-between row-sort--center"
  }, [_c('div', {
    staticClass: "s-group"
  }, [_c('a', {
    staticClass: "s-blog__post-link s-c-blue",
    attrs: {
      "href": _vm.post.url
    }
  }, [_vm._v("\n        " + _vm._s(_vm.trans('main.read_full')) + "\n      ")])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }