var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.show ? _c('div', {
    staticClass: "s-pie-chart__wrapper"
  }, [_c('div', {
    staticClass: "s-pie-chart__head"
  }, [_c('div', {
    staticClass: "s-pie-chart__title"
  }, [_vm._v("\n      " + _vm._s(_vm.title) + "\n      "), _vm.select ? _c('span', {
    staticStyle: {
      "margin-left": "1rem"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.selectedValue.name) + "\n    ")]) : _vm._e()]), _vm._v(" "), _vm.select ? _c('q-select', {
    attrs: {
      "label": _vm.selectLabel,
      "options": _vm.options,
      "option-value": "id",
      "option-label": "name"
    },
    on: {
      "input": _vm.handleChangeSelect
    },
    model: {
      value: _vm.selectedValue,
      callback: function callback($$v) {
        _vm.selectedValue = $$v;
      },
      expression: "selectedValue"
    }
  }) : _vm._e()], 1), _vm._v(" "), _c('div', {
    staticClass: "s-pie-chart__body"
  }, [_c('div', {
    ref: "populationPyramidChart",
    staticClass: "s-population-pyramid-chart"
  })])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }