var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sc-statement-form--step-subjects"
  }, [_c('v-form-inform', {
    attrs: {
      "message": _vm.trans('warning_text.modal_inform')
    }
  }), _vm._v(" "), _c('table', {
    staticClass: "s-table-cabinet"
  }, [_c('thead', {
    staticClass: "s-table__head"
  }, [_c('tr', [_c('th', {
    staticClass: "s-to-center"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('label.required_subjects')) + "\n        ")]), _vm._v(" "), _vm._l(_vm.options.required, function (column, index) {
    return _c('th', {
      key: index,
      staticClass: "s-to-center"
    }, [_vm._v("\n          " + _vm._s(column.name) + "\n        ")]);
  })], 2)]), _vm._v(" "), _c('tbody', {
    staticClass: "s-table__body"
  }, [_c('tr', [_c('td', {
    staticClass: "s-to-center"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('fields.point')) + "\n        ")]), _vm._v(" "), _vm._l(_vm.options.required, function (column, index) {
    return _c('td', {
      key: index,
      staticClass: "s-to-center",
      attrs: {
        "data-table-head": column.name
      }
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: column.grade,
        expression: "column.grade"
      }],
      attrs: {
        "type": "text",
        "disabled": "disabled"
      },
      domProps: {
        "value": column.grade
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) { return; }

          _vm.$set(column, "grade", $event.target.value);
        }
      }
    })]);
  })], 2)])]), _vm._v(" "), _vm._l(_vm.options.subjectMapped, function (specialty, specIndex) {
    return _c('div', {
      key: specIndex
    }, [_c('div', {
      staticClass: "sc-statement-form--step-subjects__title"
    }, [_vm._v("\n      " + _vm._s(specialty.name) + "\n    ")]), _vm._v(" "), _c('table', {
      staticClass: "s-table-cabinet"
    }, [_c('thead', {
      staticClass: "s-table__head"
    }, [_c('tr', [_c('th', {
      staticClass: "s-to-center"
    }, [_vm._v("\n            " + _vm._s(_vm.trans('label.special_subjects')) + "\n          ")]), _vm._v(" "), _vm._l(specialty.columns, function (column, index) {
      return _c('th', {
        key: index,
        staticClass: "s-to-center"
      }, [_vm._v("\n            " + _vm._s(column.name) + "\n          ")]);
    }), _vm._v(" "), _c('th', {
      staticClass: "s-to-center"
    }, [_vm._v("\n            " + _vm._s(_vm.trans('statement.exam_subjects.total')) + "\n          ")])], 2)]), _vm._v(" "), _c('tbody', {
      staticClass: "s-table__body"
    }, [_c('tr', [_c('td', {
      staticClass: "s-to-center"
    }, [_vm._v("\n            " + _vm._s(_vm.trans('fields.point')) + "\n          ")]), _vm._v(" "), _vm._l(specialty.columns, function (column, index) {
      return _c('td', {
        key: index,
        staticClass: "s-to-center",
        attrs: {
          "data-table-head": column.name
        }
      }, [_c('input', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: column.grade,
          expression: "column.grade"
        }],
        attrs: {
          "type": "text",
          "disabled": "disabled"
        },
        domProps: {
          "value": column.grade
        },
        on: {
          "input": function input($event) {
            if ($event.target.composing) { return; }

            _vm.$set(column, "grade", $event.target.value);
          }
        }
      })]);
    }), _vm._v(" "), _c('td', {
      staticClass: "s-to-center"
    }, [_vm._v("\n            " + _vm._s(specialty.average_point) + "\n          ")])], 2)])])]);
  }), _vm._v(" "), _c('q-form', [_vm.options.notGraded.length ? _c('div', {
    staticClass: "row"
  }, _vm._l(_vm.options.notGraded, function (subject, index) {
    return _c('q-select', {
      key: index,
      staticClass: "col-sm-4 sc-statement-form--step-subjects__title",
      attrs: {
        "options": _vm.options.points,
        "required": "",
        "label": subject.name,
        "emit-value": "",
        "map-options": ""
      },
      on: {
        "input": function input($event) {
          return _vm.setSubjectGrade(subject.id, _vm.form.point[index]);
        }
      },
      model: {
        value: _vm.form.point[index],
        callback: function callback($$v) {
          _vm.$set(_vm.form.point, index, $$v);
        },
        expression: "form.point[index]"
      }
    });
  }), 1) : _vm._e(), _vm._v(" "), _vm._t("files"), _vm._v(" "), _c('div', {
    staticClass: "d-flex justify-content-end mb-20"
  }, [_c('q-btn', {
    directives: [{
      name: "s-modal",
      rawName: "v-s-modal",
      value: {
        id: 'v-modal-statement-attestation-average-point',
        component: 'v-modal-statement-attestation-average-point',
        notPersistent: true,
        events: {
          confirmSubmit: function confirmSubmit(avgPoint) {
            return _vm.saveGrades(avgPoint);
          }
        },
        binds: {
          isForEgov: true
        }
      },
      expression: "{\n          id: 'v-modal-statement-attestation-average-point',\n          component: 'v-modal-statement-attestation-average-point',\n          notPersistent: true,\n          events: {\n            confirmSubmit: (avgPoint) => saveGrades(avgPoint),\n          },\n          binds: {\n            isForEgov: true,\n          }}"
    }],
    attrs: {
      "label": _vm.trans('button.save'),
      "color": "blue",
      "label-wider": "",
      "ellipsis": "",
      "no-caps": "",
      "disable": _vm.validate
    }
  })], 1)], 2)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }