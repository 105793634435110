var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-statement-create-view s-w-100"
  }, [_c('v-steps', {
    attrs: {
      "uri-static": _vm.uriStatic
    }
  }), _vm._v(" "), _c('div', {
    class: ['s-panel', 'mb-20', {
      'sc-statement-inner-margin no-padding': _vm.current === 2
    }, {
      'sc-statement-inner': _vm.current !== 2
    }]
  }, [_vm.current !== 8 || _vm.current !== 2 || _vm.current !== 5 ? _c('div', {
    class: [{
      's-title s-light mb-20': _vm.current !== 2
    }]
  }, [_vm._v("\n      " + _vm._s(_vm.getStepTitle()) + "\n    ")]) : _vm._e(), _vm._v(" "), _c('v-steps-body', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c('template', {
    slot: "content"
  }, [_vm.current === 2 ? _c('v-step-edu-navigator', {
    key: "2",
    attrs: {
      "uri-static": _vm.uriStatic
    },
    on: {
      "loading": _vm.loadingProcessing
    }
  }) : _vm._e(), _vm._v(" "), _vm.current === 3 ? _c('v-step-userinfo', {
    key: "3",
    attrs: {
      "profile-exists": _vm.profileExists,
      "uri-get-profile": _vm.uriGetProfile,
      "uri-send-profile-update": _vm.uriSendProfileUpdate,
      "uri-profile-options": _vm.uriProfileOptions,
      "uri-education-options": _vm.uriEducationOptions,
      "uri-user-files": _vm.uriUserFiles,
      "for-import": _vm.forImport
    },
    on: {
      "loading": _vm.loadingProcessing
    }
  }) : _vm._e(), _vm._v(" "), _vm.current === 4 && _vm.forGrant && !_vm.withPpz ? _c('v-step-qualification-pres', {
    key: "4",
    attrs: {
      "uri-qualification-data": _vm.uriQualificationData,
      "uri-check-duplicate-statements": _vm.uriCheckDuplicateStatements
    },
    on: {
      "loading": _vm.loadingProcessing
    }
  }) : _vm._e(), _vm._v(" "), _vm.current === 4 && (!_vm.forGrant || _vm.withPpz) ? _c('v-step-qualification', {
    key: "4",
    attrs: {
      "uri-qualification-data": _vm.uriQualificationData,
      "uri-check-duplicate-statements": _vm.uriCheckDuplicateStatements
    },
    on: {
      "loading": _vm.loadingProcessing
    }
  }) : _vm._e(), _vm._v(" "), _vm.current === 5 ? _c('v-step-subject-assessment', {
    key: "5",
    attrs: {
      "uri-get-subjects-statement": _vm.uriGetSubjectsStatement,
      "uri-user-files": _vm.uriUserFiles
    },
    on: {
      "loading": _vm.loadingProcessing
    }
  }) : _vm._e(), _vm._v(" "), _vm.current === 6 ? _c('v-step-documents', {
    key: "6",
    attrs: {
      "uri-send-statement": _vm.uriSendStatement,
      "uri-user-files": _vm.uriUserFiles,
      "with-ppz": _vm.withPpz,
      "for-grant": _vm.forGrant,
      "for-import": _vm.forImport,
      "need-sign": _vm.needSign
    },
    on: {
      "loading": _vm.loadingProcessing
    }
  }) : _vm._e(), _vm._v(" "), _vm.current === 7 ? _c('v-step-congratulations', {
    key: "7",
    attrs: {
      "uri-static": _vm.uriStatic,
      "uri-statement-list": _vm.uriStatementList
    }
  }) : _vm._e()], 1)], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }