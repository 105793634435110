var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.description ? _c('div', [_vm.itsLibrarian ? _c('div', {
    staticClass: "college-library-upload-btn"
  }, [_c('q-btn', {
    staticClass: "college-library-upload-btn btn-adaptive-helper",
    class: _vm.$q.screen.width >= 500 ? 'mb-10' : '',
    attrs: {
      "icon": "plus",
      "label-wider": "",
      "label": _vm.trans('button.download_book'),
      "color": "orange"
    },
    on: {
      "click": _vm.openCreateModal
    }
  })], 1) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "college-library-filter--wrapper"
  }, [_c('div', {
    staticClass: "mb-30 college-library-filter"
  }, [_vm.$q.screen.width <= 500 ? _c('q-select', {
    staticClass: "s-w-100 ",
    attrs: {
      "options": _vm.types.short,
      "emit-value": "",
      "map-options": ""
    },
    model: {
      value: _vm.filter.type,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "type", $$v);
      },
      expression: "filter.type"
    }
  }) : _c('q-btn-toggle', {
    staticClass: "college-library-filter--btn-toggle",
    attrs: {
      "toggle-color": "blue",
      "options": _vm.types.short
    },
    model: {
      value: _vm.filter.type,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "type", $$v);
      },
      expression: "filter.type"
    }
  }), _vm._v(" "), _vm.filter.type == 2 ? _c('q-select', {
    staticClass: "college-library-filter--select",
    attrs: {
      "options": _vm.qualificationFilter,
      "emit-value": "",
      "map-options": ""
    },
    on: {
      "filter": _vm.filterFn,
      "filter-abort": _vm.abortFilterFn
    },
    model: {
      value: _vm.filter.profession_id,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "profession_id", $$v);
      },
      expression: "filter.profession_id"
    }
  }) : _vm._e(), _vm._v(" "), _c('q-input', {
    staticClass: "college-library-filter--input",
    attrs: {
      "placeholder": _vm.trans('placeholder.filter_input')
    },
    model: {
      value: _vm.filter.textFilter,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "textFilter", $$v);
      },
      expression: "filter.textFilter"
    }
  })], 1)]), _vm._v(" "), _c('div', {
    staticClass: "college-library-book--wrapper"
  }, _vm._l(_vm.page, function (book) {
    return _c('div', {
      staticClass: "college-library-book"
    }, [_c('div', {
      staticClass: "college-library-card"
    }, [_c('div', {
      staticClass: "college-library-card--popover"
    }, [_c('div', {
      staticClass: "s-w-100 d-flex",
      staticStyle: {
        "justify-content": "space-evenly"
      }
    }, [_c('q-btn', {
      attrs: {
        "icon": "eye",
        "squire": "",
        "color": "blue"
      },
      on: {
        "click": function click($event) {
          return _vm.getDescription(book.id);
        }
      }
    }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('button.more')))])], 1), _vm._v(" "), book.book_path ? _c('q-btn', {
      attrs: {
        "type": "a",
        "squire": "",
        "to": _vm.getBook + '/' + book.id,
        "target": "_blank",
        "icon": "download",
        "color": "green"
      },
      on: {
        "~click": function click($event) {
          !_vm.itsLibrarian && book.getCookie && book.downloads_count++;
        }
      }
    }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('button.download')))])], 1) : _vm._e()], 1), _vm._v(" "), _c('div', {
      staticClass: "s-w-100 d-flex mt-20",
      staticStyle: {
        "justify-content": "space-evenly"
      }
    }, [_vm.itsLibrarian ? _c('q-btn', {
      attrs: {
        "icon": "pencil",
        "squire": "",
        "color": "orange"
      },
      on: {
        "click": function click($event) {
          return _vm.setEditPopup(book.id);
        }
      }
    }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('button.edit')))])], 1) : _vm._e(), _vm._v(" "), _vm.canDeleteBook ? _c('q-btn', {
      attrs: {
        "icon": "trash",
        "squire": "",
        "color": "red"
      },
      on: {
        "click": function click($event) {
          return _vm.openSuccessModal(book.id);
        }
      }
    }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('button.remove')))])], 1) : _vm._e()], 1)]), _vm._v(" "), _c('img', {
      attrs: {
        "src": book.cover_path,
        "alt": ""
      }
    })]), _vm._v(" "), _c('div', {
      staticClass: "college-library-desc--name mt-15",
      attrs: {
        "title": book.book_name
      }
    }, [_vm._v("\n          " + _vm._s(book.book_name) + " (" + _vm._s(book.year) + ")\n        ")]), _vm._v(" "), _c('div', {
      staticClass: "college-library-desc--author",
      attrs: {
        "title": book.author
      }
    }, [_vm._v("\n          " + _vm._s(book.author) + "\n        ")]), _vm._v(" "), book.book_path ? _c('div', {
      staticClass: "college-library-desc--watches mb-30 row-sort--center"
    }, [_vm._v("\n          " + _vm._s(book.downloads_count) + "\n        ")]) : _vm._e()]);
  }), 0), _vm._v(" "), _c('div', {
    staticClass: "row-sort-center mt-20"
  }, [_vm.pageCount > 1 ? _c('q-pagination', {
    attrs: {
      "max": _vm.pageCount,
      "boundary-links": true,
      "input": _vm.pageCount > 5,
      "input-class": "s-c-blue"
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  }) : _vm._e()], 1)]) : _c('div', [_vm.description ? _c('div', {
    staticClass: "container-content-head__tabs-wrapper college-library-tab"
  }, [_c('div', {
    staticClass: "container-content-head__tab-item ",
    on: {
      "click": function click($event) {
        _vm.description = false;
      }
    }
  }, [_c('div', {
    staticClass: "s-str-ico s-str-ico--thm-angle-left s-str-ico--wider"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('cabinet.back')) + "\n        ")])])]) : _vm._e(), _vm._v(" "), _c('v-library-description', {
    attrs: {
      "its-librarian": _vm.itsLibrarian,
      "can-delete-book": _vm.canDeleteBook,
      "get-book": _vm.getBook
    }
  })], 1), _vm._v(" "), _vm.itsLibrarian ? _c('v-library-modal', {
    attrs: {
      "show-warning": _vm.showWarning
    }
  }) : _vm._e(), _vm._v(" "), _vm.itsLibrarian || _vm.canDeleteBook ? _c('q-dialog', {
    attrs: {
      "persistent": ""
    },
    model: {
      value: _vm.successModal,
      callback: function callback($$v) {
        _vm.successModal = $$v;
      },
      expression: "successModal"
    }
  }, [_c('v-modal', {
    attrs: {
      "size": "480",
      "title": _vm.trans('notice.are_you_sure')
    }
  }, [_c('div', {
    staticClass: "row-sort-between-btn"
  }, [_c('q-btn', {
    staticClass: "college-library-btn",
    attrs: {
      "icon": "close",
      "label": _vm.trans('button.cancel'),
      "label-wider": "",
      "width": "20",
      "color": "blue"
    },
    on: {
      "click": function click($event) {
        _vm.successModal = false;
      }
    }
  }), _vm._v(" "), _c('q-btn', {
    staticClass: "college-library-btn",
    attrs: {
      "icon": "check",
      "label": _vm.trans('button.remove'),
      "label-wider": "",
      "width": "20",
      "color": "red"
    },
    on: {
      "click": _vm.doDelete
    }
  })], 1)])], 1) : _vm._e(), _vm._v(" "), _vm.noBooks ? _c('div', [_c('h3', {
    staticClass: "s-title"
  }, [_vm._v("\n      " + _vm._s(_vm.trans('title.no_books')) + "\n    ")])]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }