import { eventBus } from '@common/mixins';
import VJournalControl from './components/VJournalControl';
import VJournalTable from './components/VJournalTable';
import VJournalRemarks from './components/VJournalRemarks';
import VJournalTableControl from './components/VJournalTableControl';
import VJournalTitle from './components/VJournalTitle';
import VJournalTabs from './components/VJournalTabs';

export default {
  name: 'VJournal',

  components: {
    VJournalControl,
    VJournalTable,
    VJournalRemarks,
    VJournalTableControl,
    VJournalTitle,
    VJournalTabs,
  },
  props: {
    colorsArr: {
      type: Array,
      default: () => [],
    },
    apiConfig: {
      type: Object,
      default: () => ({
        remarks: {
          store: '',
          list: '',
        },
        generateWeek: {
          store: '',
          data: '',
          filters: '',
        },
        date: {
          store: '',
          update: '',
          destroy: '',
          fields: '',
          themes: '',
        },
        point: {
          store: '',
          destroy: '',
        },
        calcResult: {
          action: '',
          accesses: '',
        },
        userFiles: {
          get: '',
          store: '',
        },
        back: '',
        export: '',
        infoTable: '',
        mainTable: '',
        students: '',
        refs: '',
        restore: '',
      }),
    },
    journalId: {
      type: Number,
      required: true,
    },
    journalName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isMounted: false,
    };
  },

  async mounted() {
    this.$globalLoading.show();
    const self = this;
    await this.$trans.add([
      'label',
      'button',
      'journal',
      'table',
      'training_schedule',
      'select',
      'placeholder',
    ]);
    this.$store.commit('SET_VM', this);
    this.$store.commit('SET_JOURNAL_ID', this.journalId);
    this.$store.commit('SET_API_CONFIG', this.apiConfig);
    this.$store.commit('SET_COLORS_ARR', this.colorsArr);

    await this.$store.dispatch('GET_INFO_TABLE');
    await this.$store.dispatch('GET_STUDENTS');
    await this.initJournal();

    eventBus.$on('reloadJournal', async () => {
      this.$globalLoading.show();
      await this.initJournal();
      this.$globalLoading.hide();
    });
    setTimeout(() => {
      const table = self.$refs.table;
      if (table) {
        table.scrollTableToLastColumn();
      }
    });
    this.isMounted = true;
    this.$globalLoading.hide();
  },

  computed: {
    isRemarksTableActive() { return this.$store.getters.isRemarksTableActive; },
    isPersonal() { return this.$store.getters.isPersonal; },
    swipeDirection() { return this.isRemarksTableActive ? 'swipe-left' : 'swipe-right'; },
  },
  methods: {
    async initJournal() {
      await this.$store.dispatch('GET_REFS');
      await this.$store.dispatch('GET_JOURNAL');
      await this.$store.dispatch('GET_REMARKS_TABLE');

      if (!this.$store.getters.isModule) {
        await this.$store.dispatch('GET_GENERATE_MONTHS');
      }
    },
  },
};
