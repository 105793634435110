var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sc-curriculum-program__info"
  }, [_c('v-curriculum-program-heading', {
    attrs: {
      "name": _vm.trans('label.general_information')
    }
  }), _vm._v(" "), _c('common-info-table'), _vm._v(" "), _c('v-curriculum-program-heading', {
    attrs: {
      "name": _vm.trans('curriculum.semester_hour_distribution')
    }
  }), _vm._v(" "), _c('hours-distribution-table', {
    ref: "hoursTable"
  }), _vm._v(" "), _c('v-curriculum-program-heading', {
    attrs: {
      "name": _vm.trans('curriculum.working_program_content')
    }
  }), _vm._v(" "), _c('program-content-table', {
    ref: "contentTable"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }