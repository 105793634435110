import Vue from 'vue';
import VMenu from '@vjs/components/VMenu/VMenu.vue';

if (document.getElementById('v-menu-app')) {

  window.vMenuApp = new Vue({
    el: '#v-menu-app',
    components: { VMenu },
  });
}
