var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "s-home-statits-wrapper"
  }, [_c('div', {
    staticClass: "s-statits-fig"
  }, [_c('div', {
    staticClass: "s-home-statits__title"
  }, [_vm._v("\n      " + _vm._s(_vm.langs.title) + "\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "s-statits-fig__block s-statits-fig__block--four"
  }, [_c('div', {
    staticClass: "s-statits-fig__block-inner"
  }, [_c('img', {
    attrs: {
      "src": "".concat(_vm.staticAddr, "/indv/college/images/statistic_icon_college_01.svg")
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "s-statits-fig__block-desc"
  }, [_c('span', [_vm._v(_vm._s(_vm.beautyNum(_vm.countPersonal)))]), _vm._v(" "), _c('p', [_vm._v(_vm._s(_vm.langs.personal))])])]), _vm._v(" "), _c('div', {
    staticClass: "s-statits-fig__block s-statits-fig__block--first"
  }, [_c('div', {
    staticClass: "s-statits-fig__block-inner"
  }, [_c('img', {
    attrs: {
      "src": "".concat(_vm.staticAddr, "/indv/college/images/statistic_icon_college_03.svg")
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "s-statits-fig__block-desc"
  }, [_c('span', [_vm._v(_vm._s(_vm.beautyNum(_vm.countOrganization)))]), _vm._v(" "), _c('p', [_vm._v(_vm._s(_vm.langs.college))])])]), _vm._v(" "), _c('div', {
    staticClass: "s-statits-fig__block s-statits-fig__block--third"
  }, [_c('div', {
    staticClass: "s-statits-fig__block-inner"
  }, [_c('img', {
    attrs: {
      "src": "".concat(_vm.staticAddr, "/indv/college/images/statistic_icon_college_02.svg")
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "s-statits-fig__block-desc"
  }, [_c('span', [_vm._v(_vm._s(_vm.beautyNum(_vm.countContingent)))]), _vm._v(" "), _c('p', [_vm._v(_vm._s(_vm.langs.contingent))])])]), _vm._v(" "), _c('div', {
    staticClass: "s-statits-fig__block s-statits-fig__block--second"
  }, [_c('div', {
    staticClass: "s-statits-fig__block-inner"
  }, [_c('img', {
    attrs: {
      "src": "".concat(_vm.staticAddr, "/indv/college/images/statistic_icon_college_04.svg")
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "s-statits-fig__block-desc"
  }, [_c('span', [_vm._v(_vm._s(_vm.beautyNum(_vm.countService)))]), _vm._v(" "), _c('p', [_vm._v(_vm._s(_vm.langs.service))])])])]), _vm._v(" "), _c('div', {
    staticClass: "s-home-container"
  }, [_c('a', {
    staticClass: "s-btn s-btn--thm-green s-btn--w-200",
    attrs: {
      "href": _vm.statisticsAddr
    }
  }, [_c('span', {
    staticClass: "s-str-ico-rev s-str-ico--thm-arrow-right s-str-ico--wider"
  }, [_vm._v("\n        " + _vm._s(_vm.langs.more) + "\n      ")])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }