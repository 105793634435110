import {
  QTab,
  QTabs,
  QInput,
  QForm,
  QBtn,
  QCheckbox,
} from '@quasar/components';
import { Loading } from '@quasar/plugins';
import Validation from '@vjs/mixins/Validation';
import { requestWrapper } from '@vjs/helpers';

export default {
  name: 'v-login-form',
  mixins: [Validation],
  components: {
    QTabs,
    QTab,
    QInput,
    QForm,
    QBtn,
    QCheckbox,
  },
  props: {
    urlAuthCode: {
      type: String,
      default: '',
    },
    urlForgotPassword: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tab: 'iin',
      login: null,
      password: null,
      remember: false,
      qLoading: Loading,
      loading: false,
      tabsItems: ['iin', 'phone', 'login', 'email'],
    };
  },
  async mounted() {
    await this.$trans.add([
      'button',
      'notice',
      'auth',
    ]);
  },
  computed: {
    maskComputed() {
      switch (this.tab) {
        case 'iin': return '############';
        case 'phone': return '+7 (###) ###-##-##';

        default: return undefined;
      }
    },
    loginFieldLabelComputed() {
      switch (this.tab) {
        case 'iin': return this.trans('auth.login_tab_fields.iin');
        case 'phone': return this.trans('auth.login_tab_fields.phone');
        case 'login': return this.trans('auth.login_tab_fields.login');
        case 'email': return this.trans('auth.login_tab_fields.email');
        default: return undefined;
      }
    },
  },

  methods: {
    async changeTab() {
      this.password = null;
      this.login = null;
      this.errors = [];
    },
    async requestAction() {
      this.loading = true;
      this.qLoading.show();
      const data = { password: this.password, type: this.tab };
      data[this.tab] = this.login;
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.action,
        data,
      });

      if (!res.error && res.redirect) {
        window.location.href = res.redirect;
      }

      if (res.error) {
        this.loading = false;
        this.qLoading.hide();
      }
    },
  },
};
