var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "sc-journal__table--container"
  }, [_c('div', {
    staticClass: "sc-journal__table",
    class: _vm.isStudent ? 'sc-journal__table--for-student' : ''
  }, [_c('div', {
    staticClass: "sc-journal__table--fixed-part-container"
  }, [_c('table', {
    staticClass: "sc-journal__table--fixed-part"
  }, [_c('tr', {
    staticClass: "sc-journal__table--head-row"
  }, [_c('th', {
    staticClass: "sc-journal__table--head-cell sc-journal__table--cell",
    class: {
      'd-none': _vm.rows.length === 1 || _vm.isStudent
    }
  }, [_vm._v("\n              №\n            ")]), _vm._v(" "), _c('th', {
    staticClass: "sc-journal__table--head-cell sc-journal__table--cell"
  }, [_vm._v("\n              " + _vm._s(_vm.isStudent ? _vm.trans('journal.date_label') : _vm.trans('journal.student_fullname')) + "\n            ")])]), _vm._v(" "), _vm._l(_vm.rows, function (row, index) {
    return _c('tr', {
      key: "fullname-".concat(index),
      staticClass: "sc-journal__table--row"
    }, [_c('td', {
      staticClass: "sc-journal__table--cell",
      class: {
        'd-none': _vm.rows.length === 1 || _vm.isStudent
      }
    }, [_vm._v("\n              " + _vm._s(index + 1) + "\n            ")]), _vm._v(" "), _c('td', {
      staticClass: "sc-journal__table--cell"
    }, [!row.isCurrent ? _c('div', {
      staticClass: "sc-journal__table--retired-flag"
    }) : _vm._e(), _vm._v("\n              " + _vm._s(_vm.isStudent ? _vm.trans('journal.point_label') : _vm.$q.screen.width > 768 ? row.fullName : row.shortName) + "\n            ")])]);
  })], 2)]), _vm._v(" "), _c('div', {
    staticClass: "sc-journal__table--scroll-part-container"
  }, [_c('table', {
    staticClass: "sc-journal__table--scroll-part"
  }, [_c('tr', {
    staticClass: "sc-journal__table--head-row"
  }, [_vm._l(_vm.cols, function (col, index) {
    return _c('th', {
      key: "col-".concat(index),
      staticClass: "sc-journal__table--cell sc-journal__table--head-cell",
      class: {
        'sc-journal__table--cell-editable': (col.canEdit || col.canDestroy) && _vm.cans.edit
      },
      attrs: {
        "id": "col-".concat(index)
      },
      on: {
        "mouseover": function mouseover($event) {
          return _vm.handleTooltip("#col-".concat(index), col.tooltip);
        },
        "click": function click($event) {
          return _vm.openDayForm(col);
        }
      }
    }, [!!col.flagColor ? _c('div', {
      staticClass: "sc-journal__table--point-flag",
      style: "border-top-color: ".concat(col.flagColor)
    }) : _vm._e(), _vm._v(" "), _c('div', {
      staticClass: "sc-journal__table--cell-value"
    }, [_vm._v("\n                " + _vm._s(col.label) + "\n              ")]), _vm._v(" "), (col.canEdit || col.canDestroy) && _vm.cans.edit ? _c('q-btn', {
      attrs: {
        "color": "blue",
        "outline": "",
        "icon": "pencil",
        "squire": ""
      }
    }) : _vm._e()], 1);
  }), _vm._v(" "), _vm.cans.addDay ? _c('th', {
    staticClass: "sc-journal__table--cell sc-journal__table--head-cell sn--cursor-pointer",
    on: {
      "click": function click($event) {
        return _vm.openDayForm();
      }
    }
  }, [_c('q-icon', {
    attrs: {
      "name": "plus",
      "bold": "",
      "size": "2rem"
    }
  })], 1) : _vm._e()], 2), _vm._v(" "), _vm._l(_vm.rows, function (row, index) {
    return _c('tr', {
      key: "row-".concat(index),
      staticClass: "sc-journal__table--row"
    }, [_vm._l(_vm.cols, function (col, colKey) {
      return _c('td', {
        key: "row-".concat(index, "-col-").concat(colKey),
        staticClass: "sc-journal__table--cell-day sc-journal__table--cell",
        class: {
          'sc-journal__table--cell-editable': _vm.canEditPoint(row, col)
        },
        attrs: {
          "id": "row-".concat(index, "-col-").concat(colKey)
        },
        on: {
          "mouseover": function mouseover($event) {
            _vm.handleTooltip("#row-".concat(index, "-col-").concat(colKey), _vm.getTooltip(col, row.id));
          },
          "click": function click($event) {
            return _vm.openModalEditPoint(row, col, colKey);
          }
        }
      }, [_c('div', {
        staticClass: "sc-journal__table--cell-value"
      }, [_vm._v("\n                " + _vm._s(_vm.getLabel(col, row.id)) + "\n              ")]), _vm._v(" "), _vm.canEditPoint(row, col) ? _c('q-btn', {
        attrs: {
          "color": "blue",
          "outline": "",
          "icon": "pencil",
          "squire": ""
        }
      }) : _vm._e()], 1);
    }), _vm._v(" "), _vm.cans.addDay ? _c('td', {
      staticClass: "sc-journal__table--cell-day sc-journal__table--cell"
    }) : _vm._e()], 2);
  })], 2)])])]), _vm._v(" "), _c('v-journal-legend'), _vm._v(" "), _vm.activeTooltipText && _vm.$q.screen.width > 768 ? _c('q-tooltip', {
    ref: "journalTooltip",
    attrs: {
      "max-width": "24rem",
      "target": _vm.activeTooltipId
    }
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.activeTooltipText)
    }
  })]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }