import {
  requestWrapper,
} from '@vjs/helpers';
import {
  QForm,
  QInput,
  QField,
  QBtn,
  QCheckbox,
  QSelect,
} from '@quasar/components';
import VFormInform from '@vjs/components/VFormInform';
import eventHub from '@vjs/config/eventHub';
import _ from 'lodash';
import Validation from '../mixins/Validation';

export default {
  name: 'VStepQualificationPres',

  components: {
    QForm,
    QInput,
    QField,
    QSelect,
    VFormInform,
    QBtn,
    QCheckbox,
  },

  mixins: [Validation],

  props: {
    uriQualificationData: {
      type: String,
      required: true,
    },
    uriCheckDuplicateStatements: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      activeButton: true,
      isDisabled: true,
      disableSelect: false,
      maxNumber: 1,
      statement: {
        region: null,
        specialty: null,
        qualification: null,
        organization: null,
        edu_lang: null,
      },
      options: {
        regions: [],
        specialties: [],
        qualifications: [],
        organizations: [],
        eduLangs: [],
      },
      form: {
        educational_institution: '',
        educational_institution_id: null,
        education: null,
        isWorking: false,
        statements: [],
        specialties: [],
      },
      pairs: {
        region: 'specialties',
        specialty: 'qualifications',
        qualification: 'organizations',
        organization: 'eduLangs',
      },
      formValues: [],
    };
  },

  created() {
    eventHub.$on('SET_NEXT_STEP', this.requestForm);
  },

  async mounted() {
    this.$emit('loading', true);
    this.loading = true;

    if (this.$store.state.vSteps.userInfo !== null) {
      const {
        educational_institution,
        educational_institution_id,
        education,
      } = this.$store.state.vSteps.userInfo;
      this.form.educational_institution = educational_institution;
      this.form.educational_institution_id = educational_institution_id;
      this.form.education = education;
    }

    const res = await requestWrapper.call(this, {
      url: this.uriQualificationData,
    });

    if (!res.error) {
      this.options = res.options;
    }
    this.formValues.push({ ...this.options });
    this.form.statements.push({ ...this.statement });
    this.form.specialties.push(this.form.statements[0].specialty);
    if (this.$store.state.vSteps.qualificationInfo !== null) {
      this.form = this.$store.state.vSteps.qualificationInfo;
    }
    if (this.$store.state.vSteps.qualificationFormValues !== null) {
      this.formValues = this.$store.state.vSteps.qualificationFormValues;
      this.maxNumber = this.formValues.length;
    }
    if (this.$store.state.vSteps.qualificationOptions !== null) {
      this.options = this.$store.state.vSteps.qualificationOptions;
    }
    this.loading = false;
    this.$emit('loading', false);
  },
  beforeDestroy() {
    eventHub.$off('SET_NEXT_STEP', this.requestForm);
  },

  computed: {
    validate() {
      return !this.isDisabled;
    },
  },
  watch: {
    validate(val) {
      this.$store.commit('SET_ACCESS_STEP', {
        step: 4,
        access: val,
      });
    },
    form: {
      handler() {
        this.activateButton();
        this.disableSelects();
      },
      deep: true,
    },
  },

  methods: {
    async requestForm() {
      this.loading = true;
      const res = await requestWrapper.call(this, {
        url: this.uriCheckDuplicateStatements,
        data: this.form,
        method: 'POST',
      });

      if (res.error) {
        if (res.data.errors) {
          this.$notify({
            text: _.first(_.flatten(res.data.errors[_.first(Object.keys(res.data.errors))])) ?? this.trans('notice.error_on_server'),
            type: 'error',
          });
        }
        this.loading = false;
        return false;
      }
      this.$store.commit('SET_NEXT_STEP');
      this.$store.commit('SET_QUALIFICATION_INFO', this.form);
      this.$store.commit('SET_QUALIFICATION_FORM_VALUES', this.formValues);
      this.$store.commit('SET_QUALIFICATION_OPTIONS', this.options);
      this.loading = false;
    },
    async getQualification(key, index) {
      const excludes = [];
      for (const item in this.form.statements[index]) {
        excludes.push(item);
        if (item === key) {
          break;
        }
      }
      this.setFormValues(index, excludes);
      this.form.specialties[index] = this.form.statements[index].specialty;
      const res = await requestWrapper.call(this, {
        url: this.uriQualificationData,
        params: {
          education: this.form.education,
          ...this.form.statements[index],
          isWorking: this.form.isWorking,
          chosenSpecialties: Array.isArray(this.form.specialties)
            ? this.form.specialties.filter(item => item != null)
            : [],
        },
      });
      if (!res.error) {
        if (this.pairs[key]) {
          this.formValues[index][this.pairs[key]] = res.options[this.pairs[key]];
        }
      }
    },

    setFormValues(index, excludes = []) {
      for (const item in this.form.statements[index]) {
        if (excludes.includes(item)) {
          continue;
        }
        this.$set(this.form.statements[index], item, null);
      }
      let clearValues = false;
      for (const formValue in this.formValues[index]) {
        if (formValue === this.pairs[excludes[excludes.length - 1]]) {
          clearValues = true;
        }
        if (clearValues) {
          this.$set(this.formValues[index], formValue, this.options[formValue]);
        }
      }
    },
    activateButton() {
      let isDisabled = true;
      for (const item in this.form.statements) {
        for (const el in this.form.statements[item]) {
          if (this.form.statements[item][el] === null) {
            isDisabled = true;
            break;
          } else {
            isDisabled = false;
          }
        }
      }
      this.activeButton = !this.form.isWorking && this.maxNumber !== 4;
      this.isDisabled = isDisabled;
    },
    disableSelects() {
      this.disableSelect = this.form.statements[0]
        && this.form.statements[0].specialty !== null
        && this.maxNumber > 1
        && !this.form.isWorking;
    },
    handleIsWorkerCheckbox() { // проверить
      this.activeButton = !this.activeButton;
      if (this.maxNumber === 1) {
        this.setFormValues(0);
      } else {
        for (let i = 0; i < this.maxNumber; i++) {
          this.deleteQualification(i);
        }
      }
      this.$set(this.formValues, '0', { ...this.options });
    },
    addQualifications() {
      this.maxNumber++;
      if (this.maxNumber < 5) {
        this.formValues.push({ ...this.options });
        this.form.statements.push({ ...this.statement });
        this.form.specialties.push(this.form.statements[this.maxNumber - 1].specialty);
        this.setFormValues(this.maxNumber - 1);
      }
    },
    deleteQualification() {
      this.form.statements.pop();
      this.form.specialties.pop();
      this.formValues.pop();
      this.maxNumber--;
    },
  },
};
