import {
  QForm,
  QBtn,
  QSelect,
  QInput,
  QSpinnerGears,
  QSlideTransition,
  QField,
  QOptionGroup,
  QCheckbox,
  QRadio,
} from '@quasar/components';
import {
  requestWrapper,
} from '@vjs/helpers';
import Validation
  from '@vjs/mixins/Validation';
import _ from 'lodash';
import VNoResults from '@vjs/components/VNoResults';
import VFormInform from '@vjs/components/VFormInform/VFormInform';

export default {
  name: 'VJournalForm',

  mixins: [Validation],
  components: {
    QForm,
    QBtn,
    QSelect,
    QInput,
    QSpinnerGears,
    VNoResults,
    VFormInform,
    QSlideTransition,
    QField,
    QOptionGroup,
    QCheckbox,
    QRadio,
  },
  props: {
    uriActions: {
      type: Object,
      required: true,
      default: () => {
      },
    },
    uriGetContingent: {
      type: String,
      required: true,
    },
    uriGetContingentAll: {
      type: String,
      required: true,
    },
    uriGetOptions: {
      type: String,
      required: true,
    },
    uriGetGroups: {
      type: String,
      required: true,
    },
    uriGetSubgroups: {
      type: String,
      required: true,
    },
    uriGetQualifications: {
      type: String,
      required: true,
    },
    uriGetSubjects: {
      type: String,
      required: true,
    },
    uriGetResults: {
      type: String,
      required: true,
    },
    uriGetModuleSubjects: {
      type: String,
      required: true,
    },
    uriGetPersonnel: {
      type: String,
      required: true,
    },
    uriGetCalendarThematicPlans: {
      type: String,
      required: true,
    },
    uriGetExistingData: {
      type: String,
      required: false,
    },
    uriGetSchedules: {
      type: String,
      required: true,
    },
    uriGetModules: {
      type: String,
      required: true,
    },
    createPersonnelId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      form: {
        journalType: null,
        qualification: null,
        group: null,
        subgroup: null,
        type: [],
        semester: null,
        hours: {
          first_semester_hours: null,
          second_semester_hours: null,
        },
        schedule: null,
        markType: null,
        subject: {
          value: null,
        },
        personnel: null,
        additionalPersonnels: [],
        name: '',
        groupType: 'normal',
        isModule: 0,
        isResult: 0,
        module: null,
        moduleSubjects: [],
        calendarThematicPlans: null,
      },
      options: {
        qualifications: [],
        qualificationsFiltered: [],
        groups: [],
        groupsFiltered: [],
        subgroups: [],
        subgroupsFiltered: [],
        types: [],
        semesters: [],
        markTypes: [],
        subjects: [],
        results: [],
        personnel: [],
        personnelFiltered: [],
        calendarThematicPlans: [],
        calendarThematicPlansFiltered: [],
        additionalPersonnels: [],
        additionalPersonnelsFiltered: [],
        modules: [],
        modulesFiltered: [],
        journalType: [],
        groupTypes: [],
        schedule: [],
        moduleSubjects: [],
        moduleSubjectsFiltered: [],
      },
      contingent: [],
      contingentFiltered: [],
      contingentSelected: [],
      contingentSearch: '',
      individualContingentSelected: null,
      loading: false,
      subjectsLoading: false,
      loadingContingent: false,
      currentSubjectsPage: 1,
      subjectsLastPage: 1,
      currentResultsPage: 1,
      resultsLastPage: 1,
      isScheduleSelected: true,
    };
  },

  computed: {
    subjectInputValue() {
      return this.$refs?.subjectSelect?.inputValue === this.trans('select.placeholder')
        ? ''
        : this.$refs?.subjectSelect?.inputValue.toLowerCase();
    },
    validate() {
      let access = true;
      if (this.form.isModule && this.form.isResult) {
        for (const subject of this.form.moduleSubjects) {
          if (subject.hours === undefined) {
            access = false;
            break;
          } else {
            access = true;
          }
        }
      }
      if (['individual', 'mixed', 'personal'].includes(this.form.journalType)) {
        return this.form.type.length !== 0
          && this.form.markType !== null
          && !!this.form.name
          && (this.form.personnel !== null && this.form.personnel !== 0)
          && this.contingentSelected.length !== 0
          && (
            (
              !this.form.isModule
              && this.form.subject.value
              && typeof this.form.semester === 'string'
            )
            || (
              this.form.isModule
              && this.form.module
              && this.form.moduleSubjects.length > 0
              && access
            )
          );
      }
      return this.form.group !== null
        && (
          this.form.groupType === 'normal'
          || (this.form.groupType === 'subgroup' && this.form.subgroup !== null)
        )
        && this.form.type.length !== 0
        && !!this.form.name
        && this.form.markType !== null
        && (this.form.personnel !== null && this.form.personnel !== 0)
        && (
          (
            !this.form.isModule
            && this.form.subject.value
            && typeof this.form.semester === 'string'
          )
          || (
            this.form.isModule
            && this.form.module
            && this.form.moduleSubjects.length > 0
            && access
          )
        );
    },
  },

  async mounted() {
    await this.$trans.add([
      'label',
      'button',
      'placeholder',
      'notice',
      'curriculum',
      'main',
      'fields',
      'table',
      'journal',
      'label',
      'personnel_dicts',
      'training_schedule',
      'select',
      'warning_text',
    ]);
    this.$globalLoading.show();

    // Дефолтное значение с костыльным плейсхолдером для селектов в которых нет поиска
    if (!this.uriGetExistingData) {
      this.form.markType = {
        value: null,
        label: this.trans('select.placeholder'),
      };
      this.form.semester = {
        value: null,
        label: this.trans('select.placeholder'),
      };
      this.form.schedule = {
        value: null,
        label: this.trans('select.placeholder'),
      };
      // this.form.type = {
      //   value: null,
      //   label: this.trans('select.placeholder'),
      // };
      this.form.subject = {
        ...this.form.subject,
        label: this.trans('select.placeholder'),
      };
    }

    this.options.groupTypes = [
      {
        value: 'normal',
        label: this.trans('label.select_all_students'),
      },
      {
        value: 'subgroup',
        label: this.trans('label.select_subgroup'),
      },
    ];

    await this.requestGetQualifications();
    await this.requestGetOptions();
    this.form.journalType = 'normal';
    await this.requestGetPersonnel();

    // await this.requestGetSchedules(true);
    if (!this.uriGetExistingData) {
      if (this.form.personnel === null && this.createPersonnelId !== null) {
        this.form.personnel = this.createPersonnelId;
        await this.requestGetCalendarThematicPlans();
      }
      await this.requestGetSubjects();
      await this.requestGetResults();
    }
    if (this.uriGetExistingData) {
      await this.requestGetExistingData();
    }
    await this.requestGetModules();

    this.$globalLoading.hide();
  },
  methods: {
    async updateSelects() {
      if (this.form.isModule) {
        this.form.module = null;
        await this.requestGetModules();
      } else {
        this.form.subject = {
          value: null,
          label: this.trans('select.placeholder'),
        };
      }
    },
    // Метод при смене типа журнала для сброса выбранных полей
    async journalTypeChange(val) {
      this.contingent = [];
      this.contingentFiltered = [];
      this.contingentSelected = [];
      this.individualContingentSelected = null;
      this.form.qualification = null;
      this.form.group = null;
      this.options.groups = [];
      this.options.groupsFiltered = [];
      this.form.subgroup = null;
      this.options.subgroups = [];
      this.options.subgroupsFiltered = [];
      this.form.groupType = 'normal';
      await this.requestGetSchedules(true);
    },

    // Поиск по селектам
    defaultFilterFn(val, update, options, optionsFiltered) {
      if (val === '') {
        update(() => {
          this.options[options] = this.options[optionsFiltered];
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.options[options] = this.options[optionsFiltered].filter(item => _.lowerCase(item.label).indexOf(needle) > -1);
      });
    },

    // Поиск по селекту контингента в персональных журналах
    filterContingents(val, update) {
      if (val === '') {
        update(() => {
          this.contingent = this.contingentFiltered;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.contingent = this.contingentFiltered.filter(item => _.lowerCase(item.label).indexOf(needle) > -1);
      });
    },

    // добавление студента в список выбранных студентов
    addStudent(student) {
      this.contingentSelected.push(student);
    },
    // удаление студента из списка выбранных студентов
    deleteStudent(key) {
      this.contingentSelected.splice(key, 1);
    },

    // Запрос на получение опций квалификаций
    async requestGetQualifications() {
      const res = await requestWrapper.call(this, {
        url: this.uriGetQualifications,
        params: {
          quasar: true,
        },
      });

      if (!res.error) {
        this.options.qualifications = res.data;
        this.options.qualificationsFiltered = res.data;
      }
    },

    // Обработка виртуал-скроллинга для селекта предметов
    async onSubScroll({ index }) {
      if (!this.subjectsLoading
        && !this.form.isResult
        && this.currentSubjectsPage < this.subjectsLastPage
        && index >= (this.options.subjects.length - 21)
      ) {
        this.currentSubjectsPage += 1;

        await this.requestGetSubjects(false);
        this.$nextTick(() => {
          this.$refs.subjectSelect.refresh();
          this.subjectsLoading = false;
        });
      } else if (!this.subjectsLoading
        && this.form.isResult
        && this.currentResultsPage < this.resultsLastPage
        && index >= (this.options.results.length - 21)
      ) {
        this.currentResultsPage += 1;

        await this.requestGetResults(false);
        this.$nextTick(() => {
          this.$refs.subjectSelect.refresh();
          this.loading = false;
        });
      }
    },

    // обработка поиска по предметам
    async requestSearchSubjects(val, update) {
      this.subjectsLoading = true;
      this.currentSubjectsPage = 1;
      if (!this.form.isResult) {
        this.requestGetSubjectsByFilter().then((res) => {
          update(() => {
            this.options.subjects = res.items;
            this.subjectsLoading = false;
          });
        });
      } else {
        this.requestGetResultsByFilter().then((res) => {
          update(() => {
            this.options.results = res.items;
            this.subjectsLoading = false;
          });
        });
      }

    },
    // запрос на получение опций предметов по поиску
    async requestGetSubjectsByFilter() {
      return new Promise(async (res, rej) => {
        const result = await requestWrapper.call(this, {
          url: this.uriGetSubjects,
          params: {
            quasar: true,
            page: 1,
            search: this.subjectInputValue,
          },
        });

        if (!result.error) {
          res(result);
        } else {
          rej(result);
        }
      });
    },
    // запрос на получение опций предметов по поиску
    async requestGetResultsByFilter() {
      return new Promise(async (res, rej) => {
        const result = await requestWrapper.call(this, {
          url: this.uriGetResults,
          params: {
            quasar: true,
            page: 1,
            search: this.subjectInputValue,
          },
        });

        if (!result.error) {
          res(result);
        } else {
          rej(result);
        }
      });
    },
    // запрос на получение опций предметов
    async requestGetSubjects(offLoading = true) {
      this.subjectsLoading = true;
      const res = await requestWrapper.call(this, {
        url: this.uriGetSubjects,
        params: {
          quasar: true,
          page: this.currentSubjectsPage,
          search: this.subjectInputValue,
        },
      });

      if (!res.error) {
        this.options.subjects = [...this.options.subjects, ...res.items];
        this.subjectsLastPage = res.lastPage;
      }
      if (offLoading) {
        this.subjectsLoading = false;
      }
    },
    // запрос на получение опций предметов
    async requestGetResults(offLoading = true) {
      this.subjectsLoading = true;
      const res = await requestWrapper.call(this, {
        url: this.uriGetResults,
        params: {
          quasar: true,
          page: this.currentResultsPage,
          search: this.subjectInputValue,
        },
      });

      if (!res.error) {
        this.options.results = [...this.options.results, ...res.items];
        this.resultsLastPage = res.lastPage;
      }
      if (offLoading) {
        this.subjectsLoading = false;
      }
    },

    // запрос на получение опций типа оценок, типа занятий, семестров, расписаний
    async requestGetOptions() {
      const res = await requestWrapper.call(this, {
        url: this.uriGetOptions,
        params: {
          quasar: true,
        },
      });

      if (!res.error) {
        this.options.markTypes = res.data.typeOfEvaluations;
        this.options.types = res.data.types;
        this.options.semesters = res.data.semester_types;
        this.options.journalType = res.data.journal_types;
      }
    },
    // запрос на получение опций персонала
    async requestGetPersonnel() {
      const res = await requestWrapper.call(this, {
        url: this.uriGetPersonnel,
        params: {
          quasar: true,
        },
      });

      if (!res.error) {
        this.options.personnel = res.data;
        this.options.personnelFiltered = this.options.personnel;
        this.options.additionalPersonnels = res.data;
        this.options.additionalPersonnelsFiltered = this.options.additionalPersonnels;
      }
    },
    // запрос на получение опций КТП
    async requestGetCalendarThematicPlans() {
      this.loading = true;
      const { personnel, additionalPersonnels } = this.form;
      const filteredPersonnels = additionalPersonnels.map(item => item.value);
      const res = await requestWrapper.call(this, {
        url: this.uriGetCalendarThematicPlans,
        params: {
          quasar: true,
          personnel: [personnel, ...filteredPersonnels],
        },
      });

      if (!res.error) {
        this.options.calendarThematicPlans = res.data;
        this.options.calendarThematicPlansFiltered = this.options.calendarThematicPlans;
      }
      this.loading = false;
    },
    // запрос на получение расписаний
    async requestGetSchedules(withClear = false) {
      if (withClear) {
        this.options.schedule = [];
        this.form.schedule = {
          value: null,
          label: this.trans('select.placeholder'),
        };
      }

      let type = this.form.journalType;
      switch (type) {
        case 'individual':
          type = 'for_individual';
          break;
        case 'mixed':
          type = 'for_mixed';
          break;
        case 'normal':
          if (!this.form.group) {
            return;
          }
          break;
        default:
          break;
      }
      const res = await requestWrapper.call(this, {
        url: this.uriGetSchedules,
        params: {
          quasar: true,
          type,
          groupId: this.form.group,
        },
      });

      if (!res.error) {
        this.options.schedule = res.data;
      }
    },
    // запрос на получение модулей
    async requestGetModules() {
      const res = await requestWrapper.call(this, {
        url: this.uriGetModules,
        params: {
          quasar: true,
          with_result: this.form.isResult,
        },
      });

      if (!res.error) {
        this.options.modules = res.data;
        this.options.modulesFiltered = res.data;
      }
    },
    // запрос на получение предметов с выбранного модуля
    async requestGetModuleSubjects(clearField = true) {
      if (clearField) {
        this.form.moduleSubjects = [];
      }

      const res = await requestWrapper.call(this, {
        url: this.uriGetModuleSubjects,
        params: {
          quasar: true,
          module_id: this.form.module,
        },
      });

      if (!res.error) {
        this.options.moduleSubjects = res.data;
        this.options.moduleSubjectsFiltered = res.data;
      }
    },
    // запрос на получение уже сохраненных данных (для страницы редактирования)
    async requestGetExistingData() {
      const res = await requestWrapper.call(this, {
        url: this.uriGetExistingData,
      });

      if (!res.error) {
        this.form.journalType = res.data.journal_type;
        this.form.name = res.data.name;
        this.form.type = res.data.type;
        // if (this.form.type === '' || this.form.type === null) {
        //   this.form.type = {
        //     value: null,
        //     label: this.trans('select.placeholder'),
        //   };
        // }
        if (res.data.hours) {
          this.form.hours = res.data.hours;
        }
        this.form.personnel = res.data.personal_id;
        this.form.additionalPersonnels = res.data.personal_ids;
        this.form.calendarThematicPlans = res.data.calendarThematicPlans;
        await this.requestGetCalendarThematicPlans();

        this.form.markType = res.data.type_of_evaluation;
        this.form.isModule = res.data.is_for_module;
        this.form.isResult = res.data.with_result;
        if (this.form.isModule) {
          this.form.module = res.data.education_module_id;
          this.form.moduleSubjects = res.data.moduleSubjects;
          await this.requestGetModuleSubjects(false);
        } else {
          this.form.semester = res.data.semester;
          this.form.subject = res.data.discipline_id;
        }

        switch (this.form.journalType) {
          case 'mixed':
            await this.requestGetSchedules();
            this.contingentSelected = res.data.contingents;
            await this.requestGetContingent();
            break;
          case 'personal':
            this.contingentSelected = res.data.contingents;
            break;
          case 'individual':
            await this.requestGetSchedules();
            this.form.qualification = res.data.qualification_id;
            await this.requestGetGroups();
            this.contingentSelected = res.data.contingents;
            this.form.group = res.data.group_id;
            await this.requestGetContingent();
            this.individualContingentSelected = this.contingentSelected[0];
            break;
          case 'normal':
            this.form.qualification = res.data.qualification_id;
            await this.requestGetGroups();
            this.form.group = res.data.group_id;
            await this.requestGetSubgroups();
            if (res.data.subgroup_id) {
              this.form.subgroup = res.data.subgroup_id;
              this.form.groupType = 'subgroup';
            }
            break;
          default:
            return true;
        }
        this.form.schedule = res.data.schedule_id;

        if (!res.data.schedule_id) {
          this.isScheduleSelected = false;
        }
      }
    },
    // запрос на получение опций групп
    async requestGetGroups() {
      this.options.groups = [];
      this.contingent = [];
      if (this.form.journalType === 'individual') {
        this.individualContingentSelected = null;
      }
      if (!this.uriGetExistingData) {
        this.form.group = null;
      }
      const res = await requestWrapper.call(this, {
        url: this.uriGetGroups,
        params: {
          quasar: true,
          qualification_id: this.form.qualification,
        },
      });

      if (!res.error) {
        this.options.groups = res.data;
        this.options.groupsFiltered = this.options.groups;
      }
    },
    async handleGroupInput() {
      if (!this.uriGetExistingData) {
        this.form.groupType = 'normal';
      }
      switch (this.form.journalType) {
        case 'normal':
          await this.requestGetSubgroups();
          await this.requestGetSchedules(true);
          break;
        case 'mixed':
        case 'personal':
          await this.requestGetContingent();
          break;
        case 'individual':
          this.contingentSelected = [];
          this.individualContingentSelected = null;
          await this.requestGetContingent();
          await this.requestGetSchedules(true);
          break;
      }
      return true;
    },
    // запрос на получение списка студентов
    async requestGetContingent() {
      this.loadingContingent = true;
      this.individualContingentSelected = null;
      const res = await requestWrapper.call(this, {
        url: this.uriGetContingent,
        params: {
          group_id: this.form.group,
        },
      });

      if (!res.error) {
        this.contingent = res.data;
        this.contingentFiltered = res.data;
      }
      this.loadingContingent = false;
    },
    // Запрос на получение подгрупп из группы
    async requestGetSubgroups() {
      this.options.subgroups = [];
      if (!this.uriGetExistingData) {
        this.form.subgroup = null;
      }
      const res = await requestWrapper.call(this, {
        url: this.uriGetSubgroups,
        params: {
          quasar: true,
          group_id: this.form.group,
        },
      });

      if (!res.error) {
        this.options.subgroups = res.data;
        this.options.subgroupsFiltered = this.options.subgroups;
      }
    },
    // запрос на отправку данных для сохранения изменений или создания журнала
    async requestAction() {
      this.loading = true;
      this.$globalLoading.show();
      let actionUrl = this.uriActions.normal;
      const additionalPersonnel = [];
      for (const el of this.form.additionalPersonnels) {
        additionalPersonnel.push(el.value);
      }
      let actionData = {
        name: this.form.name,
        type: this.form.type,
        group_id: this.form.group,
        qualification_id: this.form.qualification,
        type_of_evaluation: this.form.markType,
        personal_id: this.form.personnel,
        personal_ids: additionalPersonnel,
        group_type: this.form.groupType,
        journal_type: this.form.journalType,
        schedule_id: !!this.form.schedule && typeof this.form.schedule === 'object'
          ? this.form.schedule.value
          : this.form.schedule,
        ...this.form.hours,
      };

      if (this.form.isModule) {
        const moduleSubjects = [];
        const calendarThematicPlans = [];
        _.forEach(this.form.moduleSubjects, (val) => {
          moduleSubjects.push({
            education_module_discipline_id: val.value,
            hours: val.hours,
          });
        });
        _.forEach(this.form.calendarThematicPlans, (val) => {
          calendarThematicPlans.push(val.value);
        });

        actionData = {
          ...actionData,
          with_result: this.form.isResult,
          education_module_id: this.form.module,
          is_for_module: this.form.isModule,
          moduleSubjects,
          calendarThematicPlans,
        };
      } else {
        actionData = {
          ...actionData,
          discipline_id: this.form.subject.value,
          with_result: this.form.isResult,
          semesters: this.form.semester,
          calendarThematicPlans: this.form.calendarThematicPlans,
        };
      }

      if (this.form.groupType === 'subgroup') {
        actionData = { ...actionData, subgroup_id: this.form.subgroup };
      }
      if (this.form.journalType !== 'normal') {
        actionUrl = this.uriActions.individual;
        const contingents = [];
        for (const el of this.contingentSelected) {
          contingents.push(el.value);
        }
        actionData = {
          ...actionData,
          contingents,
        };
      }
      const res = await requestWrapper.call(this, {
        method: this.uriGetExistingData ? 'patch' : 'post',
        url: actionUrl,
        data: actionData,
      });

      if (res.error) {
        this.loading = false;
        this.$globalLoading.hide();
        if (res.data.errors && res.errorType === 422) {
          this.$notify({
            text: _.first(_.flatten(res.data.errors[_.first(Object.keys(res.data.errors))])) ?? this.trans('notice.error_on_server'),
            type: 'error',
          });
        } else if (res.data.message !== undefined) {
          this.$notify({
            text: res.data.message,
            type: 'error',
          });
        }
      }
    },
    // обработка текста в списке студентов при не выбранной или пустой группе
    studentsListLabel() {
      if (!this.form.group) {
        return this.trans('placeholder.please_select_group');
      }
      return this.trans('placeholder.group_without_students');
    },
    handleModuleCheckbox(val) {
      this.form.module = null;
      this.form.type = [];
      this.form.isResult = 0;
      if (val) {
        this.form.calendarThematicPlans = [];
      } else {
        this.form.calendarThematicPlans = null;
      }
      if (!this.uriGetExistingData) {
        this.form.subject = {
          value: null,
          label: this.trans('select.placeholder'),
        };
      }
    },
    isStudentSelected(id) {
      return Boolean(this.contingentSelected.find(item => item.value === id));
    },
  },
  watch: {
    contingentSearch(val) {
      if (val === '') {
        this.contingentFiltered = this.contingent;
        return;
      }

      const needle = val.toLowerCase();
      this.contingentFiltered = this.contingent.filter(item => _.lowerCase(item.label).indexOf(needle) > -1);
    },
    individualContingentSelected(newValue) {
      if (newValue !== null) {
        this.contingentSelected = [newValue];
      }
    },
  },
};
