import Vue from 'vue';
import store from './VueJS/store';
import VMonitoringList from '../../../javascript/VueJS/balabaqsha/views/Monitorings/VMonitoringList';



if (document.getElementById('v-monitoring-list-app')) {

  window.vMonitoringListApp = new Vue({
    el: '#v-monitoring-list-app',
    components: { VMonitoringList },
    store,
  });
}
