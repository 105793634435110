var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "max-width": "100%"
    }
  }, [_c('div', {
    staticClass: "s-steps s-steps--wider"
  }, [_c('div', {
    staticClass: "s-steps__area",
    class: {
      'is-active': _vm.currentStep >= 3
    }
  }, [_c('div', {
    staticClass: "s-steps__item"
  }, [_vm._m(0), _vm._v(" "), _c('div', {
    staticClass: "s-steps__item-label mt-15"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('label.account')) + "\n        ")])])]), _vm._v(" "), _c('div', {
    staticClass: "s-steps__area",
    class: {
      'is-active': _vm.currentStep >= 2
    }
  }, [_c('div', {
    staticClass: "s-steps__item"
  }, [_vm._m(1), _vm._v(" "), _c('div', {
    staticClass: "s-steps__item-label mt-15"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('label.password')) + "\n        ")])])]), _vm._v(" "), _c('div', {
    staticClass: "s-steps__area",
    class: {
      'is-active': _vm.currentStep >= 1
    }
  }, [_vm._m(2)])]), _vm._v(" "), _c('div', {
    staticClass: "row-sort-center"
  }, [_c('div', {
    staticClass: "s-panel s-panel--auth"
  }, [_c('q-form', {
    staticClass: "form__registration",
    attrs: {
      "autocorrect": "off",
      "autocapitalize": "off",
      "autocomplete": "off",
      "spellcheck": "false"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.nextStep($event);
      }
    }
  }, [_vm.currentStep === 1 ? [_c('q-input', {
    attrs: {
      "mask": "+7 (###) ###-##-##",
      "autocomplete": "off",
      "disable": _vm.sendCode,
      "placeholder": "+7 (___) ___-__-__",
      "error": _vm.hasValidationFail('phone'),
      "error-message": _vm.getValidationMessage('phone')
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('phone');
      }
    },
    model: {
      value: _vm.phone,
      callback: function callback($$v) {
        _vm.phone = $$v;
      },
      expression: "phone"
    }
  }), _vm._v(" "), _c('q-slide-transition', [_vm.sendCode ? _c('div', [_vm.seconds > 0 ? _c('span', {
    staticClass: "form-field__required"
  }, [_vm._v("\n                " + _vm._s(_vm.trans('auth.code_timer')) + " " + _vm._s(_vm.seconds) + "  " + _vm._s(_vm.trans('auth.seconds')) + "\n              ")]) : _c('a', {
    staticClass: "s-countdown-sms__restart",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.takeCode(false);
      }
    }
  }, [_vm._v("\n                " + _vm._s(_vm.trans('auth.send_again')) + "\n              ")]), _vm._v(" "), _vm.sendCode ? _c('q-input', {
    attrs: {
      "mask": "####",
      "label": _vm.trans('auth.sms_code'),
      "error": _vm.hasValidationFail('code'),
      "error-message": _vm.getValidationMessage('code')
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('code');
      }
    },
    model: {
      value: _vm.code,
      callback: function callback($$v) {
        _vm.code = $$v;
      },
      expression: "code"
    }
  }) : _vm._e()], 1) : _vm._e()]), _vm._v(" "), _c('q-checkbox', {
    staticClass: "form__checkbox--offerta mb-20",
    model: {
      value: _vm.checkOfferta,
      callback: function callback($$v) {
        _vm.checkOfferta = $$v;
      },
      expression: "checkOfferta"
    }
  }, [_c('label', [_vm._v("\n              " + _vm._s(_vm.trans('auth.offerta_text')) + "\n              "), _c('span', {
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.offerInfo();
      }
    }
  }, [_vm._v("\n                " + _vm._s(_vm.trans('auth.offerta_link')) + "\n              ")])])])] : _vm._e(), _vm._v(" "), _vm.currentStep === 2 ? [_c('v-form-inform', {
    attrs: {
      "message": _vm.trans('auth.phone.password_rule')
    }
  }), _vm._v(" "), _c('q-input', {
    attrs: {
      "type": "password",
      "debounce": "300",
      "rules": [function (val) {
        return val.length > 7 || _vm.trans('validation.min.string', {
          attribute: _vm.trans('label.login_password'),
          min: 8
        });
      }],
      "lazy-rules": "",
      "placeholder": _vm.trans('placeholder.new_password'),
      "label": _vm.trans('label.login_password'),
      "error": _vm.hasValidationFail('password'),
      "error-message": _vm.getValidationMessage('password')
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('password');
      }
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  }), _vm._v(" "), _c('q-input', {
    attrs: {
      "type": "password",
      "debounce": "300",
      "rules": [function (val) {
        return val.length > 7 || _vm.trans('validation.min.string', {
          attribute: _vm.trans('label.confirm_the_password'),
          min: 8
        });
      }],
      "lazy-rules": "",
      "placeholder": _vm.trans('placeholder.new_password'),
      "label": _vm.trans('label.confirm_the_password'),
      "error": _vm.hasValidationFail('password_confirmation'),
      "error-message": _vm.getValidationMessage('password_confirmation')
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('password_confirmation');
      }
    },
    model: {
      value: _vm.password_confirmation,
      callback: function callback($$v) {
        _vm.password_confirmation = $$v;
      },
      expression: "password_confirmation"
    }
  })] : _vm.currentStep === 3 ? [_c('q-input', {
    attrs: {
      "mask": "############",
      "placeholder": "____________",
      "rules": [function (val) {
        return !val || _vm.validateIin(val) || _vm.trans('validation.check_iin', {
          attribute: _vm.trans('label.iin')
        });
      }],
      "lazy-rules": "",
      "label": _vm.trans('label.iin'),
      "error": _vm.hasValidationFail('iin'),
      "error-message": _vm.getValidationMessage('iin')
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('iin');
      }
    },
    model: {
      value: _vm.iin,
      callback: function callback($$v) {
        _vm.iin = $$v;
      },
      expression: "iin"
    }
  }), _vm._v(" "), false ? _c('q-checkbox', {
    attrs: {
      "label": _vm.trans('label.kz_resident')
    },
    on: {
      "input": function input($event) {
        _vm.show = $event;
      }
    },
    model: {
      value: _vm.is_resident,
      callback: function callback($$v) {
        _vm.is_resident = $$v;
      },
      expression: "is_resident"
    }
  }) : _vm._e(), _vm._v(" "), _vm.show ? [_c('q-input', {
    attrs: {
      "filled": "",
      "disable": "",
      "label": _vm.trans('label.firstname')
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  }), _vm._v(" "), _c('q-input', {
    attrs: {
      "filled": "",
      "disable": "",
      "label": _vm.trans('label.surname')
    },
    model: {
      value: _vm.surname,
      callback: function callback($$v) {
        _vm.surname = $$v;
      },
      expression: "surname"
    }
  }), _vm._v(" "), _c('q-input', {
    attrs: {
      "filled": "",
      "disable": "",
      "label": _vm.trans('label.lastname')
    },
    model: {
      value: _vm.lastname,
      callback: function callback($$v) {
        _vm.lastname = $$v;
      },
      expression: "lastname"
    }
  }), _vm._v(" "), _c('q-field', {
    attrs: {
      "label": _vm.trans('label.gender'),
      "required": "",
      "borderless": "",
      "error": _vm.hasValidationFail('gender'),
      "error-message": _vm.getValidationMessage('gender')
    }
  }, [_c('q-option-group', {
    staticClass: "d-flex",
    attrs: {
      "disable": _vm.isGenderFieldDisabled,
      "inline": "",
      "options": _vm.genderOptions
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('gender');
      }
    },
    model: {
      value: _vm.gender,
      callback: function callback($$v) {
        _vm.gender = $$v;
      },
      expression: "gender"
    }
  })], 1), _vm._v(" "), _c('q-input', {
    attrs: {
      "filled": "",
      "disable": "",
      "label": _vm.trans('label.born_date')
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('q-icon', {
          staticClass: "sn--cursor-pointer",
          attrs: {
            "name": "calendar"
          }
        })];
      },
      proxy: true
    }], null, false, 3824145385),
    model: {
      value: _vm.time,
      callback: function callback($$v) {
        _vm.time = $$v;
      },
      expression: "time"
    }
  })] : _vm._e()] : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "mt-20"
  }, [!_vm.sendCode ? _c('div', [_c('q-btn', {
    attrs: {
      "color": "green",
      "no-caps": "",
      "disable": _vm.checkCodeButtonDisabled
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.takeCode(true);
      }
    }
  }, [_vm._v("\n              " + _vm._s(_vm.trans('button.request_code')) + "\n            ")])], 1) : _c('div', [_c('q-btn', {
    attrs: {
      "color": "green",
      "no-caps": "",
      "type": "submit",
      "disable": _vm.checkDisabled
    }
  }, [_vm.currentStep === 2 ? _c('span', [_vm._v(" " + _vm._s(_vm.trans('button.save')) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.trans('button.send')) + " ")])])], 1)])], 2)], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-steps__item-icon"
  }, [_c('div', {
    staticClass: "s-steps__chain-line"
  }), _vm._v(" "), _c('span', {
    staticClass: "s-ico s-ico--thm-id-badge"
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-steps__item-icon"
  }, [_c('div', {
    staticClass: "s-steps__chain-line"
  }), _vm._v(" "), _c('span', {
    staticClass: "s-ico s-ico--thm-lock"
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-steps__item"
  }, [_c('div', {
    staticClass: "s-steps__item-icon"
  }, [_c('span', {
    staticClass: "s-ico s-ico--thm-mobile"
  })]), _vm._v(" "), _c('div', {
    staticClass: "s-steps__item-label mt-15"
  }, [_vm._v("\n          SMS\n        ")])]);
}]

export { render, staticRenderFns }