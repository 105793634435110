var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-doc-flow-form"
  }, [_c('v-form-inform', {
    attrs: {
      "message": _vm.trans('notice.document_will_be_sent_to_responsible_person') + '.'
    }
  }), _vm._v(" "), _c('v-form-inform', {
    attrs: {
      "message": _vm.trans('warning_text.document_flow_files')
    }
  }), _vm._v(" "), _c('q-form', {
    on: {
      "submit": _vm.requestAction
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 col-lg-6"
  }, [_c('q-input', {
    attrs: {
      "label": _vm.letters ? _vm.trans('workflow.letter.subject') : _vm.trans('workflow.order.name'),
      "required": "",
      "error": _vm.hasValidationFail('name'),
      "error-message": _vm.getValidationMessage('name'),
      "readonly": _vm.loading
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('name');
      }
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-12 col-lg-3 col-md-6 col-sm-6"
  }, [_c('q-input', {
    attrs: {
      "filled": "",
      "mask": "##.##.####",
      "readonly": "",
      "label": _vm.trans('workflow.order.date'),
      "required": "",
      "error": _vm.hasValidationFail('date'),
      "error-message": _vm.getValidationMessage('date')
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('date');
      },
      "click": function click() {
        return _vm.$refs.qDateActiveProxy.show();
      }
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('q-icon', {
          staticClass: "sn--cursor-pointer",
          attrs: {
            "name": "calendar"
          }
        }, [_c('q-popup-proxy', {
          ref: "qDateActiveProxy",
          attrs: {
            "transition-show": "scale",
            "transition-hide": "scale"
          }
        }, [_c('q-date', {
          attrs: {
            "mask": "DD.MM.YYYY",
            "color": "green"
          },
          on: {
            "input": function input() {
              return _vm.$refs.qDateActiveProxy.hide();
            }
          },
          model: {
            value: _vm.form.date,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "date", $$v);
            },
            expression: "form.date"
          }
        })], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.date,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "date", $$v);
      },
      expression: "form.date"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-12 col-lg-3 col-md-6 col-sm-6"
  }, [_c('q-input', {
    attrs: {
      "label": _vm.letters ? _vm.trans('workflow.letter.number') : _vm.trans('workflow.order.number'),
      "required": "",
      "error": _vm.hasValidationFail('doc_number'),
      "error-message": _vm.getValidationMessage('doc_number'),
      "readonly": _vm.loading
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('doc_number');
      }
    },
    model: {
      value: _vm.form.docNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "docNumber", $$v);
      },
      expression: "form.docNumber"
    }
  })], 1)]), _vm._v(" "), _c('div', {
    staticClass: "row mb-20 align-items-stretch"
  }, [_c('div', {
    staticClass: "col-12 col-lg-8 s-doc-flow-form__description"
  }, [_c('q-input', {
    attrs: {
      "label": _vm.letters ? _vm.trans('workflow.letter.body') : _vm.trans('workflow.order.body'),
      "filled": "",
      "type": "textarea",
      "required": "",
      "counter": "",
      "maxlength": 30000,
      "error": _vm.hasValidationFail('body'),
      "error-message": _vm.getValidationMessage('body'),
      "readonly": _vm.loading
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('body');
      }
    },
    model: {
      value: _vm.form.body,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "body", $$v);
      },
      expression: "form.body"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-12 col-lg-4 s-w-100 s-doc-flow-form__organizations"
  }, [_c('div', {
    staticClass: "s-form__label"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('label.select_college')) + ": "), _c('i', [_vm._v("*")])]), _vm._v(" "), _c('div', {
    staticClass: "s-doc-flow-form__organizations-inner s-w-100"
  }, [_c('div', {
    staticClass: "s-doc-flow-form__organizations-header s-w-100"
  }, [_c('div', {
    staticClass: "row mt-10 align-items-center s-w-100",
    staticStyle: {
      "justify-content": "space-between"
    }
  }, [_c('div', [_c('q-checkbox', {
    attrs: {
      "label": _vm.trans('label.select_all'),
      "disable": _vm.loadingOrganizations || _vm.loading
    },
    on: {
      "input": _vm.checkAll
    },
    model: {
      value: _vm.checkAllOrgs,
      callback: function callback($$v) {
        _vm.checkAllOrgs = $$v;
      },
      expression: "checkAllOrgs"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-4 s-to-right label-clear-adaptive"
  }, [_c('span', {
    staticClass: "s-c-red s-medium s-u-hover-dashed s-to-right",
    class: _vm.loadingOrganizations || _vm.loading ? 'sn--cursor-wait' : 'sn--cursor-pointer',
    on: {
      "click": _vm.clearOrganizations
    }
  }, [_vm._v("\n                  " + _vm._s(_vm.trans('label.clear')) + "\n                ")])])]), _vm._v(" "), _c('q-input', {
    attrs: {
      "placeholder": _vm.trans('label.search_by_organizations'),
      "icon": "search",
      "readonly": _vm.loadingOrganizations || _vm.loading
    },
    on: {
      "keypress": function keypress($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.handleSearch($event);
      },
      "input": _vm.debounceSearch
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('q-icon', {
          attrs: {
            "name": "search"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.searchValue,
      callback: function callback($$v) {
        _vm.searchValue = $$v;
      },
      expression: "searchValue"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "s-doc-flow-form__organizations-list"
  }, [_vm.loadingOrganizations ? _c('q-spinner-gears', {
    staticClass: "mt-20 ml-40 sn--cursor-wait",
    attrs: {
      "color": "blue",
      "size": "20rem"
    }
  }) : _vm._l(_vm.organizationsFiltered, function (organization, key) {
    return _c('q-checkbox', {
      key: key,
      staticClass: "mb-5 pr-5 s-w-100",
      attrs: {
        "val": organization.id,
        "label": organization.name,
        "disable": _vm.loading
      },
      model: {
        value: _vm.form.organizationsSelected,
        callback: function callback($$v) {
          _vm.$set(_vm.form, "organizationsSelected", $$v);
        },
        expression: "form.organizationsSelected"
      }
    });
  })], 2)]), _vm._v(" "), _c('q-input', {
    staticClass: "s-doc-flow-form__organizations-validation",
    attrs: {
      "borderless": "",
      "error": _vm.hasValidationFail('organizations'),
      "error-message": _vm.getValidationMessage('organizations')
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('organizations');
      }
    }
  })], 1)]), _vm._v(" "), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 col-lg-8 mb-10"
  }, [_c('div', {
    staticClass: "s-form__label"
  }, [_vm._v("\n          " + _vm._s(_vm.letters ? _vm.trans('workflow.letter.attach_letter_document') : _vm.trans('workflow.order.attach_order_document')) + ": "), _c('i', [_vm._v(_vm._s(_vm.letters ? '' : '*'))])])])]), _vm._v(" "), _c('div', {
    staticClass: "row-sort-between"
  }, [_c('div', {
    staticClass: " s-doc-flow-form__files s-doc-flow-form__button_adaptive_helper",
    class: _vm.hasValidationFail('files') ? '' : 'mb-15'
  }, _vm._l(_vm.form.files, function (item, key) {
    return _c('div', {
      key: key
    }, [!item.user_file_id ? _c('q-btn', {
      staticClass: "s-btn--w-200  col-sm-5 s-doc-btn-file-helper",
      attrs: {
        "color": "blue",
        "icon": "download",
        "label": _vm.trans('button.upload'),
        "label-wider": "",
        "type": "button",
        "no-caps": "",
        "loading": _vm.loading
      },
      on: {
        "click": function click($event) {
          return _vm.openSelectFile(item);
        }
      },
      model: {
        value: item['file' + key + 1],
        callback: function callback($$v) {
          _vm.$set(item, 'file' + key + 1, $$v);
        },
        expression: "item['file' + key + 1]"
      }
    }) : _c('q-btn', {
      staticClass: "s-btn--w-200 s-btn--border  col-sm-5 s-doc-btn-file-helper",
      attrs: {
        "icon": "close",
        "label": _vm.setFileName(item.user_file_name),
        "label-wider": "",
        "type": "button",
        "loading": _vm.loading
      },
      on: {
        "click": function click($event) {
          return _vm.clearFile(key);
        }
      },
      model: {
        value: item['file' + key + 1],
        callback: function callback($$v) {
          _vm.$set(item, 'file' + key + 1, $$v);
        },
        expression: "item['file' + key + 1]"
      }
    })], 1);
  }), 0), _vm._v(" "), _vm.hasValidationFail('files') ? _c('div', {
    staticClass: "row mb-15 s-w-100"
  }, [_c('div', {
    staticClass: "col-12 col-lg-8 sn-field__bottom"
  }, [_c('div', {
    staticClass: "s-form__error-message"
  }, [_vm._v("\n            " + _vm._s(_vm.getValidationMessage('files')) + "\n          ")])])]) : _vm._e(), _vm._v(" "), _c('q-btn', {
    staticClass: "s-btn--w-200 s-doc-flow-form__button_adaptive_helper ",
    attrs: {
      "color": "green",
      "label": _vm.trans(_vm.sendLabel),
      "no-caps": "",
      "type": "submit",
      "disable": !_vm.validate,
      "loading": _vm.loading
    }
  })], 1)]), _vm._v(" "), _c('select-user-file', {
    attrs: {
      "open-modal": _vm.openModalFiles,
      "url-files": _vm.uriUserFiles,
      "url-file-store": _vm.uriUserFiles,
      "validate-inform": _vm.trans('warning_text.files_supported')
    },
    on: {
      "input": _vm.selectFile,
      "select-close": _vm.onSelectClose
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }