var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-50 s-w-100 sc-journal__legend"
  }, [_c('div', {
    on: {
      "click": function click($event) {
        _vm.spoiler = !_vm.spoiler;
      }
    }
  }, [_c('div', {
    staticClass: "sc-journal__legend--title"
  }, [_c('div', {
    staticClass: "sc-journal__legend--label s-upper"
  }, [_vm._v("\n        " + _vm._s(_vm.trans('label.colors_title')) + "\n      ")]), _vm._v(" "), _c('div', {
    staticClass: "s-combine-title__line"
  }, [_c('div', {
    staticClass: "sc-journal__legend--button"
  }, [_c('span', {
    staticClass: "s-ico",
    class: "s-ico--thm-angle-double-".concat(_vm.spoiler ? 'up' : 'down')
  }), _vm._v(" "), _c('span', {
    staticClass: "sc-journal__legend--button-text"
  }, [_vm._v("\n            " + _vm._s(_vm.trans("button.".concat(_vm.spoiler ? 'hide_spoiler' : 'show_spoiler'))) + "\n          ")])])])])]), _vm._v(" "), _c('q-slide-transition', [_vm.spoiler ? _c('div', {
    staticClass: "sc-journal__legend--wrapper"
  }, [_vm._l(_vm.computedColorsArr, function (colorGroup, groupKey) {
    return [_vm._l(colorGroup, function (color, key) {
      return [_c('div', {
        key: "".concat(groupKey, "-").concat(key),
        staticClass: "row no-gutters sc-journal__legend--cell",
        class: {
          active: (groupKey + 1) % 2 !== 0
        }
      }, [_c('div', {
        staticClass: "col-2"
      }, [_c('div', {
        staticClass: "sc-journal__legend--color-squire",
        staticStyle: {
          "margin-right": "1rem"
        },
        style: "background-color: ".concat(color.name)
      })]), _vm._v(" "), _c('div', {
        staticClass: "col-10"
      }, [_c('span', [_vm._v("\n                " + _vm._s(color.label) + "\n              ")])])])];
    })];
  })], 2) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }