<template>
  <div class="sc-curriculum-program__table">
    <table-filters />
    <div class="sc-curriculum-program__table--scroll">
      <table class="s-w-100" cellspacing="0">
        <thead class="sc-curriculum-program__table--head">
          <tr
            v-for="(row, rowIndex) in rows"
            :key="`th-row${rowIndex}`"
            class="sc-curriculum-program__table--tr"
          >
            <th
              v-for="(col, colIndex) in row"
              :key="`th-col${colIndex}`"
              class="sc-curriculum-program__table--th"
              :class="col.class"
              :rowspan="col.rowspan"
              :colspan="col.colspan"
            >
              {{ col.label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(chapter, chKey) in items">
            <tr
              :key="`chapter-${chKey}`"
              class="sc-curriculum-program__table--tr sc-curriculum-program__table--chapter"
            >
              <td
                class="sc-curriculum-program__table--td"
                v-html="chapterName(chKey, chapter)"
              />
              <template v-for="(column, cKey) in cols">
                <table-data
                  v-if="chapter.lessons.length > 0"
                  :key="`td-col${cKey}`"
                  :column="column"
                  :lesson="chapter.lessons[0]"
                />
                <td
                  v-else-if="!['chapter_name', 'actions'].includes(column.attribute)"
                  :key="`td-col${cKey}`"
                  class="sc-curriculum-program__table--td"
                />
                <td
                  v-if="column.attribute === 'actions'"
                  :key="cKey"
                  class="s-to-center"
                >
                  <div
                    class="s-group s-group--to-center"
                  >
                    <q-btn
                      v-for="(action, key) in chapter.actions"
                      :key="key"
                      :color="action.color"
                      :to="action.to"
                      :icon="action.icon"
                      :label="action.label"
                      :outline="action.outline"
                      :width="action.width"
                      :target="action.target"
                      squire
                      no-caps
                      :type="action.type"
                      @click="handleModal(action, chapter)"
                    />
                  </div>
                </td>
              </template>

            </tr>
            <template v-for="(lesson, key) in chapter.lessons">
              <tr
                v-if="key !== 0"
                :key="`td-row${key}`"
                class="sc-curriculum-program__table--tr"
              >
                <td class="sc-curriculum-program__table--td" />
                <template v-for="(column, cKey) in cols">
                  <table-data
                    :key="`td-col${cKey}`"
                    :column="column"
                    :lesson="lesson"
                  />
                </template>
              </tr>
            </template>
          </template>
          <tr
            class="sc-curriculum-program__table--tr sc-curriculum-program__table--chapter"
          >
            <td class="sc-curriculum-program__table--td">
              {{ trans('curriculum.discipline_group_total') }}
            </td>
            <td class="sc-curriculum-program__table--td" />
            <td class="sc-curriculum-program__table--td">
              {{ total() }}
            </td>
            <template v-for="(column, cKey) in cols">
              <td
                v-if="!['lessons_name', 'chapter_name', 'total_hours', 'actions'].includes(column.attribute)"
                :key="`td-totals${cKey}`"
                :data-table-head="column.label"
                class="sc-curriculum-program__table--td"
              >
                {{ calculateTotals(column.attribute) }}
              </td>
            </template>
            <td class="sc-curriculum-program__table--td" />
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { QBtn } from '@quasar/components';
import { requestWrapper } from '@vjs/helpers';
import { Helper } from '@common/src/helpers';
import { eventBus } from '@common/mixins';
import helper from '../../../mixins/helper';
import TableData from './TableData';
import TableFilters from '../Buttons/TableFilters';
import _ from "lodash";

export default {
  name: 'ProgramContentTable',
  components: {
    QBtn,
    TableData,
    TableFilters,
  },
  mixins: [helper],
  // data() {
  //   return {
  //     uriConfirm: null,
  //     modalMethod: null,
  //   };
  // },
  computed: {
    rows() {
      return this.$store.getters.content.meta.rows;
    },
    items() {
      return this.$store.getters.content.items;
    },
    cols() {
      let columns = [];
      for (const row in this.rows) {
        for (const column in this.rows[row]) {
          if (this.rows[row][column].hasOwnProperty('attribute') && !columns.includes(this.rows[row][column])) {
            columns = columns.concat(this.rows[row][column]);
          }
        }
      }
      return columns.sort((a, b) => a.order - b.order);
    },
  },
  methods: {
    handleModal(action) {
      if (action.type === 'button') {
        if (action.modal === 's-modal' && action['s-modal-config']) {
          const config = action['s-modal-config'];
          const binds = config?.binds ?? {};
          const events = {};
          if (action['s-modal-config'].component === 'v-modal-confirm-new') {
            events.onApprove = async () => { await this.requestConfirmModal(); };
          }
          this.$sModal.open(
            action['s-modal-config'].component,
            { ...action['s-modal-config'], binds, events },
          );
        }
      }
    },
    async requestConfirmModal() {
      this.$emit('toggleLoaders', true);
      await this.$store.dispatch('GET_CURRICULUM_PROGRAM_CONTENT_TABLE');
      this.$emit('toggleLoaders');
    },
  },
};
</script>
