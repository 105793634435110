var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('table', {
    staticClass: "s-table-cabinet"
  }, [_c('thead', {
    staticClass: "s-table__head"
  }, _vm._l(_vm.rows, function (row, index) {
    return _c('tr', {
      key: index
    }, [index === 0 ? _c('th', {
      staticClass: "s-to-center s-table__col-1",
      attrs: {
        "rowspan": "3"
      }
    }, [_vm._v("\n        №\n      ")]) : _vm._e(), _vm._v(" "), _vm._l(row, function (column, columnIndex) {
      return _c('th', {
        key: columnIndex,
        class: [column.class ? column.class : ''],
        attrs: {
          "rowspan": column.rowspan,
          "colspan": column.colspan
        }
      }, [_vm._v("\n        " + _vm._s(column.label) + "\n      ")]);
    }), _vm._v(" "), index === 0 ? _c('th', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.hideActions,
        expression: "hideActions"
      }],
      staticClass: "s-to-center",
      class: _vm.actionsClass,
      attrs: {
        "rowspan": 3
      }
    }, [_vm._v("\n        " + _vm._s(_vm.trans('table.operations')) + "\n      ")]) : _vm._e()], 2);
  }), 0), _vm._v(" "), _c('tbody', {
    staticClass: "s-table__body"
  }, [_vm._l(_vm.items, function (item, index) {
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticClass: "s-to-center"
    }, [_vm._v("\n        " + _vm._s(index !== _vm.items.length - 1 ? _vm.itemIndexNumber(index) : '') + "\n      ")]), _vm._v(" "), _vm._l(_vm.columns, function (column, columnIndex) {
      return _c('td', {
        key: columnIndex,
        class: column.cellClass,
        attrs: {
          "data-table-head": column.label
        }
      }, [_vm._v("\n        " + _vm._s(_vm.getAttribute(item, column.attribute)) + "\n      ")]);
    }), _vm._v(" "), _c('td', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.hideActions,
        expression: "hideActions"
      }],
      staticClass: "s-to-center",
      attrs: {
        "data-table-head": _vm.trans('table.operations')
      }
    }, [item.actions ? _c('div', {
      staticClass: "s-group s-group--to-center",
      class: _vm.operationsWrapperClass
    }, [_vm._l(item.actions, function (action, actionIndex) {
      return [action.component ? _c(action.component, _vm._b({
        key: actionIndex,
        tag: "component"
      }, 'component', action.binds, false)) : _c('a', {
        key: actionIndex,
        staticClass: "s-btn-table s-btn--ico",
        attrs: {
          "href": action.link,
          "title": action.title
        }
      }, [action.icon ? _c('span', {
        staticClass: "s-ico",
        class: action.icon.classes
      }) : _vm._e(), _vm._v("\n              " + _vm._s(action.label ? action.label : '') + "\n            ")])];
    })], 2) : _vm._e()])], 2);
  }), _vm._v(" "), _vm.rowTotal ? _c('tr', [_c('td'), _vm._v(" "), _vm._l(_vm.columns, function (column) {
    return _c('td', {
      class: column.cellClass,
      attrs: {
        "data-table-head": column.label
      }
    }, [_vm._v("\n        " + _vm._s(_vm.getAttribute(_vm.rowTotal, column.attribute)) + "\n      ")]);
  }), _vm._v(" "), _c('td', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.hideActions,
      expression: "hideActions"
    }],
    staticClass: "s-to-center",
    attrs: {
      "data-table-head": _vm.trans('table.operations')
    }
  })], 2) : _vm._e()], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }