var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.teachers.length ? _c('div', [_c('div', {
    staticClass: "s-b-white contest__table"
  }, [_c('div', {
    staticClass: "s-contest__table--head"
  }, [_c('div', {
    staticClass: "s-contest__table--row",
    class: !_vm.isPersonnelContest ? 's-contest__table--college' : ''
  }, [_c('div', {
    staticClass: "s-table__col--to-center"
  }, [_vm._v("\n            " + _vm._s(_vm.trans('contest.place')) + "\n          ")]), _vm._v(" "), _vm._l(_vm.columns, function (column) {
    return _c('div', {
      class: column.class
    }, [_vm._v("\n            " + _vm._s(column.label) + "\n          ")]);
  })], 2)]), _vm._v(" "), _c('div', {
    staticClass: "s-contest__table--body"
  }, _vm._l(_vm.teachers, function (item, index) {
    return _c('div', {
      staticClass: "s-contest__table--row s-contest__table--border",
      class: !_vm.isPersonnelContest ? 's-contest__table--college' : ''
    }, [_c('div', {
      staticClass: "s-contest__table--data s-table__col--to-center"
    }, [[1, 2, 3].includes(item.place) && _vm.isPersonnelContest || item.place === 1 ? _c('img', {
      attrs: {
        "src": "/images/medal_".concat(item.place, ".svg")
      }
    }) : _c('span', [_vm._v(" " + _vm._s(item.place) + " ")])]), _vm._v(" "), _vm._l(_vm.columns, function (column) {
      return _c('div', {
        staticClass: "s-contest__table--data",
        class: column.cellClass,
        attrs: {
          "data-table-head": column.label
        }
      }, [_c('span', {
        staticClass: "page-link",
        attrs: {
          "aria-hidden": "true"
        },
        domProps: {
          "innerHTML": _vm._s(_vm.getAttribute(item, column.attribute))
        }
      })]);
    })], 2);
  }), 0)]), _vm._v(" "), _c('ul', {
    staticClass: "pagination mt-50 mb-50",
    attrs: {
      "role": "navigation"
    }
  }, [_vm._l(_vm.pagination.links, function (link, index) {
    return [link.disabled || link.link === null ? [_c('li', {
      key: index,
      staticClass: "page-item disabled",
      attrs: {
        "aria-disabled": "true"
      }
    }, [_c('span', {
      staticClass: "page-link",
      attrs: {
        "aria-hidden": "true"
      },
      domProps: {
        "innerHTML": _vm._s(link.text)
      }
    })])] : link.current ? [_c('li', {
      key: index,
      staticClass: "page-item is-active",
      attrs: {
        "aria-current": "page"
      }
    }, [_c('span', {
      staticClass: "page-link",
      domProps: {
        "innerHTML": _vm._s(link.text)
      }
    })])] : [_c('li', {
      key: index,
      staticClass: "page-item"
    }, [_c('a', {
      staticClass: "page-link",
      attrs: {
        "href": link.link
      },
      domProps: {
        "innerHTML": _vm._s(link.text)
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.requestGetContestResults(link.link);
        }
      }
    })])]];
  })], 2)]) : _c('v-no-results', {
    attrs: {
      "image": "search",
      "title": _vm.trans('warning_text.no_data_title'),
      "text": _vm.trans('warning_text.no_data_label')
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }