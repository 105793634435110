var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row align-items-center mb-20"
  }, [_c('v-wrapper-q-select', {
    class: {
      'col-lg-2': _vm.$q.screen.width > 1110,
      'col-md-3': _vm.$q.screen.width < 1110 && _vm.$q.screen.width > 710,
      'col-md-6': _vm.$q.screen.width < 710 && _vm.$q.screen.width > 510,
      'col-sm-12': _vm.$q.screen.width < 510
    },
    attrs: {
      "options": _vm.disciplines,
      "extra-binds": {
        hideBottomSpace: true
      }
    },
    on: {
      "input": _vm.updateTable
    },
    model: {
      value: _vm.discipline,
      callback: function callback($$v) {
        _vm.discipline = $$v;
      },
      expression: "discipline"
    }
  }), _vm._v(" "), _c('div', {
    class: {
      'col-lg-2': _vm.$q.screen.width > 1110,
      'col-md-3': _vm.$q.screen.width < 1110 && _vm.$q.screen.width > 710,
      'col-md-6': _vm.$q.screen.width < 710 && _vm.$q.screen.width > 510,
      'col-sm-12': _vm.$q.screen.width < 510
    }
  }, [_c('q-btn-dropdown', {
    staticClass: "s-w-100",
    attrs: {
      "color": "blue",
      "label": _vm.trans('button.add'),
      "menu-anchor": "bottom right"
    }
  }, [_c('q-list', _vm._l(_vm.buttons, function (item, key) {
    return _c('q-item', {
      key: key,
      attrs: {
        "clickable": ""
      },
      on: {
        "click": function click($event) {
          return _vm.handleAction(item.value);
        }
      }
    }, [_c('q-item-section', [_c('q-item-label', [_vm._v(" " + _vm._s(item.label) + " ")])], 1)], 1);
  }), 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }