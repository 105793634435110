var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('q-form', {
    attrs: {
      "autocorrect": "off",
      "autocapitalize": "off",
      "autocomplete": "off",
      "spellcheck": "false"
    },
    on: {
      "submit": _vm.approve
    }
  }, [_c('q-input', {
    staticClass: "mb-30",
    attrs: {
      "label": _vm.trans("label.type_message"),
      "filled": "",
      "type": "textarea",
      "required": "",
      "counter": "",
      "maxlength": 1000
    },
    model: {
      value: _vm.message,
      callback: function callback($$v) {
        _vm.message = $$v;
      },
      expression: "message"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "row-sort-between-btn"
  }, [_c('q-btn', {
    attrs: {
      "no-caps": "",
      "width": "15",
      "color": "grey",
      "label": _vm.trans('button.cancel')
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('onClose');
      }
    }
  }), _vm._v(" "), _c('q-btn', {
    attrs: {
      "no-caps": "",
      "width": "15",
      "color": "green",
      "disable": !_vm.message.length,
      "type": "submit",
      "label": _vm.trans('button.approve')
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }