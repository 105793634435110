import { Form, SelectUserFileHandler } from '@common/mixins';
import { Helper } from '@common/src/helpers';
import _ from 'lodash';
import { QForm, QSlideTransition } from '@quasar/components';
import { requestWrapper } from '@vjs/helpers';


export default {
  mixins: [Form, SelectUserFileHandler],
  name: 'ModalJournalEditPoint',
  components: { QForm, QSlideTransition },
  props: {
    resource: {
      type: Object,
      required: true,
    },
    strictPointEdit: {
      type: Boolean,
      default: false,
    },
    canCorrectAbsence: {
      type: Boolean,
      default: false,
    },
    isUncertifiable: {
      type: Boolean,
      default: false,
    },
    isReleasable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const vm = this;
    const fields = {
      contingentId: { name: 'contingentId' },
      dayId: { name: 'dayId' },
      absentedCorrectionUserFileId: {
        value: [],
        name: 'absentedCorrectionUserFileId',
        component: 'select-user-file-field',
        labelTransKey: 'files.contingent_files.certificate_of_illness',
        v_if: this.canCorrectAbsence,
        required: this.strictPointEdit,
      },
      absented: {
        value: false,
        name: 'absented',
        component: 'q-checkbox-field',
        checkboxLabelTransKey: 'label.absented',
        v_if: !this.canCorrectAbsence,
        class: 'd-inline-block',
        events: {
          input: () => {
            if (vm.fields.absented.value) {
              vm.fields.released.value = false;
              vm.fields.uncertified.value = false;
            }
          },
        },
      },
      uncertified: {
        value: false,
        name: 'uncertified',
        component: 'q-checkbox-field',
        checkboxLabelTransKey: 'label.uncertified',
        class: 'd-inline-block',
        v_if: vm.isUncertifiable,
        events: {
          input: () => {
            if (vm.fields.uncertified.value) {
              vm.fields.released.value = false;
              vm.fields.absented.value = false;
              vm.fields.point.value = vm.fields.point.component === 'q-radio-field' ? 2 : 0;
            }
          },
        },
      },
      released: {
        value: false,
        name: 'released',
        component: 'q-checkbox-field',
        checkboxLabelTransKey: 'label.released',
        class: 'd-inline-block',
        v_if: vm.isReleasable,
        events: {
          input: () => {
            if (vm.fields.released.value) {
              vm.fields.uncertified.value = false;
              vm.fields.absented.value = false;
              vm.fields.point.value = vm.fields.point.component === 'q-radio-field' ? 5 : 100;
            }
          },
        },
      },
      point: { value: null },
    };

    _.forEach(vm.resource, (value, attribute) => {
      if (Object.prototype.hasOwnProperty.call(fields, attribute)) {
        if (attribute === 'absented' && this.canCorrectAbsence) {
          return;
        }
        _.set(fields, `${attribute}.value`, value);
      }
    });

    return {
      fields,
    };
  },

  async mounted() {
    await this.$trans.add('files');
    this.fields.point = { ...this.fields.point, ...this.pointField };
  },

  computed: {
    pointField() {
      let component;
      let labelTransKey = 'label.your_rate';
      switch (this.typeOfEvaluation) {
        case 'percent': component = 'number-field'; break;
        case 'credit': component = 'q-radio-field'; labelTransKey = 'label.result'; break;
        default: component = 'q-radio-field'; break;
      }
      return {
        v_if(vm) {
          if (vm.fields.uncertified.value || vm.fields.released.value) {
            return false;
          }
          return (!vm.fields.absented.value && !vm.canCorrectAbsence)
            || vm.resource.absented;
        },
        component,
        labelTransKey,
        name: 'point',
        options: this.options,
        required: true,
        inline: true,
        config: {
          mask: ['99', '999'],
          greedy: false,
          min: 0,
          max: 100,
          placeholder: '',
        },
      };
    },
    options() {
      const { typeOfEvaluation, trans } = this;
      if (typeOfEvaluation === 'number') {
        return Array(4).fill().map((value, index) => ({
          value: index + 2,
          label: String(index + 2),
        }));
      }
      return [
        { label: trans('journal.point.failed'), value: 0 },
        { label: trans('journal.point.passed'), value: 100 },
      ];
    },
    typeOfEvaluation() { return this.$store.getters.typeOfEvaluation; },
    apiConfig() { return this.$store.getters.apiConfig; },
    validate() {
      if (this.fields.uncertified.value || this.fields.released.value) {
        return true;
      }
      if (this.strictPointEdit && this.resource.absented) {
        return this.fields.absentedCorrectionUserFileId.value.length
          && this.fields.point.value !== null
          && this.fields.point.value !== -1;
      }
      return this.resource.absented
        || (this.fields.point.value !== null && this.fields.point.value !== -1)
        || this.fields.absented.value;
    },
    validateDelete() {
      if (this.fields.uncertified.value || this.fields.released.value) {
        return true;
      }
      if (this.strictPointEdit && this.resource.absented) {
        return this.fields.absentedCorrectionUserFileId.value.length;
      }
      return (
         this.resource.absented
          || !this.canCorrectAbsence
      )
        && this.resource?.point !== null
        && this.resource?.point !== undefined;
    },
    deletePointComponent() {
      return this.canCorrectAbsence && !this.resource.absented
        ? 'v-modal-confirm'
        : 'v-modal-confirm-with-message';
    },
  },

  methods: {
    async confirmDelete(reason) {
      this.$globalLoading.show();
      const { dayId, contingentId } = this.resource;
      const url = Helper.urlGenerate(
        this.apiConfig.point.destroy,
        { dayId, contingentId },
      );

      const data = { reason };
      if (this.fields.absentedCorrectionUserFileId.value?.length) {
        data.absentedCorrectionUserFileId = this.fields.absentedCorrectionUserFileId.value[0]?.id;
      }

      const res = await requestWrapper.call(this, {
        url,
        method: 'delete',
        data,
      });

      if (!res.error) {
        this.$store.commit('DELETE_POINT_FROM_DAY', { dayId, contingentId });
        this.$emit('onCloseAll');
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }

      this.$globalLoading.hide();
    },
    async handleAjaxFormSubmit() {
      this.$globalLoading.show();

      const data = {};
      _.forEach(this.fields, (el) => {
        if (el.name === 'absentedCorrectionUserFileId') {
          _.set(data, this.nameToDot(el.name), el.value[0]?.id);
        } else {
          _.set(data, this.nameToDot(el.name), el.value);
        }
      });

      const { apiConfig, makeJournalUrl, journalId } = this.$store.getters;
      const res = await requestWrapper.call(this, {
        url: makeJournalUrl(apiConfig.point.store, journalId),
        method: 'post',
        data,
      });

      if (!res.error) {
        const { currentFilter, isModule } = this.$store.getters;
        await this.$store.dispatch(
          'GET_JOURNAL', { [isModule ? 'discipline_id' : 'semester']: currentFilter },
        );
        this.$emit('onClose');
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }

      this.$globalLoading.hide();
    },
  },
};
