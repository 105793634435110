var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-homework-quiz-result-item"
  }, [_c('div', {
    staticClass: "s-homework-quiz-result-item__label"
  }, [_vm._v("\n    " + _vm._s(_vm.trans('label.question')) + ":\n  ")]), _vm._v(" "), _c('div', {
    staticClass: "s-homework-quiz-result-item__value",
    class: _vm.item.isCorrect ? 's-homework-quiz-result-item__value-correct' : 's-homework-quiz-result-item__value-failed',
    domProps: {
      "innerHTML": _vm._s(_vm.item.question)
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "s-form-filter__spoiler-head mb-20",
    on: {
      "click": function click($event) {
        _vm.spoiler = !_vm.spoiler;
      }
    }
  }, [_c('h5', {
    staticClass: "s-combine-title"
  }, [_c('span', {
    staticClass: "s-combine-title__label"
  }, [_vm._v("\n        " + _vm._s(_vm.trans('label.watch_answers')) + "\n      ")]), _vm._v(" "), _c('span', {
    staticClass: "s-combine-title__line"
  }, [_c('button', {
    staticClass: "s-combine-title__button",
    attrs: {
      "type": "button"
    }
  }, [_c('span', {
    staticClass: "s-str-ico s-str-ico--wider s-c-grey",
    class: "s-str-ico--thm-angle-".concat(_vm.spoiler ? 'up' : 'down')
  })])])])]), _vm._v(" "), _c('q-slide-transition', [_vm.spoiler ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-6 col-md-6 col-sm-12"
  }, [_c('div', {
    staticClass: "s-homework-quiz-result-item__label"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('label.students_answer')) + ":\n        ")]), _vm._v(" "), _c('div', {
    staticClass: "s-homework-quiz-result-item__value",
    domProps: {
      "innerHTML": _vm._s(_vm.item.answer ? _vm.item.answer : "<p> ".concat(_vm.trans('label.answer_not_defined'), " </p>"))
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "col-lg-6 col-md-6 col-sm-12"
  }, [_c('div', {
    staticClass: "s-homework-quiz-result-item__label"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('label.correct_answer')) + ":\n        ")]), _vm._v(" "), _c('div', {
    staticClass: "s-homework-quiz-result-item__value",
    domProps: {
      "innerHTML": _vm._s(_vm.item.correctAnswer ? _vm.item.correctAnswer : "<p> ".concat(_vm.trans('label.answer_not_defined'), " </p>"))
    }
  })])]) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }