var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-ticket-exam-process"
  }, [_c('q-form', [_c('div', {
    staticClass: "exam-questions__item"
  }, [_c('div', {
    staticClass: "exam-questions__header"
  }, [_c('div', {
    staticClass: "exam-ticket-time-name"
  }, [_vm.timer.testTimeLeft > 0 && _vm.isProcess ? _c('v-timer', {
    attrs: {
      "timestamp-start": _vm.timer.testStartTime * 1000,
      "timestamp-end": _vm.timer.testEndTime * 1000,
      "timestamp-left": _vm.timer.testTimeLeft * 1000
    }
  }) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "s-test-process__center-username"
  }, [_vm._v("\n            " + _vm._s(_vm.student.fullname) + "\n          ")]), _vm._v(" "), _c('div', {
    staticClass: "s-test-process__group",
    class: _vm.student.group && _vm.student.group.length > 20 ? 'no-overflow' : ''
  }, [_vm._v("\n            " + _vm._s(_vm.student.group) + "\n          ")])], 1), _vm._v(" "), _c('div', {
    staticClass: "s-static-rectangle"
  }, [_vm._v("\n          " + _vm._s("".concat(_vm.trans('label.ticket_name'), ": ").concat(_vm.ticket.name)) + "\n        ")])]), _vm._v(" "), _c('div', {
    staticClass: "exam-questions__line"
  }, [_c('h5', {
    staticClass: "s-title"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('label.ticket_body')) + "\n        ")]), _vm._v(" "), _c('hr', {
    staticClass: "s-w-100 s-hr"
  })]), _vm._v(" "), _c('div', {
    staticClass: "sn-ck-editor-data",
    domProps: {
      "innerHTML": _vm._s(_vm.ticket.body)
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "mb-20"
  }, [_c('h3', {
    staticClass: "s-title s-title--double-line s-upper s-light mb-10"
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.trans('label.answer')) + " ")])]), _vm._v(" "), _vm.isProcess ? _c('div', [_c('v-form-inform', {
    attrs: {
      "message": _vm.trans('warning_text.ticket_file_warn')
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "mb-20"
  }, [_c('ckeditor', {
    attrs: {
      "editor": _vm.editor,
      "config": _vm.editorConfig,
      "disabled": _vm.loading
    },
    on: {
      "ready": _vm.onEditorReady
    },
    model: {
      value: _vm.body,
      callback: function callback($$v) {
        _vm.body = $$v;
      },
      expression: "body"
    }
  })], 1), _vm._v(" "), _c('div', [_c('div', {
    staticClass: "sn-field__label"
  }, [_vm._v("\n            " + _vm._s(_vm.trans('label.add_file')) + ":\n          ")]), _vm._v(" "), _vm._l(_vm.student.files, function (file, i) {
    return _c('div', {
      key: i,
      staticClass: "row align-items-end mb-20"
    }, [_c('div', {
      staticClass: "col-sm-6 col-md-4 col-12",
      class: _vm.$q.screen.width < 576 ? 'mb-10' : ''
    }, [_c('q-btn', {
      staticClass: "s-w-100",
      attrs: {
        "label": _vm.trans(_vm.fileIsImage(file.file) ? 'label.preview' : 'label.attached'),
        "color": "green",
        "icon-right": "clip",
        "label-wider": "",
        "ellipsis": "",
        "no-caps": ""
      },
      on: {
        "click": function click($event) {
          _vm.fileIsImage(file.file) ? _vm.handleFilePreview(file.file) : undefined;
        }
      }
    })], 1), _vm._v(" "), _c('div', {
      staticClass: "col-sm-6 col-md-8 col-12"
    }, [_c('q-btn', {
      staticClass: "s-w-100",
      attrs: {
        "outline": "",
        "label": file.name,
        "label-wider": "",
        "color": "grey",
        "no-caps": "",
        "ellipsis": "",
        "icon-right": "close"
      },
      on: {
        "click": function click($event) {
          return _vm.clearFile(file);
        }
      }
    })], 1)]);
  }), _vm._v(" "), _vm.student.files.length < 3 ? _c('div', {
    staticClass: "row align-items-end mb-20"
  }, [_c('div', {
    staticClass: "col-sm-6 col-md-4 col-12",
    class: _vm.$q.screen.width < 576 ? 'mb-10' : ''
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "label": _vm.trans('button.add'),
      "color": "blue",
      "icon-right": "plus",
      "label-wider": "",
      "ellipsis": "",
      "no-caps": ""
    },
    on: {
      "click": _vm.triggerFile
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-sm-6 col-md-8 col-12"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "outline": "",
      "label": _vm.trans('label.file_not_chosen'),
      "label-wider": "",
      "color": "grey",
      "no-caps": "",
      "ellipsis": ""
    },
    on: {
      "click": function click($event) {
        return _vm.clearFile(_vm.file);
      }
    }
  })], 1)]) : _vm._e()], 2), _vm._v(" "), _c('input', {
    ref: "fileInput",
    staticClass: "d-none",
    attrs: {
      "type": "file",
      "accept": "application/msword, application/pdf, image/*"
    },
    on: {
      "change": _vm.fileChange
    }
  }), _vm._v(" "), !!_vm.currentFilePreview ? _c('file-preview-modal', {
    attrs: {
      "modal": !!_vm.currentFilePreview,
      "src": _vm.currentFilePreview
    },
    on: {
      "modal-close": function modalClose($event) {
        _vm.currentFilePreview = null;
      }
    }
  }) : _vm._e()], 1) : _c('div', {
    staticClass: "mb-20"
  }, [_c('div', {
    staticClass: "exam-questions__item"
  }, [_vm.student.result ? _c('div', [_c('div', {
    staticClass: "exam-questions__header"
  }, [_c('div', {
    staticClass: "s-static-rectangle"
  }, [_vm._v("\n                " + _vm._s("".concat(_vm.trans('fields.point'), ": ").concat(_vm.student.result)) + "\n              ")])]), _vm._v(" "), _c('div', {
    staticClass: "exam-questions__line"
  }, [_c('hr', {
    staticClass: "s-w-100 s-hr"
  })])]) : _vm._e(), _vm._v(" "), _vm.student.answer ? _c('div', {
    staticClass: "sn-ck-editor-data",
    class: _vm.student.files.length > 0 ? 'mb-20' : '',
    domProps: {
      "innerHTML": _vm._s(_vm.student.answer)
    }
  }) : _vm._e(), _vm._v(" "), _vm.student.files.length > 0 ? _c('div', {
    staticClass: "row"
  }, _vm._l(_vm.student.files, function (file, i) {
    return _c('div', {
      key: i,
      staticClass: "col-12 col-sm-12 col-lg-4 col-md-4 mb-10"
    }, [_c('q-btn', {
      staticClass: "s-w-100",
      attrs: {
        "label": file.name,
        "type": "a",
        "color": "orange",
        "target": "_blank",
        "to": file.url
      }
    })], 1);
  }), 0) : _vm._e()]), _vm._v(" "), _vm.student.resultBody ? _c('div', [_c('h3', {
    staticClass: "s-title s-title--double-line s-upper s-light mb-10"
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.trans('fields.body_result')) + " ")])]), _vm._v(" "), _c('div', {
    staticClass: "exam-questions__item"
  }, [_c('div', {
    staticClass: "sn-ck-editor-data",
    domProps: {
      "innerHTML": _vm._s(_vm.student.resultBody)
    }
  })])]) : _vm._e(), _vm._v(" "), _vm.isChecking ? _c('div', [_c('h3', {
    staticClass: "s-title s-title--double-line s-upper s-light mb-10"
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.trans('label.checking')) + " ")])]), _vm._v(" "), _c('div', {
    staticClass: "exam-questions__item"
  }, [_c('div', {
    staticClass: "exam-questions__header"
  }, [_c('q-input', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: _vm.ticket.typeOfEvaluationMask,
      expression: "ticket.typeOfEvaluationMask"
    }],
    staticClass: "s-maxw-200",
    class: _vm.loading ? 'sn--cursor-wait' : '',
    attrs: {
      "label": _vm.trans('fields.point') + _vm.typeOfEvaluationName,
      "disable": _vm.loading,
      "required": ""
    },
    model: {
      value: _vm.teacher.result,
      callback: function callback($$v) {
        _vm.$set(_vm.teacher, "result", _vm._n($$v));
      },
      expression: "teacher.result"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "exam-questions__line"
  }, [_c('h5', {
    staticClass: "s-title"
  }, [_vm._v("\n                " + _vm._s(_vm.trans('fields.body_result')) + "\n              ")]), _vm._v(" "), _c('hr', {
    staticClass: "s-w-100 s-hr"
  })]), _vm._v(" "), _c('ckeditor', {
    attrs: {
      "editor": _vm.editor,
      "config": _vm.editorConfig,
      "disabled": _vm.loading
    },
    model: {
      value: _vm.teacher.body,
      callback: function callback($$v) {
        _vm.$set(_vm.teacher, "body", $$v);
      },
      expression: "teacher.body"
    }
  })], 1)]) : _vm._e()])]), _vm._v(" "), _c('div', {
    staticClass: "row justify-content-between"
  }, [_c('div', {
    staticClass: "col-12 col-sm-auto mb-20"
  }, [_c('q-btn', {
    staticClass: "s-btn--w-200 s-w-100",
    attrs: {
      "color": "grey",
      "icon": _vm.isProcess ? 'close' : 'angle-left',
      "label-wider": "",
      "label": _vm.isProcess ? _vm.trans('button.cancel') : _vm.trans('button.back'),
      "to": _vm.uriBack,
      "type": "a",
      "loading": _vm.loading
    }
  })], 1), _vm._v(" "), _vm.isProcess || _vm.isChecking ? _c('div', {
    staticClass: "col-12 col-sm-auto mb-20"
  }, [_c('q-btn', {
    staticClass: "s-btn--w-200 s-w-100",
    attrs: {
      "color": "blue",
      "label": _vm.trans('button.save'),
      "icon": "check",
      "label-wider": "",
      "loading": _vm.loading,
      "disable": !_vm.validate
    },
    on: {
      "click": function click($event) {
        _vm.openModal = true;
      }
    }
  })], 1) : _vm._e()])]), _vm._v(" "), _c('v-confirm-dialog', {
    attrs: {
      "modal": _vm.openModal,
      "loading": _vm.loading,
      "confirm": _vm.requestAction,
      "submit-color": "blue"
    },
    on: {
      "modal-close": function modalClose($event) {
        _vm.openModal = $event;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }