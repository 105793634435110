var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {}, [_c('table', {
    staticClass: "s-table s-table--striped s-table--thm-head-grey"
  }, [_c('thead', {
    staticClass: "s-table__head"
  }, [_c('tr', [_c('th', {
    staticClass: "s-to-center"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('main.profession')) + "\n        ")]), _vm._v(" "), _c('th', {
    staticClass: "s-to-center"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('statement.qualification')) + "\n        ")]), _vm._v(" "), _c('th', {
    staticClass: "s-to-center"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('main.count_places')) + "\n        ")]), _vm._v(" "), _c('th', {
    staticClass: "s-to-center"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('label.edu_level')) + "\n        ")]), _vm._v(" "), _c('th', {
    staticClass: "s-to-center "
  }, [_vm._v("\n          " + _vm._s(_vm.trans('table.prepare_type')) + "\n        ")]), _vm._v(" "), _c('th', {
    staticClass: "s-to-center"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('label.edu_lang')) + "\n        ")]), _vm._v(" "), _c('th', {
    staticClass: "s-to-center"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('main.edu_form')) + "\n        ")]), _vm._v(" "), _c('th', {
    staticClass: "s-to-center s-maxw-200"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('statement.start_date_slash_end_date')) + "\n        ")]), _vm._v(" "), _c('th', {
    staticClass: "s-to-center"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('statement.statements_count')) + "\n        ")])])]), _vm._v(" "), _c('tbody', {
    staticClass: "s-table__body"
  }, [_vm._l(_vm.table, function (data, parentKey) {
    return _vm._l(data.items, function (item, childKey) {
      return _c('tr', {
        key: "parent-".concat(parentKey, "-child-").concat(childKey)
      }, [childKey === 0 ? _c('td', {
        attrs: {
          "data-table-head": _vm.trans('main.profession'),
          "rowspan": data.items.length
        }
      }, [_c('div', [_vm._v("\n              " + _vm._s(data.profession) + "\n            ")])]) : _vm._e(), _vm._v(" "), _c('td', {
        attrs: {
          "data-table-head": _vm.trans('statement.qualification')
        }
      }, [_c('div', [_vm._v("\n              " + _vm._s(item.qualification) + "\n            ")])]), _vm._v(" "), _c('td', {
        attrs: {
          "data-table-head": _vm.trans('main.overall_places')
        }
      }, [_c('div', {
        staticClass: "s-to-center"
      }, [_vm._v("\n              " + _vm._s(item.places_count) + "\n            ")])]), _vm._v(" "), _c('td', {
        attrs: {
          "data-table-head": _vm.trans('label.edu_level')
        }
      }, [_c('div', {
        staticClass: "s-to-center"
      }, [_vm._v("\n              " + _vm._s(item.edu_level) + "\n            ")])]), _vm._v(" "), _c('td', {
        attrs: {
          "data-table-head": _vm.trans('table.prepare_type')
        }
      }, [_c('div', {
        class: _vm.$q.screen.width > 490 ? 's-to-center' : ''
      }, [_vm._v("\n              " + _vm._s(item.prepare_type) + "\n            ")])]), _vm._v(" "), _c('td', {
        attrs: {
          "data-table-head": _vm.trans('label.edu_lang')
        }
      }, [_c('div', {
        class: _vm.$q.screen.width > 490 ? 's-to-center' : ''
      }, [_vm._v("\n              " + _vm._s(item.edu_lang) + "\n            ")])]), _vm._v(" "), _c('td', {
        attrs: {
          "data-table-head": _vm.trans('main.edu_form')
        }
      }, [_c('div', {
        staticClass: "s-to-center"
      }, [_vm._v("\n              " + _vm._s(item.edu_form) + "\n            ")])]), _vm._v(" "), _c('td', {
        attrs: {
          "data-table-head": _vm.trans('statement.start_date_slash_end_date')
        }
      }, [_c('div', {
        staticClass: "s-to-center"
      }, [_vm._v("\n              " + _vm._s("".concat(item.date_start, " - ").concat(item.date_end)) + "\n            ")])]), _vm._v(" "), _c('td', {
        attrs: {
          "data-table-head": _vm.trans('statement.start_date_slash_end_date')
        }
      }, [_c('div', {
        staticClass: "s-to-center"
      }, [_vm._v("\n              " + _vm._s(item.statements_count) + "\n            ")])])]);
    });
  })], 2)]), _vm._v(" "), _vm.admissionModal && _vm.admissionModalLink ? _c('v-passport-admission-modal', {
    attrs: {
      "modal": _vm.admissionModal,
      "link": _vm.admissionModalLink
    },
    on: {
      "modal-close": function modalClose($event) {
        _vm.admissionModal = $event;
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }