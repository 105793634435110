var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._l(_vm.buttons, function (btn, i) {
    return _c('q-btn', {
      key: i,
      staticClass: "s-w-100 mb-10",
      attrs: {
        "color": btn.color,
        "outline": btn.outline,
        "label": btn.label,
        "no-caps": ""
      },
      on: {
        "click": function click($event) {
          return _vm.handleButton(btn);
        }
      }
    });
  }), _vm._v(" "), _c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "no-caps": "",
      "color": "grey",
      "label": _vm.trans('button.cancel')
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('onClose');
      }
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }