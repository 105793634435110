import './bootstrap-app';
import './v-adaptive-menu-app';
import oembedTransform from './lib/oembed';
import './v-library-app';
import './v-insta-app';
import './v-exam-questions-app';
import './v-menu-app';
import './v-statistic-app';
import './v-doc-flow-form-app';
import './v-contingent-monitoring-app';
import './v-journal-form-app';
import './v-reset-pass-app';
import './v-monitoring-list-app';
import './v-diploma-list-app';
import './v-diploma-contingent-form-app';
import './v-diploma-form-app';
import './v-exam-tickets-app';
import './v-ticket-process-app';
import './v-catalog-list-view-app';
import './v-statement-admission-form-app';
import './v-passport-page-view-app';
import './v-statement-create-view';
import './v-statement-files-edit-app';
import './v-application-list-view-app';
import './v-admission-result-org-list-app';
import './v-admission-result-list-app';
import './v-schedule-view-app';
import './v-login-form-app';

window.oembedTransform = oembedTransform;
