import {
  QForm,
  QBtn,
  QSelect,
  QInput,
  QField,
  QCheckbox,
} from '@quasar/components';
import {
  requestWrapper,
} from '@vjs/helpers';
import Validation
  from '@vjs/mixins/Validation';
import VFormInform from '@vjs/components/VFormInform/VFormInform';
import { Helper } from '@common/src/helpers';
import _ from 'lodash';

export default {
  name: 'VEducationModuleForm',
  components: {
    QForm,
    QInput,
    QBtn,
    QSelect,
    QCheckbox,
    QField,
    VFormInform,
  },
  mixins: [Validation],
  props: {
    uriAction: {
      type: String,
      required: true,
    },
    uriBack: {
      type: String,
      required: true,
    },
    uriGetDisciplines: {
      type: String,
      required: true,
    },
    uriGetResults: {
      type: String,
      required: true,
    },
    uriGetExistingData: {
      type: String,
      required: false,
    },
    method: {
      type: String,
      required: true,
    },
    resource: {
      type: [Array, Object],
      required: false,
    },
  },
  data() {
    return {
      loading: false,
      disciplinesLoading: false,
      currentResultsPage: 1,
      resultsLastPage: 1,
      currentDisciplinesPage: 1,
      disciplinesLastPage: 1,
      form: {
        name: null,
        index: null,
        name_kz: null,
        index_kz: null,
        with_result: 0,
        disciplines: [],
      },
      options: {
        results: [],
        disciplines: [],
      },
    };
  },
  async mounted() {
    await this.$trans.add([
      'label',
      'button',
      'placeholder',
      'notice',
      'curriculum',
      'main',
      'fields',
      'table',
      'journal',
      'label',
      'personnel_dicts',
      'training_schedule',
      'select',
      'warning_text',
    ]);
    this.$globalLoading.show();
    await this.requestGetDisciplines();
    await this.requestGetResults();
    if (this.resource !== undefined) {
      this.form = this.resource.fields;
    }
    this.$globalLoading.hide();
  },
  computed: {
    subjectInputValue() {
      return this.$refs?.subjectSelect?.inputValue === this.trans('select.placeholder')
        ? ''
        : this.$refs?.subjectSelect?.inputValue.toLowerCase();
    },
    validate() {
      return this.form.name !== null
        && this.form.index !== null
        && this.form.name_kz !== null
        && this.form.index_kz !== null
        && this.form.disciplines.length > 0;
    },
  },
  methods: {
    // Обработка виртуал-скроллинга для селекта предметов
    async onSubScroll({ index }) {
      if (!this.disciplinesLoading
        && !this.form.with_result
        && this.currentDisciplinesPage < this.disciplinesLastPage
        && index >= (this.options.disciplines.length - 21)
      ) {
        this.currentDisciplinesPage += 1;

        await this.requestGetDisciplines(false);
        this.$nextTick(() => {
          this.$refs.subjectSelect.refresh();
          this.disciplinesLoading = false;
        });
      } else if (!this.disciplinesLoading
        && this.form.with_result
        && this.currentResultsPage < this.resultsLastPage
        && index >= (this.options.results.length - 21)
      ) {
        this.currentResultsPage += 1;

        await this.requestGetResults(false);
        this.$nextTick(() => {
          this.$refs.subjectSelect.refresh();
          this.disciplinesLoading = false;
        });
      }
    },
    // обработка поиска по предметам
    async requestSearchSubjects(val, update) {
      this.disciplinesLoading = true;
      this.currentDisciplinesPage = 1;
      this.currentResultsPage = 1;
      if (!this.form.with_result) {
        this.requestGetDisciplinesByFilter().then((res) => {
          update(() => {
            this.options.disciplines = res.items;
            this.disciplinesLoading = false;
          });
        });
      } else {
        this.requestGetResultsByFilter().then((res) => {
          update(() => {
            this.options.results = res.items;
            this.disciplinesLoading = false;
          });
        });
      }
    },
    // запрос на получение опций предметов по поиску
    async requestGetDisciplinesByFilter() {
      return new Promise(async (res, rej) => {
        const result = await requestWrapper.call(this, {
          url: this.uriGetDisciplines,
          params: {
            quasar: true,
            page: 1,
            search: this.subjectInputValue,
          },
        });

        if (!result.error) {
          res(result);
        } else {
          rej(result);
        }
      });
    },
    // запрос на получение опций предметов по поиску
    async requestGetResultsByFilter() {
      return new Promise(async (res, rej) => {
        const result = await requestWrapper.call(this, {
          url: this.uriGetResults,
          params: {
            quasar: true,
            page: 1,
            search: this.subjectInputValue,
          },
        });

        if (!result.error) {
          res(result);
        } else {
          rej(result);
        }
      });
    },
    // запрос на получение опций предметов
    async requestGetResults(offLoading = true) {
      this.disciplinesLoading = true;
      const res = await requestWrapper.call(this, {
        url: this.uriGetResults,
        params: {
          quasar: true,
          page: this.currentResultsPage,
          search: this.subjectInputValue,
        },
      });

      if (!res.error) {
        this.options.results = [...this.options.results, ...res.items];
        this.resultsLastPage = res.lastPage;
      }
      if (offLoading) {
        this.disciplinesLoading = false;
      }
    },
    async requestGetDisciplines(offLoading = true) {
      this.disciplinesLoading = true;
      const res = await requestWrapper.call(this, {
        url: this.uriGetDisciplines,
        params: {
          quasar: true,
          page: this.currentDisciplinesPage,
          search: this.subjectInputValue,
        },
      });
      if (!res.error) {
        this.options.disciplines = [...this.options.disciplines, ...res.items];
        this.disciplinesLastPage = res.lastPage;
      }
      if (offLoading) {
        this.disciplinesLoading = false;
      }
    },
    async updateSelect() {
      this.form.disciplines = [];
    },
    // запрос на отправку данных для сохранения изменений или создания журнала
    async requestAction() {
      this.loading = true;
      this.$globalLoading.show();;
      const disciplines = [];
      _.forEach(this.form.disciplines, (val) => {
        disciplines.push(val.value);
      });
      const actionData = {
        name: this.form.name,
        index: this.form.index,
        name_kz: this.form.name_kz,
        index_kz: this.form.index_kz,
        with_result: this.form.with_result,
        disciplines,
      };
      const res = await requestWrapper.call(this, {
        method: this.method,
        url: this.uriAction,
        data: actionData,
      });
      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
        this.loading = false;
        this.$globalLoading.hide();
      }
    },
  },
};
