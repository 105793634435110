var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sc-statement-form--step-subjects"
  }, [_c('v-form-inform', {
    attrs: {
      "message": _vm.trans('warning_text.modal_inform')
    }
  }), _vm._v(" "), _vm.options.required.length > 0 ? _c('table', {
    staticClass: "s-table-cabinet"
  }, [_c('thead', {
    staticClass: "s-table__head"
  }, [_c('tr', [_c('th', {
    staticClass: "s-to-center"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('label.required_subjects')) + "\n        ")]), _vm._v(" "), _vm._l(_vm.options.required, function (column, index) {
    return _c('th', {
      key: index,
      staticClass: "s-to-center"
    }, [_vm._v("\n          " + _vm._s(column.name) + "\n        ")]);
  })], 2)]), _vm._v(" "), _c('tbody', {
    staticClass: "s-table__body"
  }, [_c('tr', [_c('td', {
    staticClass: "s-to-center"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('fields.point')) + "\n        ")]), _vm._v(" "), _vm._l(_vm.options.required, function (column, index) {
    return _c('td', {
      key: index,
      staticClass: "s-to-center",
      attrs: {
        "data-table-head": column.name
      }
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: column.grade,
        expression: "column.grade"
      }],
      attrs: {
        "type": "text",
        "disabled": "disabled"
      },
      domProps: {
        "value": column.grade
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) { return; }

          _vm.$set(column, "grade", $event.target.value);
        }
      }
    })]);
  })], 2)])]) : _vm._e(), _vm._v(" "), _vm._l(_vm.options.subjectMapped, function (specialty, specIndex) {
    return _c('div', {
      key: specIndex
    }, [_c('div', {
      staticClass: "sc-statement-form--step-subjects__title"
    }, [_vm._v("\n      " + _vm._s(specialty.name) + "\n    ")]), _vm._v(" "), _c('table', {
      staticClass: "s-table-cabinet"
    }, [_c('thead', {
      staticClass: "s-table__head"
    }, [_c('tr', [_c('th', {
      staticClass: "s-to-center"
    }, [_vm._v("\n            " + _vm._s(_vm.trans('label.special_subjects')) + "\n          ")]), _vm._v(" "), _vm._l(specialty.columns, function (column, index) {
      return _c('th', {
        key: index,
        staticClass: "s-to-center"
      }, [_vm._v("\n            " + _vm._s(column.name) + "\n          ")]);
    }), _vm._v(" "), _c('th', {
      staticClass: "s-to-center"
    }, [_vm._v("\n            " + _vm._s(_vm.trans('statement.exam_subjects.total')) + "\n          ")])], 2)]), _vm._v(" "), _c('tbody', {
      staticClass: "s-table__body"
    }, [_c('tr', [_c('td', {
      staticClass: "s-to-center"
    }, [_vm._v("\n            " + _vm._s(_vm.trans('fields.point')) + "\n          ")]), _vm._v(" "), _vm._l(specialty.columns, function (column, index) {
      return _c('td', {
        key: index,
        staticClass: "s-to-center",
        attrs: {
          "data-table-head": column.name
        }
      }, [_c('input', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: column.grade,
          expression: "column.grade"
        }],
        attrs: {
          "type": "text",
          "disabled": "disabled"
        },
        domProps: {
          "value": column.grade
        },
        on: {
          "input": function input($event) {
            if ($event.target.composing) { return; }

            _vm.$set(column, "grade", $event.target.value);
          }
        }
      })]);
    }), _vm._v(" "), _c('td', {
      staticClass: "s-to-center"
    }, [_vm._v("\n            " + _vm._s(Number(specialty.average_point).toPrecision(3)) + "\n          ")])], 2)])])]);
  }), _vm._v(" "), _c('q-form', [_vm.options.notGraded.length ? _c('div', {
    staticClass: "row"
  }, _vm._l(_vm.options.notGraded, function (subject, index) {
    return _c('q-select', {
      key: index,
      staticClass: "col-sm-4 sc-statement-form--step-subjects__select\n        sc-statement-form--step-subjects__title",
      attrs: {
        "options": _vm.options.points,
        "required": "",
        "label": subject.name,
        "emit-value": "",
        "map-options": ""
      },
      on: {
        "input": function input($event) {
          return _vm.setSubjectGrade(subject.id, _vm.form.point[index]);
        }
      },
      model: {
        value: _vm.form.point[index],
        callback: function callback($$v) {
          _vm.$set(_vm.form.point, index, $$v);
        },
        expression: "form.point[index]"
      }
    });
  }), 1) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "sn-field__label mt-20 mb-20"
  }, [_vm._v("\n      " + _vm._s(_vm.trans('label.el_diploma')) + ": "), _c('i', [_vm._v("*")])]), _vm._v(" "), _c('div', {
    staticClass: "row align-items-end mb-20"
  }, [_c('div', {
    staticClass: "col-sm-6 col-md-4 col-12",
    class: _vm.$q.screen.width < 576 ? 'mb-10' : ''
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "label": _vm.form.diploma.length === 0 ? _vm.trans('button.add') : _vm.trans('label.attached'),
      "color": _vm.form.diploma.length === 0 ? 'blue' : 'green',
      "label-wider": "",
      "ellipsis": "",
      "no-caps": "",
      "icon-right": _vm.form.diploma.length === 0 ? 'plus' : 'clip'
    },
    on: {
      "click": function click($event) {
        return _vm.triggerFile('diploma');
      }
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-sm-6 col-md-8 col-12"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "outline": "",
      "label": _vm.form.diploma.length === 0 ? _vm.trans('label.file_not_chosen') : _vm.trans('label.files_downloaded', {
        count: _vm.form.diploma.length
      }),
      "label-wider": "",
      "color": "grey",
      "no-caps": "",
      "ellipsis": "",
      "icon-right": _vm.form.diploma.length === 0 ? undefined : 'close'
    },
    on: {
      "click": function click($event) {
        return _vm.clearFile('diploma');
      }
    }
  })], 1)]), _vm._v(" "), _c('div', {
    staticClass: "sn-field__label mt-20 mb-20"
  }, [_vm._v("\n      " + _vm._s(_vm.trans('label.el_diploma_2')) + ": "), _c('i', [_vm._v("*")])]), _vm._v(" "), _c('div', {
    staticClass: "row align-items-end mb-20"
  }, [_c('div', {
    staticClass: "col-sm-6 col-md-4 col-12",
    class: _vm.$q.screen.width < 576 ? 'mb-10' : ''
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "label": _vm.form.diploma_2.length === 0 ? _vm.trans('button.add') : _vm.trans('label.attached'),
      "color": _vm.form.diploma_2.length === 0 ? 'blue' : 'green',
      "label-wider": "",
      "ellipsis": "",
      "no-caps": "",
      "icon-right": _vm.form.diploma_2.length === 0 ? 'plus' : 'clip'
    },
    on: {
      "click": function click($event) {
        return _vm.triggerFile('diploma_2');
      }
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-sm-6 col-md-8 col-12"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "outline": "",
      "label": _vm.form.diploma_2.length === 0 ? _vm.trans('label.file_not_chosen') : _vm.trans('label.files_downloaded', {
        count: _vm.form.diploma_2.length
      }),
      "label-wider": "",
      "color": "grey",
      "no-caps": "",
      "ellipsis": "",
      "icon-right": _vm.form.diploma_2.length === 0 ? undefined : 'close'
    },
    on: {
      "click": function click($event) {
        return _vm.clearFile('diploma_2');
      }
    }
  })], 1)]), _vm._v(" "), _c('select-user-file', {
    attrs: {
      "open-modal": _vm.modalFiles,
      "url-files": _vm.uriUserFiles,
      "url-file-store": _vm.uriUserFiles,
      "multiple": ""
    },
    on: {
      "input": _vm.selectFile,
      "select-close": _vm.fileModalClose
    }
  })], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }