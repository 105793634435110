import {
  QTable,
  QBtn,
} from '@quasar/components';
import VNoResults from '@vjs/components/VNoResults';
import {
  requestWrapper,
} from '@vjs/helpers';
import { Loading } from '@quasar/plugins';
import axios from 'axios';

export default {
  name: 'VNameBookView',
  components: {
    VNoResults,
    QTable,
    QBtn,
  },
  props: {
    urlGroupsList: String,
    urlContingentList: String,
    urlExportContingentList: String,
  },
  data() {
    return {
      qLoading: Loading,
      loading: false,
      filters: {
        group: null,
      },
      pagination: {
        rowsPerPage: 20,
      },
      options: {
        groups: [],
      },
      table: {
        rows: [],
        columns: [],
      },
    };
  },
  async mounted() {
    await this.$trans.add([
      'label',
      'button',
      'warning_text',
      'export',
    ]);
    await this.requestGetOptions();
  },
  methods: {
    async requestGetOptions() {
      this.qLoading.show({ message: this.trans('label.wait_for_load_data') });
      const res = await requestWrapper.call(this, {
        method: 'get',
        url: this.urlGroupsList,
      });
      if (!res.error) {
        this.options.groups = res.data;
      }
      this.qLoading.hide();
    },
    async requestGetContingentList() {
      this.qLoading.show({ message: this.trans('label.wait_for_load_data') });
      this.loading = true;
      const res = await requestWrapper.call(this, {
        method: 'get',
        url: this.urlContingentList,
        params: this.filters,
      });
      if (!res.error) {
        this.table = res;
        this.$nextTick(() => {
          const wrapper = $('.sn--scroll')[0];
          const wrapper_scroll = $('.table-on-fire__inner--double-scroll')[0];
          $('.double-scroll--content').width($('.sn-table').width());
          $(wrapper).scroll(() => {
            wrapper_scroll.scrollLeft = wrapper.scrollLeft;
          });
          $(wrapper_scroll).scroll(() => {
            wrapper.scrollLeft = wrapper_scroll.scrollLeft;
          });
        });
      }
      this.qLoading.hide();
      this.loading = false;
    },
    async exportContingents() {
      this.qLoading.show({ message: this.trans('label.wait_for_load_data') });
      axios({
        url: `${this.urlExportContingentList}`,
        method: 'GET',
        responseType: 'blob',
        params: this.filters,
      })
        .then((response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement('a');
          fileLink.href = fileURL;
          const date = new Date();
          const group = this.options.groups.find(o => o.value === this.filters.group);
          const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
          fileLink.setAttribute('download',
            `${this.trans('export.filenames.monitoring_name_book_export', {
              group: group.label,
              date: formattedDate,
            })}.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.qLoading.hide();
        });
    },
  },
};
