var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sc-curriculum-program__heading mt-20"
  }, [_c('div', {
    staticClass: "sc-curriculum-program__heading--title"
  }, [_c('div', {
    staticClass: "sc-curriculum-program__heading--label s-upper"
  }, [_vm._v("\n      " + _vm._s(_vm.name) + "\n    ")]), _vm._v(" "), _c('div', {
    staticClass: "s-combine-title__line"
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }