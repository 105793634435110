import { QField, QBtn } from '@quasar/components';
import { requestWrapper } from '@vjs/helpers';
import { Helper } from '@common/src/helpers';

export default {
  name: 'VStudentTranscript',

  components: { QField, QBtn },

  props: {
    apiConfig: {
      type: Object,
      default: () => ({
        action: '',
        getData: '',
        subjects: '',
        typesOfEvaluation: '',
        back: '',
      }),
    },
  },

  data() {
    return {
      transcripts: [],
      options: {
        typesOfEvaluation: [],
        subjects: [],
      },
      subjectSelectConfig: {
        loading: false,
        disableVirtualScrolling: false,
        currentPage: 1,
        lastPage: 1,
        inputValue: '',
      },
    };
  },


  async beforeMount() {
    await this.$trans.add(['student_transcript', 'button']);
  },
  async mounted() {
    await this.requestGetTypesOfEvaluation();
    await this.requestGetSubjects();
    await this.requestGetData();
  },

  methods: {
    async requestSubmit() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.apiConfig.action,
        method: 'post',
        data: {
          transcripts: this.transcripts,
        },
      });

      if (res.error) {
        this.$globalLoading.hide();
        Helper.handlerResponseErrorNew(this, res);
      }
    },
    async requestGetData() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.apiConfig.getData,
      });

      if (!res.error) {
        this.transcripts = res.data ?? [];
      }
      this.$globalLoading.hide();
    },
    async requestGetTypesOfEvaluation() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.apiConfig.typesOfEvaluation,
      });

      if (!res.error) {
        this.options.typesOfEvaluation = res;
      }
      this.$globalLoading.hide();
    },
    handleSelectSubject(val) {
      setTimeout(async () => {
        this.subjectSelectConfig.inputValue = '';
        const { id, name } = val[0];
        this.transcripts.push({
          subjectId: id,
          name,
          typeOfEvaluation: 0,
          point: null,
        });
        if (this.subjectsComputed.length < 25) {
          await this.onSubScroll({ index: 99999 });
        }
      });
    },
    removeTranscript(index) {
      this.$delete(this.transcripts, index);
    },
    async requestGetSubjects(loadingOff = false) {
      this.subjectSelectConfig.loading = true;
      const res = await requestWrapper.call(this, {
        url: this.apiConfig.subjects,
        params: {
          page: this.subjectSelectConfig.currentPage,
          search: this.subjectSelectConfig.inputValue.toLowerCase(),
        },
      });

      if (!res.error) {
        this.options.subjects = [...this.options.subjects, ...res.items];
        this.subjectSelectConfig.lastPage = res.lastPage;
      }
      if (loadingOff) {
        this.subjectSelectConfig.loading = false;
      }
    },
    // Обработка виртуал-скроллинга для селекта предметов
    async onSubScroll({ index }) {
      if (!this.subjectSelectConfig.loading
        && this.subjectSelectConfig.currentPage < this.subjectSelectConfig.lastPage
        && index >= (this.options.subjects.length - 21)
      ) {
        this.subjectSelectConfig.currentPage += 1;
        await this.requestGetSubjects(false);
        this.$nextTick(() => {
          this.$refs.subjectSelect.$refs['q-select-wrapper-ref'].refresh();
          this.subjectSelectConfig.loading = false;
        });
      }
    },
    async requestGetSubjectsByFilter() {
      return new Promise(async (res, rej) => {
        const result = await requestWrapper.call(this, {
          url: this.apiConfig.subjects,
          params: {
            page: 1,
            search: this.subjectSelectConfig.inputValue.toLowerCase(),
          },
        });

        if (!result.error) {
          res(result);
        } else {
          rej(result);
        }
      });
    },
    async requestSearchSubjects(val, update) {
      this.subjectSelectConfig.loading = true;
      this.subjectSelectConfig.currentPage = 1;
      this.requestGetSubjectsByFilter().then((res) => {
        update(() => {
          this.options.subjects = res.items;
          this.subjectSelectConfig.loading = false;
        });
      });
    },
    handleInput(transcript) {
      const min = 0;
      const max = 100;
      const value = Number(transcript.point);

      if (value < min) {
        transcript.point = min;
      } else if (value > max) {
        transcript.point = max;
      } else if (transcript.point !== value) {
        transcript.point = value; // Дабы избежать значений по типу '000'
      }
    },
  },
  computed: {
    selectedSubjects() {
      const result = [];
      _.forEach(this.transcripts, (item) => {
        result.push({ id: item.subjectId, name: item.name });
      });
      return result;
    },
    subjectsComputed() {
      if (this.options.subjects && !_.isEmpty(this.options.subjects)) {
        return _.differenceWith(this.options.subjects, this.selectedSubjects, _.isEqual);
      }
      return [];
    },
    points() {
      return [
        { id: 2, name: this.trans('student_transcript.types_of_evaluation.not_acceptable') },
        { id: 3, name: this.trans('student_transcript.types_of_evaluation.acceptable') },
        { id: 4, name: this.trans('student_transcript.types_of_evaluation.good') },
        { id: 5, name: this.trans('student_transcript.types_of_evaluation.perfect') },
      ];
    },
    creditNotCredit() {
      return [
        { id: 100, name: this.trans('student_transcript.types_of_evaluation.credit') },
        { id: 0, name: this.trans('student_transcript.types_of_evaluation.not_credit') },
      ];
    },
  },
};
