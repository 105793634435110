var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "journal-form"
  }, [_c('v-form-inform', {
    attrs: {
      "message": _vm.trans('warning_text.modal_inform')
    }
  }), _vm._v(" "), _c('q-form', [_c('q-input', {
    attrs: {
      "label": _vm.trans('label.name_ru'),
      "required": ""
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _vm._v(" "), _c('q-input', {
    attrs: {
      "label": _vm.trans('label.index_ru'),
      "required": ""
    },
    model: {
      value: _vm.form.index,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "index", $$v);
      },
      expression: "form.index"
    }
  }), _vm._v(" "), _c('q-input', {
    attrs: {
      "label": _vm.trans('label.name_kz'),
      "required": ""
    },
    model: {
      value: _vm.form.name_kz,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name_kz", $$v);
      },
      expression: "form.name_kz"
    }
  }), _vm._v(" "), _c('q-input', {
    attrs: {
      "label": _vm.trans('label.index_kz'),
      "required": ""
    },
    model: {
      value: _vm.form.index_kz,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "index_kz", $$v);
      },
      expression: "form.index_kz"
    }
  }), _vm._v(" "), _c('q-checkbox', {
    attrs: {
      "label": _vm.trans('fields.with_result'),
      "true-value": 1,
      "false-value": 0,
      "disable": _vm.resource !== undefined
    },
    on: {
      "input": function input($event) {
        return _vm.updateSelect();
      }
    },
    model: {
      value: _vm.form.with_result,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "with_result", $$v);
      },
      expression: "form.with_result"
    }
  }), _vm._v(" "), _c('q-select', {
    ref: "subjectSelect",
    staticClass: "sn-select__input--noclear wrap-chips",
    class: _vm.loading ? 'sn--cursor-wait' : '',
    attrs: {
      "required": "",
      "use-input": "",
      "use-chips": "",
      "filled": "",
      "bottom-slots": "",
      "fill-input": "",
      "multiple": "",
      "input-debounce": "500",
      "options": _vm.form.with_result ? _vm.options.results : _vm.options.disciplines,
      "label": _vm.form.with_result ? _vm.trans('label.results_label') : _vm.trans('label.subjects'),
      "readonly": !!_vm.uriGetExistingData,
      "loading": _vm.loading,
      "disable": _vm.loading
    },
    on: {
      "filter": _vm.requestSearchSubjects,
      "virtual-scroll": _vm.onSubScroll
    },
    model: {
      value: _vm.form.disciplines,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "disciplines", $$v);
      },
      expression: "form.disciplines"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "row-sort-between"
  }, [_c('q-btn', {
    staticClass: "s-btn--w-200 btn-adaptive-helper",
    attrs: {
      "label": _vm.trans('button.cancel'),
      "color": "blue",
      "icon": "mark",
      "type": "a",
      "to": _vm.uriBack
    }
  }), _vm._v(" "), _c('q-btn', {
    staticClass: "s-btn--w-200 btn-adaptive-helper",
    attrs: {
      "label": _vm.trans('button.save'),
      "color": "green",
      "icon": "mark",
      "loading": _vm.loading,
      "disable": !_vm.validate
    },
    on: {
      "click": _vm.requestAction
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }