import { QTooltip, QIcon } from '@quasar/components';
import VJournalLegend from '../VJournalLegend';

export default {
  name: 'VJournalTable',

  components: { QTooltip, QIcon, VJournalLegend },

  props: {},
  data() {
    return {
      activeTooltipText: null,
      activeTooltipId: null,
    };
  },

  methods: {
    scrollTableToLastColumn() {
      const currentDayColEls = document.getElementsByClassName('sc-journal__table--head-cell');
      const currentDayColEl = currentDayColEls[currentDayColEls.length - 2];
      const scrollContainerEl = document.getElementsByClassName('sc-journal__table--scroll-part-container')[0];
      if (currentDayColEl && scrollContainerEl) {
        const elLeft = currentDayColEl.offsetLeft + currentDayColEl.offsetWidth;
        const elParentLeft = scrollContainerEl.offsetLeft + scrollContainerEl.offsetWidth;
        if (elLeft >= elParentLeft) {
          scrollContainerEl.scrollLeft = elLeft - elParentLeft;
        }
      }
    },
    openDayForm(day = null) {
      if (!this.cans.edit || (day && !day.canEdit && !day.canDestroy)) {
        return;
      }
      this.$sModal.open('v-modal-journal-day-form', {
        component: 'v-modal-journal-day-form',
        notPersistent: true,
        binds: { day },
      });
    },
    openModalEditPoint(row, col, index) {
      if (!this.canEditPoint(row, col)) {
        return null;
      }
      const point = this.getPointObj(col, row.id);
      return this.$sModal.open('v-modal-journal-edit-point', {
        component: 'v-modal-journal-edit-point',
        notPersistent: true,
        binds: {
          resource: {
            contingentId: row.id,
            dayId: col.id,
            dayIndex: index,
            ...point,
          },
          strictPointEdit: this.cans.strictPointEdit,
          canCorrectAbsence: col.canCorrectAbsence && point?.absented,
          isUncertifiable: col.isUncertifiable,
          isReleasable: col.isReleasable,
        },
      });
    },
    getLabel(col, rowId) { return col.points?.[rowId]?.label; },
    getPointObj(col, rowId) { return col.points?.[rowId]; },
    getTooltip(col, rowId) { return col.points?.[rowId]?.tooltip; },
    canEditPoint(row, col) {
      const point = this.getPointObj(col, row.id);
      if (point && this.cans.strictPointEdit && !point?.absented) {
        return false;
      }
      return this.cans.edit
        && (col.canEditPoint || (col.canCorrectAbsence && point?.absented))
        && !row.isRetired && row.isCurrent;
    },
    handleTooltip(ref, tooltip) {
      if (!tooltip || this.$q.screen.width < 769) {
        return;
      }
      this.activeTooltipId = ref;
      this.activeTooltipText = tooltip;
      this.$nextTick(() => {
        this.$refs.journalTooltip.updatePosition();
      });
    },
  },

  computed: {
    cans() { return this.$store.getters.cans; },
    isStudent() { return this.$store.getters.isStudent; },
    cols() { return this.$store.getters.journalDays; },
    rows() {
      const { students, journalId, isPersonal } = this.$store.getters;
      let result = _.cloneDeep(students);
      if (isPersonal && journalId) {
        result = result.filter(item => item.journalId === journalId);
      } else if (!this.$store.getters.showRetiredStudents) {
        result = result.filter(item => item.isCurrent);
      }
      return result;
    },
  },
};
