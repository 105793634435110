import { Form } from '@common/mixins';
import _ from 'lodash';
import { QForm, QSlideTransition } from '@quasar/components';
import { requestWrapper } from '@vjs/helpers';
import { Helper } from '@common/src/helpers';

export default {
  mixins: [Form],
  name: 'ModalJournalCalcResult',
  components: { QForm, QSlideTransition },
  props: {},
  data() {
    const { calcResultTypes, disciplines } = this.$store.getters.refs;
    const fields = {
      type: {
        value: null,
        name: 'type',
        component: 'q-select-field',
        labelTransKey: 'label.type_calc_result',
        values: calcResultTypes ?? [],
        required: true,
        events: {
          input: async () => {
            await this.$store.dispatch('GET_CALC_RESULT_DATA', this.preparedData());
          },
        },
      },
      moduleSubject: {
        value: null,
        name: 'moduleSubject',
        component: 'q-select-field',
        labelTransKey: 'label.subject',
        values: disciplines ?? [],
        required: true,
        v_if(vm) {
          return vm.checkVIf('moduleSubject');
        },
        events: {
          input: async () => {
            await this.$store.dispatch('GET_CALC_RESULT_DATA', this.preparedData());
          },
        },
      },
      date: {
        value: '',
        name: 'date',
        component: 'q-date-field',
        labelTransKey: 'table.date',
        required: false,
        v_if(vm) {
          return vm.checkVIf('date');
        },
        events: {
          input: async () => {
            await this.$store.dispatch('GET_CALC_RESULT_DATA', this.preparedData());
          },
        },
      },
      allowAbsents: {
        value: false,
        name: 'allowAbsents',
        component: 'q-checkbox-field',
        checkboxLabelTransKey: 'label.allow_absents',
        required: false,
        v_if(vm) {
          return vm.checkVIf('allowAbsents');
        },
      },
    };

    return {
      fields,
    };
  },

  computed: {
    cans() { return this.$store.getters.cans; },
    refs() { return this.$store.getters.refs; },
    isModule() { return this.$store.getters.isModule; },
    checkVIf() {
      return (field) => {
        let allowedTypes = [];
        switch (field) {
          case 'moduleSubject':
            return this.isModule
              && this.fields.type.value
              && this.fields.type.value !== 'final_point_module'
              && this.fields.type.value !== 'half_year_module'
              && this.cans.calcResultShowDiscipline;
          case 'date':
            allowedTypes = ['module_edu_result', 'avg_module_edu_result', 'end_semester', 'half_year_module'];
            return (
              this.cans.calcResultShowDate
              && allowedTypes.includes(this.fields.type.value)
            ) || this.fields.type.value === 'attestation_day';
          case 'allowAbsents':
            allowedTypes = ['module_edu_result', 'end_semester', 'attestation_day'];
            return this.cans.calcResultAllowAbsents
              && allowedTypes.includes(this.fields.type.value);
          default:
            return true;
        }
      };
    },
  },

  methods: {
    preparedData() {
      const data = {};
      _.forEach(this.fields, (el) => {
        if (this.checkVIf(el.name)) {
          _.set(data, this.nameToDot(el.name), el.value);
        }
      });
      return data;
    },
    async handleAjaxFormSubmit() {
      this.$globalLoading.show();

      const data = this.preparedData();

      const { apiConfig, makeJournalUrl, journalId } = this.$store.getters;
      const res = await requestWrapper.call(this, {
        url: makeJournalUrl(apiConfig.calcResult.action, journalId),
        method: 'post',
        data,
      });

      if (!res.error) {
        const filter = this.$store.getters.currentFilter;
        await this.$store.dispatch(
          'GET_JOURNAL', { [this.isModule ? 'discipline_id' : 'semester']: filter },
        );
        this.$emit('onClose');
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }

      this.$globalLoading.hide();
    },
  },
};
