import { QPopupProxy, QIcon, QSlideTransition } from '@quasar/components';
import { Helper } from '@common/src/helpers';
import _ from 'lodash';
import { requestWrapper } from '@vjs/helpers';
import { eventBus } from '@common/mixins';

export default {
  name: 'VJournalControl',

  components: { QPopupProxy, QIcon, QSlideTransition },

  props: {
    uriGetData: String,
  },
  data() {
    return {
      resultSpoiler: false,
    };
  },

  methods: {
    makeBtn(
      label = '',
      onClick = () => {},
      color = 'blue',
      outline,
      icon,
      withHelp,
    ) {
      return {
        color, icon, label, onClick, outline, withHelp,
      };
    },
    handleExport() {
      this.$globalLoading.show();
      const vm = this;
      const {
        isModule, apiConfig, currentFilter, makeJournalUrl, journalId,
      } = this.$store.getters;

      const url = makeJournalUrl(apiConfig.export, journalId);
      const config = {
        responseType: 'blob',
        params: {
          [isModule ? 'discipline_id' : 'semester']: currentFilter,
          method: 'GET',
          headers: {
            'Accept-Language': window.core_project.locale,
          },
        },
      };

      this.$http.get(url, config)
        .then(
          (response) => {
            vm.$globalLoading.hide();
            const origFilename = (response.headers.get('Content-Disposition') || '').split('filename*=utf-8\'\'')[1];
            let filename = '';
            try {
              filename = decodeURIComponent(origFilename);
            } catch (e) {
              filename = origFilename;
            }
            const result = document.createElement('a');
            result.href = window.URL.createObjectURL(response.body);
            result.download = filename;
            result.click();
          },
          (response) => {
            vm.$globalLoading.hide();
            Helper.handlerResponseError(response);
          },
        );
    },
    showGenerateModal() {
      if (!this.isModule && _.isEmpty(this.refs.generateMonths)) {
        this.$notify({
          text: this.trans('journal.errors.no_days_for_generate'),
          type: 'warn',
        });
        return;
      }

      this.$sModal.open('v-modal-journal-generate-weeks', {
        component: 'v-modal-journal-generate-weeks',
        notPersistent: true,
      });
    },
    showConfirmModal() {
      this.$sModal.open('v-modal-confirm', {
        title: this.trans('notice.are_you_sure'),
        component: 'v-modal-confirm',
        notPersistent: true,
        events: { onApprove: this.confirmRestore },
      });
    },
    async confirmRestore() {
      this.$globalLoading.show();
      const {
        apiConfig, makeJournalUrl, journalId, currentFilter,
      } = this.$store.getters;

      const url = makeJournalUrl(apiConfig.restore, journalId);
      const res = await requestWrapper.call(this, {
        url,
        method: 'patch',
      });

      if (!res.error) {
        await this.$store.dispatch(
          'GET_JOURNAL', { [this.isModule ? 'discipline_id' : 'semester']: currentFilter },
        );
        eventBus.$emit('onCloseAll');
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }

      this.$globalLoading.hide();
    },
    async showCalcResultModal() {
      await this.$store.dispatch('GET_CALC_RESULT_DATA');
      this.$sModal.open('v-modal-journal-calc-result', {
        component: 'v-modal-journal-calc-result',
        notPersistent: true,
      });
    },
  },
  computed: {
    table() { return this.$store.getters.infoTable; },
    cans() { return this.$store.getters.cans; },
    refs() { return this.$store.getters.refs; },
    isPersonal() { return this.$store.getters.isPersonal; },
    isModule() { return this.$store.getters.isModule; },
    resultPopupTexts() {
      if (this.isModule) {
        return [
          this.trans('journal.calc_result_popup.final_module'),
          this.trans('journal.calc_result_popup.year_module'),
          this.trans('journal.calc_result_popup.summary_module'),
        ];
      }
      return [
        this.trans('journal.calc_result_popup.attestation'),
        this.trans('journal.calc_result_popup.year'),
        this.trans('journal.calc_result_popup.final'),
      ];
    },
    buttons() {
      const result = [];
      const {
        makeBtn, trans, cans, $store, isPersonal,
        handleExport, showCalcResultModal, showGenerateModal, showConfirmModal,
      } = this;

      const { isRemarksTableActive, students } = $store.getters;

      if (cans.calcResults) {
        result.push(makeBtn(trans('journal.calculate_result'), showCalcResultModal, undefined, undefined, undefined, true));
      }

      if (cans.export) {
        result.push(makeBtn(trans('journal.export'), handleExport, 'green', false, 'download'));
      }

      if (!isRemarksTableActive && !isPersonal && students.length > 1) {
        result.push(makeBtn(
          $store.getters.showRetiredStudents ? trans('journal.hide_retired') : trans('journal.show_retired'),
          () => $store.commit('TOGGLE_SHOW_RETIRED_STUDENTS'),
          undefined,
          true,
        ));
      }

      if (cans.generateDays) {
        result.push(makeBtn(trans('journal.generate_week'), showGenerateModal, undefined, true, 'calendar-alt'));
      }

      if (cans.restore) {
        result.push(makeBtn(trans('button.restore'), showConfirmModal, 'orange', true, 'trash-restore'));
      }

      return result;
    },
  },
};
