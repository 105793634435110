<template>
  <div class="row align-items-center mb-20">
    <v-wrapper-q-select
      v-model="discipline"
      :options="disciplines"
      :class="{
        'col-lg-2': $q.screen.width > 1110,
        'col-md-3': $q.screen.width < 1110 && $q.screen.width > 710,
        'col-md-6': $q.screen.width < 710 && $q.screen.width > 510,
        'col-sm-12': $q.screen.width < 510,
      }"
      :extra-binds="{
        hideBottomSpace: true,
      }"
      @input="updateTable"
    />
    <div
      :class="{
        'col-lg-2': $q.screen.width > 1110,
        'col-md-3': $q.screen.width < 1110 && $q.screen.width > 710,
        'col-md-6': $q.screen.width < 710 && $q.screen.width > 510,
        'col-sm-12': $q.screen.width < 510,
      }"
    >
      <q-btn-dropdown
        color="blue"
        :label="trans('button.add')"
        menu-anchor="bottom right"
        class="s-w-100"
      >
        <q-list>
          <q-item
            v-for="(item, key) in buttons"
            :key="key"
            clickable
            @click="handleAction(item.value)"
          >
            <q-item-section>
              <q-item-label> {{ item.label }} </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown>
    </div>
  </div>
</template>

<script>
import {QBtnDropdown, QList, QItem, QItemSection, QItemLabel} from '@quasar/components';

export default {
  name: 'TableFilters',
  components: {
    QBtnDropdown,
    QList,
    QItem,
    QItemSection,
    QItemLabel,
  },
  data() {
    return {
      discipline: null,
    };
  },
  computed: {
    disciplines() {
      const options = this.$store.getters.disciplines.filter(el => !el.is_module);
      options.unshift({id: null, name: this.trans('label.not_selected')});
      return options;
    },
    buttons() {
      return [
        {value: 'v-modal-add-chapter', label: this.trans('button.add_chapter')},
        {value: 'v-modal-add-lesson', label: this.trans('button.add_lesson')},
      ];
    },
  },
  methods: {
    handleAction(val) {
      let title = this.trans('curriculum.program.lesson_title');
      if (val === this.buttons[0].value) {
        title = this.trans('curriculum.program.chapter_title');
      }
      this.$sModal.open(val, {
        component: val,
        notPersistent: true,
        title,
      });
    },
    updateTable() {
      this.$globalLoading.show();
      this.$store.commit('SET_DISCIPLINE_ID', this.discipline);
      this.$store.dispatch('GET_CURRICULUM_PROGRAM_CONTENT_TABLE', {discipline_id: this.discipline});
      this.$globalLoading.hide();
    },
  },
};
</script>
