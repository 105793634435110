var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sc-journal__table-control"
  }, [_c('div', {
    staticClass: "sc-journal__table-control--select"
  }, [!_vm.isRemarksTableActive ? _c('v-wrapper-q-select', {
    attrs: {
      "options": _vm.filterOptions,
      "use-input": false,
      "extra-binds": {
        hideBottomSpace: true,
        placeholder: _vm.trans('training_schedule.semester')
      }
    },
    model: {
      value: _vm.filter,
      callback: function callback($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }) : _vm._e()], 1), _vm._v(" "), _vm.cans.seeRemarks && !_vm.cans.restore ? _c('q-btn', {
    attrs: {
      "color": "blue",
      "no-caps": "",
      "label-wider": "",
      "outline": ""
    },
    on: {
      "click": _vm.handleRemarksBtn
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.isRemarksTableActive ? _vm.trans('label.journal') : _vm.trans('journal.comments')))]), _vm._v(" "), _vm.newRemarksCount && !_vm.cans.changeRemarks && !_vm.isRemarksTableActive ? _c('div', {
    staticClass: "sc-journal__remarks-counter"
  }, [_vm._v("\n      " + _vm._s(_vm.newRemarksCount) + "\n    ")]) : _vm._e()]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }