<template>
  <div class="sc-journal__table-control">
    <div
      class="sc-journal__table-control--select"
    >
      <v-wrapper-q-select
        v-if="!isRemarksTableActive"
        v-model="filter"
        :options="filterOptions"
        :use-input="false"
        :extra-binds="{
          hideBottomSpace:true,
          placeholder: trans('training_schedule.semester'),
        }"
      />
    </div>
    <q-btn
      v-if="cans.seeRemarks && !cans.restore"
      color="blue"
      no-caps
      label-wider
      outline
      @click="handleRemarksBtn"
    >
      <span>{{ isRemarksTableActive ? trans('label.journal') : trans('journal.comments') }}</span>
      <div
        v-if="newRemarksCount && !cans.changeRemarks && !isRemarksTableActive"
        class="sc-journal__remarks-counter"
      >
        {{ newRemarksCount }}
      </div>
    </q-btn>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'VJournalTableControl',
  components: {},
  data() {
    return { filter: null };
  },

  computed: {
    cans() { return this.$store.getters.cans; },
    isRemarksTableActive() { return this.$store.getters.isRemarksTableActive; },
    newRemarksCount() { return this.$store.getters.remarks.filter(item => !item.isViewed)?.length; },
    filterOptions() {
      if (this.$store.getters.isModule) {
        return this.$store.getters.refs.disciplines;
      }
      return this.$store.getters.refs.semesters;
    },
  },
  watch: {
    currentSemester(val) {
      if (_.isEqual(val, this.filter)) {
        return;
      }
      this.filter = val;
    },
    async filter(val) {
      const { isModule } = this.$store.getters;
      this.$store.commit('SET_CURRENT_FILTER', val);
      await this.$store.dispatch(
        'GET_JOURNAL', { [isModule ? 'discipline_id' : 'semester']: val },
      );
    },
  },

  mounted() {
    if (!this.$store.getters.isModule) {
      this.$set(this, 'filter', this.$store.getters.currentSemester);
    }
  },
  methods: {
    handleRemarksBtn() {
      this.$store.commit('TOGGLE_IS_REMARKS_TABLE_ACTIVE');
    },
  },
};
</script>
