var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('q-dialog', {
    attrs: {
      "value": _vm.modal
    },
    on: {
      "input": _vm.handleModal
    }
  }, [_c('v-modal', {
    attrs: {
      "title": _vm.name,
      "size": "big"
    }
  }, [_c('table', {
    staticClass: "s-table-cabinet s-table-cabinet--modal"
  }, [_c('thead', {
    staticClass: "s-table__head"
  }, [_c('tr', {
    staticClass: "row s-w-100 no-gutters"
  }, _vm._l(_vm.required, function (column) {
    return _c('th', {
      staticClass: "s-to-center",
      class: _vm.classForTable
    }, [_vm._v("\n            " + _vm._s(column.name) + "\n          ")]);
  }), 0)]), _vm._v(" "), _c('tbody', {
    staticClass: "s-table__body"
  }, [_c('tr', {
    staticClass: "row s-w-100 no-gutters"
  }, _vm._l(_vm.required, function (column) {
    return _c('td', {
      staticClass: "s-to-center",
      class: _vm.classForTable,
      attrs: {
        "data-table-head": column.name
      }
    }, [_vm._v("\n            " + _vm._s(column.grade) + "\n          ")]);
  }), 0)])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }