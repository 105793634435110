<template>
  <div class="sc-journal-plan__control">
    <div
      v-for="(item, key) in table"
      :key="key"
      class="sc-journal-plan__panel sc-journal-plan__editor"
    >
      <template v-if="key !== 'files'">
        <div class="sc-journal-plan__title sc-journal-plan__title--border mb-15">
          {{ item.label }}:
        </div>
        <div
          v-if="item.value"
          class="sc-journal-plan__text"
          v-html="item.value"
        />
        <div
          v-else
          class="sc-journal-plan__text sc-journal-plan__text--grey"
        >
          {{ trans('table.empty_value') }}
        </div>
      </template>
      <template v-else>
        <div class="sc-journal-plan__title sc-journal-plan__title--border mb-15">
          {{ item.label }}:
        </div>
        <template v-if="item.value.length !== 0">
          <div class="d-flex align-items-center mt-10" v-for="el in item.value">
            <template v-if="['jpeg', 'jpg', 'png', 'gif','bmp'].includes(el.extension)">
              <vue-image-modal
                :uri-image="el.value"
                class="d-inline-block"
              ></vue-image-modal>
            </template>
            <template v-else>
              <a
                class="d-inline-block s-btn-table s-btn--ico"
                :href="el.value"
                target="_blank">
                <span class="s-ico s-ico--thm-eye"></span>
              </a>
            </template>
            <span class="d-inline-block ml-20"> {{ el.name }}</span>
          </div>
        </template>
        <div
          v-else
          class="sc-journal-plan__text sc-journal-plan__text--grey"
        >
          {{ trans('label.not_load') }}
        </div>
      </template>
    </div>
    <div class="row justify-content-end mb-60 mt-60">
      <div
        class="col-lg-3 col-md-3 col-sm-12"
        :class="$q.screen.width < 768 ? 'order-2' : ''"
      >
        <q-btn
          v-if="canEditPlan"
          class="s-w-100"
          :to="uriPlanEdit"
          type="a"
          color="blue"
          :label="trans('button.edit')"
          no-caps
        />
      </div>
    </div>
  </div>
</template>

<script>
import { requestWrapper } from '@vjs/helpers';

export default {
  name: 'VJournalPlanDescription',
  props: {
    uriPlanDescription: {
      type: String,
      default: null,
    },
    canEditPlan: Boolean,
    uriPlanEdit: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      table: [],
    };
  },
  async mounted() {
    await this.getInfoTable();
  },
  methods: {
    async getInfoTable() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.uriPlanDescription,
      });

      if (!res.error) {
        this.table = res.data;
      }
      this.$globalLoading.hide();
    },
  },
};
</script>
