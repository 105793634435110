var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('q-form', {
    attrs: {
      "autocorrect": "off",
      "autocapitalize": "off",
      "autocomplete": "off",
      "spellcheck": "false"
    },
    on: {
      "submit": _vm.handleAjaxFormSubmit
    }
  }, [_vm._l(_vm.fields, function (field) {
    return _c('q-slide-transition', {
      key: field.name
    }, [_vm.handleVif(field) ? _c('div', [_c(field.component, _vm._g({
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.handleVShow(field),
        expression: "handleVShow(field)"
      }],
      tag: "component",
      attrs: {
        "field": field,
        "field-name": field.labelTransKey ? _vm.trans(field.labelTransKey) : field.label,
        "errors": _vm.getErrors(field.name)
      },
      model: {
        value: field.value,
        callback: function callback($$v) {
          _vm.$set(field, "value", $$v);
        },
        expression: "field.value"
      }
    }, field.events ? field.events : {}))], 1) : _vm._e()]);
  }), _vm._v(" "), _c('div', {
    staticClass: "s-group s-group--wider justify-content-between"
  }, [_c('q-btn', {
    attrs: {
      "no-caps": "",
      "color": "grey",
      "label": _vm.trans('button.cancel')
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('onClose');
      }
    }
  }), _vm._v(" "), _c('q-btn', {
    attrs: {
      "color": "blue",
      "no-caps": "",
      "label": _vm.trans('button.send'),
      "type": "submit"
    }
  })], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }