export default {
  methods: {
    exportTable() {
      const vm = this;

      if (!vm.isActiveExport) {
        return;
      }

      const loader = vm.$loading.show();
      const config = {
        responseType: 'blob',
        method: 'GET',
        params: vm.getParams(),
        headers: {
          'Accept-Language': window.core_project.locale,
        },
      };

      config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;

      const request = vm.$http.get(vm.exportUrl, config);

      request.then(
        (response) => {
          if (vm.isInstantExport) {
            vm.instantExport(vm, response);
          } else {
            show_notice(vm.trans('notice.export_start_success'), 'notice');
            vm.$set(vm, 'isActiveExport', false);
          }
          loader.hide();
        },
        (response) => {
          console.log(response);
        },
      );
    },
    async instantExport(vm, response) {
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      const date = new Date();
      const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
      fileLink.setAttribute('download',
        vm.isPpz
          ? `${vm.trans('export.filenames.monitoring_statements_admission_of_commissions_ppz', {
            date: formattedDate,
          })}.xlsx`
          : `${vm.trans('export.filenames.monitoring_statements_admission_of_commissions', {
            date: formattedDate,
          })}.xlsx`);
      document.body.appendChild(fileLink);
      fileLink.click();
    },
  },
};
