<template>
  <div class="sc-journal-plan">
    <q-form
      autocorrect="off"
      autocapitalize="off"
      autocomplete="off"
      spellcheck="false"
      @submit="handleAjaxFormSubmit"
    >
      <div
        v-for="(item, key) in table"
        :key="key"
        class="mb-20"
      >
        <template v-if="key !== 'files'">
          <div class="sc-journal-plan__title mb-10">
            {{ item.label }}:
          </div>
          <ckeditor
            v-model="item.value"
            :editor="editor"
            :config="editorConfig"
          />
        </template>
      </div>
      <div v-for="(file, name) in form" class="row justify-content-between align-items-end mt-40">
        <div
          class="col-lg-3 col-md-6 col-sm-12"
        >
          <q-btn
            class="s-w-100"
            :label="file.length === 0 ? trans('button.add') : trans('label.attached')"
            :color="file.length === 0 ? 'blue' : 'green'"
            :icon-right="file.length === 0 ? 'plus' : 'clip'"
            label-wider
            ellipsis
            no-caps
            @click="triggerFile(name)"
          />
        </div>
        <div class="col-lg-9 col-md-6 col-sm-12">
          <q-btn
            outline
            :label="file.length === 0 ? trans('label.file_not_chosen') : file.name"
            class="s-w-100"
            label-wider
            color="grey"
            no-caps
            ellipsis
            :icon-right="file.length === 0 ? undefined : 'close'"
            @click="clearFile(name)"
          />
        </div>
      </div>
      <div class="row justify-content-between mb-60 mt-60">
        <div
          class="col-lg-3 col-md-6 col-sm-12"
          :class="$q.screen.width < 768 ? 'order-2' : ''"
        >
          <q-btn
            class="s-w-100"
            :to="backLink"
            type="a"
            color="grey"
            :label="trans('button.cancel')"
            no-caps
          />
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 mb-10">
          <q-btn
            class="s-w-100"
            type="submit"
            color="green"
            :label="trans('button.submit')"
            no-caps
          />
        </div>
      </div>
      <select-user-file
        :open-modal="modalFiles"
        :url-files="uriUserFiles"
        :url-file-store="uriUserFiles"
        @input="selectFile"
        @select-close="fileModalClose"
      />
    </q-form>
  </div>
</template>

<script>
import { requestWrapper } from '@vjs/helpers';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Helper } from '@common/src/helpers';
import SelectUserFile from '@app_college/views/SelectUserFile';
import Files from '@app_college/views/VStatementCreateView/mixins/Files';

export default {
  name: 'VJournalPlanEditForm',
  components: {
    SelectUserFile,
  },
  mixins: [Files],
  props: {
    uriPlanUpdate: {
      type: String,
      default: null,
    },
    uriPlanDescription: {
      type: String,
      default: null,
    },
    backLink: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      table: [],
      form: {
        file_one: [],
        file_two: [],
        file_three: [],
      },
      editor: ClassicEditor,
      editorConfig: {
        language: 'ru',
        toolbar: {
          items: [
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            'blockQuote',
            'undo',
            'redo',
          ],
        },
      },
    };
  },
  async mounted() {
    await this.getInfoTable();
  },
  methods: {
    async handleAjaxFormSubmit() {
      this.$globalLoading.show();
      const data = {};
      const files = [];
      _.forEach(this.table, (el, key) => {
        _.set(data, key, el.value);
      });
      _.forEach(this.form, (el) => {
        files.push(el);
      });
      _.set(data, 'files', files);
      const res = await requestWrapper.call(this, {
        url: this.uriPlanUpdate,
        method: 'patch',
        data,
      });

      if (res.error) {
        Helper.handlerResponseErrorNew(this, res);
      }

      this.$globalLoading.hide();
    },
    async getInfoTable() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.uriPlanDescription,
      });

      if (!res.error) {
        this.table = res.data;
        if (this.table.files.value.length) {
          this.form.file_one = this.table.files.value[0] ?? [];
          this.form.file_two = this.table.files.value[1] ?? [];
          this.form.file_three = this.table.files.value[2] ?? [];
        }
      }
      this.$globalLoading.hide();
    },
  },
};
</script>
