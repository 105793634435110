var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "s-application-block",
    class: {
      'is-locked': _vm.locked.isLocked
    }
  }, [_c('div', {
    staticClass: "s-application-block__columns"
  }, [_c('div', {
    staticClass: "s-application-block__column-left"
  }, [_c('div', {
    staticClass: "s-application-block__head"
  }, [_c('div', {
    staticClass: "s-application-block__name"
  }, [_vm._v("\n          " + _vm._s(_vm.label) + "\n        ")]), _vm._v(" "), _c('div', {
    staticClass: "s-application-block__mark"
  }, [_vm._v("\n          " + _vm._s(_vm.identifierDate) + "\n        ")])]), _vm._v(" "), _c('div', {
    staticClass: "s-application-block__body"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('transition', {
    attrs: {
      "name": "a-slide-vertical"
    }
  }, [_vm.stepsTrigger ? _c('div', {
    staticClass: "row"
  }, _vm._l(_vm.descAdditional, function (item, idx) {
    return _c('div', {
      key: idx,
      staticClass: "col-lg-4 col-md-4 col-sm-4"
    }, [_c('div', {
      staticClass: "s-desc-item flex-direction-row"
    }, [_c('div', {
      staticClass: "s-desc-item__label mb-5"
    }, [_c('img', {
      attrs: {
        "src": "/images/acc.png"
      }
    })]), _vm._v(" "), _c('div', [_c('div', {
      staticClass: "s-desc-item__label mb-5"
    }, [_vm._v("\n                      " + _vm._s(item.label) + ":\n                    ")]), _vm._v(" "), _c('div', {
      staticClass: "s-desc-item__value"
    }, [_vm._v("\n                      " + _vm._s(item.value) + "\n                    ")])])])]);
  }), 0) : _vm._e()])], 1), _vm._v(" "), _c('div', {
    staticClass: "row"
  }, [_vm.type === 'service' ? [_c('div', {
    staticClass: "col-lg-5 col-md-5 col-sm-5"
  }, [_c('div', {
    staticClass: "s-desc-item"
  }, [_c('div', {
    staticClass: "s-desc-item__label mb-5"
  }, [_vm._v("\n                  " + _vm._s(_vm.desc[0].label) + ":\n                ")]), _vm._v(" "), _c('div', {
    staticClass: "s-desc-item__value"
  }, [_vm._v("\n                  " + _vm._s(_vm.desc[0].value) + "\n                ")])])]), _vm._v(" "), _c('div', {
    staticClass: "col-lg-5 col-md-5 col-sm-5"
  }, [_c('div', {
    staticClass: "s-desc-item"
  }, [_c('div', {
    staticClass: "s-desc-item__label mb-5"
  }, [_vm._v("\n                  " + _vm._s(_vm.desc[1].label) + ":\n                ")]), _vm._v(" "), _c('div', {
    staticClass: "s-desc-item__value"
  }, [_vm._v("\n                  " + _vm._s(_vm.desc[1].value) + "\n                ")])])]), _vm._v(" "), _c('div', {
    staticClass: "col-lg-2 col-md-2 col-sm-2"
  }, [_c('div', {
    staticClass: "s-desc-item"
  }, [_c('div', {
    staticClass: "s-desc-item__label mb-5"
  }, [_vm._v("\n                  " + _vm._s(_vm.desc[2].label) + ":\n                ")]), _vm._v(" "), _c('div', {
    staticClass: "s-desc-item__value"
  }, [_vm._v("\n                  " + _vm._s(_vm.desc[2].value) + "\n                ")])])])] : _vm._l(_vm.desc, function (item, idx) {
    return _c('div', {
      key: idx,
      staticClass: "col-lg-2 col-md-2 col-sm-2"
    }, [_c('div', {
      staticClass: "s-desc-item"
    }, [_c('div', {
      staticClass: "s-desc-item__label mb-5"
    }, [_vm._v("\n                  " + _vm._s(item.label) + ":\n                ")]), _vm._v(" "), _c('div', {
      staticClass: "s-desc-item__value"
    }, [_vm._v("\n                  " + _vm._s(item.value) + "\n                ")])])]);
  })], 2)])]), _vm._v(" "), !_vm.locked.isLocked ? _c('div', {
    staticClass: "s-application-block__column-right"
  }, [_c('div', {
    staticClass: "s-application-block__controls"
  }, [_c('div', {
    staticClass: "s-btn s-btn--border s-btn--thm-light-purple",
    on: {
      "click": function click($event) {
        _vm.stepsTrigger = !_vm.stepsTrigger;
      }
    }
  }, [_c('div', {
    class: "s-str-ico-rev s-ico--thm-angle-".concat(_vm.stepsTrigger ? 'up' : 'down')
  }, [_vm._v("\n            " + _vm._s(_vm.stepsTrigger ? _vm.trans('statement.hide') : _vm.trans('statement.show_more')) + "\n          ")])]), _vm._v(" "), _vm.canRemove ? _c('q-btn', {
    attrs: {
      "icon": "trash",
      "color": "red",
      "squire": "",
      "outline": ""
    },
    on: {
      "click": function click($event) {
        _vm.modalConfirmRemove = true;
      }
    }
  }, [_c('q-tooltip', [_vm._v("\n            " + _vm._s(_vm.trans('statement.remove')) + "\n          ")])], 1) : _vm._e()], 1), _vm._v(" "), _vm.docsBtn.active ? _c('q-field', {
    staticClass: "s-w-100 s-application-block__controls--update-btn",
    attrs: {
      "borderless": "",
      "preview": "",
      "label": _vm.trans('label.doc_086_short')
    }
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "color": "green",
      "no-caps": "",
      "label": _vm.trans('button.add')
    },
    on: {
      "click": function click($event) {
        _vm.docsModal = !_vm.docsModal;
      }
    }
  })], 1) : _vm._e()], 1) : _vm._e()]), _vm._v(" "), !_vm.locked.isLocked ? _c('div', {
    staticClass: "s-application-block__steps"
  }, _vm._l(_vm.steps, function (step, stepIndex) {
    return _c('div', {
      key: stepIndex,
      staticClass: "s-application-block__step"
    }, [_c('div', {
      staticClass: "s-application-block__step-head",
      class: "s-application-block__step-head--".concat(step.status)
    }, [_c('div', {
      staticClass: "s-vis-ellipsis"
    }, [_c('span', {
      class: "s-str-ico s-ico--thm-".concat(_vm.getIconByStatus(step.status))
    }, [_vm._v("\n            " + _vm._s(step.title) + "\n          ")])])]), _vm._v(" "), _c('transition', {
      attrs: {
        "name": "a-slide-vertical"
      }
    }, [_vm.stepsTrigger ? _c('div', {
      staticClass: "s-application-block__step-body"
    }, [_c('div', {
      staticClass: "s-application-block__step-text",
      domProps: {
        "innerHTML": _vm._s(step.text)
      }
    }), _vm._v(" "), step.controls ? _c('div', {
      staticClass: "s-group s-group--to-center"
    }, [_vm._l(step.controls, function (ctrl, ctrlIndex) {
      return [_c('div', {
        key: ctrlIndex,
        staticClass: "d-flex justify-content-center"
      }, [_c('q-btn', {
        staticClass: "s-btn",
        attrs: {
          "width": "15",
          "label": ctrl.label,
          "color": ctrl.color ? ctrl.color : 'green',
          "disable": ctrl.disabled,
          "no-caps": "",
          "type": ctrl.disabled ? 'button' : 'a',
          "to": ctrl.disabled ? undefined : ctrl.link,
          "target": ctrl.target ? ctrl.target : '_self'
        }
      })], 1)];
    })], 2) : _vm._e()]) : _vm._e()])], 1);
  }), 0) : _vm._e(), _vm._v(" "), _vm.locked.isLocked ? _c('div', {
    staticClass: "s-application-block__warning"
  }, [_c('div', {
    staticClass: "s-application-block__warning-message",
    domProps: {
      "innerHTML": _vm._s(_vm.locked.label)
    }
  }, [_vm._v("\n      " + _vm._s(_vm.trans('statement.is_interest_stoplist')) + "\n    ")]), _vm._v(" "), _vm.locked.btn.withBtn ? _c('q-btn', {
    attrs: {
      "color": "green",
      "label": _vm.locked.btn.label,
      "no-caps": "",
      "type": "a",
      "target": _vm.locked.btn.target ? _vm.locked.btn.target : '_self',
      "to": _vm.locked.btn.link,
      "width": "20"
    }
  }) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _c('v-confirm-dialog', {
    attrs: {
      "modal": _vm.modalConfirmRemove,
      "loading": _vm.loadingRemove,
      "confirm": _vm.removeApplication
    },
    on: {
      "modal-close": function modalClose($event) {
        _vm.modalConfirmRemove = $event;
      }
    }
  }), _vm._v(" "), _vm.docsBtn.active ? _c('v-application-docs-modal', {
    attrs: {
      "modal": _vm.docsModal,
      "uri-user-files": _vm.docsBtn.uriUserFiles,
      "uri-update-files": _vm.docsBtn.uriAction
    },
    on: {
      "modal-close": function modalClose($event) {
        _vm.docsModal = $event;
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }