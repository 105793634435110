var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sc-curriculum-program"
  }, [_c('v-curriculum-program-title', {
    attrs: {
      "name": _vm.groupName
    }
  }), _vm._v(" "), _c('v-curriculum-program-info')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }