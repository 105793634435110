var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sc-student-transcript"
  }, [_c('v-wrapper-q-select', {
    ref: "subjectSelect",
    attrs: {
      "options": _vm.subjectsComputed,
      "input-debounce": "500",
      "use-options-from-prop": "",
      "extra-binds": {
        labelTransKey: 'student_transcript.select_subject',
        hideBottomSpace: true
      },
      "multiple": true,
      "show-multiple-values": false,
      "custom-filter-fn": _vm.requestSearchSubjects
    },
    on: {
      "input-value-change": function inputValueChange($event) {
        _vm.subjectSelectConfig.inputValue = $event;
      },
      "virtual-scroll": _vm.onSubScroll,
      "input": _vm.handleSelectSubject
    }
  }), _vm._v(" "), _vm.transcripts.length ? _c('table', {
    staticClass: "sc-student-transcript__table"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v(_vm._s(_vm.trans('student_transcript.selected_subjects')) + ":")]), _vm._v(" "), _c('th', [_vm._v(_vm._s(_vm.trans('student_transcript.type_of_evaluation')) + ":")]), _vm._v(" "), _c('th', [_vm._v(_vm._s(_vm.trans('student_transcript.point')) + ":")]), _vm._v(" "), _c('th')])]), _vm._v(" "), _c('tbody', _vm._l(_vm.transcripts, function (transcript, key) {
    return _c('tr', {
      key: key
    }, [_c('td', [_c('q-field', {
      attrs: {
        "hide-bottom-space": ""
      }
    }, [_vm._v("\n            " + _vm._s(transcript.name) + "\n          ")])], 1), _vm._v(" "), _c('td', [_c('v-wrapper-q-select', {
      attrs: {
        "use-input": false,
        "options": _vm.options.typesOfEvaluation,
        "use-options-from-prop": "",
        "extra-binds": {
          hideBottomSpace: true
        }
      },
      on: {
        "input": function input($event) {
          transcript.point = null;
        }
      },
      model: {
        value: transcript.typeOfEvaluation,
        callback: function callback($$v) {
          _vm.$set(transcript, "typeOfEvaluation", $$v);
        },
        expression: "transcript.typeOfEvaluation"
      }
    })], 1), _vm._v(" "), _c('td', [transcript.typeOfEvaluation === 1 ? _c('q-field', {
      staticClass: "sn-input",
      attrs: {
        "hide-bottom-space": ""
      },
      scopedSlots: _vm._u([{
        key: "rawControl",
        fn: function fn(_ref) {
          var id = _ref.id;
          return [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: transcript.point,
              expression: "transcript.point"
            }, {
              name: "mask",
              rawName: "v-mask",
              value: {
                mask: ['99', '100'],
                greedy: false,
                placeholder: ''
              },
              expression: "{\n                  mask: ['99', '100'],\n                  greedy: false,\n                  placeholder: '',\n                }"
            }],
            staticClass: "sn-field__native sn-placeholder",
            attrs: {
              "id": id,
              "type": "number"
            },
            domProps: {
              "value": transcript.point
            },
            on: {
              "input": [function ($event) {
                if ($event.target.composing) { return; }

                _vm.$set(transcript, "point", $event.target.value);
              }, function ($event) {
                return _vm.handleInput(transcript);
              }]
            }
          })];
        }
      }], null, true)
    }) : _c('v-wrapper-q-select', {
      attrs: {
        "use-input": false,
        "options": transcript.typeOfEvaluation === 0 ? _vm.points : _vm.creditNotCredit,
        "use-options-from-prop": "",
        "extra-binds": {
          hideBottomSpace: true
        }
      },
      model: {
        value: transcript.point,
        callback: function callback($$v) {
          _vm.$set(transcript, "point", $$v);
        },
        expression: "transcript.point"
      }
    })], 1), _vm._v(" "), _c('td', [_c('div', {
      class: _vm.$q.screen.width < 768 ? 's-group' : 'd-flex align-items-center justify-content-center'
    }, [_c('q-btn', {
      attrs: {
        "squire": _vm.$q.screen.width >= 768,
        "color": "orange",
        "label": _vm.$q.screen.width < 768 ? _vm.trans('student_transcript.remove_subject') : undefined,
        "icon": _vm.$q.screen.width < 768 ? undefined : 'close',
        "no-caps": ""
      },
      on: {
        "click": function click($event) {
          return _vm.removeTranscript(key);
        }
      }
    })], 1)])]);
  }), 0)]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "s-group s-group--wider justify-content-between mt-40"
  }, [_c('q-btn', {
    staticClass: "s-btn--w-200",
    attrs: {
      "label": _vm.trans('button.cancel'),
      "color": "grey",
      "no-caps": "",
      "type": "a",
      "to": _vm.apiConfig.back
    }
  }), _vm._v(" "), _c('q-btn', {
    staticClass: "s-btn--w-200",
    attrs: {
      "label": _vm.trans('button.save'),
      "color": "green",
      "no-caps": "",
      "disable": _vm.transcripts.length === 0
    },
    on: {
      "click": _vm.requestSubmit
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }