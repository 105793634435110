import { Helper } from '@common/src/helpers';

export default {
  makeProgramUrl: (url, parentId) => Helper.urlGenerate(url, { parentId }),
  vm: null,
  curriculumProgramApiConfig: {

  },
  parentId: null,
  commonInfo: {},
  semesterNumber: null,
  courseNumber: null,
  disciplines: [],
  programDisciplineId: null,
  content: {
    meta: [],
    items: [],
  },
};
