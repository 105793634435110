var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('q-dialog', {
    staticClass: "library-modal",
    model: {
      value: _vm.modal,
      callback: function callback($$v) {
        _vm.modal = $$v;
      },
      expression: "modal"
    }
  }, [_c('v-modal', {
    attrs: {
      "title": _vm.getModalName(_vm.modalType)
    }
  }, [_c('v-form-inform', {
    attrs: {
      "message": _vm.trans('warning_text.modal_inform')
    }
  }), _vm._v(" "), _c('q-form', {
    ref: "myForm",
    on: {
      "submit": _vm.sendForm
    }
  }, [_c('q-input', {
    class: _vm.loading ? 'sn--cursor-wait' : '',
    attrs: {
      "type": "text",
      "name": "book_name",
      "required": "",
      "rules": [_vm.required(_vm.trans('validation.required', {
        attribute: _vm.trans('label.book_name')
      }))],
      "label": _vm.trans('label.book_name'),
      "placeholder": _vm.trans('placeholder.name'),
      "disable": _vm.loading,
      "loading": _vm.loading
    },
    model: {
      value: _vm.modalData.book_name,
      callback: function callback($$v) {
        _vm.$set(_vm.modalData, "book_name", $$v);
      },
      expression: "modalData.book_name"
    }
  }), _vm._v(" "), _c('q-input', {
    class: _vm.loading ? 'sn--cursor-wait' : '',
    attrs: {
      "type": "text",
      "name": "author",
      "required": "",
      "rules": [_vm.required(_vm.trans('validation.required', {
        attribute: _vm.trans('label.author')
      }))],
      "label": _vm.trans('label.author'),
      "placeholder": _vm.trans('placeholder.author'),
      "disable": _vm.loading,
      "loading": _vm.loading
    },
    model: {
      value: _vm.modalData.author,
      callback: function callback($$v) {
        _vm.$set(_vm.modalData, "author", $$v);
      },
      expression: "modalData.author"
    }
  }), _vm._v(" "), _c('q-input', {
    class: _vm.loading ? 'sn--cursor-wait' : '',
    attrs: {
      "type": "text",
      "name": "year",
      "required": "",
      "rules": [_vm.required(_vm.trans('validation.required', {
        attribute: _vm.trans('label.create_year')
      })), _vm.validateYear()],
      "label": _vm.trans('label.create_year'),
      "placeholder": _vm.trans('placeholder.year'),
      "disable": _vm.loading,
      "loading": _vm.loading
    },
    model: {
      value: _vm.modalData.year,
      callback: function callback($$v) {
        _vm.$set(_vm.modalData, "year", $$v);
      },
      expression: "modalData.year"
    }
  }), _vm._v(" "), _c('q-select', {
    class: _vm.loading ? 'sn--cursor-wait' : '',
    attrs: {
      "options": _vm.types.full,
      "label": _vm.trans('label.book_type'),
      "emit-value": "",
      "map-options": "",
      "disable": _vm.loading,
      "loading": _vm.loading
    },
    model: {
      value: _vm.modalData.type,
      callback: function callback($$v) {
        _vm.$set(_vm.modalData, "type", $$v);
      },
      expression: "modalData.type"
    }
  }), _vm._v(" "), _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.modalData.type === 2 ? _c('q-select', {
    class: _vm.loading ? 'sn--cursor-wait' : '',
    attrs: {
      "options": _vm.qualification,
      "label": _vm.trans('label.profession'),
      "emit-value": "",
      "map-options": "",
      "disable": _vm.loading,
      "loading": _vm.loading
    },
    on: {
      "filter": _vm.filterFn,
      "filter-abort": _vm.abortFilterFn
    },
    model: {
      value: _vm.modalData.profession_id,
      callback: function callback($$v) {
        _vm.$set(_vm.modalData, "profession_id", $$v);
      },
      expression: "modalData.profession_id"
    }
  }) : _vm._e()], 1), _vm._v(" "), _c('div', {
    staticClass: "mb-10"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('label.description')) + ":\n        ")]), _vm._v(" "), _c('ckeditor', {
    attrs: {
      "editor": _vm.editor,
      "config": _vm.editorConfig,
      "disabled": _vm.loading
    },
    model: {
      value: _vm.modalData.description,
      callback: function callback($$v) {
        _vm.$set(_vm.modalData, "description", $$v);
      },
      expression: "modalData.description"
    }
  }), _vm._v(" "), _c('q-checkbox', {
    class: _vm.loading ? 'sn--cursor-wait' : '',
    attrs: {
      "true-value": 1,
      "false-value": 0,
      "type": "checkbox",
      "label": _vm.trans('label.visible_to_students'),
      "disable": _vm.loading
    },
    model: {
      value: _vm.modalData.visible_to_students,
      callback: function callback($$v) {
        _vm.$set(_vm.modalData, "visible_to_students", $$v);
      },
      expression: "modalData.visible_to_students"
    }
  }), _vm._v(" "), _c('br'), _vm._v(" "), !_vm.modalData.id || !_vm.modalData.book_path ? _c('q-checkbox', {
    class: _vm.loading ? 'sn--cursor-wait' : '',
    attrs: {
      "true-value": 1,
      "false-value": 0,
      "type": "checkbox",
      "label": _vm.trans('placeholder.left_source'),
      "disable": _vm.loading || _vm.modalData.id && _vm.modalData.book_link
    },
    on: {
      "input": _vm.handleLinkVisibleCheck
    },
    model: {
      value: _vm.modalData.link_visible,
      callback: function callback($$v) {
        _vm.$set(_vm.modalData, "link_visible", $$v);
      },
      expression: "modalData.link_visible"
    }
  }) : _vm._e(), _vm._v(" "), _vm.modalData.link_visible ? _c('q-input', {
    class: _vm.loading ? 'sn--cursor-wait' : '',
    attrs: {
      "type": "text",
      "required": "",
      "label": _vm.trans('placeholder.book_link'),
      "placeholder": _vm.trans('placeholder.book_link'),
      "disable": _vm.loading,
      "loading": _vm.loading
    },
    model: {
      value: _vm.modalData.book_link,
      callback: function callback($$v) {
        _vm.$set(_vm.modalData, "book_link", $$v);
      },
      expression: "modalData.book_link"
    }
  }) : _vm._e(), _vm._v(" "), _c('div', [_c('div', {
    staticClass: "row justify-content-between mt-15",
    class: _vm.loading ? 'sn--non-selectable sn--disabled sn--cursor-wait' : ''
  }, [!_vm.modalData.link_visible ? _c('div', {
    staticClass: "col-12 col-sm-6 mb-10"
  }, [_c('label', {
    staticClass: "s-w-100",
    class: ['s-btn', 'college-library-upload-file', {
      's-btn--thm-orange': !_vm.modalData.book,
      's-btn--thm-green': _vm.modalData.book || _vm.editBook
    }],
    attrs: {
      "for": _vm.loading ? '' : 'file_book'
    }
  }, [_c('span', {
    staticClass: "s-str-ico s-str-ico--thm-plus  s-str-ico--wider"
  }, [_vm._v("\n                  " + _vm._s(_vm.getBookName()) + "\n                ")])]), _vm._v(" "), _c('input', {
    ref: "file_book",
    staticClass: "hidden",
    attrs: {
      "id": "file_book",
      "type": "file",
      "name": "file[book]"
    },
    on: {
      "change": function change($event) {
        return _vm.bookUpload();
      }
    }
  })]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "col-12 col-sm-6"
  }, [_c('label', {
    staticClass: "s-w-100",
    class: ['s-btn', 'college-library-upload-file', {
      's-btn--thm-orange': !_vm.modalData.cover,
      's-btn--thm-green': _vm.modalData.cover || _vm.editCover
    }],
    attrs: {
      "for": _vm.loading ? '' : 'file_cover'
    }
  }, [_c('span', {
    staticClass: "s-str-ico s-str-ico--thm-plus  s-str-ico--wider': !modalData.cover"
  }, [_vm._v("\n                  " + _vm._s(_vm.getCoverName()) + "\n                ")])]), _vm._v(" "), _c('input', {
    ref: "file_cover",
    staticClass: "hidden",
    attrs: {
      "id": "file_cover",
      "type": "file",
      "name": "file[cover]"
    },
    on: {
      "change": function change($event) {
        return _vm.coverUpload();
      }
    }
  })])])]), _vm._v(" "), _c('div', {
    staticClass: "row align-items-stretch justify-content-end"
  }, [_c('div', {
    staticClass: "col-12 col-sm-auto"
  }, [_c('q-btn', {
    staticClass: "mt-20 s-w-100",
    class: _vm.loading ? 'sn--cursor-wait' : '',
    attrs: {
      "label-wider": "",
      "type": "submit",
      "color": "green",
      "icon": "check",
      "no-caps": "",
      "label": _vm.trans('button.send'),
      "disable": _vm.loading
    }
  })], 1)])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }