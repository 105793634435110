var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.statement.soc_status_id !== 1 && _vm.approveSocStatusActive && _vm.statement.is_check !== 0 ? _c('a', {
    staticClass: "s-btn s-btn--thm-orange s-btn--w-150",
    attrs: {
      "data-action": "confirm",
      "data-method": "POST",
      "href": _vm.approveSocStatusUrl
    }
  }, [_vm._v("\n    " + _vm._s(_vm.trans('button.approve_soc_status')) + "\n  ")]) : _vm._e(), _vm._v(" "), !_vm.statement.is_check ? [_vm.signXml === null ? _c('button', {
    staticClass: "s-btn s-btn--thm-blue s-btn--w-150",
    class: _vm.signXml === null ? 's-btn--thm-blue' : 's-btn--thm-green',
    on: {
      "click": function click($event) {
        return _vm.sign();
      }
    }
  }, [_vm._v("\n      " + _vm._s(_vm.signXml === null ? _vm.trans('button.add_sign_ncalayer') : _vm.trans('button.add_signed_ncalayer')) + "\n    ")]) : _c('label', {
    staticClass: "s-btn"
  }, [_vm._v("\n      " + _vm._s(_vm.trans('button.add_signed_ncalayer')) + "\n    ")]), _vm._v(" "), !_vm.isNedb ? [_vm.signXml !== null ? [_vm.statement.soc_status_id !== 1 && _vm.approveSocStatusActive ? _c('button', {
    directives: [{
      name: "s-modal",
      rawName: "v-s-modal",
      value: {
        id: 'v-modal-statement-attestation-average-point',
        component: 'v-modal-statement-attestation-average-point',
        notPersistent: true,
        binds: {
          uriSubmit: _vm.modalApproveWithSocStatusCandidate,
          signXml: _vm.signXml
        }
      },
      expression: "{\n            id: 'v-modal-statement-attestation-average-point',\n            component: 'v-modal-statement-attestation-average-point',\n            notPersistent: true,\n            binds: {\n              uriSubmit: modalApproveWithSocStatusCandidate,\n              signXml: signXml\n            }}"
    }],
    staticClass: "s-btn s-btn--thm-orange s-btn--w-150"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('button.approve_with_soc_status')) + "\n        ")]) : _vm._e(), _vm._v(" "), _c('button', {
    directives: [{
      name: "s-modal",
      rawName: "v-s-modal",
      value: {
        id: 'v-modal-statement-attestation-average-point',
        component: 'v-modal-statement-attestation-average-point',
        notPersistent: true,
        binds: {
          uriSubmit: _vm.modalApproveCandidate,
          signXml: _vm.signXml
        }
      },
      expression: "{\n            id: 'v-modal-statement-attestation-average-point',\n            component: 'v-modal-statement-attestation-average-point',\n            notPersistent: true,\n            binds: {\n              uriSubmit: modalApproveCandidate,\n              signXml: signXml\n            }}"
    }],
    staticClass: "s-btn s-btn--thm-green s-btn--w-150"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('button.approve')) + "\n        ")])] : [_vm.statement.soc_status_id !== 1 && _vm.approveSocStatusActive ? _c('button', {
    staticClass: "s-btn s-btn--thm-orange s-btn--w-150",
    attrs: {
      "disabled": "disabled"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.trans('button.approve_with_soc_status')) + "\n        ")]) : _vm._e(), _vm._v(" "), _c('button', {
    staticClass: "s-btn s-btn--thm-green s-btn--w-150",
    attrs: {
      "disabled": "disabled"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.trans('button.approve')) + "\n        ")])]] : [_vm.statement.soc_status_id !== 1 && _vm.approveSocStatusActive ? _c('button', {
    staticClass: "s-btn s-btn--thm-orange s-btn--w-150",
    attrs: {
      "data-toggle": "modal",
      "disabled": _vm.signXml === null,
      "data-target": '#modal-approve-with-soc-status-candidate-' + _vm.statement.id
    }
  }, [_vm._v("\n        " + _vm._s(_vm.trans('button.approve_with_soc_status')) + "\n      ")]) : _vm._e(), _vm._v(" "), _c('button', {
    directives: [{
      name: "s-modal",
      rawName: "v-s-modal",
      value: {
        id: 'v-modal-statement-attestation-average-point',
        component: 'v-modal-statement-attestation-average-point',
        notPersistent: true,
        binds: {
          uriSubmit: _vm.modalApproveCandidate,
          signXml: _vm.signXml
        }
      },
      expression: "{\n          id: 'v-modal-statement-attestation-average-point',\n          component: 'v-modal-statement-attestation-average-point',\n          notPersistent: true,\n          binds: {\n            uriSubmit: modalApproveCandidate,\n            signXml: signXml\n          }}"
    }],
    staticClass: "s-btn s-btn--thm-green s-btn--w-150",
    attrs: {
      "disabled": _vm.signXml === null
    }
  }, [_vm._v("\n        " + _vm._s(_vm.trans('button.approve')) + "\n      ")])], _vm._v(" "), _c('button', {
    staticClass: "s-btn s-btn--thm-red s-btn--w-150",
    attrs: {
      "data-toggle": "modal",
      "disabled": _vm.signXml === null,
      "data-target": '#modal-confirm-candidate-' + _vm.statement.id
    }
  }, [_vm._v("\n      " + _vm._s(_vm.trans('button.decline')) + "\n    ")])] : _vm._e(), _vm._v(" "), _vm.setTargetTrainingActive ? _c('a', {
    staticClass: "s-btn s-btn--thm-blue s-btn--w-150",
    attrs: {
      "data-action": "confirm",
      "data-method": "POST",
      "data-inform-message": _vm.trans('notice.statement_target_training_warning'),
      "href": _vm.setTargetTrainingUrl
    }
  }, [_vm._v("\n    " + _vm._s(_vm.trans('button.set_target_training')) + "\n  ")]) : _vm._e(), _vm._v(" "), _vm.statementReceipt && _vm.statement.is_check ? _c('a', {
    staticClass: "s-btn s-btn--thm-green s-btn--w-150",
    attrs: {
      "target": "_blank",
      "href": _vm.statementReceiptUrl
    }
  }, [_vm._v("\n    " + _vm._s(_vm.trans('statement.download_receipt')) + "\n  ")]) : _vm._e(), _vm._v(" "), _c('v-statement-sign-modals', {
    attrs: {
      "statement": _vm.statement,
      "set-modal-confirm-candidate-rework": _vm.setModalConfirmCandidateRework,
      "statement-disapprove": _vm.statementDisapprove,
      "modal-confirm-candidate": _vm.modalConfirmCandidate,
      "modal-approve-with-soc-status-candidate": _vm.approveWithStatusUrl,
      "modal-approve-candidate": _vm.approveUrl,
      "sign-xml": _vm.signXml,
      "csrf-token": _vm.csrfToken
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }