var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('q-dialog', {
    attrs: {
      "persistent": "",
      "value": _vm.modal
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('modal-close', false);
      }
    }
  }, [_c('v-modal', {
    attrs: {
      "title": _vm.trans('label.preview'),
      "size": "medium"
    }
  }, [_c('div', {
    staticClass: "s-modal__body d-flex flex-column"
  }, [_c('img', {
    attrs: {
      "alt": "image-preview",
      "src": _vm.src
    }
  })])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }