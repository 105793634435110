var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('q-form', {
    staticClass: "modal-reassign-specialty-places",
    attrs: {
      "autocorrect": "off",
      "autocapitalize": "off",
      "autocomplete": "off",
      "spellcheck": "false"
    },
    on: {
      "submit": _vm.handleAjaxFormSubmit
    }
  }, [_c('div', {
    staticClass: "mb-20"
  }, [_c('div', {
    staticClass: "specialty-info-table"
  }, [_c('div', [_vm._v(_vm._s(_vm.trans('label.current_specialty')) + ":")]), _vm._v(" "), _c('div', [_vm._v(_vm._s(_vm.row.specialty))]), _vm._v(" "), _c('div', [_vm._v(_vm._s(_vm.trans('label.unused_places_count')) + ":")]), _vm._v(" "), _c('div', [_vm._v(_vm._s(_vm.row.places_count))])]), _vm._v(" "), _c('v-wrapper-q-select', {
    attrs: {
      "required": "",
      "options": _vm.specialities,
      "extra-binds": {
        label: _vm.trans('label.select_specialty'),
        required: true,
        hideBottomSpace: true
      }
    },
    on: {
      "input": _vm.requestGetEduLevels
    },
    model: {
      value: _vm.form.specialtyId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "specialtyId", $$v);
      },
      expression: "form.specialtyId"
    }
  }), _vm._v(" "), _c('q-slide-transition', [_vm.eduLevels.length ? _c('v-wrapper-q-select', {
    attrs: {
      "required": "",
      "options": _vm.eduLevels,
      "use-input": false,
      "extra-binds": {
        label: _vm.trans('label.select_edu_level'),
        required: true,
        hideBottomSpace: true
      }
    },
    model: {
      value: _vm.form.eduLevel,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "eduLevel", $$v);
      },
      expression: "form.eduLevel"
    }
  }) : _vm._e()], 1), _vm._v(" "), _c('q-field', {
    staticClass: "sn-input",
    attrs: {
      "hide-bottom-space": "",
      "label": _vm.trans('label.places_count'),
      "required": ""
    },
    scopedSlots: _vm._u([{
      key: "rawControl",
      fn: function fn(_ref) {
        var id = _ref.id;
        return [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form.places,
            expression: "form.places"
          }, {
            name: "mask",
            rawName: "v-mask",
            value: {
              mask: ['99', '100'],
              greedy: false,
              placeholder: ''
            },
            expression: "{\n            mask: ['99', '100'],\n            greedy: false,\n            placeholder: '',\n          }"
          }],
          staticClass: "sn-field__native sn-placeholder",
          attrs: {
            "id": id,
            "min": "0",
            "max": _vm.row.places_count,
            "type": "number"
          },
          domProps: {
            "value": _vm.form.places
          },
          on: {
            "input": [function ($event) {
              if ($event.target.composing) { return; }

              _vm.$set(_vm.form, "places", $event.target.value);
            }, function ($event) {
              return _vm.handleInput();
            }]
          }
        })];
      }
    }])
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "s-group s-group--wider justify-content-between"
  }, [_c('q-btn', {
    attrs: {
      "width": "15",
      "no-caps": "",
      "color": "grey",
      "label": _vm.trans('button.cancel')
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('onClose');
      }
    }
  }), _vm._v(" "), _c('q-btn', {
    attrs: {
      "color": "blue",
      "width": "15",
      "no-caps": "",
      "disable": !_vm.validate,
      "label": _vm.trans('button.send'),
      "type": "submit"
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }