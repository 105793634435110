var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('q-tabs', {
    staticClass: "sc-journal-tabs",
    class: {
      'sc-journal-tabs--without-scroll': _vm.$q.platform.is.desktop
    },
    attrs: {
      "active-color": "green",
      "indicator-color": "green",
      "mobile-arrows": "",
      "no-caps": ""
    },
    on: {
      "input": _vm.handleClickStudentTab
    },
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, _vm._l(_vm.students, function (tab, key) {
    return _c('q-tab', {
      key: key,
      class: _vm.$q.platform.is.mobile ? '' : 's-w-100',
      attrs: {
        "name": tab.journalId,
        "label": tab.shortName
      }
    });
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }