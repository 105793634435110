var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('q-form', {
    attrs: {
      "autocorrect": "off",
      "autocapitalize": "off",
      "autocomplete": "off",
      "spellcheck": "false"
    },
    on: {
      "submit": _vm.handleAjaxFormSubmit
    }
  }, [_vm._l(_vm.fields, function (field) {
    return _c('q-slide-transition', {
      key: field.name
    }, [_vm.handleVif(field) ? _c('div', {
      class: field.class ? field.class : ''
    }, [_c(field.component, _vm._g({
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.handleVShow(field),
        expression: "handleVShow(field)"
      }],
      tag: "component",
      attrs: {
        "field": field,
        "field-name": field.labelTransKey ? _vm.trans(field.labelTransKey) : field.label,
        "errors": _vm.getErrors(field.name)
      },
      model: {
        value: field.value,
        callback: function callback($$v) {
          _vm.$set(field, "value", $$v);
        },
        expression: "field.value"
      }
    }, field.events ? field.events : {}))], 1) : _vm._e()]);
  }), _vm._v(" "), _c('div', {
    staticClass: "s-group s-group--equal s-group--wider"
  }, [_c('q-btn', {
    attrs: {
      "no-caps": "",
      "color": "grey",
      "label": _vm.trans('button.cancel')
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('onClose');
      }
    }
  }), _vm._v(" "), _vm.resource && _vm.resource.point !== null && _vm.resource.point !== undefined ? _c('q-btn', {
    directives: [{
      name: "s-modal",
      rawName: "v-s-modal",
      value: {
        id: _vm.deletePointComponent,
        title: _vm.trans('notice.are_you_sure'),
        component: _vm.deletePointComponent,
        events: {
          onApprove: _vm.confirmDelete
        }
      },
      expression: "{\n        id: deletePointComponent,\n        title: trans('notice.are_you_sure'),\n        component: deletePointComponent,\n        events: { onApprove: confirmDelete },\n      }"
    }],
    attrs: {
      "color": "red",
      "no-caps": "",
      "disable": !_vm.validateDelete,
      "label": _vm.trans('button.delete')
    }
  }) : _vm._e(), _vm._v(" "), _c('q-btn', {
    attrs: {
      "disable": !_vm.validate,
      "color": "blue",
      "no-caps": "",
      "label": _vm.trans('button.send'),
      "type": "submit"
    }
  })], 1), _vm._v(" "), _c('v-modal-select-user-files', {
    attrs: {
      "open-modal": _vm.isUserFileModalOpen,
      "url-files": _vm.apiConfig.userFiles.get,
      "url-file-store": _vm.apiConfig.userFiles.store,
      "multiple": "",
      "max-files": 1,
      "full-option": ""
    },
    on: {
      "input": _vm.handleUserModalInput,
      "select-close": _vm.handleCloseUserModal
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }