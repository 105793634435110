var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-statement-create__item-inner college-v-step-qualification"
  }, [_c('v-form-inform', {
    attrs: {
      "message": _vm.trans('warning_text.modal_inform')
    }
  }), _vm._v(" "), _c('q-form', {
    staticClass: "sc-form-layout"
  }, [_c('q-checkbox', {
    staticClass: "mb-10",
    attrs: {
      "label": _vm.trans('statement.prepare_qualified_worker')
    },
    on: {
      "input": _vm.handleIsWorkerCheckbox
    },
    model: {
      value: _vm.form.isWorking,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "isWorking", $$v);
      },
      expression: "form.isWorking"
    }
  }), _vm._v(" "), _vm._l(_vm.formValues, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "sc-statement-outer"
    }, [_c('div', {
      staticClass: "d-flex justify-content-between align-items-center mb-10"
    }, [_c('h3', {
      staticClass: "s-title s-light"
    }, [_vm._v("\n          " + _vm._s(_vm.trans('statement.specialty') + ' №' + (index + 1)) + "\n        ")]), _vm._v(" "), index !== 0 && index === _vm.formValues.length - 1 ? _c('div', {
      staticClass: "d-flex justify-content-end"
    }, [_c('q-btn', {
      attrs: {
        "color": "grey",
        "icon": "close",
        "squire": ""
      },
      on: {
        "click": function click($event) {
          return _vm.deleteQualification(index);
        }
      }
    })], 1) : _vm._e()]), _vm._v(" "), _c('v-wrapper-q-select', {
      attrs: {
        "options": item.regions,
        "option-value": "value",
        "option-label": "label",
        "extra-binds": {
          required: true,
          labelTransKey: 'label.region',
          disable: index === 0 && _vm.disableSelect || item.regions.length === 1
        }
      },
      on: {
        "input": function input($event) {
          return _vm.getQualification('region', index);
        }
      },
      model: {
        value: _vm.form.statements[index].region,
        callback: function callback($$v) {
          _vm.$set(_vm.form.statements[index], "region", $$v);
        },
        expression: "form.statements[index].region"
      }
    }), _vm._v(" "), _c('v-wrapper-q-select', {
      attrs: {
        "options": item.specialties,
        "option-value": "value",
        "option-label": "label",
        "extra-binds": {
          required: true,
          labelTransKey: 'statement.specialty',
          disable: index === 0 && _vm.disableSelect || item.specialties.length === 1
        }
      },
      on: {
        "input": function input($event) {
          return _vm.getQualification('specialty', index);
        }
      },
      model: {
        value: _vm.form.statements[index].specialty,
        callback: function callback($$v) {
          _vm.$set(_vm.form.statements[index], "specialty", $$v);
        },
        expression: "form.statements[index].specialty"
      }
    }), _vm._v(" "), _c('v-wrapper-q-select', {
      attrs: {
        "options": item.qualifications,
        "option-value": "value",
        "option-label": "label",
        "extra-binds": {
          required: true,
          labelTransKey: 'statement.qualification',
          disable: item.qualifications.length === 1
        }
      },
      on: {
        "input": function input($event) {
          return _vm.getQualification('qualification', index);
        }
      },
      model: {
        value: _vm.form.statements[index].qualification,
        callback: function callback($$v) {
          _vm.$set(_vm.form.statements[index], "qualification", $$v);
        },
        expression: "form.statements[index].qualification"
      }
    }), _vm._v(" "), _c('v-wrapper-q-select', {
      attrs: {
        "options": item.organizations,
        "option-value": "value",
        "option-label": "label",
        "extra-binds": {
          required: true,
          labelTransKey: 'main.colleges',
          disable: item.organizations.length === 1
        }
      },
      on: {
        "input": function input($event) {
          return _vm.getQualification('organization', index);
        }
      },
      model: {
        value: _vm.form.statements[index].organization,
        callback: function callback($$v) {
          _vm.$set(_vm.form.statements[index], "organization", $$v);
        },
        expression: "form.statements[index].organization"
      }
    }), _vm._v(" "), _c('q-select', {
      attrs: {
        "options": item.eduLangs,
        "required": "",
        "disable": item.eduLangs.length === 1,
        "label": _vm.trans('statement.edu_lang'),
        "emit-value": "",
        "map-options": ""
      },
      model: {
        value: _vm.form.statements[index].edu_lang,
        callback: function callback($$v) {
          _vm.$set(_vm.form.statements[index], "edu_lang", $$v);
        },
        expression: "form.statements[index].edu_lang"
      }
    })], 1);
  }), _vm._v(" "), _vm.activeButton ? _c('q-btn', {
    staticClass: "col-6 align-self-center mt-10",
    attrs: {
      "color": "green",
      "no-caps": "",
      "disable": _vm.isDisabled,
      "icon-right": "plus",
      "label-wider": "",
      "label": _vm.trans('button.add')
    },
    on: {
      "click": function click($event) {
        return _vm.addQualifications();
      }
    }
  }) : _vm._e()], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }