<template>
  <div class="sc-title-link">
    <a :href="href">
      <q-icon
        name="long-arrow-left"
        icon-class="s-ico-fa"
      />
    </a>
    {{ $q.screen.width > 640 ? `${trans('label.journal')} — ${name}` : trans('label.journal') }}
  </div>
</template>

<script>
import { QIcon } from '@quasar/components';

export default {
  name: 'VJournalTitle',
  components: { QIcon },
  props: {
    name: { type: String, required: true },
  },
  computed: {
    href() {
      return this.$store.getters.apiConfig.back;
    },
  },
};
</script>
