var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('q-form', {
    attrs: {
      "autocorrect": "off",
      "autocapitalize": "off",
      "autocomplete": "off",
      "spellcheck": "false"
    },
    on: {
      "submit": _vm.handleAjaxFormSubmit
    }
  }, [_c('div', {
    staticClass: "mb-20"
  }, [_c('q-input', {
    attrs: {
      "required": "",
      "mask": "##",
      "label": _vm.trans('label.statement_attestation_point_five')
    },
    model: {
      value: _vm.form.pointFive,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "pointFive", $$v);
      },
      expression: "form.pointFive"
    }
  }), _vm._v(" "), _c('q-input', {
    attrs: {
      "required": "",
      "mask": "##",
      "label": _vm.trans('label.statement_attestation_point_four')
    },
    model: {
      value: _vm.form.pointFour,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "pointFour", $$v);
      },
      expression: "form.pointFour"
    }
  }), _vm._v(" "), _c('q-input', {
    attrs: {
      "required": "",
      "mask": "##",
      "label": _vm.trans('label.statement_attestation_point_three')
    },
    model: {
      value: _vm.form.pointThree,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "pointThree", $$v);
      },
      expression: "form.pointThree"
    }
  }), _vm._v(" "), _c('q-field', {
    attrs: {
      "disable": "",
      "label": _vm.trans('label.statement_attestation_point_average')
    }
  }, [_vm._v("\n      " + _vm._s(_vm.averagePoint) + "\n    ")])], 1), _vm._v(" "), _c('div', {
    staticClass: "s-group s-group--wider justify-content-between"
  }, [_c('q-btn', {
    attrs: {
      "width": "15",
      "no-caps": "",
      "color": "grey",
      "label": _vm.trans('button.cancel')
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('onClose');
      }
    }
  }), _vm._v(" "), _c('q-btn', {
    attrs: {
      "color": "blue",
      "width": "15",
      "no-caps": "",
      "disable": !_vm.validate,
      "label": _vm.trans('button.send'),
      "type": "submit"
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }