var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('q-form', {
    attrs: {
      "autocorrect": "off",
      "autocapitalize": "off",
      "autocomplete": "off",
      "spellcheck": "false"
    },
    on: {
      "submit": _vm.handleAjaxFormSubmit
    }
  }, [_c('div', {
    staticClass: "sn-field__label ml-5 mr-5"
  }, [_vm._v("\n    " + _vm._s(_vm.trans('label.holidays.first_half_year_dates')) + ": "), _c('i', [_vm._v("*")])]), _vm._v(" "), _c('div', {
    staticClass: "s-group s-group--equal s-group--wider"
  }, [_c('v-wrapper-q-date', {
    staticClass: "no-padding",
    model: {
      value: _vm.fields.first_semester_start,
      callback: function callback($$v) {
        _vm.$set(_vm.fields, "first_semester_start", $$v);
      },
      expression: "fields.first_semester_start"
    }
  }), _vm._v(" "), _c('v-wrapper-q-date', {
    staticClass: "no-padding",
    model: {
      value: _vm.fields.first_semester_end,
      callback: function callback($$v) {
        _vm.$set(_vm.fields, "first_semester_end", $$v);
      },
      expression: "fields.first_semester_end"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "sn-field__label ml-5 mr-5 mt-10"
  }, [_vm._v("\n    " + _vm._s(_vm.trans('label.holidays.second_half_year_dates')) + ":\n  ")]), _vm._v(" "), _c('div', {
    staticClass: "s-group s-group--equal s-group--wider"
  }, [_c('v-wrapper-q-date', {
    staticClass: "no-padding",
    model: {
      value: _vm.fields.second_semester_start,
      callback: function callback($$v) {
        _vm.$set(_vm.fields, "second_semester_start", $$v);
      },
      expression: "fields.second_semester_start"
    }
  }), _vm._v(" "), _c('v-wrapper-q-date', {
    staticClass: "no-padding",
    model: {
      value: _vm.fields.second_semester_end,
      callback: function callback($$v) {
        _vm.$set(_vm.fields, "second_semester_end", $$v);
      },
      expression: "fields.second_semester_end"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "s-group s-group--equal s-group--wider mt-10"
  }, [_c('q-btn', {
    attrs: {
      "no-caps": "",
      "color": "grey",
      "label": _vm.trans('button.cancel')
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('onClose');
      }
    }
  }), _vm._v(" "), _c('q-btn', {
    attrs: {
      "disable": !_vm.validate,
      "color": "green",
      "no-caps": "",
      "label": _vm.trans('button.send'),
      "type": "submit"
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }