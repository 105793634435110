import Vue from 'vue';
import store from './VueJS/store';
import VApplicationListView from './VueJS/views/VApplicationListView/index';

if (document.getElementById('v-application-list-view-app')) {

  window.vApplicationListViewApp = new Vue({
    el: '#v-application-list-view-app',
    components: { VApplicationListView },
    store,
  });
}
