var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-passport-page"
  }, [_c('div', {
    staticClass: "s-passport-page__columns-wrapper"
  }, [_c('div', {
    staticClass: "s-passport-page__column-left"
  }, [_c('div', {
    staticClass: "s-passport-page__panel mb-30"
  }, [_c('v-photo-album', {
    attrs: {
      "pic-list": _vm.organization.picItems,
      "autoplay": true,
      "org-type": "college"
    }
  }), _vm._v(" "), _vm._l(_vm.navButtons, function (btn, key) {
    return _c('q-btn', {
      key: key,
      staticClass: "s-w-100 mt-15",
      attrs: {
        "color": "blue",
        "label": btn.label,
        "no-caps": "",
        "outline": ""
      },
      on: {
        "click": function click($event) {
          btn.name === 'callCommission' ? _vm.callCommission() : _vm.scrollTo(btn.name);
        }
      }
    });
  })], 2), _vm._v(" "), _c('div', {
    staticClass: "s-passport-page__panel mb-30"
  }, [_c('div', {
    staticClass: "s-passport-page__name mb-30"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('index.gov_services')) + "\n        ")]), _vm._v(" "), _vm._l(_vm.organization.govServices, function (btn, index) {
    return _c('q-btn', {
      key: index,
      staticClass: "s-w-100",
      class: index !== _vm.organization.govServices.length - 1 ? 'mb-15' : '',
      attrs: {
        "color": btn.isActive ? 'blue' : 'grey',
        "type": btn.isActive ? 'a' : 'button',
        "to": btn.isActive ? btn.link : undefined,
        "label": btn.label,
        "no-caps": ""
      },
      on: {
        "click": function click($event) {
          return _vm.handleGovServiceBtn(btn);
        }
      }
    });
  })], 2)]), _vm._v(" "), _c('div', {
    staticClass: "s-passport-page__column-right"
  }, [_c('q-tabs', {
    staticClass: "sn-tabs--thm-button mb-20",
    attrs: {
      "vertical": _vm.$q.screen.width < 1349,
      "inline-label": "",
      "no-caps": "",
      "align": "left",
      "active-color": "white",
      "active-bg-color": "blue"
    },
    on: {
      "input": _vm.changedTab
    },
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, _vm._l(_vm.tabs, function (tabItem, key) {
    return _c('q-tab', {
      key: key,
      staticClass: "s-w-100",
      attrs: {
        "name": tabItem.name,
        "label": tabItem.label
      }
    });
  }), 1), _vm._v(" "), _vm.tab === 'about' ? [_c('div', {
    staticClass: "s-passport-page__panel mb-30"
  }, [_c('div', {
    ref: "about",
    staticClass: "s-passport-page__name mb-30"
  }, [_vm._v("\n            " + _vm._s(_vm.organization.name) + "\n          ")]), _vm._v(" "), _c('div', {
    staticClass: "row flex-column"
  }, _vm._l(_vm.organization.aboutOrg, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "col-auto",
      class: index !== _vm.organization.aboutOrg.length - 1 ? 'mb-15' : ''
    }, [_c('div', {
      staticClass: "s-desc-item__label"
    }, [_vm._v("\n                " + _vm._s(item.label) + ":\n              ")]), _vm._v(" "), _c('div', {
      staticClass: "s-desc-item__value"
    }, [_vm._v("\n                " + _vm._s(item.value) + "\n              ")])]);
  }), 0)]), _vm._v(" "), _vm.organization.description ? _c('div', {
    ref: "description",
    staticClass: "s-passport-page__panel mb-30"
  }, [_c('div', {
    staticClass: "s-passport-page__panel-title mb-30"
  }, [_c('div', {
    staticClass: "s-str-ico-fa s-ico-fa--thm-pen-alt"
  }, [_vm._v("\n              " + _vm._s(_vm.trans('label.description')) + "\n            ")])]), _vm._v(" "), _c('div', {
    staticClass: "s-plain-text",
    domProps: {
      "innerHTML": _vm._s(_vm.organization.description)
    }
  })]) : _vm._e()] : _c('div', {
    ref: "postsRef"
  }, [_vm.posts.items.length === 0 ? _c('div', {
    staticClass: "s-plain-text"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('warning_text.no_results')) + "\n        ")]) : _vm._e(), _vm._v(" "), _vm._l(_vm.postsPaginated, function (post, key) {
    return _c('v-passport-page-post-item', {
      key: key,
      attrs: {
        "post": post
      }
    });
  }), _vm._v(" "), _vm.postsCountOfPages ? _c('div', {
    staticClass: "row justify-content-center mt-30"
  }, [_c('div', {
    staticClass: "col-auto"
  }, [_c('q-pagination', {
    attrs: {
      "max": _vm.postsCountOfPages,
      "max-pages": 5,
      "boundary-links": true,
      "input": _vm.postsCountOfPages > 5,
      "input-class": "s-c-blue"
    },
    on: {
      "input": function input($event) {
        return _vm.scrollTo('postsRef', true);
      }
    },
    model: {
      value: _vm.posts.currentPage,
      callback: function callback($$v) {
        _vm.$set(_vm.posts, "currentPage", $$v);
      },
      expression: "posts.currentPage"
    }
  })], 1)]) : _vm._e()], 2)], 2)]), _vm._v(" "), _vm.tab === 'about' ? [_vm.organization.conditions ? _c('div', {
    ref: "conditions",
    staticClass: "s-passport-page__panel mb-30"
  }, [_c('div', {
    staticClass: "s-passport-page__panel-title mb-30"
  }, [_c('div', {
    staticClass: "s-str-ico-fa s-ico-fa--thm-file-alt"
  }, [_vm._v("\n          " + _vm._s(_vm.trans('fields.conditions_of_admission')) + "\n        ")])]), _vm._v(" "), _c('div', {
    staticClass: "s-plain-text",
    domProps: {
      "innerHTML": _vm._s(_vm.organization.conditions)
    }
  })]) : _vm._e(), _vm._v(" "), _vm.organization.table.length > 0 || _vm.organization.paidTable.length > 0 ? _c('div', {
    ref: "admission"
  }, [_vm.organization.table.length > 0 ? _c('div', {
    staticClass: "s-passport-page__panel mb-30",
    staticStyle: {
      "overflow": "auto"
    }
  }, [_c('div', {
    staticClass: "s-passport-page__panel-title mb-30"
  }, [_c('div', {
    staticClass: "s-str-ico-fa s-ico-fa--thm-users"
  }, [_vm._v("\n            " + _vm._s(_vm.trans('statement.grant_commission')) + "\n          ")])]), _vm._v(" "), _c('v-passport-table', {
    attrs: {
      "table": _vm.organization.table
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.organization.paidTable.length > 0 ? _c('div', {
    staticClass: "s-passport-page__panel mb-30",
    staticStyle: {
      "overflow": "auto"
    }
  }, [_c('div', {
    staticClass: "s-passport-page__panel-title mb-30"
  }, [_c('div', {
    staticClass: "s-str-ico-fa s-ico-fa--thm-users"
  }, [_vm._v("\n            " + _vm._s(_vm.trans('statement.paid_commission')) + "\n          ")])]), _vm._v(" "), _c('v-passport-table', {
    attrs: {
      "table": _vm.organization.paidTable
    }
  })], 1) : _vm._e()]) : _vm._e()] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }