var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.$q.screen.width < 1280 ? _c('div', [_c('v-no-results', {
    attrs: {
      "image": "pc",
      "title": _vm.trans('warning_text.use_pc_title'),
      "text": _vm.trans('warning_text.use_pc_label')
    }
  })], 1) : _c('div', [_c('q-form', {
    attrs: {
      "autocorrect": "off",
      "autocapitalize": "off",
      "autocomplete": "off",
      "spellcheck": "false"
    },
    on: {
      "submit": _vm.submit
    }
  }, [_c('v-form-inform', {
    attrs: {
      "message": _vm.trans('label.monitoring_contingent_select_filters')
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "row align-items-stretch mb-10"
  }, [_vm.hasDepartmentFilter ? _c('div', {
    staticClass: "col-md-4 col-sm-6"
  }, [_c('q-select', {
    staticClass: "sn-select__input--noclear",
    class: _vm.options.departments.length === 0 ? 'sn--cursor-not-allowed' : '',
    attrs: {
      "filled": "",
      "bottom-slots": "",
      "options": _vm.options.departments,
      "label": _vm.trans('label.real_department'),
      "hide-selected": "",
      "fill-input": "",
      "placeholder": !_vm.filters.department.value ? _vm.trans('placeholder.select_department') : '',
      "required": "",
      "use-input": "",
      "disable": _vm.options.departmentsFiltered.length === 0,
      "input-debounce": "0"
    },
    on: {
      "filter": function filter(val, update) {
        return _vm.defaultFilterFn(val, update, 'departments', 'departmentsFiltered');
      },
      "input": _vm.requestGetOrganizations
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_c('q-icon', {
          attrs: {
            "name": "place"
          }
        })];
      },
      proxy: true
    }, {
      key: "append",
      fn: function fn() {
        return [_c('q-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "name": "close"
          },
          on: {
            "click": _vm.clearDepartment
          }
        })];
      },
      proxy: true
    }], null, false, 4187154870),
    model: {
      value: _vm.filters.department,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "department", $$v);
      },
      expression: "filters.department"
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.hasOrganizationFilter ? _c('div', {
    staticClass: "col-md-4 col-sm-6"
  }, [_c('q-select', {
    staticClass: "sn-select__input--noclear",
    class: _vm.options.organizations.length === 0 ? 'sn--cursor-not-allowed' : '',
    attrs: {
      "filled": "",
      "bottom-slots": "",
      "options": _vm.options.organizations,
      "label": _vm.trans('label.organization'),
      "placeholder": !_vm.filters.organization.value ? _vm.trans('placeholder.select_organization') : '',
      "required": "",
      "hide-selected": "",
      "fill-input": "",
      "use-input": "",
      "disable": _vm.options.organizationsFiltered.length === 0,
      "input-debounce": "0"
    },
    on: {
      "filter": function filter(val, update) {
        return _vm.defaultFilterFn(val, update, 'organizations', 'organizationsFiltered');
      },
      "input": _vm.selectOrganization
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_c('q-icon', {
          attrs: {
            "name": "place"
          }
        })];
      },
      proxy: true
    }, {
      key: "append",
      fn: function fn() {
        return [_c('q-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "name": "close"
          },
          on: {
            "click": _vm.clearOrganization
          }
        })];
      },
      proxy: true
    }], null, false, 687037481),
    model: {
      value: _vm.filters.organization,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "organization", $$v);
      },
      expression: "filters.organization"
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.hasProfessionFilter ? _c('div', {
    staticClass: "col-md-4 col-sm-6"
  }, [_c('q-select', {
    staticClass: "sn-select__input--noclear",
    class: _vm.options.professions.length === 0 ? 'sn--cursor-not-allowed' : '',
    attrs: {
      "filled": "",
      "bottom-slots": "",
      "options": _vm.options.professions,
      "label": _vm.trans('label.profession'),
      "placeholder": !_vm.filters.profession.value ? _vm.trans('placeholder.select_profession') : '',
      "required": "",
      "hide-selected": "",
      "fill-input": "",
      "use-input": "",
      "disable": _vm.options.professionsFiltered.length === 0,
      "input-debounce": "0"
    },
    on: {
      "filter": function filter(val, update) {
        return _vm.defaultFilterFn(val, update, 'professions', 'professionsFiltered');
      },
      "input": _vm.selectProfession
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_c('q-icon', {
          attrs: {
            "name": "place"
          }
        })];
      },
      proxy: true
    }, {
      key: "append",
      fn: function fn() {
        return [_c('q-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "name": "close"
          },
          on: {
            "click": _vm.clearOrganization
          }
        })];
      },
      proxy: true
    }], null, false, 687037481),
    model: {
      value: _vm.filters.profession,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "profession", $$v);
      },
      expression: "filters.profession"
    }
  })], 1) : _vm._e(), _vm._v(" "), !_vm.hasProfessionFilter || _vm.filters.profession.value && !_vm.filters.profession.isCreative ? _c('div', {
    staticClass: "col-md-4 col-sm-6"
  }, [_c('q-select', {
    staticClass: "sn-select__input--noclear",
    class: _vm.options.groups.length === 0 ? 'sn--cursor-not-allowed' : '',
    attrs: {
      "filled": "",
      "bottom-slots": "",
      "options": _vm.options.groups,
      "label": _vm.trans('label.group'),
      "use-input": "",
      "hide-selected": "",
      "fill-input": "",
      "input-debounce": "0",
      "placeholder": !_vm.filters.group.value ? _vm.trans('label.choose_group') : '',
      "required": "",
      "disable": _vm.options.groupsFiltered.length === 0
    },
    on: {
      "input": _vm.selectGroup,
      "filter": function filter(val, update) {
        return _vm.defaultFilterFn(val, update, 'groups', 'groupsFiltered');
      }
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_c('q-icon', {
          attrs: {
            "name": "place"
          }
        })];
      },
      proxy: true
    }, {
      key: "append",
      fn: function fn() {
        return [_c('q-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "name": "close"
          },
          on: {
            "click": _vm.clearGroup
          }
        })];
      },
      proxy: true
    }], null, false, 4115609293),
    model: {
      value: _vm.filters.group,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "group", $$v);
      },
      expression: "filters.group"
    }
  })], 1) : _vm._e(), _vm._v(" "), !_vm.isAttendance && !_vm.isModule ? _c('div', {
    staticClass: "col-md-4 col-sm-6"
  }, [_c('q-select', {
    attrs: {
      "filled": "",
      "bottom-slots": "",
      "options": _vm.options.semesters,
      "label": _vm.trans('label.semester'),
      "required": ""
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_c('q-icon', {
          attrs: {
            "name": "place"
          }
        })];
      },
      proxy: true
    }], null, false, 2603134871),
    model: {
      value: _vm.filters.semester,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "semester", $$v);
      },
      expression: "filters.semester"
    }
  })], 1) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "col-md-4 col-sm-6"
  }, [_c('q-select', {
    class: _vm.options.contingent.length === 0 ? 'sn--cursor-not-allowed' : '',
    attrs: {
      "filled": "",
      "bottom-slots": "",
      "options": _vm.options.contingent,
      "label": _vm.trans('label.students'),
      "emit-value": "",
      "map-options": "",
      "multiple": "",
      "disable": _vm.options.contingent.length === 0,
      "display-value": _vm.contingentValue
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_c('q-icon', {
          attrs: {
            "name": "place"
          }
        })];
      },
      proxy: true
    }, {
      key: "append",
      fn: function fn() {
        return [_c('q-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "name": "close"
          },
          on: {
            "click": function click($event) {
              _vm.filters.contingents = [];
            }
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filters.contingents,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "contingents", $$v);
      },
      expression: "filters.contingents"
    }
  })], 1), _vm._v(" "), !_vm.isAttendance && _vm.isModule ? _c('div', {
    staticClass: "col-md-4 col-sm-6"
  }, [_c('q-select', {
    staticClass: "sn-select__input--noclear",
    class: _vm.options.modules.length === 0 ? 'sn--cursor-not-allowed' : '',
    attrs: {
      "filled": "",
      "bottom-slots": "",
      "options": _vm.options.modules,
      "label": _vm.trans('label.module'),
      "placeholder": !_vm.filters.module.value ? _vm.trans('label.choose_module') : '',
      "use-input": "",
      "hide-selected": "",
      "fill-input": "",
      "input-debounce": "0",
      "disable": _vm.options.modulesFiltered.length === 0
    },
    on: {
      "input": _vm.requestGetModuleDisciplines,
      "filter": function filter(val, update) {
        return _vm.defaultFilterFn(val, update, 'modules', 'modulesFiltered');
      }
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_c('q-icon', {
          attrs: {
            "name": "place"
          }
        })];
      },
      proxy: true
    }, {
      key: "append",
      fn: function fn() {
        return [_c('q-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "name": "close"
          },
          on: {
            "click": _vm.clearModule
          }
        })];
      },
      proxy: true
    }], null, false, 13729320),
    model: {
      value: _vm.filters.module,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "module", $$v);
      },
      expression: "filters.module"
    }
  })], 1) : _vm._e(), _vm._v(" "), !_vm.isAttendance && _vm.isModule ? _c('div', {
    staticClass: "col-md-4 col-sm-6"
  }, [_c('q-select', {
    staticClass: "sn-select__input--noclear",
    class: _vm.options.moduleDisciplines.length === 0 ? 'sn--cursor-not-allowed' : '',
    attrs: {
      "filled": "",
      "bottom-slots": "",
      "options": _vm.options.moduleDisciplines,
      "label": _vm.trans('table.discipline'),
      "placeholder": !_vm.filters.moduleDiscipline.value ? _vm.trans('label.choose_discipline') : '',
      "use-input": "",
      "hide-selected": "",
      "fill-input": "",
      "input-debounce": "0",
      "disable": _vm.options.moduleDisciplinesFiltered.length === 0
    },
    on: {
      "filter": function filter(val, update) {
        return _vm.defaultFilterFn(val, update, 'moduleDisciplines', 'moduleDisciplinesFiltered');
      }
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_c('q-icon', {
          attrs: {
            "name": "place"
          }
        })];
      },
      proxy: true
    }, {
      key: "append",
      fn: function fn() {
        return [_c('q-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "name": "close"
          },
          on: {
            "click": function click($event) {
              _vm.filters.moduleDiscipline = {};
            }
          }
        })];
      },
      proxy: true
    }], null, false, 1411698864),
    model: {
      value: _vm.filters.moduleDiscipline,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "moduleDiscipline", $$v);
      },
      expression: "filters.moduleDiscipline"
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.isAttendance ? _c('div', {
    staticClass: "col-md-2 col-sm-3"
  }, [_c('q-input', {
    attrs: {
      "filled": "",
      "mask": "##.##.####",
      "color": "blue",
      "label": _vm.trans('label.created_at_from'),
      "placeholder": _vm.trans('placeholder.created_at_from')
    },
    on: {
      "click": function click() {
        return _vm.$refs.qDateFromActiveProxy.show();
      }
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('q-icon', {
          staticClass: "sn--cursor-pointer",
          attrs: {
            "name": "calendar"
          }
        }, [_c('q-popup-proxy', {
          ref: "qDateFromActiveProxy",
          attrs: {
            "transition-show": "scale",
            "transition-hide": "scale"
          }
        }, [_c('q-date', {
          attrs: {
            "today-btn": "",
            "mask": "DD.MM.YYYY",
            "color": "blue"
          },
          on: {
            "input": function input() {
              return _vm.$refs.qDateFromActiveProxy.hide();
            }
          },
          model: {
            value: _vm.filters.date_from,
            callback: function callback($$v) {
              _vm.$set(_vm.filters, "date_from", $$v);
            },
            expression: "filters.date_from"
          }
        })], 1)], 1)];
      },
      proxy: true
    }], null, false, 3236362433),
    model: {
      value: _vm.filters.date_from,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "date_from", $$v);
      },
      expression: "filters.date_from"
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.isAttendance ? _c('div', {
    staticClass: "col-md-2 col-sm-3"
  }, [_c('q-input', {
    attrs: {
      "filled": "",
      "mask": "##.##.####",
      "color": "blue",
      "label": _vm.trans('label.created_at_to'),
      "placeholder": _vm.trans('placeholder.created_at_to')
    },
    on: {
      "click": function click() {
        return _vm.$refs.qDateToActiveProxy.show();
      }
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('q-icon', {
          staticClass: "sn--cursor-pointer",
          attrs: {
            "name": "calendar"
          }
        }, [_c('q-popup-proxy', {
          ref: "qDateToActiveProxy",
          attrs: {
            "transition-show": "scale",
            "transition-hide": "scale"
          }
        }, [_c('q-date', {
          attrs: {
            "today-btn": "",
            "mask": "DD.MM.YYYY",
            "color": "blue"
          },
          on: {
            "input": function input() {
              return _vm.$refs.qDateToActiveProxy.hide();
            }
          },
          model: {
            value: _vm.filters.date_to,
            callback: function callback($$v) {
              _vm.$set(_vm.filters, "date_to", $$v);
            },
            expression: "filters.date_to"
          }
        })], 1)], 1)];
      },
      proxy: true
    }], null, false, 1503746988),
    model: {
      value: _vm.filters.date_to,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "date_to", $$v);
      },
      expression: "filters.date_to"
    }
  })], 1) : _vm._e()]), _vm._v(" "), _c('div', {
    staticClass: "row align-items-stretch justify-content-end"
  }, [_c('div', {
    staticClass: "col-sm-auto mb-10"
  }, [_c('q-btn', {
    staticClass: "s-btn--w-200",
    attrs: {
      "color": "blue",
      "no-caps": "",
      "disable": _vm.table.columns.length < 2,
      "label": _vm.trans('button.export'),
      "loading": _vm.loading
    },
    on: {
      "click": _vm.exportu
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-sm-auto mb-10"
  }, [_c('q-btn', {
    staticClass: "s-btn--w-200",
    attrs: {
      "no-caps": "",
      "label": _vm.trans('button.clear_filter'),
      "color": "orange",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.clearFilters
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-sm-auto"
  }, [_c('q-btn', {
    staticClass: "s-btn--w-200",
    attrs: {
      "color": "green",
      "no-caps": "",
      "label": _vm.trans('button.submit'),
      "loading": _vm.loading,
      "type": "submit",
      "disable": !_vm.filters.group.value && (!_vm.filters.profession.value || !_vm.filters.profession.isCreative)
    }
  })], 1)])], 1), _vm._v(" "), !_vm.noResults && _vm.table.columns.length > 1 ? _c('div', [_c('v-table', {
    attrs: {
      "separator": "cell",
      "rows": _vm.table.rows,
      "columns": _vm.table.columns,
      "search": false,
      "on-fire": "",
      "loading": _vm.loading,
      "column-filter": !_vm.isAttendance,
      "wrap-cells": ""
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.noResults ? _c('v-no-results', {
    attrs: {
      "image": "search",
      "title": _vm.trans('warning_text.no_data_title'),
      "text": _vm.trans('warning_text.no_data_label')
    }
  }) : _vm._e()], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }