var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "sc-title-link sc-title-link--small d-flex justify-content-start align-items-start mt-20"
  }, [_c('a', {
    attrs: {
      "href": _vm.backLink
    }
  }, [_c('q-icon', {
    attrs: {
      "name": "long-arrow-left",
      "icon-class": "s-ico-fa"
    }
  })], 1), _vm._v(" "), _c('span', {
    class: _vm.$q.screen.width <= 760 ? 's-to-center' : ''
  }, [_vm._v(_vm._s(_vm.tableName))])]), _vm._v(" "), _c('div', {
    staticClass: "row justify-content-between mb-10"
  }, [_c('div', {
    staticClass: "col-12 col-sm-auto mb-20 button-philter"
  }, [_c('button', {
    staticClass: "s-btn s-btn--w-200 s-w-100 s-btn--thm-blue",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.toggleFilter($event);
      }
    }
  }, [_c('span', {
    staticClass: "s-str-ico s-str-ico--wider s-str-ico--thm-filter"
  }, [_vm._v(_vm._s(_vm.trans('main.filter_list')))])])]), _vm._v(" "), _vm._t("buttons")], 2), _vm._v(" "), _c('q-form', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showFilter,
      expression: "showFilter"
    }],
    attrs: {
      "autocorrect": "off",
      "autocapitalize": "off",
      "autocomplete": "off",
      "spellcheck": "false"
    },
    on: {
      "submit": _vm.requestGetTable
    }
  }, [_c('div', {
    staticClass: "row align-items-stretch mb-10"
  }, [_c('div', {
    staticClass: "col-12 col-sm-6 col-lg-3"
  }, [_c('q-input', {
    staticClass: "sc-admission-result__color--blue",
    attrs: {
      "dense": "",
      "debounce": "300",
      "placeholder": _vm.trans('placeholder.search'),
      "label": _vm.trans('statement.search_by_name'),
      "input-class": "sc-admission-result__color--blue",
      "color": "blue"
    },
    model: {
      value: _vm.filter,
      callback: function callback($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }, [_c('q-icon', {
    attrs: {
      "slot": "append",
      "name": "search",
      "color": "blue"
    },
    slot: "append"
  })], 1)], 1), _vm._v(" "), _c('div', {
    staticClass: "col-12 col-sm-6 col-lg-3"
  }, [_c('q-select', {
    staticClass: "sn-select__input--noclear sc-admission-result__color--blue",
    attrs: {
      "filled": "",
      "map-options": "",
      "emit-value": "",
      "options": _vm.options.organization,
      "label": _vm.trans('statement.org_name')
    },
    model: {
      value: _vm.filters.organization,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "organization", $$v);
      },
      expression: "filters.organization"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-12 col-sm-6 col-lg-3"
  }, [_c('q-select', {
    staticClass: "sn-select__input--noclear sc-admission-result__color--blue",
    attrs: {
      "filled": "",
      "map-options": "",
      "emit-value": "",
      "options": _vm.options.eduLevel,
      "label": _vm.trans('label.edu_level')
    },
    model: {
      value: _vm.filters.eduLevel,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "eduLevel", $$v);
      },
      expression: "filters.eduLevel"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-12 col-sm-6 col-lg-3"
  }, [_c('q-select', {
    staticClass: "sn-select__input--noclear sc-admission-result__color--blue",
    attrs: {
      "filled": "",
      "map-options": "",
      "emit-value": "",
      "options": _vm.options.qualification,
      "label": _vm.trans('statement.qualification'),
      "popup-content-style": {
        width: _vm.$q.platform.is.desktop ? '1px' : undefined
      }
    },
    model: {
      value: _vm.filters.qualification,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "qualification", $$v);
      },
      expression: "filters.qualification"
    }
  })], 1)]), _vm._v(" "), _c('div', {
    staticClass: "row mb-10"
  }, [_c('div', {
    staticClass: "col-12 col-sm-6 col-lg-3"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    class: _vm.$q.screen.width <= 760 ? 'mb-20' : '',
    attrs: {
      "color": "green",
      "no-caps": "",
      "width": "20",
      "label": _vm.trans('button.submit'),
      "loading": _vm.loading,
      "type": "submit",
      "disabled": !_vm.validateFilterBtn
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-12 col-sm-6 col-lg-3"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "color": "orange",
      "no-caps": "",
      "width": "20",
      "label": _vm.trans('button.clear_filter'),
      "loading": _vm.loading
    },
    on: {
      "click": _vm.clearFilters
    }
  })], 1)])]), _vm._v(" "), _vm.selectFilters ? _c('v-no-results', {
    staticStyle: {
      "border-top": "1px solid rgba(0, 0, 0, 0.12)"
    },
    attrs: {
      "image": "search",
      "title": _vm.trans('warning_text.select_filters_title'),
      "text": _vm.trans('warning_text.select_filters_text')
    }
  }) : _c('q-table', {
    staticClass: "s-b-white sc-admission-result__list",
    attrs: {
      "data": _vm.table.rows,
      "columns": _vm.table.columns,
      "row-key": "__index",
      "flat": _vm.isTableFlat,
      "grid": _vm.isTableGrid,
      "pagination": _vm.pagination,
      "filter": _vm.filter,
      "filter-method": _vm.searchByName,
      "no-data-label": _vm.trans('label.no_data'),
      "no-results-label": _vm.trans('label.no_results'),
      "separator": "cell",
      "wrap-cells": "",
      "color": "blue",
      "loading": _vm.loading
    },
    on: {
      "update:pagination": function updatePagination($event) {
        _vm.pagination = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn(props) {
        return [!_vm.$q.screen.lt.sm ? _c('q-btn', {
          staticClass: "q-ml-md sc-admission-result__border sc-admission-result__color--blue",
          style: {
            position: 'absolute',
            right: 0,
            top: props.inFullscreen ? '-2rem' : '-5rem'
          },
          attrs: {
            "squire": "",
            "unelevated": "",
            "icon": "fullscreen"
          },
          on: {
            "click": props.toggleFullscreen
          }
        }, [_c('q-tooltip', [_vm._v("\n          " + _vm._s(props.inFullscreen ? _vm.trans('label.fullscreen_off') : _vm.trans('label.fullscreen_on')) + "\n        ")])], 1) : _vm._e()];
      }
    }, {
      key: "no-data",
      fn: function fn() {
        return [_vm.noResults ? _c('v-no-results', {
          staticClass: "s-w-100",
          attrs: {
            "image": "search",
            "title": _vm.trans('warning_text.no_filter_result_title'),
            "text": _vm.trans('warning_text.no_filter_result_text')
          }
        }) : _vm._e()];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }