<template>
  <q-dialog
    :value="modal"
    @input="handleModal"
  >
    <v-modal
      :title="trans('label.doc_extend_direction')"
      size="medium"
    >
      <q-form
        class="mb-30"
      >
        <div
          v-for="(file,name) in form"
          :key="name"
        >
          <div class="sn-field__label">
            {{ getFileLabel(name) }}: <i v-if="isFileRequired(name)">*</i>
          </div>
          <div class="row align-items-end mb-20">
            <div
              class="col-sm-6 col-md-5 col-12"
              :class="$q.screen.width < 576 ? 'mb-10' : ''"
            >
              <q-btn
                class="s-w-100"
                :label="file.length === 0 ? trans('button.add') : trans('label.attached')"
                :color="file.length === 0 ? 'blue' : 'green'"
                :icon-right="file.length === 0 ? 'plus' : 'clip'"
                label-wider
                ellipsis
                no-caps
                @click="triggerFile(name)"
              />
            </div>
            <div class="col-sm-6 col-md-7 col-12">
              <q-btn
                outline
                :label="file.length === 0 ? trans('label.file_not_chosen') : trans('label.files_downloaded', {count: file.length})"
                class="s-w-100"
                label-wider
                color="grey"
                no-caps
                ellipsis
                :icon-right="file.length === 0 ? undefined : 'close'"
                @click="clearFile(name)"
              />
            </div>
          </div>
        </div>
      </q-form>
      <select-user-file
        :open-modal="modalFiles"
        :url-files="uriUserFiles"
        :url-file-store="uriUserFiles"
        multiple
        @input="selectFile"
        @select-close="fileModalClose"
      />
      <div class="row justify-content-between">
        <div class="col-12 col-sm-auto mb-10">
          <q-btn
            v-close-popup
            class="s-w-100"
            width="20"
            :label="trans('button.cancel')"
            label-wider
            color="grey"
          />
        </div>
        <div class="col-12 col-sm-auto">
          <q-btn
            class="s-w-100"
            width="20"
            :label="trans('button.send')"
            color="green"
            :disable="!validate"
            @click="send"
          />
        </div>
      </div>
    </v-modal>
  </q-dialog>
</template>

<script>
import {
  QDialog,
  QBtn,
  QForm,
} from '@quasar/components';
import { Loading } from '@quasar/plugins';
import {
  requestWrapper,
} from '@vjs/helpers';
import { ClosePopup } from '@quasar/directives';
import VModal from '@vjs/components/VModal';
import eventHub from '@vjs/config/eventHub';
import _ from 'lodash';
import SelectUserFile from '../../SelectUserFile/index.vue';
import StatementFiles from '../../VStatementCreateView/mixins/StatementFiles';
import Files from '../../VStatementCreateView/mixins/Files';

export default {
  name: 'VApplicationDocsModal',
  components: {
    QDialog,
    QBtn,
    QForm,
    VModal,
    SelectUserFile,
  },

  directives: { ClosePopup },

  mixins: [Files, StatementFiles],

  props: {
    modal: Boolean,
    uriUpdateFiles: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        doc_medical_examination_086_y: [],
      },
      type: null,
      qLoading: Loading,
      activeFile: null,
    };
  },

  computed: {
    validate() {
      return this.form.doc_medical_examination_086_y.length !== 0;
    },
  },

  methods: {
    handleModal() {
      this.form.doc_medical_examination_086_y = [];
      this.$emit('modal-close', false);
    },
    async send() {
      this.qLoading.show();
      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.uriUpdateFiles,
        data: {
          files: this.form,
        },
      });

      if (res.error) {
        this.qLoading.hide();
        if (res.data.errors && res.errorType === 422) {
          this.$notify({
            text: _.first(_.flatten(res.data.errors[_.first(Object.keys(res.data.errors))])) ?? this.trans('notice.error_on_server'),
            type: 'error',
          });
        }
        return;
      }

      if (!res.error) {
        this.handleModal();

        if (res.alert && res.alert.message) {
          this.$notify({
            text: res.alert.message,
            type: 'success',
          });
        }
        eventHub.$emit('updatedDocs', true);
      }

      this.qLoading.hide();
    },
  },
};
</script>
