var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "row-sort-between-btn"
  }, [_c('q-btn', {
    attrs: {
      "no-caps": "",
      "width": "15",
      "color": "grey",
      "label": _vm.trans('button.cancel')
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('onClose');
      }
    }
  }), _vm._v(" "), _c('q-btn', {
    attrs: {
      "no-caps": "",
      "width": "15",
      "color": "green",
      "label": _vm.trans('button.approve')
    },
    on: {
      "click": _vm.approve
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }