var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.loading ? _c('div', {
    staticClass: "sc-statement-form--step-navigator"
  }, [_c('div', {
    staticClass: "sc-statement-form--step-navigator__description d-flex"
  }, [_c('div', {
    staticClass: "sc-statement-form--step-navigator__banner",
    style: "background-image: url(".concat(_vm.backgroundLeft, ");")
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('p', {
    staticClass: "col-6 sc-statement-form--step-navigator__text",
    domProps: {
      "innerHTML": _vm._s(_vm.trans('statement.edu_navigator_main_text'))
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "col-6"
  }, [_c('img', {
    staticClass: "sc-statement-form--step-navigator__image",
    attrs: {
      "src": _vm.banner,
      "alt": _vm.trans('statement.step_edu_navigator')
    }
  })])]), _vm._v(" "), _c('p', {
    staticClass: "sc-statement-form--step-navigator__text\n         sc-statement-form--step-navigator__text--small",
    domProps: {
      "innerHTML": _vm._s(_vm.trans('statement.edu_navigator_warning'))
    }
  })])]), _vm._v(" "), _c('div', {
    staticClass: "sc-statement-form--step-navigator__buttons"
  }, [_c('div', {
    staticClass: "d-flex flex-column sc-statement-form--step-navigator__action",
    style: "background-image: url(".concat(_vm.backgroundRight, ");")
  }, [_c('p', {
    staticClass: "sc-statement-form--step-navigator__text\n        sc-statement-form--step-navigator__text--middle\n        sc-statement-form--step-navigator__text--blue ml-1",
    domProps: {
      "innerHTML": _vm._s(_vm.trans('statement.edu_navigator_action'))
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "s-group s-group--to-center"
  }, [_c('q-btn', {
    attrs: {
      "outline": "",
      "color": "blue",
      "label": _vm.trans('button.edu_navigation'),
      "target": "_blank",
      "type": "a",
      "to": _vm.trans('statement.edu_navigator_link'),
      "no-caps": "",
      "width": "20"
    }
  }), _vm._v(" "), _c('q-btn', {
    attrs: {
      "color": "green",
      "label": _vm.trans('button.edu_next'),
      "disable": !this.$store.getters.getStep().access,
      "width": "20"
    },
    on: {
      "click": _vm.handleNext
    }
  })], 1)])])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }