var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column align-items-center sc-contingent-performance-book s-w-100"
  }, [_c('div', {
    staticClass: "s-group s-group--wider s-group--equal mb-30"
  }, _vm._l(_vm.tabs, function (tab, key) {
    return _c('q-btn', {
      key: key,
      attrs: {
        "no-caps": "",
        "label-wider": "",
        "width": "15",
        "color": "blue",
        "outline": tab !== _vm.activeTab,
        "label": _vm.trans("contingent-performance-book.tabs.".concat(tab))
      },
      on: {
        "click": function click($event) {
          return _vm.handleTab(tab);
        }
      }
    });
  }), 1), _vm._v(" "), _c('div', {
    staticClass: "s-w-100",
    staticStyle: {
      "max-width": "110rem"
    }
  }, [_vm.hasFilters ? _c('div', {
    staticClass: "mb-20 row"
  }, [_c('q-select', {
    staticClass: "col-lg-4 col-md-6 col-sm-12",
    attrs: {
      "options": _vm.options.academicYears,
      "label": _vm.trans("contingent-performance-book.filters.academicYear"),
      "bottom-slots": "",
      "filled": "",
      "emit-value": "",
      "map-options": "",
      "placeholder": _vm.trans('select.placeholder')
    },
    on: {
      "input": function input($event) {
        return _vm.requestGetData(_vm.activeTab, true, true);
      }
    },
    model: {
      value: _vm.filters.academicYear,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "academicYear", $$v);
      },
      expression: "filters.academicYear"
    }
  })], 1) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "sc-contingent-performance-book--wrapper"
  }, [_c('transition', {
    attrs: {
      "name": _vm.swipeDirection
    }
  }, [_c(_vm.activeComponent, _vm._g(_vm._b({
    key: _vm.activeTab,
    tag: "component"
  }, 'component', _vm.activeComponentProps, false), _vm.activeComponentEvents))], 1)], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }