var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "s-statement-create__item-inner"
  }, [_c('v-form-inform', {
    attrs: {
      "message": _vm.trans('warning_text.modal_inform')
    }
  }), _vm._v(" "), _c('q-form', {
    staticClass: "mb-10"
  }, [!_vm.forImport ? _c('q-checkbox', {
    attrs: {
      "label": _vm.trans('statement.send_as_parent')
    },
    on: {
      "input": _vm.handleIsParentCheckbox
    },
    model: {
      value: _vm.form.isParent,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "isParent", $$v);
      },
      expression: "form.isParent"
    }
  }) : _vm._e(), _vm._v(" "), _c('q-input', {
    attrs: {
      "required": "",
      "label": _vm.trans('label.iin'),
      "mask": "############",
      "readonly": _vm.access,
      "rules": [function (val) {
        return _vm.validateIin(val) || _vm.trans('validation.check_iin', {
          attribute: _vm.trans('label.iin')
        });
      }],
      "lazy-rules": "",
      "error": _vm.hasValidationFail('iin'),
      "error-message": _vm.getValidationMessage('iin')
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('iin');
      }
    },
    nativeOn: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.requestForCheckIin($event);
      }
    },
    scopedSlots: _vm._u([_vm.form.isParent || _vm.forImport ? {
      key: "after",
      fn: function fn() {
        return [_c('q-btn', {
          attrs: {
            "color": "orange",
            "label": _vm.trans('label.iin_button_text'),
            "no-wrap": "",
            "no-caps": "",
            "loading": _vm.loading,
            "disable": !_vm.checkIinBtnDisable
          },
          on: {
            "click": _vm.requestForCheckIin
          }
        })];
      },
      proxy: true
    } : null], null, true),
    model: {
      value: _vm.form.iin,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "iin", $$v);
      },
      expression: "form.iin"
    }
  }), _vm._v(" "), _c('q-slide-transition', {
    attrs: {
      "duration": 1000
    }
  }, [_vm.access ? _c('div', [_c('q-input', {
    attrs: {
      "required": "",
      "label": _vm.trans('label.surname'),
      "readonly": ""
    },
    model: {
      value: _vm.form.surname,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "surname", $$v);
      },
      expression: "form.surname"
    }
  }), _vm._v(" "), _c('q-input', {
    attrs: {
      "required": "",
      "label": _vm.trans('label.firstname'),
      "readonly": ""
    },
    model: {
      value: _vm.form.firstname,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "firstname", $$v);
      },
      expression: "form.firstname"
    }
  }), _vm._v(" "), _c('q-input', {
    attrs: {
      "label": _vm.trans('label.middle_name'),
      "readonly": ""
    },
    model: {
      value: _vm.form.lastname,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "lastname", $$v);
      },
      expression: "form.lastname"
    }
  }), _vm._v(" "), _c('v-wrapper-q-select', {
    attrs: {
      "options": _vm.options.citizenship,
      "option-value": "value",
      "option-label": "label",
      "extra-binds": {
        required: true,
        labelTransKey: 'label.citizenship'
      }
    },
    model: {
      value: _vm.form.citizenship_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "citizenship_id", $$v);
      },
      expression: "form.citizenship_id"
    }
  }), _vm._v(" "), _c('v-wrapper-q-select', {
    attrs: {
      "options": _vm.options.nationality,
      "option-value": "value",
      "option-label": "label",
      "extra-binds": {
        required: true,
        labelTransKey: 'label.select_nationality'
      }
    },
    model: {
      value: _vm.form.nationality_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "nationality_id", $$v);
      },
      expression: "form.nationality_id"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6 col-12"
  }, [_c('q-input', {
    attrs: {
      "required": "",
      "readonly": "",
      "mask": "##.##.####",
      "label": _vm.trans('label.born_date')
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('q-icon', {
          attrs: {
            "name": "calendar"
          }
        })];
      },
      proxy: true
    }], null, false, 942384392),
    model: {
      value: _vm.form.born_date,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "born_date", $$v);
      },
      expression: "form.born_date"
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-md-6 col-12 pl-40"
  }, [_c('q-field', {
    attrs: {
      "label": _vm.trans('label.gender'),
      "borderless": ""
    }
  }, [_c('q-option-group', {
    staticClass: "d-flex",
    attrs: {
      "inline": "",
      "options": _vm.options.genders
    },
    model: {
      value: _vm.form.sex,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "sex", $$v);
      },
      expression: "form.sex"
    }
  })], 1)], 1)]), _vm._v(" "), _c('q-input', {
    attrs: {
      "required": !_vm.form.isFromCorrection,
      "label": _vm.trans('label.phone'),
      "mask": "+7 (###) ###-##-##",
      "placeholder": "+7 (___) ___-__-__",
      "error": _vm.hasValidationFail('phone'),
      "error-message": _vm.getValidationMessage('phone')
    },
    on: {
      "input": function input($event) {
        return _vm.flashValidationFail('phone');
      }
    },
    model: {
      value: _vm.form.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "phone", $$v);
      },
      expression: "form.phone"
    }
  }), _vm._v(" "), _c('q-checkbox', {
    attrs: {
      "label": _vm.trans('label.after_correction')
    },
    model: {
      value: _vm.form.isFromCorrection,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "isFromCorrection", $$v);
      },
      expression: "form.isFromCorrection"
    }
  }), _vm._v(" "), _vm.form.isParent ? [_c('div', {
    staticClass: "sn-field__label"
  }, [_vm._v("\n              " + _vm._s(_vm.trans('statement.doc_relation')) + ": "), _c('i', [_vm._v("*")])]), _vm._v(" "), _c('div', {
    staticClass: "row align-items-end mb-20"
  }, [_c('div', {
    staticClass: "col-sm-6 col-md-4 col-12",
    class: _vm.$q.screen.width < 576 ? 'mb-10' : ''
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "label": _vm.form.doc_relation.length === 0 ? _vm.trans('button.add') : _vm.trans('label.attached'),
      "color": _vm.form.doc_relation.length === 0 ? 'blue' : 'green',
      "icon-right": _vm.form.doc_relation.length === 0 ? 'plus' : 'clip',
      "label-wider": "",
      "ellipsis": "",
      "no-caps": ""
    },
    on: {
      "click": function click($event) {
        return _vm.triggerFile('doc_relation');
      }
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-sm-6 col-md-8 col-12"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "outline": "",
      "label": _vm.form.doc_relation.length === 0 ? _vm.trans('label.file_not_chosen') : _vm.trans('label.files_downloaded', {
        count: _vm.form.doc_relation.length
      }),
      "label-wider": "",
      "color": "grey",
      "no-caps": "",
      "ellipsis": "",
      "icon-right": _vm.form.doc_relation.length === 0 ? undefined : 'close'
    },
    on: {
      "click": function click($event) {
        return _vm.clearFile('doc_relation');
      }
    }
  })], 1)])] : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "mt-10"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "sn-field__label m-0"
  }, [_vm._v("\n                " + _vm._s(_vm.trans('statement.lgot_type')) + ": "), _c('i', [_vm._v("*")])])]), _vm._v(" "), _c('q-select', {
    attrs: {
      "required": "",
      "options": _vm.options.socialStatuses,
      "multiple": "",
      "emit-value": "",
      "map-options": ""
    },
    on: {
      "add": _vm.filterStatuses
    },
    model: {
      value: _vm.form.soc_status_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "soc_status_id", $$v);
      },
      expression: "form.soc_status_id"
    }
  }), _vm._v(" "), _c('v-form-inform', {
    staticClass: "m-0",
    attrs: {
      "message": _vm.trans('statement.social_statuses_tooltip')
    }
  })], 1), _vm._v(" "), _vm.form.soc_status_id[0] !== 1 ? [_c('div', {
    staticClass: "sn-field__label mt-10"
  }, [_vm._v("\n              " + _vm._s(_vm.trans('label.doc_for_privilege')) + ": "), _c('i', [_vm._v("*")])]), _vm._v(" "), _c('div', {
    staticClass: "row align-items-end mb-20"
  }, [_c('div', {
    staticClass: "col-sm-6 col-md-4 col-12",
    class: _vm.$q.screen.width < 576 ? 'mb-10' : ''
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "label": _vm.form.doc_for_privilege.length === 0 ? _vm.trans('button.add') : _vm.trans('label.attached'),
      "color": _vm.form.doc_for_privilege.length === 0 ? 'blue' : 'green',
      "icon-right": _vm.form.doc_for_privilege.length === 0 ? 'plus' : 'clip',
      "label-wider": "",
      "ellipsis": "",
      "no-caps": ""
    },
    on: {
      "click": function click($event) {
        return _vm.triggerFile('doc_for_privilege');
      }
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-sm-6 col-md-8 col-12"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "outline": "",
      "label": _vm.form.doc_for_privilege.length === 0 ? _vm.trans('label.file_not_chosen') : _vm.trans('label.files_downloaded', {
        count: _vm.form.doc_for_privilege.length
      }),
      "label-wider": "",
      "color": "grey",
      "no-caps": "",
      "ellipsis": "",
      "icon-right": _vm.form.doc_for_privilege.length === 0 ? undefined : 'close'
    },
    on: {
      "click": function click($event) {
        return _vm.clearFile('doc_for_privilege');
      }
    }
  })], 1)])] : _vm._e(), _vm._v(" "), _c('q-select', {
    attrs: {
      "required": "",
      "options": _vm.options.eduLevels,
      "label": _vm.trans('label.edu_level'),
      "emit-value": "",
      "map-options": ""
    },
    on: {
      "input": _vm.clearNextStep
    },
    model: {
      value: _vm.form.education,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "education", $$v);
      },
      expression: "form.education"
    }
  }), _vm._v(" "), _c('q-slide-transition', {
    attrs: {
      "duration": 500
    }
  }, [!_vm.form.noInstitutionInList ? _c('div', [_c('v-wrapper-q-select', {
    attrs: {
      "options": _vm.options.regions,
      "option-value": "value",
      "option-label": "label",
      "extra-binds": {
        required: true,
        labelTransKey: 'label.region'
      }
    },
    on: {
      "input": function input($event) {
        return _vm.getInstitution('region');
      }
    },
    model: {
      value: _vm.form.region,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "region", $$v);
      },
      expression: "form.region"
    }
  }), _vm._v(" "), _c('v-wrapper-q-select', {
    attrs: {
      "options": _vm.educationOptions.departments,
      "option-value": "value",
      "option-label": "label",
      "extra-binds": {
        required: true,
        labelTransKey: 'label.select_department'
      }
    },
    on: {
      "input": _vm.getInstitution
    },
    model: {
      value: _vm.form.department,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "department", $$v);
      },
      expression: "form.department"
    }
  }), _vm._v(" "), _c('v-wrapper-q-select', {
    attrs: {
      "options": _vm.educationOptions.localities,
      "option-value": "value",
      "option-label": "label",
      "extra-binds": {
        required: true,
        labelTransKey: 'label.select_locality'
      }
    },
    on: {
      "input": _vm.getInstitution
    },
    model: {
      value: _vm.form.locality,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "locality", $$v);
      },
      expression: "form.locality"
    }
  }), _vm._v(" "), _c('v-wrapper-q-select', {
    attrs: {
      "options": _vm.educationOptions.educationInstitutions,
      "option-value": "value",
      "option-label": "label",
      "extra-binds": {
        required: true,
        labelTransKey: 'label.previous_education_place'
      }
    },
    model: {
      value: _vm.form.educational_institution_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "educational_institution_id", $$v);
      },
      expression: "form.educational_institution_id"
    }
  })], 1) : _c('q-input', {
    staticClass: "s-ellipsis",
    attrs: {
      "required": "",
      "label": _vm.trans('label.previous_education_place')
    },
    model: {
      value: _vm.form.educational_institution,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "educational_institution", $$v);
      },
      expression: "form.educational_institution"
    }
  })], 1), _vm._v(" "), _c('q-checkbox', {
    attrs: {
      "label": _vm.trans('statement.no_institution_in_list')
    },
    on: {
      "input": _vm.cleanInstitutions
    },
    model: {
      value: _vm.form.noInstitutionInList,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "noInstitutionInList", $$v);
      },
      expression: "form.noInstitutionInList"
    }
  }), _vm._v(" "), _c('q-select', {
    attrs: {
      "required": "",
      "options": _vm.options.eduLangs,
      "label": _vm.trans('statement.edu_lang_school'),
      "emit-value": "",
      "map-options": ""
    },
    model: {
      value: _vm.form.edu_lang,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "edu_lang", $$v);
      },
      expression: "form.edu_lang"
    }
  })], 2) : _vm._e()])], 1), _vm._v(" "), _c('select-user-file', {
    attrs: {
      "open-modal": _vm.modalFiles,
      "url-files": _vm.uriUserFiles,
      "url-file-store": _vm.uriUserFiles,
      "multiple": ""
    },
    on: {
      "input": _vm.selectFile,
      "select-close": _vm.fileModalClose
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }