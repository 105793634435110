import { requestWrapper } from '@vjs/helpers';
import { Loading } from '@quasar/plugins';
import {
  QBtn,
  QIcon,
  QInput,
  QTable,
  QTd,
  QTh,
  QTooltip,
  QTr,
} from '@quasar/components';
import VNoResults from '@vjs/components/VNoResults';
import VConfirmDialogNew from '@vjs/modals/VConfirmDialogNew';
import VFormInform from '@vjs/components/VFormInform';

export default {
  name: 'VOrganizationDisciplinesTable',
  components: {
    QTable,
    QTh,
    QTr,
    QTd,
    QBtn,
    QTooltip,
    QInput,
    QIcon,
    VConfirmDialogNew,
    VNoResults,
    VFormInform,
  },
  props: {
    uriGetData: {
      type: String,
      required: true,
    },
    uriUpdateDiscipline: {
      type: String,
      required: true,
    },
    uriDeleteDiscipline: {
      type: String,
      required: true,
    },
    uriGetDisciplines: {
      type: String,
      required: true,
    },
    uriAddSubjectsForm: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      refs: {
        disciplines: [],
      },
      rows: [],
      qLoader: Loading,
      editDisciplineRowId: null,
      editDisciplineOption: {},
      editDisciplineId: null,
      disciplineSelectInputValue: '',
      disciplinesLoading: false,
      disableVirtualScrolling: false,
      currentDisciplinesPage: 1,
      disciplinesLastPage: 1,
      isModalOpened: false,
      deleteDisciplineId: null,
      nameFilter: null,
    };
  },
  async mounted() {
    await this.$trans.add(['placeholder', 'label', 'table', 'warning_text']);
    await this.loadTable();
    await this.requestGetDisciplines();
  },
  methods: {
    onEditDiscipline(row) {
      this.editDisciplineRowId = row.id;
      this.editDisciplineId = row.educationDisciplineId;
      this.editDisciplineOption = { id: row.educationDisciplineId, name: row.name };
    },
    async loadTable() {
      this.qLoader.show({ message: this.trans('label.wait_for_load') });
      const res = await requestWrapper.call(this, {
        url: this.uriGetData,
      });

      if (!res.error) {
        this.rows = res;
      } else {
        this.$notify({
          text: this.trans('notice.error_on_server'),
          type: 'error',
        });
      }

      this.qLoader.hide();
    },
    async onConfirmModal() {
      this.qLoader.show({ message: this.trans('label.wait_for_load') });

      if (this.deleteDisciplineId) {
        await this.onDeleteDiscipline();
      } else {
        await this.onUpdateDiscipline();
      }
      this.onModalClose();
      this.qLoader.hide();
    },
    onModalClose() {
      this.deleteDisciplineId = null;
      this.isModalOpened = false;
    },
    async onDeleteDisciplineOpenModal(disciplineId) {
      this.isModalOpened = true;
      this.deleteDisciplineId = disciplineId;
    },
    async onDeleteDiscipline() {
      const data = {
        deleteDisciplineId: this.deleteDisciplineId,
      };
      const res = await requestWrapper.call(this, {
        method: 'delete',
        url: this.uriDeleteDiscipline,
        data,
      });

      if (res.data && res.data.errors && res.errorType === 422) {
        this.$notify({
          text: _.first(_.flatten(res.data.errors[_.first(Object.keys(res.data.errors))])) ?? this.trans('notice.error_on_server'),
          type: 'error',
        });
      } else {
        this.rows = this.rows.filter(item => item.id !== this.deleteDisciplineId);
      }
    },
    async onUpdateDisciplineOpenModal() {
      this.isModalOpened = true;
    },
    async onUpdateDiscipline() {
      this.qLoader.show({ message: this.trans('label.wait_for_load') });
      const data = {
        updateDisciplineId: this.editDisciplineRowId,
        newDisciplineId: this.editDisciplineId,
      };

      const res = await requestWrapper.call(this, {
        method: 'post',
        url: this.uriUpdateDiscipline,
        data,
      });

      if (!res.error) {
        const ind = _.findIndex(this.rows, { id: this.editDisciplineRowId });
        this.rows[ind].educationDisciplineId = this.editDisciplineOption.id;
        this.rows[ind].name = this.editDisciplineOption.name;
        this.rows[ind].kzName = this.editDisciplineOption.kzName;
        this.rows[ind].ruName = this.editDisciplineOption.ruName;
      }
      this.qLoader.hide();
      this.onCancelUpdateDiscipline();
    },
    onCancelUpdateDiscipline() {
      this.onEditDiscipline({});
    },
    async requestSearchDisciplines(val, update) {
      this.disciplinesLoading = true;
      this.currentDisciplinesPage = 1;
      await this.requestGetDisciplinesByFilter().then((res) => {
        update(() => {
          this.refs.disciplines = res.items;
          this.disciplinesLoading = false;
        });
      });
    },
    async requestGetDisciplinesByFilter() {
      return new Promise(async (res, rej) => {
        const result = await requestWrapper.call(this, {
          url: this.uriGetDisciplines,
          params: {
            page: 1,
            search: this.disciplineSelectInputValue.toLowerCase(),
          },
        });

        if (!result.error) {
          res(result);
        } else {
          rej(result);
        }
      });
    },
    // Обработка виртуал-скроллинга для селекта предметов
    async onSubScroll({ index }) {
      if (!this.disciplinesLoading
        && this.currentDisciplinesPage < this.disciplinesLastPage
        && index >= (this.refs.disciplines.length - 21)
      ) {
        this.currentDisciplinesPage += 1;
        await this.requestGetDisciplines(false);
        this.$nextTick(() => {
          this.$refs.subjectSelect.$refs['q-select-wrapper-ref'].refresh();
          this.disciplinesLoading = false;
        });
      }
    },
    async requestGetDisciplines(loadingOff = true) {
      this.disciplinesLoading = true;
      const res = await requestWrapper.call(this, {
        url: this.uriGetDisciplines,
        params: {
          page: this.currentDisciplinesPage,
          search: this.disciplineSelectInputValue.toLowerCase(),
        },
      });

      if (!res.error) {
        this.refs.disciplines = [...this.refs.disciplines, ...res.items];
        this.disciplinesLastPage = res.lastPage;
      }
      if (loadingOff) {
        this.disciplinesLoading = false;
      }
    },
  },
  computed: {
    columns() {
      return [
        {
          label: this.$trans.get('table.id'),
          field: 'educationDisciplineId',
          align: 'center',
          style: 'min-width: 75px;',
        },
        {
          label: this.$trans.get('table.ru_name'),
          field: 'ruName',
          align: 'left',
          style: 'max-width:400px; min-width: 390px;',
        },
        {
          label: this.$trans.get('table.kz_name'),
          field: 'kzName',
          align: 'left',
          style: 'max-width:400px; min-width: 390px;',
        },
        {
          label: this.$trans.get('table.operations'),
          field: 'actions',
          align: 'center',
          style: 'width: 14rem',
        },
      ];
    },
  },
};
