import sentry from '@vendor/sentry';
import Translator from '@common/plugins/Translator';
import { eventBus } from '@common/mixins';
import store from '@app_college/store';
import quasarApp from '@quasar/install';
import { Loading } from '@quasar/plugins';
import Notify from '@vjs/components/Notify/Notify';
import Notifications from '@vjs/plugins/Notification';
import axios from 'axios';
import plugin from '@common/plugins';
import {
  QBtn,
  QDialog,
  QForm,
  QInput,
  QSelect,
  QTooltip,
} from '@quasar/components';
import Inputmask from 'inputmask';

export default function (Vue) {
  plugin(Vue);
  const inputmaskPlugin = {
    install(_Vue) {
      _Vue.directive('mask', {
        bind(el, binding) {
          Inputmask(binding.value).mask(el);
        },
      });
    },
  };
  Vue.use(inputmaskPlugin);

  sentry(Vue, 'https://07f63e00afdf49bfba86becb82759eb4@sentry.services.snation.kz/4');

  Vue.use(Translator, { store, eventBus });

  Vue.use(quasarApp, {
    components: {
      QTooltip,
      QInput,
      QForm,
      QDialog,
    },
    plugins: {
      Loading,
    },
  });
  Vue.component('q-btn', QBtn);
  Vue.component('q-select', QSelect);
  Vue.component('notify', Notify);
  Vue.use(Notifications);

  Vue.prototype.$axios = axios;

  if (window.core_project) {
    axios.defaults.headers.common = {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': window.core_project.csrfToken || '',
      'Accept-Language': window.core_project.locale,
    };
  } else {
    // eslint-disable-next-line no-console
    console.log('No window.core_project! You\'re probably testing static components');
  }
}
