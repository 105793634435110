var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sc-journal"
  }, [_c('v-journal-title', {
    attrs: {
      "name": _vm.journalName
    }
  }), _vm._v(" "), _c('v-journal-control'), _vm._v(" "), _vm.isMounted ? [_c('v-journal-table-control'), _vm._v(" "), _c('transition', {
    attrs: {
      "mode": "out-in",
      "name": _vm.swipeDirection
    }
  }, [_vm.isRemarksTableActive ? _c('v-journal-remarks') : _c('div', [_vm.isPersonal && _vm.$store.getters.students.length > 1 ? _c('v-journal-tabs') : _vm._e(), _vm._v(" "), _c('v-journal-table', {
    ref: "table"
  })], 1)], 1)] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }