import { Loading } from '@quasar/plugins';
import VSteps from './VSteps';
import VStepsBody from './VStepsBody';
import VStepUserinfo from './VStepUserinfo';
import VStepQualification from './VStepQualification';
import VStepQualificationPres from './VStepQualificationPres';
import VStepDocuments from './VStepDocuments';
import VStepCongratulations from './VStepCongratulations';
import VStepEduNavigator from './VStepEduNavigator';
import VStepSubjectAssessment from './VStepSubjectAssessment';

export default {
  name: 'VStatementCreateView',
  components: {
    VSteps,
    VStepsBody,
    VStepUserinfo,
    VStepQualification,
    VStepDocuments,
    VStepCongratulations,
    VStepQualificationPres,
    VStepEduNavigator,
    VStepSubjectAssessment,
  },
  props: {
    uriStatic: {
      type: String,
      default: '',
    },
    // user info
    profileExists: {
      type: Boolean,
      required: true,
    },
    uriGetProfile: {
      type: String,
      required: true,
    },
    uriSendProfileUpdate: {
      type: String,
      required: true,
    },
    uriProfileOptions: {
      type: String,
      required: true,
    },
    uriEducationOptions: {
      type: String,
      required: true,
    },
    // step qualification
    uriQualificationData: {
      type: String,
      required: true,
    },
    uriGetSubjectsStatement: {
      type: String,
      required: true,
    },
    uriCheckDuplicateStatements: {
      type: String,
      required: true,
    },
    // final step
    uriSendStatement: {
      type: String,
      required: true,
    },
    uriStatementList: {
      type: String,
      required: true,
    },
    uriUserFiles: {
      type: String,
      required: true,
    },
    withPpz: false,
    forImport: false,
    forGrant: false,
    needSign: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  data() {
    return {
      loading: false,
      qLoading: Loading,
    };
  },

  beforeCreate() {
    this.$trans.add([
      'statement',
      'label',
      'button',
      'placeholder',
      'validation',
      'notice',
      'college',
      'warning_text',
      'main',
      'fields',
    ]);
  },

  created() {
    if (this.forImport) {
      this.$store.commit('SET_HIDDEN_STEP', { step: 1, hidden: true });
      this.$store.commit('SET_HIDDEN_STEP', { step: 2, hidden: true });
      this.$store.commit('SET_CURRENT_STEP', 3);
      this.$store.commit('SET_BACKWARD_STEP', { step: 3, backward: false });
      if (this.withPpz || !this.forGrant) {
        this.$store.commit('SET_HIDDEN_STEP', { step: 5, hidden: true });
        this.$store.commit('SET_ACCESS_STEP', { step: 5, access: false });
        this.$store.commit('SET_VISIBLE_STEP', { step: 5, visible: false });
      }
    }
  },

  computed: {
    current() {
      return this.$store.state.vSteps.currentStep;
    },
  },

  methods: {
    getStepTitle() {
      switch (this.current) {
        // case 2: return this.trans('statement.step_edu_navigator');
        case 3: return this.trans('statement.step_user');
        case 4: return this.trans('statement.step_qualification');
        case 5: return this.trans('fields.points');
        case 6: return this.trans('statement.step_documents');
        default: return '';
      }
    },
    loadingProcessing(status) {
      this.loading = status;
      if (status === true) {
        this.qLoading.show();
      }
      if (status === false) {
        this.qLoading.hide();
      }
    },
    sendUserInfo(data) {
      this.userInfo = data;
    },
  },
};
