var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sc-admission-result"
  }, [_c('div', {
    staticClass: "sc-title-link sc-title-link--small d-flex justify-content-start align-items-start"
  }, [_c('a', {
    attrs: {
      "href": _vm.backLink
    }
  }, [_c('q-icon', {
    attrs: {
      "name": "long-arrow-left",
      "icon-class": "s-ico-fa"
    }
  })], 1), _vm._v(" "), _c('span', {
    class: _vm.$q.screen.width <= 760 ? 's-to-center' : ''
  }, [_vm._v(_vm._s(_vm.tableName))])]), _vm._v(" "), _c('q-table', {
    attrs: {
      "data": _vm.table.rowsFiltered,
      "columns": _vm.table.columns,
      "table-class": "sc-admission-result__table",
      "pagination": _vm.pagination,
      "row-key": "name",
      "hide-header": "",
      "wrap-cells": "",
      "flat": "",
      "no-data-label": _vm.trans('label.no_data'),
      "no-results-label": _vm.trans('label.no_results'),
      "loading": _vm.loading
    },
    on: {
      "update:pagination": function updatePagination($event) {
        _vm.pagination = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "sn-table__top-filters"
        }, [_c('q-input', {
          attrs: {
            "dense": "",
            "debounce": "300",
            "placeholder": _vm.trans('placeholder.search'),
            "color": "blue",
            "input-class": "sc-admission-result__color--blue"
          },
          on: {
            "input": _vm.search
          },
          model: {
            value: _vm.filter,
            callback: function callback($$v) {
              _vm.filter = $$v;
            },
            expression: "filter"
          }
        }, [_c('q-icon', {
          attrs: {
            "slot": "append",
            "name": "search",
            "color": "blue"
          },
          slot: "append"
        })], 1)], 1)];
      }
    }, {
      key: "body",
      fn: function fn(props) {
        return [_c('q-tr', {
          attrs: {
            "props": props
          }
        }, [_vm.$q.screen.width > 760 ? _c('q-td', {
          staticClass: "sc-admission-result__index s-to-center"
        }, [_vm._v("\n          " + _vm._s(props.row.__index) + "\n        ")]) : _vm._e(), _vm._v(" "), _c('q-td', [_vm._v("\n          " + _vm._s(props.row.name) + "\n        ")]), _vm._v(" "), _c('q-td', {
          staticClass: "s-to-right"
        }, _vm._l(props.row.actions, function (action, key) {
          return _c('q-btn', {
            key: key,
            attrs: {
              "color": action.color,
              "to": action.to,
              "icon": action.icon,
              "squire": "",
              "loading": _vm.loading,
              "type": action.type
            }
          }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('button.see')))])], 1);
        }), 1)], 1)];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }