var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sc-journal-plan"
  }, [_c('q-form', {
    attrs: {
      "autocorrect": "off",
      "autocapitalize": "off",
      "autocomplete": "off",
      "spellcheck": "false"
    },
    on: {
      "submit": _vm.handleAjaxFormSubmit
    }
  }, [_vm._l(_vm.table, function (item, key) {
    return _c('div', {
      key: key,
      staticClass: "mb-20"
    }, [key !== 'files' ? [_c('div', {
      staticClass: "sc-journal-plan__title mb-10"
    }, [_vm._v("\n          " + _vm._s(item.label) + ":\n        ")]), _vm._v(" "), _c('ckeditor', {
      attrs: {
        "editor": _vm.editor,
        "config": _vm.editorConfig
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    })] : _vm._e()], 2);
  }), _vm._v(" "), _vm._l(_vm.form, function (file, name) {
    return _c('div', {
      staticClass: "row justify-content-between align-items-end mt-40"
    }, [_c('div', {
      staticClass: "col-lg-3 col-md-6 col-sm-12"
    }, [_c('q-btn', {
      staticClass: "s-w-100",
      attrs: {
        "label": file.length === 0 ? _vm.trans('button.add') : _vm.trans('label.attached'),
        "color": file.length === 0 ? 'blue' : 'green',
        "icon-right": file.length === 0 ? 'plus' : 'clip',
        "label-wider": "",
        "ellipsis": "",
        "no-caps": ""
      },
      on: {
        "click": function click($event) {
          return _vm.triggerFile(name);
        }
      }
    })], 1), _vm._v(" "), _c('div', {
      staticClass: "col-lg-9 col-md-6 col-sm-12"
    }, [_c('q-btn', {
      staticClass: "s-w-100",
      attrs: {
        "outline": "",
        "label": file.length === 0 ? _vm.trans('label.file_not_chosen') : file.name,
        "label-wider": "",
        "color": "grey",
        "no-caps": "",
        "ellipsis": "",
        "icon-right": file.length === 0 ? undefined : 'close'
      },
      on: {
        "click": function click($event) {
          return _vm.clearFile(name);
        }
      }
    })], 1)]);
  }), _vm._v(" "), _c('div', {
    staticClass: "row justify-content-between mb-60 mt-60"
  }, [_c('div', {
    staticClass: "col-lg-3 col-md-6 col-sm-12",
    class: _vm.$q.screen.width < 768 ? 'order-2' : ''
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "to": _vm.backLink,
      "type": "a",
      "color": "grey",
      "label": _vm.trans('button.cancel'),
      "no-caps": ""
    }
  })], 1), _vm._v(" "), _c('div', {
    staticClass: "col-lg-3 col-md-6 col-sm-12 mb-10"
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "type": "submit",
      "color": "green",
      "label": _vm.trans('button.submit'),
      "no-caps": ""
    }
  })], 1)]), _vm._v(" "), _c('select-user-file', {
    attrs: {
      "open-modal": _vm.modalFiles,
      "url-files": _vm.uriUserFiles,
      "url-file-store": _vm.uriUserFiles
    },
    on: {
      "input": _vm.selectFile,
      "select-close": _vm.fileModalClose
    }
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }