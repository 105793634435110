var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-lg-flex justify-content-between align-items-center"
  }, [_c('v-wrapper-q-select', {
    attrs: {
      "options": _vm.options.groups,
      "option-value": "value",
      "option-label": "label"
    },
    on: {
      "input": _vm.requestGetContingentList
    },
    model: {
      value: _vm.filters.group,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "group", $$v);
      },
      expression: "filters.group"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "s-btn--w-200",
    class: _vm.$q.screen.lt.md ? 'mb-20' : ''
  }, [_c('q-btn', {
    staticClass: "s-w-100",
    attrs: {
      "color": "blue",
      "no-caps": "",
      "disable": _vm.table.rows.length == 0,
      "label": _vm.trans('button.export')
    },
    on: {
      "click": _vm.exportContingents
    }
  })], 1)], 1), _vm._v(" "), _vm.table.rows.length > 1 ? _c('div', [_vm._m(0), _vm._v(" "), _c('q-table', {
    attrs: {
      "data": _vm.table.rows,
      "columns": _vm.table.columns,
      "row-key": "name",
      "flat": !_vm.$q.screen.lt.md,
      "grid": _vm.$q.screen.lt.md,
      "pagination": _vm.pagination,
      "no-data-label": _vm.trans('label.no_data'),
      "no-results-label": _vm.trans('label.no_results'),
      "separator": "cell",
      "search": false,
      "wrap-cells": ""
    },
    on: {
      "update:pagination": function updatePagination($event) {
        _vm.pagination = $event;
      }
    }
  })], 1) : _c('v-no-results', {
    attrs: {
      "image": "search",
      "title": _vm.trans("warning_text.".concat(_vm.filters.group && !_vm.loading ? 'no_data_title' : 'no_group_checked_title')),
      "text": _vm.trans(_vm.filters.group && !_vm.loading ? 'warning_text.no_data_label' : '')
    }
  })], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-on-fire__inner--double-scroll"
  }, [_c('div', {
    staticClass: "double-scroll--content"
  })]);
}]

export { render, staticRenderFns }