import Vue from 'vue';
import VueTimeago from 'vue-timeago';
import VInsta from '@vjs/views/VInsta/VInsta.vue';


if (document.getElementById('v-insta-app')) {
  Vue.use(VueTimeago, {
    name: 'Timeago',
    locale: 'ru',
    locales: {
      ru: require('@vjs/views/VInsta/locales/ru/index.js'),
      kz: require('@vjs/views/VInsta/locales/kz/index.js'),
    },
  });
  window.vInstaApp = new Vue({
    el: '#v-insta-app',
    components: { VInsta },
  });
}
