import _ from 'lodash';
import {
  QTooltip,
  QInput,
  QToggle,
  QForm,
  QBtn,
} from '@quasar/components';
import { genFormDataArray } from '@vjs/helpers';
import { mapGetters } from 'vuex';
import { required } from '@vjs/validations';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import changeAnswerCorrectHelper from '../../helpers/changeAnswerCorrectHelper';
import filterDisabledAnswersHelper from '../../helpers/filterDisabledAnswersHelper';

export default {
  name: 'VExamQuestionAdd',
  components: {
    QTooltip,
    QInput,
    QToggle,
    QForm,
    QBtn,
  },
  created() {
    this.dataQuestion.answers.push({ answer: '', correct: 1, weight: 0, disable: true });
    this.dataQuestion.answers.push({ answer: '', correct: 0, weight: 0, disable: true });
    this.$store.commit('setQuestion', this.dataQuestion);
  },
  props: {
    group: {
      type: Object,
    },
  },
  data() {
    return {
      storeData: this.$store,
      dataQuestion: {
        question: '',
        answers: [],
      },
      editor: ClassicEditor,
      editorConfig: {
        language: 'ru',
        toolbar: {
          items: [
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            'imageUpload',
            'blockQuote',
            'insertTable',
            'undo',
            'redo',
            'math'
          ],
        },
        image: {
          toolbar: ['toggleImageCaption', 'imageTextAlternative']
        },
      },
      UploadAdapter(loader, store) {
        this.upload = () => loader.file
          .then(file => new Promise((resolve) => {
            const formData = new FormData();
            _.each(loader, (item, index) => {
              formData.append(index, item);
            });
            store.dispatch('FILE_QUESTION', { payload: formData, file, resolve });
          }));
        this.abort = () => {
          console.log('Abort upload.');
        };
      },
      disableSave: false,
    };
  },
  watch: {
  },
  mounted() {
  },
  computed: {
    ...mapGetters([
      'getQuestion',
    ]),
  },
  methods: {
    required: message => val => required(message)(val),
    onEditorReady(instance) {
      const fileRepository = instance.plugins.get('FileRepository');

      if (fileRepository != null) {
        fileRepository.createUploadAdapter = loader => new this.UploadAdapter(
          loader, this.storeData,
        );
      }
    },
    cancelQuestion() {
      this.$store.commit('setQuestionAdd');
    },
    async questionAddForm() {
      let vm = this;

      if (vm.disableSave) return;
      const data = genFormDataArray({
        question: this.dataQuestion.question,
        answers: this.dataQuestion.answers,
      });
      vm.disableSave = true;
      const res = await this.$store.dispatch('CREATE_QUESTION', data);

      let text = this.trans(res.text);
      if (res.type === 'error') {
        text += ': ';
        _.forEach(res.data.errors, (item) => {
          text += `${item[0]}<br/>`;
        });
      }
      this.$notify({
        text,
        type: res.type,
      });
      vm.disableSave = false;
      if (res.type !== 'error') {
        window.location.reload();
      }
    },
    newAnswer() {
      this.dataQuestion.answers.push({ answer: '', correct: 0, weight: 0, disable: false });
      filterDisabledAnswersHelper(this.dataQuestion);
      this.$store.commit('setQuestion', this.dataQuestion);
    },
    deleteNewAnswer(key) {
      this.dataQuestion.answers.splice(key, 1);
      filterDisabledAnswersHelper(this.dataQuestion);
      this.$store.commit('setQuestion', this.dataQuestion);
    },
    largerTwo() {
      return this.getQuestion.answers.length > 2;
    },
    changeAnswerCorrect(el) {
      this.dataQuestion.answers = changeAnswerCorrectHelper(this.dataQuestion, el);
    },
  },
};
