var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sc-journal__remarks"
  }, [!_vm.cans.changeRemarks && !_vm.rows.length ? _c('v-no-results', {
    staticClass: "s-w-100",
    attrs: {
      "image": "search",
      "title": _vm.trans('warning_text.no_result_title'),
      "text": _vm.trans('warning_text.no_result_text')
    }
  }) : _c('q-table', {
    staticClass: "s-b-white",
    attrs: {
      "data": _vm.rows,
      "columns": _vm.cols,
      "row-key": "id",
      "flat": _vm.$q.screen.width > 767,
      "grid": _vm.$q.screen.width <= 767,
      "no-data-label": _vm.trans('label.no_data'),
      "separator": "vertical",
      "wrap-cells": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn(props) {
        return [_c('q-tr', {
          attrs: {
            "props": props
          }
        }, _vm._l(props.cols, function (col) {
          return _c('q-th', {
            key: col.name,
            style: {
              width: "".concat(col.width, "px")
            },
            attrs: {
              "props": props
            }
          }, [_vm._v("\n          " + _vm._s(col.label) + "\n        ")]);
        }), 1)];
      }
    }, {
      key: "body",
      fn: function fn(props) {
        return [_c('q-tr', {
          attrs: {
            "props": props
          }
        }, [_vm._l(props.cols, function (col, i) {
          return [col.name !== '__index' && col.name !== 'actions' ? _c('q-td', {
            key: "".concat(i, "-").concat(props.row.__index),
            class: {
              's-to-center': col.name !== 'description'
            }
          }, [_vm._v("\n            " + _vm._s(props.row[col.name]) + "\n          ")]) : _vm._e(), _vm._v(" "), col.name === 'actions' ? _c('q-td', {
            key: col.name,
            staticClass: "s-to-center"
          }, [_vm.cans.changeRemarks || !props.row.isViewed ? _c('q-btn', {
            attrs: {
              "color": "blue",
              "icon": _vm.cans.changeRemarks ? 'trash' : 'check',
              "squire": "",
              "outline": ""
            },
            on: {
              "click": function click($event) {
                return _vm.openConfirmModal(props.row);
              }
            }
          }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans("label.".concat(_vm.cans.changeRemarks ? 'delete_remark' : 'check_remark'))))])], 1) : _vm._e()], 1) : _vm._e()];
        })], 2)];
      }
    }, _vm.cans.changeRemarks ? {
      key: "bottom-row",
      fn: function fn() {
        return [_c('q-tr', [_c('q-td', {
          staticClass: "s-to-center"
        }, [_vm._v("\n          " + _vm._s(_vm.isAddingNewRemark ? _vm.newRemark.date : null) + "\n        ")]), _vm._v(" "), _c('q-td', [_vm.isAddingNewRemark ? _c('q-input', {
          model: {
            value: _vm.newRemark.description,
            callback: function callback($$v) {
              _vm.$set(_vm.newRemark, "description", $$v);
            },
            expression: "newRemark.description"
          }
        }) : _vm._e()], 1), _vm._v(" "), _c('q-td', {
          staticClass: "s-to-center"
        }, [_vm._v("\n          " + _vm._s(_vm.isAddingNewRemark ? _vm.newRemark.creator : null) + "\n        ")]), _vm._v(" "), _c('q-td', {
          staticClass: "s-to-center"
        }, [_vm.isAddingNewRemark ? _c('div', {
          staticClass: "s-group s-group--align-center justify-content-center"
        }, [_c('q-btn', {
          attrs: {
            "color": "green",
            "icon": "check",
            "squire": "",
            "outline": "",
            "disable": !_vm.newRemark.description
          },
          on: {
            "click": _vm.handleCreateRemark
          }
        }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('label.save_remark')))])], 1), _vm._v(" "), _c('q-btn', {
          attrs: {
            "color": "red",
            "icon": "close",
            "squire": "",
            "outline": ""
          },
          on: {
            "click": _vm.handleCancelCreation
          }
        }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('label.cancel_remark_creation')))])], 1)], 1) : _c('q-btn', {
          attrs: {
            "color": "blue",
            "icon": "plus",
            "squire": "",
            "outline": ""
          },
          on: {
            "click": function click($event) {
              _vm.isAddingNewRemark = true;
            }
          }
        }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('label.create_remark')))])], 1)], 1)], 1)];
      },
      proxy: true
    } : null, {
      key: "item",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "sn-table__grid-item col-xs-12 col-sm-6 col-md-4 col-lg-4"
        }, [_c('div', {
          staticClass: "sn-table__grid-item-card sn-table__card"
        }, [_c('div', {
          staticClass: "sn-table__grid-item-row"
        }, [_c('div', {
          staticClass: "sn-table__grid-item-value"
        }, [_vm._v("\n              " + _vm._s("\u2116 ".concat(props.row.__index)) + "\n            ")])]), _vm._v(" "), _vm._l(props.cols, function (col, i) {
          return [_c('div', {
            key: i,
            staticClass: "sn-table__grid-item-row"
          }, [_c('div', {
            staticClass: "sn-table__grid-item-title"
          }, [_vm._v("\n                " + _vm._s(col.label) + "\n              ")]), _vm._v(" "), _c('div', {
            staticClass: "sn-table__grid-item-value"
          }, [col.name === 'actions' ? _c('div', {
            staticClass: "s-group s-group--to-center"
          }, [_vm.cans.changeRemarks || !props.row.isViewed ? _c('q-btn', {
            attrs: {
              "color": "blue",
              "icon": _vm.cans.changeRemarks ? 'trash' : 'check',
              "squire": "",
              "outline": ""
            },
            on: {
              "click": function click($event) {
                return _vm.openConfirmModal(props.row);
              }
            }
          }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans("label.".concat(_vm.cans.changeRemarks ? 'delete_remark' : 'check_remark'))))])], 1) : _vm._e()], 1) : [_vm._v("\n                  " + _vm._s(col.value) + "\n                ")]], 2)])];
        })], 2)])];
      }
    }, _vm.cans.changeRemarks ? {
      key: "grid-bottom-item",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "sn-table__grid-item col-xs-12 col-sm-6 col-md-4 col-lg-4"
        }, [_c('div', {
          staticClass: "sn-table__grid-item-card sn-table__card"
        }, [_vm._l(props.cols, function (col, i) {
          return [_c('div', {
            key: i,
            staticClass: "sn-table__grid-item-row"
          }, [_c('div', {
            staticClass: "sn-table__grid-item-title"
          }, [_vm._v("\n                " + _vm._s(col.label) + "\n              ")]), _vm._v(" "), _c('div', {
            staticClass: "sn-table__grid-item-value"
          }, [col.name === 'actions' ? _c('div', {
            staticClass: "s-group s-group--to-center"
          }, [_vm.isAddingNewRemark ? [_c('q-btn', {
            attrs: {
              "color": "green",
              "icon": "check",
              "squire": "",
              "outline": "",
              "disable": !_vm.newRemark.description
            },
            on: {
              "click": _vm.handleCreateRemark
            }
          }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('label.save_remark')))])], 1), _vm._v(" "), _c('q-btn', {
            attrs: {
              "color": "red",
              "icon": "close",
              "squire": "",
              "outline": ""
            },
            on: {
              "click": _vm.handleCancelCreation
            }
          }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('label.cancel_remark_creation')))])], 1)] : _c('q-btn', {
            attrs: {
              "color": "blue",
              "icon": "plus",
              "squire": "",
              "outline": ""
            },
            on: {
              "click": function click($event) {
                _vm.isAddingNewRemark = true;
              }
            }
          }, [_c('q-tooltip', [_vm._v(_vm._s(_vm.trans('label.create_remark')))])], 1)], 2) : _vm.isAddingNewRemark ? [col.name === 'date' ? [_vm._v("\n                    " + _vm._s(_vm.newRemark.date) + "\n                  ")] : _vm._e(), _vm._v(" "), col.name === 'description' ? [_c('q-input', {
            model: {
              value: _vm.newRemark.description,
              callback: function callback($$v) {
                _vm.$set(_vm.newRemark, "description", $$v);
              },
              expression: "newRemark.description"
            }
          })] : _vm._e(), _vm._v(" "), col.name === 'creator' ? [_vm._v("\n                    " + _vm._s(_vm.newRemark.creator) + "\n                  ")] : _vm._e()] : _vm._e()], 2)])];
        })], 2)])];
      }
    } : null], null, true)
  }), _vm._v(" "), _c('v-confirm-dialog', {
    attrs: {
      "modal": _vm.modal,
      "confirm": _vm.handleConfirmModal
    },
    on: {
      "modal-close": function modalClose($event) {
        _vm.modal = $event;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }