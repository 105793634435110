var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sc-contingent-performance-book__card"
  }, [_c('div', {
    staticClass: "sc-contingent-performance-book__card--title",
    domProps: {
      "innerHTML": _vm._s(_vm.title)
    }
  }), _vm._v(" "), _vm._l(_vm.data, function (item, key) {
    return [_c('div', {
      key: "".concat(key, "-label"),
      staticClass: "sc-contingent-performance-book__card--label"
    }, [_vm._v("\n      " + _vm._s(item.label) + "\n    ")]), _vm._v(" "), _c('div', {
      key: "".concat(key, "-value"),
      staticClass: "sc-contingent-performance-book__card--value"
    }, [_vm._v("\n      " + _vm._s(item.value) + "\n    ")])];
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }