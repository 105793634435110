var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('q-form', {
    staticClass: "sc-journal__day-form-modal",
    attrs: {
      "autocorrect": "off",
      "autocapitalize": "off",
      "autocomplete": "off",
      "spellcheck": "false"
    },
    on: {
      "submit": _vm.handleAjaxFormSubmit
    }
  }, [_vm._l(_vm.fields, function (field) {
    return _c('q-slide-transition', {
      key: field.name
    }, [_vm.handleVif(field) ? _c(field.component, _vm._g({
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.handleVShow(field),
        expression: "handleVShow(field)"
      }],
      tag: "component",
      attrs: {
        "field": field,
        "field-name": field.labelTransKey ? _vm.trans(field.labelTransKey) : field.label,
        "errors": _vm.getErrors(field.name)
      },
      model: {
        value: field.value,
        callback: function callback($$v) {
          _vm.$set(field, "value", $$v);
        },
        expression: "field.value"
      }
    }, field.events ? field.events : {})) : _vm._e()], 1);
  }), _vm._v(" "), _c('div', {
    staticClass: "s-group s-group--wider justify-content-between s-group--equal"
  }, [_c('q-btn', {
    attrs: {
      "no-caps": "",
      "color": "grey",
      "label": _vm.trans('button.cancel')
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('onClose');
      }
    }
  }), _vm._v(" "), _vm.day && _vm.day.canDestroy ? _c('q-btn', {
    directives: [{
      name: "s-modal",
      rawName: "v-s-modal",
      value: {
        id: 'v-modal-confirm',
        title: _vm.trans('notice.are_you_sure'),
        component: 'v-modal-confirm',
        events: {
          onApprove: _vm.confirmDelete
        }
      },
      expression: "{\n        id: 'v-modal-confirm',\n        title: trans('notice.are_you_sure'),\n        component: 'v-modal-confirm',\n        events: { onApprove: confirmDelete },\n      }"
    }],
    attrs: {
      "no-caps": "",
      "color": "red",
      "label": _vm.trans('button.remove')
    }
  }) : _vm._e(), _vm._v(" "), _c('q-btn', {
    attrs: {
      "color": "blue",
      "no-caps": "",
      "disable": !_vm.validate,
      "label": _vm.trans('button.send'),
      "type": "submit"
    }
  })], 1), _vm._v(" "), _vm.day && _vm.day.uriDayPlanEdit && _vm.day.canAddPlan ? _c('q-btn', {
    staticClass: "s-w-100 mt-10",
    attrs: {
      "type": "a",
      "target": "_blank",
      "to": _vm.day.uriDayPlanEdit,
      "color": "green",
      "no-caps": "",
      "label": _vm.trans('button.lesson_plan')
    }
  }) : _vm._e(), _vm._v(" "), _vm.day && (_vm.day.canAddHomework || _vm.day.uriHomeworkShow) ? _c('q-btn', {
    staticClass: "s-w-100 mt-10",
    attrs: {
      "type": "a",
      "target": "_blank",
      "to": _vm.day.uriHomeworkShow || _vm.day.uriHomeworkCreate,
      "color": "orange",
      "no-caps": "",
      "label": _vm.day.uriHomeworkShow ? _vm.trans('button.show_homework') : _vm.trans('button.add_homework')
    }
  }) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }