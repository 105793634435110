export default {
  userInfo: null,
  signXml: null,
  eduNavigator: null,
  qualificationInfo: null,
  qualificationFormValues: null,
  qualificationOptions: null,
  userInfoSchool: null,
  subjectsInfo: null,
  currentStep: 2,
  steps: {
    information: {
      step: 1,
      access: false,
      backward: false,
      label: 'statement.step_info',
      image: 'images/college/statement/step_information_active.svg',
      activeImage: 'images/college/statement/step_information_active.svg',
      visible: false,
      hidden: false,
    },
    edu_navigator: {
      step: 2,
      access: true,
      backward: false,
      label: 'statement.step_edu_navigator',
      image: 'images/college/statement/step_edu_navigator.svg',
      activeImage: 'images/college/statement/step_edu_navigator_active.svg',
      visible: true,
      hidden: false,
    },
    user: {
      step: 3,
      access: false,
      backward: true,
      label: 'statement.step_user',
      image: 'images/college/statement/step_user.svg',
      activeImage: 'images/college/statement/step_user_active.svg',
      visible: true,
      hidden: false,
    },
    qualification: {
      step: 4,
      access: false,
      backward: true,
      label: 'statement.step_qualification',
      image: 'images/college/statement/step_qualification.svg',
      activeImage: 'images/college/statement/step_qualification_active.svg',
      visible: true,
      hidden: false,
    },
    subjects: {
      step: 5,
      access: false,
      backward: true,
      label: 'fields.points',
      image: 'images/college/statement/step_documents.svg',
      activeImage: 'images/college/statement/step_documents_active.svg',
      visible: true,
      hidden: false,
    },
    documents: {
      step: 6,
      access: false,
      backward: true,
      label: 'statement.step_documents',
      image: 'images/college/statement/step_docs.svg',
      activeImage: 'images/college/statement/step_docs_active.svg',
      visible: true,
      hidden: false,
    },
  },
};
